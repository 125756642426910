import { useEffect ,useState} from 'react';
import Notification from 'ui-components/Notification';
import { Accordion, ThemeProvider, createTheme } from '@aws-amplify/ui-react';
import {UserPropertiesUpdateForm} from 'ui-components/UserPropertiesUpdateForm';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import React from 'react';
import config from '../aws-exports';
Amplify.configure(config);


const loadStripeScript = () => {
  const script = document.createElement('script');
  script.src = "https://js.stripe.com/v3/pricing-table.js";
  script.async = true;
  document.body.appendChild(script);
};
const printUserAttributes = async () => {
  try {
    const userAttributes = await fetchUserAttributes();
    console.log('User attributes:', userAttributes);
    console.log('Email:', userAttributes.email);
  }
  catch (e) { console.log(e); }
};




const Test = () => {

  useEffect(() => {
    printUserAttributes();
   // loadStripeScript();
  })

  const handleOnClick = () => {
    console.log('button clicked');
  }


  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
    {/*      <stripe-pricing-table pricing-table-id="prctbl_1PA7VzB4RVLkPCccaArKVsVi"
        publishable-key="pk_test_51OnXu9B4RVLkPCcc5Yg5BR3zngaAjWFSWQDLSiSbOs6I3cbNOXoivTQn5rhGv97N6GK8NUoTaiaAeTT4nQjYaYPH00cPcGZjvM">
      </stripe-pricing-table>
*/}

  <div className='text-md'>Test</div>

  <div className='text-xl'><button onClick={handleOnClick}>subscribe</button></div>


</div>


  );
};

export default Test;
