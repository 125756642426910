/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getProfile } from "../graphql/queries";
import { updateProfile } from "../graphql/mutations";
const client = generateClient();
export default function ProfileUpdateForm(props) {
  const {
    id: idProp,
    profile: profileModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    account_owner: "",
    profile_name: "",
    company_description: "",
    purpose_of_campaign: "",
    call_to_action: "",
    contact_person: "",
  };
  const [account_owner, setAccount_owner] = React.useState(
    initialValues.account_owner
  );
  const [profile_name, setProfile_name] = React.useState(
    initialValues.profile_name
  );
  const [company_description, setCompany_description] = React.useState(
    initialValues.company_description
  );
  const [purpose_of_campaign, setPurpose_of_campaign] = React.useState(
    initialValues.purpose_of_campaign
  );
  const [call_to_action, setCall_to_action] = React.useState(
    initialValues.call_to_action
  );
  const [contact_person, setContact_person] = React.useState(
    initialValues.contact_person
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = profileRecord
      ? { ...initialValues, ...profileRecord }
      : initialValues;
    setAccount_owner(cleanValues.account_owner);
    setProfile_name(cleanValues.profile_name);
    setCompany_description(cleanValues.company_description);
    setPurpose_of_campaign(cleanValues.purpose_of_campaign);
    setCall_to_action(cleanValues.call_to_action);
    setContact_person(cleanValues.contact_person);
    setErrors({});
  };
  const [profileRecord, setProfileRecord] = React.useState(profileModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getProfile.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getProfile
        : profileModelProp;
      setProfileRecord(record);
    };
    queryData();
  }, [idProp, profileModelProp]);
  React.useEffect(resetStateValues, [profileRecord]);
  const validations = {
    account_owner: [],
    profile_name: [{ type: "Required" }],
    company_description: [{ type: "Required" }],
    purpose_of_campaign: [],
    call_to_action: [],
    contact_person: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          account_owner: account_owner ?? null,
          profile_name,
          company_description,
          purpose_of_campaign: purpose_of_campaign ?? null,
          call_to_action: call_to_action ?? null,
          contact_person: contact_person ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateProfile.replaceAll("__typename", ""),
            variables: {
              input: {
                id: profileRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ProfileUpdateForm")}
      {...rest}
    >
      <TextField
        label="Account owner"
        isRequired={false}
        isReadOnly={false}
        value={account_owner}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner: value,
              profile_name,
              company_description,
              purpose_of_campaign,
              call_to_action,
              contact_person,
            };
            const result = onChange(modelFields);
            value = result?.account_owner ?? value;
          }
          if (errors.account_owner?.hasError) {
            runValidationTasks("account_owner", value);
          }
          setAccount_owner(value);
        }}
        onBlur={() => runValidationTasks("account_owner", account_owner)}
        errorMessage={errors.account_owner?.errorMessage}
        hasError={errors.account_owner?.hasError}
        {...getOverrideProps(overrides, "account_owner")}
      ></TextField>
      <TextField
        label="Profile name"
        isRequired={true}
        isReadOnly={false}
        value={profile_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              profile_name: value,
              company_description,
              purpose_of_campaign,
              call_to_action,
              contact_person,
            };
            const result = onChange(modelFields);
            value = result?.profile_name ?? value;
          }
          if (errors.profile_name?.hasError) {
            runValidationTasks("profile_name", value);
          }
          setProfile_name(value);
        }}
        onBlur={() => runValidationTasks("profile_name", profile_name)}
        errorMessage={errors.profile_name?.errorMessage}
        hasError={errors.profile_name?.hasError}
        {...getOverrideProps(overrides, "profile_name")}
      ></TextField>
      <TextField
        label="Company description"
        isRequired={true}
        isReadOnly={false}
        value={company_description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              profile_name,
              company_description: value,
              purpose_of_campaign,
              call_to_action,
              contact_person,
            };
            const result = onChange(modelFields);
            value = result?.company_description ?? value;
          }
          if (errors.company_description?.hasError) {
            runValidationTasks("company_description", value);
          }
          setCompany_description(value);
        }}
        onBlur={() =>
          runValidationTasks("company_description", company_description)
        }
        errorMessage={errors.company_description?.errorMessage}
        hasError={errors.company_description?.hasError}
        {...getOverrideProps(overrides, "company_description")}
      ></TextField>
      <TextField
        label="Purpose of campaign"
        isRequired={false}
        isReadOnly={false}
        value={purpose_of_campaign}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              profile_name,
              company_description,
              purpose_of_campaign: value,
              call_to_action,
              contact_person,
            };
            const result = onChange(modelFields);
            value = result?.purpose_of_campaign ?? value;
          }
          if (errors.purpose_of_campaign?.hasError) {
            runValidationTasks("purpose_of_campaign", value);
          }
          setPurpose_of_campaign(value);
        }}
        onBlur={() =>
          runValidationTasks("purpose_of_campaign", purpose_of_campaign)
        }
        errorMessage={errors.purpose_of_campaign?.errorMessage}
        hasError={errors.purpose_of_campaign?.hasError}
        {...getOverrideProps(overrides, "purpose_of_campaign")}
      ></TextField>
      <TextField
        label="Call to action"
        isRequired={false}
        isReadOnly={false}
        value={call_to_action}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              profile_name,
              company_description,
              purpose_of_campaign,
              call_to_action: value,
              contact_person,
            };
            const result = onChange(modelFields);
            value = result?.call_to_action ?? value;
          }
          if (errors.call_to_action?.hasError) {
            runValidationTasks("call_to_action", value);
          }
          setCall_to_action(value);
        }}
        onBlur={() => runValidationTasks("call_to_action", call_to_action)}
        errorMessage={errors.call_to_action?.errorMessage}
        hasError={errors.call_to_action?.hasError}
        {...getOverrideProps(overrides, "call_to_action")}
      ></TextField>
      <TextField
        label="Contact person"
        isRequired={false}
        isReadOnly={false}
        value={contact_person}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              profile_name,
              company_description,
              purpose_of_campaign,
              call_to_action,
              contact_person: value,
            };
            const result = onChange(modelFields);
            value = result?.contact_person ?? value;
          }
          if (errors.contact_person?.hasError) {
            runValidationTasks("contact_person", value);
          }
          setContact_person(value);
        }}
        onBlur={() => runValidationTasks("contact_person", contact_person)}
        errorMessage={errors.contact_person?.errorMessage}
        hasError={errors.contact_person?.hasError}
        {...getOverrideProps(overrides, "contact_person")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || profileModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || profileModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
