/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function FeaturesV2breakpoint4(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="45px"
      height="45px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 45, height: 45 }}
      paths={[
        {
          d: "M16.875 24.375L20.625 24.375L20.625 28.125L16.875 28.125L16.875 24.375ZM16.875 9.375L20.625 9.375L20.625 20.625L16.875 20.625L16.875 9.375ZM18.7312 0C8.38125 0 0 8.4 0 18.75C0 29.1 8.38125 37.5 18.7312 37.5C29.1 37.5 37.5 29.1 37.5 18.75C37.5 8.4 29.1 0 18.7312 0ZM18.75 33.75C10.4625 33.75 3.75 27.0375 3.75 18.75C3.75 10.4625 10.4625 3.75 18.75 3.75C27.0375 3.75 33.75 10.4625 33.75 18.75C33.75 27.0375 27.0375 33.75 18.75 33.75Z",
          fill: "rgba(211,47,47,1)",
          fillRule: "nonzero",
          style: { transform: "translate(8.33%, 8.33%)" },
        },
      ]}
      {...getOverrideProps(overrides, "FeaturesV2breakpoint4")}
      {...rest}
    ></Icon>
  );
}
