/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Flex,
  Loader,
  Text,
  TextAreaField,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function WaitingForConfirmation(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        TextAreaField: {},
        "Frame 4393": {},
        Benutzername: {},
        "Frame 445575261795": {},
        user_name: {},
        "Frame 445565261794": {},
        "Frame 44582": {},
        "verbleibende Nachrichten": {},
        "Frame 445575573874": {},
        remaining_ph: {},
        "Frame 445565573876": {},
        FrameRemainingMsgs: {},
        "Frame 44558": {},
        MyIcon5236720: {},
        FrameReject: {},
        MyIcon5236722: {},
        FrameAccept: {},
        DecisionFrame: {},
        Loader: {},
        "Frame 44458": {},
        "Frame 44453": {},
        Score: {},
        "Frame 44560": {},
        score_placeholder: {},
        "Frame 44559": {},
        "Frame 44561": {},
        "Frame 4426": {},
        Layout: {},
        WaitingForConfirmation: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        TextAreaField: { width: "unset", alignSelf: "stretch" },
        "Frame 4393": {},
        Benutzername: {},
        "Frame 445575261795": {
          justifyContent: "center",
          padding: "0px 3px 0px 3px",
        },
        user_name: {},
        "Frame 445565261794": {},
        "Frame 44582": { width: "unset" },
        "verbleibende Nachrichten": {},
        "Frame 445575573874": {},
        remaining_ph: {},
        "Frame 445565573876": {},
        FrameRemainingMsgs: {},
        "Frame 44558": {
          direction: "column",
          height: "unset",
          justifyContent: "center",
        },
        MyIcon5236720: {},
        FrameReject: {},
        MyIcon5236722: {},
        FrameAccept: {},
        DecisionFrame: {},
        Loader: {},
        "Frame 44458": {},
        "Frame 44453": { shrink: "0" },
        Score: {},
        "Frame 44560": {},
        score_placeholder: {},
        "Frame 44559": {},
        "Frame 44561": {},
        "Frame 4426": { shrink: "0" },
        Layout: { shrink: "1", grow: "1", basis: "0" },
        WaitingForConfirmation: { width: "529px", height: "745px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="1130px"
      height="529px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="20px 0px 20px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "WaitingForConfirmation")}
      {...rest}
    >
      <Flex
        gap="12px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="20px 40px 20px 40px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          borderRadius="4px"
          padding="4px 4px 4px 4px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 4393")}
        >
          <TextAreaField
            width="755px"
            height="284px"
            placeholder="text_holder_wfc"
            shrink="0"
            borderRadius="4px"
            backgroundColor="rgba(245,245,245,1)"
            size="default"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "TextAreaField")}
          ></TextAreaField>
        </Flex>
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 4426")}
        >
          <Flex
            gap="2px"
            direction="row"
            width="unset"
            height="61.87px"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44558")}
          >
            <Flex
              gap="2px"
              direction="column"
              width="218.95px"
              height="61.87px"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44582")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445575261795")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="218.08px"
                  height="30px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Benutzername"
                  {...getOverrideProps(overrides, "Benutzername")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445565261794")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="218.95px"
                  height="30px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="user_name"
                  {...getOverrideProps(overrides, "user_name")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="2px"
              direction="column"
              width="unset"
              height="61.87px"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "FrameRemainingMsgs")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="0px 3px 0px 3px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445575573874")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="218.08px"
                  height="30px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="verbleibende Nachrichten"
                  {...getOverrideProps(overrides, "verbleibende Nachrichten")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445565573876")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="218.95px"
                  height="30px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="remaining_ph"
                  {...getOverrideProps(overrides, "remaining_ph")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44453")}
          >
            <Flex
              gap="20px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "DecisionFrame")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "FrameReject")}
              >
                <MyIcon
                  width="50px"
                  height="50px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  borderRadius="50px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(211,47,47,1)"
                  type="type37"
                  {...getOverrideProps(overrides, "MyIcon5236720")}
                ></MyIcon>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "FrameAccept")}
              >
                <MyIcon
                  width="50px"
                  height="50px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  borderRadius="50px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(46,125,50,1)"
                  type="checkmark"
                  {...getOverrideProps(overrides, "MyIcon5236722")}
                ></MyIcon>
              </Flex>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44458")}
            >
              <Loader
                width="20.92px"
                shrink="0"
                size="large"
                variation="circular"
                {...getOverrideProps(overrides, "Loader")}
              ></Loader>
            </Flex>
          </Flex>
          <Flex
            gap="2px"
            direction="column"
            width="unset"
            height="61.87px"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="none"
            {...getOverrideProps(overrides, "Frame 44561")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="220.07px"
              height="unset"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              border="1px SOLID rgba(245,245,245,1)"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44560")}
            >
              <Text
                fontFamily="Inter"
                fontSize="17.5px"
                fontWeight="400"
                color="rgba(245,245,245,1)"
                lineHeight="26.25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="220.07px"
                height="30px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Score"
                {...getOverrideProps(overrides, "Score")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="218.92px"
              height="unset"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(245,245,245,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44559")}
            >
              <Text
                fontFamily="Inter"
                fontSize="17.5px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="26.25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="218.92px"
                height="30px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="score_placeholder"
                {...getOverrideProps(overrides, "score_placeholder")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
