/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getIgAccounts } from "../graphql/queries";
import { updateIgAccounts } from "../graphql/mutations";
const client = generateClient();
export default function IgAccountsUpdateForm(props) {
  const {
    id: idProp,
    igAccounts: igAccountsModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    username: "",
    password: "",
    proxy_url: "",
    account_owner: "",
    createdAt: "",
    updatedAt: "",
    is_active: false,
  };
  const [username, setUsername] = React.useState(initialValues.username);
  const [password, setPassword] = React.useState(initialValues.password);
  const [proxy_url, setProxy_url] = React.useState(initialValues.proxy_url);
  const [account_owner, setAccount_owner] = React.useState(
    initialValues.account_owner
  );
  const [createdAt, setCreatedAt] = React.useState(initialValues.createdAt);
  const [updatedAt, setUpdatedAt] = React.useState(initialValues.updatedAt);
  const [is_active, setIs_active] = React.useState(initialValues.is_active);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = igAccountsRecord
      ? { ...initialValues, ...igAccountsRecord }
      : initialValues;
    setUsername(cleanValues.username);
    setPassword(cleanValues.password);
    setProxy_url(cleanValues.proxy_url);
    setAccount_owner(cleanValues.account_owner);
    setCreatedAt(cleanValues.createdAt);
    setUpdatedAt(cleanValues.updatedAt);
    setIs_active(cleanValues.is_active);
    setErrors({});
  };
  const [igAccountsRecord, setIgAccountsRecord] =
    React.useState(igAccountsModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getIgAccounts.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getIgAccounts
        : igAccountsModelProp;
      setIgAccountsRecord(record);
    };
    queryData();
  }, [idProp, igAccountsModelProp]);
  React.useEffect(resetStateValues, [igAccountsRecord]);
  const validations = {
    username: [],
    password: [],
    proxy_url: [],
    account_owner: [],
    createdAt: [],
    updatedAt: [],
    is_active: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          username: username ?? null,
          password: password ?? null,
          proxy_url: proxy_url ?? null,
          account_owner: account_owner ?? null,
          createdAt: createdAt ?? null,
          updatedAt: updatedAt ?? null,
          is_active: is_active ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateIgAccounts.replaceAll("__typename", ""),
            variables: {
              input: {
                id: igAccountsRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "IgAccountsUpdateForm")}
      {...rest}
    >
      <TextField
        label="Username"
        isRequired={false}
        isReadOnly={false}
        value={username}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              username: value,
              password,
              proxy_url,
              account_owner,
              createdAt,
              updatedAt,
              is_active,
            };
            const result = onChange(modelFields);
            value = result?.username ?? value;
          }
          if (errors.username?.hasError) {
            runValidationTasks("username", value);
          }
          setUsername(value);
        }}
        onBlur={() => runValidationTasks("username", username)}
        errorMessage={errors.username?.errorMessage}
        hasError={errors.username?.hasError}
        {...getOverrideProps(overrides, "username")}
      ></TextField>
      <TextField
        label="Password"
        isRequired={false}
        isReadOnly={false}
        value={password}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              username,
              password: value,
              proxy_url,
              account_owner,
              createdAt,
              updatedAt,
              is_active,
            };
            const result = onChange(modelFields);
            value = result?.password ?? value;
          }
          if (errors.password?.hasError) {
            runValidationTasks("password", value);
          }
          setPassword(value);
        }}
        onBlur={() => runValidationTasks("password", password)}
        errorMessage={errors.password?.errorMessage}
        hasError={errors.password?.hasError}
        {...getOverrideProps(overrides, "password")}
      ></TextField>
      <TextField
        label="Proxy url"
        isRequired={false}
        isReadOnly={false}
        value={proxy_url}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              username,
              password,
              proxy_url: value,
              account_owner,
              createdAt,
              updatedAt,
              is_active,
            };
            const result = onChange(modelFields);
            value = result?.proxy_url ?? value;
          }
          if (errors.proxy_url?.hasError) {
            runValidationTasks("proxy_url", value);
          }
          setProxy_url(value);
        }}
        onBlur={() => runValidationTasks("proxy_url", proxy_url)}
        errorMessage={errors.proxy_url?.errorMessage}
        hasError={errors.proxy_url?.hasError}
        {...getOverrideProps(overrides, "proxy_url")}
      ></TextField>
      <TextField
        label="Account owner"
        isRequired={false}
        isReadOnly={false}
        value={account_owner}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              username,
              password,
              proxy_url,
              account_owner: value,
              createdAt,
              updatedAt,
              is_active,
            };
            const result = onChange(modelFields);
            value = result?.account_owner ?? value;
          }
          if (errors.account_owner?.hasError) {
            runValidationTasks("account_owner", value);
          }
          setAccount_owner(value);
        }}
        onBlur={() => runValidationTasks("account_owner", account_owner)}
        errorMessage={errors.account_owner?.errorMessage}
        hasError={errors.account_owner?.hasError}
        {...getOverrideProps(overrides, "account_owner")}
      ></TextField>
      <TextField
        label="Created at"
        isRequired={false}
        isReadOnly={false}
        value={createdAt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              username,
              password,
              proxy_url,
              account_owner,
              createdAt: value,
              updatedAt,
              is_active,
            };
            const result = onChange(modelFields);
            value = result?.createdAt ?? value;
          }
          if (errors.createdAt?.hasError) {
            runValidationTasks("createdAt", value);
          }
          setCreatedAt(value);
        }}
        onBlur={() => runValidationTasks("createdAt", createdAt)}
        errorMessage={errors.createdAt?.errorMessage}
        hasError={errors.createdAt?.hasError}
        {...getOverrideProps(overrides, "createdAt")}
      ></TextField>
      <TextField
        label="Updated at"
        isRequired={false}
        isReadOnly={false}
        value={updatedAt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              username,
              password,
              proxy_url,
              account_owner,
              createdAt,
              updatedAt: value,
              is_active,
            };
            const result = onChange(modelFields);
            value = result?.updatedAt ?? value;
          }
          if (errors.updatedAt?.hasError) {
            runValidationTasks("updatedAt", value);
          }
          setUpdatedAt(value);
        }}
        onBlur={() => runValidationTasks("updatedAt", updatedAt)}
        errorMessage={errors.updatedAt?.errorMessage}
        hasError={errors.updatedAt?.hasError}
        {...getOverrideProps(overrides, "updatedAt")}
      ></TextField>
      <SwitchField
        label="Is active"
        defaultChecked={false}
        isDisabled={false}
        isChecked={is_active}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              username,
              password,
              proxy_url,
              account_owner,
              createdAt,
              updatedAt,
              is_active: value,
            };
            const result = onChange(modelFields);
            value = result?.is_active ?? value;
          }
          if (errors.is_active?.hasError) {
            runValidationTasks("is_active", value);
          }
          setIs_active(value);
        }}
        onBlur={() => runValidationTasks("is_active", is_active)}
        errorMessage={errors.is_active?.errorMessage}
        hasError={errors.is_active?.hasError}
        {...getOverrideProps(overrides, "is_active")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || igAccountsModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || igAccountsModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
