import React from 'react';
import { useEffect , useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { listUserProperties } from 'graphql/queries';
import Dashboard from 'ui-components/Dashboard';
import { fetchAuthSession } from 'aws-amplify/auth';

const DashboardWrapper = () => {
  const client = generateClient({ authMode: "userPool"});
  const [userProperties, setUserProperties] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail]= useState('');

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const session = await fetchAuthSession();
        console.log("logged_in_details", session);
        setUserEmail(session.tokens.idToken.payload.email)
        console.log(session.tokens.idToken.payload.name)
        setIsLoggedIn(true);

      } catch (error) {
        console.log("User not logged in or unable to fetch session", error);
      }
    };

    fetchSession();
  }, []);

  const fetchFirstItem = async () => {
    try {
      //console.log("Fetching the first item...");
      const itemData = await client.graphql({
        query: listUserProperties, // Your GraphQL query to list items
        variables: {
            //limit: 1 // Adjust this limit as needed
          }, // Assuming your listMessageLogs query supports pagination
        authMode: "userPool"
      });
      setUserProperties(itemData.data.listUserProperties.items[0]);
      //console.log(itemData)
     
    } catch (err) {
      console.error('Error fetching the first item:', err);
    }
  };

  useEffect(() => {
    fetchFirstItem();
    //console.log("Fetching the first item...");
    //console.log("XXX:", itemData);
    //console.log("X",userProperties)
  }
  , []);


    return (
      <div className="flex flex-col items-center justify-start min-h-screen w-full ">
        <Dashboard overrides={{
          Dashboard :{
            width : "100%",
          },
          Layout:{
            maxWidth : "1100px",
          },
          li_ph:{
            children : userEmail,
          },
          status_ph:{
            children : userProperties.stripe_subscription_status,
          },
          st_ph :{
            children : userProperties.sub_tier,
          },
          sl_ph :{
            children : userProperties.sub_label,
          },
          d_g_ph:{
            children : userProperties.total_msgs_generated_day,
          },
          w_gen_ph:{
            children : userProperties.total_msgs_generated_week,
          },
          m_gen_ph:{
            children : userProperties.total_msgs_generated_month,
          },
          all_gen_ph :{
            children : userProperties.total_msgs_generated,
          },
          d_s_ph:{
            children : userProperties.total_msgs_sent_day,
          },
          w_s_ph:{
            children : userProperties.total_msgs_sent_week,
          },
          m_s_ph : {
            children : userProperties.total_msgs_sent_month,
          },
          all_s_ph :{
            children : userProperties.total_msgs_sent,
          },
          available_credits_ph:{
            children : userProperties.credits_available,
          },
          used_credits_ph:{
            children : userProperties.credits_used,
          },
          sub_begin_ph:{
            children : userProperties.stripe_subscription_start,
          },

        }}/>
      </div>
    );
  };
  



export default DashboardWrapper;