/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function Notification(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: { notification_placeholder: {}, Layout: {}, Notification: {} },
      variantValues: { breakpoint: "large", info: "success" },
    },
    {
      overrides: {
        notification_placeholder: {},
        Layout: {},
        Notification: { width: "852px", backgroundColor: "rgba(198,40,40,1)" },
      },
      variantValues: { breakpoint: "large", info: "error" },
    },
    {
      overrides: {
        notification_placeholder: {},
        Layout: {},
        Notification: { width: "852px", backgroundColor: "rgba(246,114,35,1)" },
      },
      variantValues: { breakpoint: "large", info: "warning" },
    },
    {
      overrides: {
        notification_placeholder: { fontSize: "14px", lineHeight: "21px" },
        Layout: { padding: "0px 15px 0px 15px" },
        Notification: { width: "532px" },
      },
      variantValues: { breakpoint: "medium", info: "success" },
    },
    {
      overrides: {
        notification_placeholder: { fontSize: "14px", lineHeight: "21px" },
        Layout: { padding: "0px 15px 0px 15px" },
        Notification: { width: "660px", backgroundColor: "rgba(246,114,35,1)" },
      },
      variantValues: { breakpoint: "medium", info: "warning" },
    },
    {
      overrides: {
        notification_placeholder: { fontSize: "14px", lineHeight: "21px" },
        Layout: { padding: "0px 15px 0px 15px" },
        Notification: { width: "660px", backgroundColor: "rgba(198,40,40,1)" },
      },
      variantValues: { breakpoint: "medium", info: "error" },
    },
    {
      overrides: {
        notification_placeholder: { fontSize: "12px", lineHeight: "18px" },
        Layout: { padding: "0px 10px 0px 10px" },
        Notification: { width: "368px" },
      },
      variantValues: { breakpoint: "small", info: "success" },
    },
    {
      overrides: {
        notification_placeholder: { fontSize: "12px", lineHeight: "18px" },
        Layout: { padding: "0px 10px 0px 10px" },
        Notification: { width: "415px", backgroundColor: "rgba(246,114,35,1)" },
      },
      variantValues: { breakpoint: "small", info: "warning" },
    },
    {
      overrides: {
        notification_placeholder: { fontSize: "12px", lineHeight: "18px" },
        Layout: { padding: "0px 10px 0px 10px" },
        Notification: { width: "415px", backgroundColor: "rgba(198,40,40,1)" },
      },
      variantValues: { breakpoint: "small", info: "error" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    small: "small",
    small: "small",
    medium: "medium",
    medium: "medium",
    medium: "medium",
    large: "large",
    large: "large",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="868px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      borderRadius="4px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(27,94,32,1)"
      display="flex"
      {...getOverrideProps(overrides, "Notification")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 25px 0px 25px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="24px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="notification_placeholder"
          {...getOverrideProps(overrides, "notification_placeholder")}
        ></Text>
      </Flex>
    </Flex>
  );
}
