/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getRetrievedTargetProfiles } from "../graphql/queries";
import { updateRetrievedTargetProfiles } from "../graphql/mutations";
const client = generateClient();
export default function RetrievedTargetProfilesUpdateForm(props) {
  const {
    id: idProp,
    retrievedTargetProfiles: retrievedTargetProfilesModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    pk: "",
    username: "",
    full_name: "",
    profile_pic_url: "",
    profile_pic_url_hd: "",
    is_verified: false,
    media_count: "",
    follower_count: "",
    following_count: "",
    biography: "",
    external_url: "",
    account_type: "",
    is_business: false,
    public_email: "",
    contact_phone_number: "",
    public_phone_country_code: "",
    public_phone_number: "",
    business_contact_method: "",
    business_category_name: "",
    category_name: "",
    category: "",
    address_street: "",
    city_id: "",
    city_name: "",
    latitude: "",
    longitude: "",
    zip: "",
    instagram_location_id: "",
    interop_messaging_user_fbid: "",
    is_private: false,
    assumed_gender: "",
    assumed_location: "",
    assumed_location_pk: "",
    assumed_name: "",
    profile_pic_s3_link: "",
  };
  const [pk, setPk] = React.useState(initialValues.pk);
  const [username, setUsername] = React.useState(initialValues.username);
  const [full_name, setFull_name] = React.useState(initialValues.full_name);
  const [profile_pic_url, setProfile_pic_url] = React.useState(
    initialValues.profile_pic_url
  );
  const [profile_pic_url_hd, setProfile_pic_url_hd] = React.useState(
    initialValues.profile_pic_url_hd
  );
  const [is_verified, setIs_verified] = React.useState(
    initialValues.is_verified
  );
  const [media_count, setMedia_count] = React.useState(
    initialValues.media_count
  );
  const [follower_count, setFollower_count] = React.useState(
    initialValues.follower_count
  );
  const [following_count, setFollowing_count] = React.useState(
    initialValues.following_count
  );
  const [biography, setBiography] = React.useState(initialValues.biography);
  const [external_url, setExternal_url] = React.useState(
    initialValues.external_url
  );
  const [account_type, setAccount_type] = React.useState(
    initialValues.account_type
  );
  const [is_business, setIs_business] = React.useState(
    initialValues.is_business
  );
  const [public_email, setPublic_email] = React.useState(
    initialValues.public_email
  );
  const [contact_phone_number, setContact_phone_number] = React.useState(
    initialValues.contact_phone_number
  );
  const [public_phone_country_code, setPublic_phone_country_code] =
    React.useState(initialValues.public_phone_country_code);
  const [public_phone_number, setPublic_phone_number] = React.useState(
    initialValues.public_phone_number
  );
  const [business_contact_method, setBusiness_contact_method] = React.useState(
    initialValues.business_contact_method
  );
  const [business_category_name, setBusiness_category_name] = React.useState(
    initialValues.business_category_name
  );
  const [category_name, setCategory_name] = React.useState(
    initialValues.category_name
  );
  const [category, setCategory] = React.useState(initialValues.category);
  const [address_street, setAddress_street] = React.useState(
    initialValues.address_street
  );
  const [city_id, setCity_id] = React.useState(initialValues.city_id);
  const [city_name, setCity_name] = React.useState(initialValues.city_name);
  const [latitude, setLatitude] = React.useState(initialValues.latitude);
  const [longitude, setLongitude] = React.useState(initialValues.longitude);
  const [zip, setZip] = React.useState(initialValues.zip);
  const [instagram_location_id, setInstagram_location_id] = React.useState(
    initialValues.instagram_location_id
  );
  const [interop_messaging_user_fbid, setInterop_messaging_user_fbid] =
    React.useState(initialValues.interop_messaging_user_fbid);
  const [is_private, setIs_private] = React.useState(initialValues.is_private);
  const [assumed_gender, setAssumed_gender] = React.useState(
    initialValues.assumed_gender
  );
  const [assumed_location, setAssumed_location] = React.useState(
    initialValues.assumed_location
  );
  const [assumed_location_pk, setAssumed_location_pk] = React.useState(
    initialValues.assumed_location_pk
  );
  const [assumed_name, setAssumed_name] = React.useState(
    initialValues.assumed_name
  );
  const [profile_pic_s3_link, setProfile_pic_s3_link] = React.useState(
    initialValues.profile_pic_s3_link
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = retrievedTargetProfilesRecord
      ? { ...initialValues, ...retrievedTargetProfilesRecord }
      : initialValues;
    setPk(cleanValues.pk);
    setUsername(cleanValues.username);
    setFull_name(cleanValues.full_name);
    setProfile_pic_url(cleanValues.profile_pic_url);
    setProfile_pic_url_hd(cleanValues.profile_pic_url_hd);
    setIs_verified(cleanValues.is_verified);
    setMedia_count(cleanValues.media_count);
    setFollower_count(cleanValues.follower_count);
    setFollowing_count(cleanValues.following_count);
    setBiography(cleanValues.biography);
    setExternal_url(cleanValues.external_url);
    setAccount_type(cleanValues.account_type);
    setIs_business(cleanValues.is_business);
    setPublic_email(cleanValues.public_email);
    setContact_phone_number(cleanValues.contact_phone_number);
    setPublic_phone_country_code(cleanValues.public_phone_country_code);
    setPublic_phone_number(cleanValues.public_phone_number);
    setBusiness_contact_method(cleanValues.business_contact_method);
    setBusiness_category_name(cleanValues.business_category_name);
    setCategory_name(cleanValues.category_name);
    setCategory(cleanValues.category);
    setAddress_street(cleanValues.address_street);
    setCity_id(cleanValues.city_id);
    setCity_name(cleanValues.city_name);
    setLatitude(cleanValues.latitude);
    setLongitude(cleanValues.longitude);
    setZip(cleanValues.zip);
    setInstagram_location_id(cleanValues.instagram_location_id);
    setInterop_messaging_user_fbid(cleanValues.interop_messaging_user_fbid);
    setIs_private(cleanValues.is_private);
    setAssumed_gender(cleanValues.assumed_gender);
    setAssumed_location(cleanValues.assumed_location);
    setAssumed_location_pk(cleanValues.assumed_location_pk);
    setAssumed_name(cleanValues.assumed_name);
    setProfile_pic_s3_link(cleanValues.profile_pic_s3_link);
    setErrors({});
  };
  const [retrievedTargetProfilesRecord, setRetrievedTargetProfilesRecord] =
    React.useState(retrievedTargetProfilesModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getRetrievedTargetProfiles.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getRetrievedTargetProfiles
        : retrievedTargetProfilesModelProp;
      setRetrievedTargetProfilesRecord(record);
    };
    queryData();
  }, [idProp, retrievedTargetProfilesModelProp]);
  React.useEffect(resetStateValues, [retrievedTargetProfilesRecord]);
  const validations = {
    pk: [],
    username: [],
    full_name: [],
    profile_pic_url: [],
    profile_pic_url_hd: [],
    is_verified: [],
    media_count: [],
    follower_count: [],
    following_count: [],
    biography: [],
    external_url: [],
    account_type: [],
    is_business: [],
    public_email: [],
    contact_phone_number: [],
    public_phone_country_code: [],
    public_phone_number: [],
    business_contact_method: [],
    business_category_name: [],
    category_name: [],
    category: [],
    address_street: [],
    city_id: [],
    city_name: [],
    latitude: [],
    longitude: [],
    zip: [],
    instagram_location_id: [],
    interop_messaging_user_fbid: [],
    is_private: [],
    assumed_gender: [],
    assumed_location: [],
    assumed_location_pk: [],
    assumed_name: [],
    profile_pic_s3_link: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          pk: pk ?? null,
          username: username ?? null,
          full_name: full_name ?? null,
          profile_pic_url: profile_pic_url ?? null,
          profile_pic_url_hd: profile_pic_url_hd ?? null,
          is_verified: is_verified ?? null,
          media_count: media_count ?? null,
          follower_count: follower_count ?? null,
          following_count: following_count ?? null,
          biography: biography ?? null,
          external_url: external_url ?? null,
          account_type: account_type ?? null,
          is_business: is_business ?? null,
          public_email: public_email ?? null,
          contact_phone_number: contact_phone_number ?? null,
          public_phone_country_code: public_phone_country_code ?? null,
          public_phone_number: public_phone_number ?? null,
          business_contact_method: business_contact_method ?? null,
          business_category_name: business_category_name ?? null,
          category_name: category_name ?? null,
          category: category ?? null,
          address_street: address_street ?? null,
          city_id: city_id ?? null,
          city_name: city_name ?? null,
          latitude: latitude ?? null,
          longitude: longitude ?? null,
          zip: zip ?? null,
          instagram_location_id: instagram_location_id ?? null,
          interop_messaging_user_fbid: interop_messaging_user_fbid ?? null,
          is_private: is_private ?? null,
          assumed_gender: assumed_gender ?? null,
          assumed_location: assumed_location ?? null,
          assumed_location_pk: assumed_location_pk ?? null,
          assumed_name: assumed_name ?? null,
          profile_pic_s3_link: profile_pic_s3_link ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateRetrievedTargetProfiles.replaceAll("__typename", ""),
            variables: {
              input: {
                id: retrievedTargetProfilesRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "RetrievedTargetProfilesUpdateForm")}
      {...rest}
    >
      <TextField
        label="Pk"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={pk}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              pk: value,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.pk ?? value;
          }
          if (errors.pk?.hasError) {
            runValidationTasks("pk", value);
          }
          setPk(value);
        }}
        onBlur={() => runValidationTasks("pk", pk)}
        errorMessage={errors.pk?.errorMessage}
        hasError={errors.pk?.hasError}
        {...getOverrideProps(overrides, "pk")}
      ></TextField>
      <TextField
        label="Username"
        isRequired={false}
        isReadOnly={false}
        value={username}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username: value,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.username ?? value;
          }
          if (errors.username?.hasError) {
            runValidationTasks("username", value);
          }
          setUsername(value);
        }}
        onBlur={() => runValidationTasks("username", username)}
        errorMessage={errors.username?.errorMessage}
        hasError={errors.username?.hasError}
        {...getOverrideProps(overrides, "username")}
      ></TextField>
      <TextField
        label="Full name"
        isRequired={false}
        isReadOnly={false}
        value={full_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name: value,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.full_name ?? value;
          }
          if (errors.full_name?.hasError) {
            runValidationTasks("full_name", value);
          }
          setFull_name(value);
        }}
        onBlur={() => runValidationTasks("full_name", full_name)}
        errorMessage={errors.full_name?.errorMessage}
        hasError={errors.full_name?.hasError}
        {...getOverrideProps(overrides, "full_name")}
      ></TextField>
      <TextField
        label="Profile pic url"
        isRequired={false}
        isReadOnly={false}
        value={profile_pic_url}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url: value,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.profile_pic_url ?? value;
          }
          if (errors.profile_pic_url?.hasError) {
            runValidationTasks("profile_pic_url", value);
          }
          setProfile_pic_url(value);
        }}
        onBlur={() => runValidationTasks("profile_pic_url", profile_pic_url)}
        errorMessage={errors.profile_pic_url?.errorMessage}
        hasError={errors.profile_pic_url?.hasError}
        {...getOverrideProps(overrides, "profile_pic_url")}
      ></TextField>
      <TextField
        label="Profile pic url hd"
        isRequired={false}
        isReadOnly={false}
        value={profile_pic_url_hd}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd: value,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.profile_pic_url_hd ?? value;
          }
          if (errors.profile_pic_url_hd?.hasError) {
            runValidationTasks("profile_pic_url_hd", value);
          }
          setProfile_pic_url_hd(value);
        }}
        onBlur={() =>
          runValidationTasks("profile_pic_url_hd", profile_pic_url_hd)
        }
        errorMessage={errors.profile_pic_url_hd?.errorMessage}
        hasError={errors.profile_pic_url_hd?.hasError}
        {...getOverrideProps(overrides, "profile_pic_url_hd")}
      ></TextField>
      <SwitchField
        label="Is verified"
        defaultChecked={false}
        isDisabled={false}
        isChecked={is_verified}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified: value,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.is_verified ?? value;
          }
          if (errors.is_verified?.hasError) {
            runValidationTasks("is_verified", value);
          }
          setIs_verified(value);
        }}
        onBlur={() => runValidationTasks("is_verified", is_verified)}
        errorMessage={errors.is_verified?.errorMessage}
        hasError={errors.is_verified?.hasError}
        {...getOverrideProps(overrides, "is_verified")}
      ></SwitchField>
      <TextField
        label="Media count"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={media_count}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count: value,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.media_count ?? value;
          }
          if (errors.media_count?.hasError) {
            runValidationTasks("media_count", value);
          }
          setMedia_count(value);
        }}
        onBlur={() => runValidationTasks("media_count", media_count)}
        errorMessage={errors.media_count?.errorMessage}
        hasError={errors.media_count?.hasError}
        {...getOverrideProps(overrides, "media_count")}
      ></TextField>
      <TextField
        label="Follower count"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={follower_count}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count: value,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.follower_count ?? value;
          }
          if (errors.follower_count?.hasError) {
            runValidationTasks("follower_count", value);
          }
          setFollower_count(value);
        }}
        onBlur={() => runValidationTasks("follower_count", follower_count)}
        errorMessage={errors.follower_count?.errorMessage}
        hasError={errors.follower_count?.hasError}
        {...getOverrideProps(overrides, "follower_count")}
      ></TextField>
      <TextField
        label="Following count"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={following_count}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count: value,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.following_count ?? value;
          }
          if (errors.following_count?.hasError) {
            runValidationTasks("following_count", value);
          }
          setFollowing_count(value);
        }}
        onBlur={() => runValidationTasks("following_count", following_count)}
        errorMessage={errors.following_count?.errorMessage}
        hasError={errors.following_count?.hasError}
        {...getOverrideProps(overrides, "following_count")}
      ></TextField>
      <TextField
        label="Biography"
        isRequired={false}
        isReadOnly={false}
        value={biography}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography: value,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.biography ?? value;
          }
          if (errors.biography?.hasError) {
            runValidationTasks("biography", value);
          }
          setBiography(value);
        }}
        onBlur={() => runValidationTasks("biography", biography)}
        errorMessage={errors.biography?.errorMessage}
        hasError={errors.biography?.hasError}
        {...getOverrideProps(overrides, "biography")}
      ></TextField>
      <TextField
        label="External url"
        isRequired={false}
        isReadOnly={false}
        value={external_url}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url: value,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.external_url ?? value;
          }
          if (errors.external_url?.hasError) {
            runValidationTasks("external_url", value);
          }
          setExternal_url(value);
        }}
        onBlur={() => runValidationTasks("external_url", external_url)}
        errorMessage={errors.external_url?.errorMessage}
        hasError={errors.external_url?.hasError}
        {...getOverrideProps(overrides, "external_url")}
      ></TextField>
      <TextField
        label="Account type"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={account_type}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type: value,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.account_type ?? value;
          }
          if (errors.account_type?.hasError) {
            runValidationTasks("account_type", value);
          }
          setAccount_type(value);
        }}
        onBlur={() => runValidationTasks("account_type", account_type)}
        errorMessage={errors.account_type?.errorMessage}
        hasError={errors.account_type?.hasError}
        {...getOverrideProps(overrides, "account_type")}
      ></TextField>
      <SwitchField
        label="Is business"
        defaultChecked={false}
        isDisabled={false}
        isChecked={is_business}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business: value,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.is_business ?? value;
          }
          if (errors.is_business?.hasError) {
            runValidationTasks("is_business", value);
          }
          setIs_business(value);
        }}
        onBlur={() => runValidationTasks("is_business", is_business)}
        errorMessage={errors.is_business?.errorMessage}
        hasError={errors.is_business?.hasError}
        {...getOverrideProps(overrides, "is_business")}
      ></SwitchField>
      <TextField
        label="Public email"
        isRequired={false}
        isReadOnly={false}
        value={public_email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email: value,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.public_email ?? value;
          }
          if (errors.public_email?.hasError) {
            runValidationTasks("public_email", value);
          }
          setPublic_email(value);
        }}
        onBlur={() => runValidationTasks("public_email", public_email)}
        errorMessage={errors.public_email?.errorMessage}
        hasError={errors.public_email?.hasError}
        {...getOverrideProps(overrides, "public_email")}
      ></TextField>
      <TextField
        label="Contact phone number"
        isRequired={false}
        isReadOnly={false}
        value={contact_phone_number}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number: value,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.contact_phone_number ?? value;
          }
          if (errors.contact_phone_number?.hasError) {
            runValidationTasks("contact_phone_number", value);
          }
          setContact_phone_number(value);
        }}
        onBlur={() =>
          runValidationTasks("contact_phone_number", contact_phone_number)
        }
        errorMessage={errors.contact_phone_number?.errorMessage}
        hasError={errors.contact_phone_number?.hasError}
        {...getOverrideProps(overrides, "contact_phone_number")}
      ></TextField>
      <TextField
        label="Public phone country code"
        isRequired={false}
        isReadOnly={false}
        value={public_phone_country_code}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code: value,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.public_phone_country_code ?? value;
          }
          if (errors.public_phone_country_code?.hasError) {
            runValidationTasks("public_phone_country_code", value);
          }
          setPublic_phone_country_code(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "public_phone_country_code",
            public_phone_country_code
          )
        }
        errorMessage={errors.public_phone_country_code?.errorMessage}
        hasError={errors.public_phone_country_code?.hasError}
        {...getOverrideProps(overrides, "public_phone_country_code")}
      ></TextField>
      <TextField
        label="Public phone number"
        isRequired={false}
        isReadOnly={false}
        value={public_phone_number}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number: value,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.public_phone_number ?? value;
          }
          if (errors.public_phone_number?.hasError) {
            runValidationTasks("public_phone_number", value);
          }
          setPublic_phone_number(value);
        }}
        onBlur={() =>
          runValidationTasks("public_phone_number", public_phone_number)
        }
        errorMessage={errors.public_phone_number?.errorMessage}
        hasError={errors.public_phone_number?.hasError}
        {...getOverrideProps(overrides, "public_phone_number")}
      ></TextField>
      <TextField
        label="Business contact method"
        isRequired={false}
        isReadOnly={false}
        value={business_contact_method}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method: value,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.business_contact_method ?? value;
          }
          if (errors.business_contact_method?.hasError) {
            runValidationTasks("business_contact_method", value);
          }
          setBusiness_contact_method(value);
        }}
        onBlur={() =>
          runValidationTasks("business_contact_method", business_contact_method)
        }
        errorMessage={errors.business_contact_method?.errorMessage}
        hasError={errors.business_contact_method?.hasError}
        {...getOverrideProps(overrides, "business_contact_method")}
      ></TextField>
      <TextField
        label="Business category name"
        isRequired={false}
        isReadOnly={false}
        value={business_category_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name: value,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.business_category_name ?? value;
          }
          if (errors.business_category_name?.hasError) {
            runValidationTasks("business_category_name", value);
          }
          setBusiness_category_name(value);
        }}
        onBlur={() =>
          runValidationTasks("business_category_name", business_category_name)
        }
        errorMessage={errors.business_category_name?.errorMessage}
        hasError={errors.business_category_name?.hasError}
        {...getOverrideProps(overrides, "business_category_name")}
      ></TextField>
      <TextField
        label="Category name"
        isRequired={false}
        isReadOnly={false}
        value={category_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name: value,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.category_name ?? value;
          }
          if (errors.category_name?.hasError) {
            runValidationTasks("category_name", value);
          }
          setCategory_name(value);
        }}
        onBlur={() => runValidationTasks("category_name", category_name)}
        errorMessage={errors.category_name?.errorMessage}
        hasError={errors.category_name?.hasError}
        {...getOverrideProps(overrides, "category_name")}
      ></TextField>
      <TextField
        label="Category"
        isRequired={false}
        isReadOnly={false}
        value={category}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category: value,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.category ?? value;
          }
          if (errors.category?.hasError) {
            runValidationTasks("category", value);
          }
          setCategory(value);
        }}
        onBlur={() => runValidationTasks("category", category)}
        errorMessage={errors.category?.errorMessage}
        hasError={errors.category?.hasError}
        {...getOverrideProps(overrides, "category")}
      ></TextField>
      <TextField
        label="Address street"
        isRequired={false}
        isReadOnly={false}
        value={address_street}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street: value,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.address_street ?? value;
          }
          if (errors.address_street?.hasError) {
            runValidationTasks("address_street", value);
          }
          setAddress_street(value);
        }}
        onBlur={() => runValidationTasks("address_street", address_street)}
        errorMessage={errors.address_street?.errorMessage}
        hasError={errors.address_street?.hasError}
        {...getOverrideProps(overrides, "address_street")}
      ></TextField>
      <TextField
        label="City id"
        isRequired={false}
        isReadOnly={false}
        value={city_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id: value,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.city_id ?? value;
          }
          if (errors.city_id?.hasError) {
            runValidationTasks("city_id", value);
          }
          setCity_id(value);
        }}
        onBlur={() => runValidationTasks("city_id", city_id)}
        errorMessage={errors.city_id?.errorMessage}
        hasError={errors.city_id?.hasError}
        {...getOverrideProps(overrides, "city_id")}
      ></TextField>
      <TextField
        label="City name"
        isRequired={false}
        isReadOnly={false}
        value={city_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name: value,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.city_name ?? value;
          }
          if (errors.city_name?.hasError) {
            runValidationTasks("city_name", value);
          }
          setCity_name(value);
        }}
        onBlur={() => runValidationTasks("city_name", city_name)}
        errorMessage={errors.city_name?.errorMessage}
        hasError={errors.city_name?.hasError}
        {...getOverrideProps(overrides, "city_name")}
      ></TextField>
      <TextField
        label="Latitude"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={latitude}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude: value,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.latitude ?? value;
          }
          if (errors.latitude?.hasError) {
            runValidationTasks("latitude", value);
          }
          setLatitude(value);
        }}
        onBlur={() => runValidationTasks("latitude", latitude)}
        errorMessage={errors.latitude?.errorMessage}
        hasError={errors.latitude?.hasError}
        {...getOverrideProps(overrides, "latitude")}
      ></TextField>
      <TextField
        label="Longitude"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={longitude}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude: value,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.longitude ?? value;
          }
          if (errors.longitude?.hasError) {
            runValidationTasks("longitude", value);
          }
          setLongitude(value);
        }}
        onBlur={() => runValidationTasks("longitude", longitude)}
        errorMessage={errors.longitude?.errorMessage}
        hasError={errors.longitude?.hasError}
        {...getOverrideProps(overrides, "longitude")}
      ></TextField>
      <TextField
        label="Zip"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={zip}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip: value,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.zip ?? value;
          }
          if (errors.zip?.hasError) {
            runValidationTasks("zip", value);
          }
          setZip(value);
        }}
        onBlur={() => runValidationTasks("zip", zip)}
        errorMessage={errors.zip?.errorMessage}
        hasError={errors.zip?.hasError}
        {...getOverrideProps(overrides, "zip")}
      ></TextField>
      <TextField
        label="Instagram location id"
        isRequired={false}
        isReadOnly={false}
        value={instagram_location_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id: value,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.instagram_location_id ?? value;
          }
          if (errors.instagram_location_id?.hasError) {
            runValidationTasks("instagram_location_id", value);
          }
          setInstagram_location_id(value);
        }}
        onBlur={() =>
          runValidationTasks("instagram_location_id", instagram_location_id)
        }
        errorMessage={errors.instagram_location_id?.errorMessage}
        hasError={errors.instagram_location_id?.hasError}
        {...getOverrideProps(overrides, "instagram_location_id")}
      ></TextField>
      <TextField
        label="Interop messaging user fbid"
        isRequired={false}
        isReadOnly={false}
        value={interop_messaging_user_fbid}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid: value,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.interop_messaging_user_fbid ?? value;
          }
          if (errors.interop_messaging_user_fbid?.hasError) {
            runValidationTasks("interop_messaging_user_fbid", value);
          }
          setInterop_messaging_user_fbid(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "interop_messaging_user_fbid",
            interop_messaging_user_fbid
          )
        }
        errorMessage={errors.interop_messaging_user_fbid?.errorMessage}
        hasError={errors.interop_messaging_user_fbid?.hasError}
        {...getOverrideProps(overrides, "interop_messaging_user_fbid")}
      ></TextField>
      <SwitchField
        label="Is private"
        defaultChecked={false}
        isDisabled={false}
        isChecked={is_private}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private: value,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.is_private ?? value;
          }
          if (errors.is_private?.hasError) {
            runValidationTasks("is_private", value);
          }
          setIs_private(value);
        }}
        onBlur={() => runValidationTasks("is_private", is_private)}
        errorMessage={errors.is_private?.errorMessage}
        hasError={errors.is_private?.hasError}
        {...getOverrideProps(overrides, "is_private")}
      ></SwitchField>
      <TextField
        label="Assumed gender"
        isRequired={false}
        isReadOnly={false}
        value={assumed_gender}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender: value,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.assumed_gender ?? value;
          }
          if (errors.assumed_gender?.hasError) {
            runValidationTasks("assumed_gender", value);
          }
          setAssumed_gender(value);
        }}
        onBlur={() => runValidationTasks("assumed_gender", assumed_gender)}
        errorMessage={errors.assumed_gender?.errorMessage}
        hasError={errors.assumed_gender?.hasError}
        {...getOverrideProps(overrides, "assumed_gender")}
      ></TextField>
      <TextField
        label="Assumed location"
        isRequired={false}
        isReadOnly={false}
        value={assumed_location}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location: value,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.assumed_location ?? value;
          }
          if (errors.assumed_location?.hasError) {
            runValidationTasks("assumed_location", value);
          }
          setAssumed_location(value);
        }}
        onBlur={() => runValidationTasks("assumed_location", assumed_location)}
        errorMessage={errors.assumed_location?.errorMessage}
        hasError={errors.assumed_location?.hasError}
        {...getOverrideProps(overrides, "assumed_location")}
      ></TextField>
      <TextField
        label="Assumed location pk"
        isRequired={false}
        isReadOnly={false}
        value={assumed_location_pk}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk: value,
              assumed_name,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.assumed_location_pk ?? value;
          }
          if (errors.assumed_location_pk?.hasError) {
            runValidationTasks("assumed_location_pk", value);
          }
          setAssumed_location_pk(value);
        }}
        onBlur={() =>
          runValidationTasks("assumed_location_pk", assumed_location_pk)
        }
        errorMessage={errors.assumed_location_pk?.errorMessage}
        hasError={errors.assumed_location_pk?.hasError}
        {...getOverrideProps(overrides, "assumed_location_pk")}
      ></TextField>
      <TextField
        label="Assumed name"
        isRequired={false}
        isReadOnly={false}
        value={assumed_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name: value,
              profile_pic_s3_link,
            };
            const result = onChange(modelFields);
            value = result?.assumed_name ?? value;
          }
          if (errors.assumed_name?.hasError) {
            runValidationTasks("assumed_name", value);
          }
          setAssumed_name(value);
        }}
        onBlur={() => runValidationTasks("assumed_name", assumed_name)}
        errorMessage={errors.assumed_name?.errorMessage}
        hasError={errors.assumed_name?.hasError}
        {...getOverrideProps(overrides, "assumed_name")}
      ></TextField>
      <TextField
        label="Profile pic s3 link"
        isRequired={false}
        isReadOnly={false}
        value={profile_pic_s3_link}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              pk,
              username,
              full_name,
              profile_pic_url,
              profile_pic_url_hd,
              is_verified,
              media_count,
              follower_count,
              following_count,
              biography,
              external_url,
              account_type,
              is_business,
              public_email,
              contact_phone_number,
              public_phone_country_code,
              public_phone_number,
              business_contact_method,
              business_category_name,
              category_name,
              category,
              address_street,
              city_id,
              city_name,
              latitude,
              longitude,
              zip,
              instagram_location_id,
              interop_messaging_user_fbid,
              is_private,
              assumed_gender,
              assumed_location,
              assumed_location_pk,
              assumed_name,
              profile_pic_s3_link: value,
            };
            const result = onChange(modelFields);
            value = result?.profile_pic_s3_link ?? value;
          }
          if (errors.profile_pic_s3_link?.hasError) {
            runValidationTasks("profile_pic_s3_link", value);
          }
          setProfile_pic_s3_link(value);
        }}
        onBlur={() =>
          runValidationTasks("profile_pic_s3_link", profile_pic_s3_link)
        }
        errorMessage={errors.profile_pic_s3_link?.errorMessage}
        hasError={errors.profile_pic_s3_link?.hasError}
        {...getOverrideProps(overrides, "profile_pic_s3_link")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || retrievedTargetProfilesModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || retrievedTargetProfilesModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
