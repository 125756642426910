/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Button, Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
import Checkcircleoutlineplans from "./Checkcircleoutlineplans";
export default function PriceTable(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        "Unsere Preismodelle": {},
        "Frame 4441680": {},
        "W\u00E4hlen Sie Ihren Tarif": {},
        "Frame 4441681": {},
        "W\u00E4hlen Sie den Tarif \u201CStart\u201D, wenn Sie noch nie zuvor Automatisierungen mit Instagram durchgef\u00FChrt haben oder nur sehr wenig Erfahrung damit haben. Dieser Tarif erh\u00F6ht schrittweise die Anzahl der t\u00E4glich gesendeten Nachrichten, um ein nat\u00FCrliches Verhalten zu simulieren.":
          {},
        "Frame 4441682": {},
        "Frame 432": {},
        Start: {},
        "60 \u20AC/Monat": {},
        "Nutzen Sie unsere verg\u00FCnstigten Preise w\u00E4hrend der Betaphase. Preise k\u00F6nnen nach der Betaphase variieren.":
          {},
        "Frame 4295702964": {},
        check_circle_outline_plans5702970: {},
        "bis zu 600 Nachrichten": {},
        "Frame 1385702969": {},
        check_circle_outline_plans5702973: {},
        "Ein verkn\u00FCpftes Konto": {},
        "Frame 1405702972": {},
        check_circle_outline_plans5702976: {},
        "unbegrenzte Unternehmensprofile5702977": {},
        "Frame 1415702975": {},
        check_circle_outline_plans57021079: {},
        "Support und Updates57021080": {},
        "Frame 14257021078": {},
        check_circle_outline_plans57021089: {},
        "Zugang zu allen Funktionen57021090": {},
        "Frame 14357021088": {},
        "Frame 44416725702968": {},
        "Frame 44416775702963": {},
        Button_1: {},
        "Frame 44416785702979": {},
        "Frame 44416735702978": {},
        "Frame 44416765702962": {},
        "* Um ein Abonnement abzuschlie\u00DFen, m\u00FCssen Sie ein Konto haben. Sie werden direkt weitergeleitet.":
          {},
        "Frame 4441687": {},
        "Frame 390": {},
        Erweitert: {},
        "__ \u20AC/Monat": {},
        "Bald verf\u00FCgbar": {},
        "Frame 4295708969": {},
        check_circle_outline_plans5708975: {},
        "900-10000 Nachrichten": {},
        "Frame 1385708974": {},
        check_circle_outline_plans5708978: {},
        "2-10 verkn\u00FCpfte Konten": {},
        "Frame 1405708977": {},
        check_circle_outline_plans5708981: {},
        "unbegrenzte Unternehmensprofile5708982": {},
        "Frame 1415708980": {},
        check_circle_outline_plans5708984: {},
        "Support und Updates5708985": {},
        "Frame 1425708983": {},
        check_circle_outline_plans5708987: {},
        "Zugang zu allen Funktionen5708988": {},
        "Frame 1435708986": {},
        check_circle_outline_plans57371086: {},
        "fr\u00FCher Zugang zu neuen Funktionen": {},
        "Frame 144": {},
        "Frame 44416725708973": {},
        "Frame 44416775708968": {},
        Button_2: {},
        "Frame 44416785708990": {},
        "Frame 44416735708989": {},
        "Frame 44416765708967": {},
        "Frame 391": {},
        "Frame 4441674": {},
        Layout: {},
        PriceTable: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        "Unsere Preismodelle": { fontSize: "36px" },
        "Frame 4441680": {},
        "W\u00E4hlen Sie Ihren Tarif": {},
        "Frame 4441681": {},
        "W\u00E4hlen Sie den Tarif \u201CStart\u201D, wenn Sie noch nie zuvor Automatisierungen mit Instagram durchgef\u00FChrt haben oder nur sehr wenig Erfahrung damit haben. Dieser Tarif erh\u00F6ht schrittweise die Anzahl der t\u00E4glich gesendeten Nachrichten, um ein nat\u00FCrliches Verhalten zu simulieren.":
          {},
        "Frame 4441682": {},
        "Frame 432": {},
        Start: {},
        "60 \u20AC/Monat": {},
        "Nutzen Sie unsere verg\u00FCnstigten Preise w\u00E4hrend der Betaphase. Preise k\u00F6nnen nach der Betaphase variieren.":
          {},
        "Frame 4295702964": {},
        check_circle_outline_plans5702970: {},
        "bis zu 600 Nachrichten": {},
        "Frame 1385702969": {},
        check_circle_outline_plans5702973: {},
        "Ein verkn\u00FCpftes Konto": {},
        "Frame 1405702972": {},
        check_circle_outline_plans5702976: {},
        "unbegrenzte Unternehmensprofile5702977": {},
        "Frame 1415702975": {},
        check_circle_outline_plans57021079: {},
        "Support und Updates57021080": {},
        "Frame 14257021078": {},
        check_circle_outline_plans57021089: {},
        "Zugang zu allen Funktionen57021090": {},
        "Frame 14357021088": {},
        "Frame 44416725702968": {},
        "Frame 44416775702963": {},
        Button_1: {},
        "Frame 44416785702979": {},
        "Frame 44416735702978": {},
        "Frame 44416765702962": {},
        "* Um ein Abonnement abzuschlie\u00DFen, m\u00FCssen Sie ein Konto haben. Sie werden direkt weitergeleitet.":
          {
            children:
              "* Um ein Abonnement abzuschlie\u00DFen, m\u00FCssen Sie ein Konto haben. Sie werden direkt weitergeleitet.",
          },
        "Frame 4441687": {},
        "Frame 390": { shrink: "0", alignSelf: "stretch" },
        Erweitert: {},
        "__ \u20AC/Monat": {},
        "Bald verf\u00FCgbar": {},
        "Frame 4295708969": {},
        check_circle_outline_plans5708975: {},
        "900-10000 Nachrichten": {},
        "Frame 1385708974": {},
        check_circle_outline_plans5708978: {},
        "2-10 verkn\u00FCpfte Konten": {},
        "Frame 1405708977": {},
        check_circle_outline_plans5708981: {},
        "unbegrenzte Unternehmensprofile5708982": {},
        "Frame 1415708980": {},
        check_circle_outline_plans5708984: {},
        "Support und Updates5708985": {},
        "Frame 1425708983": {},
        check_circle_outline_plans5708987: {},
        "Zugang zu allen Funktionen5708988": {},
        "Frame 1435708986": {},
        check_circle_outline_plans57371086: {},
        "fr\u00FCher Zugang zu neuen Funktionen": {},
        "Frame 144": {},
        "Frame 44416725708973": {},
        "Frame 44416775708968": {},
        Button_2: {},
        "Frame 44416785708990": {},
        "Frame 44416735708989": {},
        "Frame 44416765708967": {},
        "Frame 391": { shrink: "0", alignSelf: "stretch" },
        "Frame 4441674": {
          gap: "40px",
          direction: "column",
          padding: "10px 20px 10px 20px",
        },
        Layout: { padding: "60px 60px 60px 60px" },
        PriceTable: { width: "896px" },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        "Unsere Preismodelle": { fontSize: "32px" },
        "Frame 4441680": {},
        "W\u00E4hlen Sie Ihren Tarif": {},
        "Frame 4441681": {},
        "W\u00E4hlen Sie den Tarif \u201CStart\u201D, wenn Sie noch nie zuvor Automatisierungen mit Instagram durchgef\u00FChrt haben oder nur sehr wenig Erfahrung damit haben. Dieser Tarif erh\u00F6ht schrittweise die Anzahl der t\u00E4glich gesendeten Nachrichten, um ein nat\u00FCrliches Verhalten zu simulieren.":
          {},
        "Frame 4441682": { padding: "0px 20px 0px 0px" },
        "Frame 432": {},
        Start: {},
        "60 \u20AC/Monat": {},
        "Nutzen Sie unsere verg\u00FCnstigten Preise w\u00E4hrend der Betaphase. Preise k\u00F6nnen nach der Betaphase variieren.":
          {},
        "Frame 4295702964": {},
        check_circle_outline_plans5702970: {},
        "bis zu 600 Nachrichten": {},
        "Frame 1385702969": {},
        check_circle_outline_plans5702973: {},
        "Ein verkn\u00FCpftes Konto": {},
        "Frame 1405702972": {},
        check_circle_outline_plans5702976: {},
        "unbegrenzte Unternehmensprofile5702977": {},
        "Frame 1415702975": {},
        check_circle_outline_plans57021079: {},
        "Support und Updates57021080": {},
        "Frame 14257021078": {},
        check_circle_outline_plans57021089: {},
        "Zugang zu allen Funktionen57021090": {},
        "Frame 14357021088": {},
        "Frame 44416725702968": {},
        "Frame 44416775702963": {},
        Button_1: {},
        "Frame 44416785702979": {},
        "Frame 44416735702978": {},
        "Frame 44416765702962": {},
        "* Um ein Abonnement abzuschlie\u00DFen, m\u00FCssen Sie ein Konto haben. Sie werden direkt weitergeleitet.":
          {
            children:
              "* Um ein Abonnement abzuschlie\u00DFen, m\u00FCssen Sie ein Konto haben. Sie werden direkt weitergeleitet.",
          },
        "Frame 4441687": {},
        "Frame 390": { shrink: "0", alignSelf: "stretch" },
        Erweitert: {},
        "__ \u20AC/Monat": {},
        "Bald verf\u00FCgbar": {},
        "Frame 4295708969": {},
        check_circle_outline_plans5708975: {},
        "900-10000 Nachrichten": {},
        "Frame 1385708974": {},
        check_circle_outline_plans5708978: {},
        "2-10 verkn\u00FCpfte Konten": {},
        "Frame 1405708977": {},
        check_circle_outline_plans5708981: {},
        "unbegrenzte Unternehmensprofile5708982": {},
        "Frame 1415708980": {},
        check_circle_outline_plans5708984: {},
        "Support und Updates5708985": {},
        "Frame 1425708983": {},
        check_circle_outline_plans5708987: {},
        "Zugang zu allen Funktionen5708988": {},
        "Frame 1435708986": {},
        check_circle_outline_plans57371086: {},
        "fr\u00FCher Zugang zu neuen Funktionen": {},
        "Frame 144": {},
        "Frame 44416725708973": {},
        "Frame 44416775708968": {},
        Button_2: {},
        "Frame 44416785708990": {},
        "Frame 44416735708989": {},
        "Frame 44416765708967": {},
        "Frame 391": { shrink: "0", alignSelf: "stretch" },
        "Frame 4441674": {
          gap: "40px",
          direction: "column",
          padding: "10px 0px 10px 0px",
        },
        Layout: { padding: "60px 20px 60px 20px" },
        PriceTable: { width: "373px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="row"
      width="1132px"
      height="unset"
      justifyContent="center"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(0,48,83,1)"
      display="flex"
      {...getOverrideProps(overrides, "PriceTable")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        padding="10px 60px 40px 60px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="12px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 0px 10px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 432")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4441680")}
          >
            <Text
              fontFamily="Poppins"
              fontSize="38px"
              fontWeight="600"
              color="rgba(246,114,35,1)"
              lineHeight="45px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Unsere Preismodelle"
              {...getOverrideProps(overrides, "Unsere Preismodelle")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="10px 10px 10px 10px"
            display="none"
            {...getOverrideProps(overrides, "Frame 4441681")}
          >
            <Text
              fontFamily="Inter"
              fontSize="32px"
              fontWeight="600"
              color="rgba(245,245,245,1)"
              lineHeight="32px"
              textAlign="left"
              display="none"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Wählen Sie Ihren Tarif"
              {...getOverrideProps(overrides, "W\u00E4hlen Sie Ihren Tarif")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4441682")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(245,245,245,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Wählen Sie den Tarif “Start”, wenn Sie noch nie zuvor Automatisierungen mit Instagram durchgeführt haben oder nur sehr wenig Erfahrung damit haben. Dieser Tarif erhöht schrittweise die Anzahl der täglich gesendeten Nachrichten, um ein natürliches Verhalten zu simulieren."
              {...getOverrideProps(
                overrides,
                "W\u00E4hlen Sie den Tarif \u201CStart\u201D, wenn Sie noch nie zuvor Automatisierungen mit Instagram durchgef\u00FChrt haben oder nur sehr wenig Erfahrung damit haben. Dieser Tarif erh\u00F6ht schrittweise die Anzahl der t\u00E4glich gesendeten Nachrichten, um ein nat\u00FCrliches Verhalten zu simulieren."
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="21px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 83px 0px 83px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 4441674")}
        >
          <Flex
            gap="21.234567642211914px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            boxShadow="0px 1.7695473432540894px 3.5390946865081787px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
            borderRadius="10px"
            padding="28.31275749206543px 28.31275749206543px 28.31275749206543px 28.31275749206543px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 390")}
          >
            <Flex
              gap="21px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44416765702962")}
            >
              <Flex
                gap="21px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44416775702963")}
              >
                <Flex
                  gap="7.078189373016357px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 4295702964")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="14.156378746032715px"
                    fontWeight="600"
                    color="rgba(30,136,229,1)"
                    lineHeight="26.543210983276367px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Start"
                    {...getOverrideProps(overrides, "Start")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="28.31275749206543px"
                    fontWeight="600"
                    color="rgba(13,26,38,1)"
                    lineHeight="22.119340896606445px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="60 €/Monat"
                    {...getOverrideProps(overrides, "60 \u20AC/Monat")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="600"
                    color="rgba(92,102,112,1)"
                    lineHeight="22.119340896606445px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Nutzen Sie unsere vergünstigten Preise während der Betaphase. Preise können nach der Betaphase variieren."
                    {...getOverrideProps(
                      overrides,
                      "Nutzen Sie unsere verg\u00FCnstigten Preise w\u00E4hrend der Betaphase. Preise k\u00F6nnen nach der Betaphase variieren."
                    )}
                  ></Text>
                </Flex>
                <Flex
                  gap="21px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44416725702968")}
                >
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 1385702969")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans5702970"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="bis zu 600 Nachrichten"
                      {...getOverrideProps(overrides, "bis zu 600 Nachrichten")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 1405702972")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans5702973"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="Ein verknüpftes Konto "
                      {...getOverrideProps(
                        overrides,
                        "Ein verkn\u00FCpftes Konto"
                      )}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 1415702975")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans5702976"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="unbegrenzte Unternehmensprofile"
                      {...getOverrideProps(
                        overrides,
                        "unbegrenzte Unternehmensprofile5702977"
                      )}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 14257021078")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans57021079"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="Support und Updates"
                      {...getOverrideProps(
                        overrides,
                        "Support und Updates57021080"
                      )}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 14357021088")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans57021089"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="Zugang zu allen Funktionen"
                      {...getOverrideProps(
                        overrides,
                        "Zugang zu allen Funktionen57021090"
                      )}
                    ></Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-end"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44416735702978")}
              >
                <Flex
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44416785702979")}
                >
                  <Button
                    width="unset"
                    height="unset"
                    border="0.88px SOLID rgba(0,0,0,0)"
                    borderRadius="3.5390946865081787px"
                    padding="6.193415701389313px 13.27160507440567px 6.193415701389313px 13.27160507440567px"
                    shrink="0"
                    alignSelf="stretch"
                    backgroundColor="rgba(30,136,229,1)"
                    size="large"
                    isDisabled={false}
                    variation="primary"
                    children="Abonnieren"
                    {...getOverrideProps(overrides, "Button_1")}
                  ></Button>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 22px 0px 22px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4441687")}
            >
              <Text
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="14.522727012634277px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="*  Um ein Abonnement abzuschließen, müssen Sie ein Konto haben. Sie werden direkt weitergeleitet."
                {...getOverrideProps(
                  overrides,
                  "* Um ein Abonnement abzuschlie\u00DFen, m\u00FCssen Sie ein Konto haben. Sie werden direkt weitergeleitet."
                )}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="21.234567642211914px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            boxShadow="0px 1.7695473432540894px 3.5390946865081787px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
            borderRadius="10px"
            padding="28.31275749206543px 28.31275749206543px 28.31275749206543px 28.31275749206543px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 391")}
          >
            <Flex
              gap="21px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44416765708967")}
            >
              <Flex
                gap="21px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44416775708968")}
              >
                <Flex
                  gap="7.078189373016357px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 4295708969")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="14.156378746032715px"
                    fontWeight="600"
                    color="rgba(30,136,229,1)"
                    lineHeight="26.543210983276367px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Erweitert"
                    {...getOverrideProps(overrides, "Erweitert")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="28.31275749206543px"
                    fontWeight="600"
                    color="rgba(13,26,38,1)"
                    lineHeight="22.119340896606445px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="__ €/Monat"
                    {...getOverrideProps(overrides, "__ \u20AC/Monat")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="600"
                    color="rgba(92,102,112,1)"
                    lineHeight="22.119340896606445px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Bald verfügbar"
                    {...getOverrideProps(overrides, "Bald verf\u00FCgbar")}
                  ></Text>
                </Flex>
                <Flex
                  gap="21px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44416725708973")}
                >
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 1385708974")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans5708975"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="900-10000 Nachrichten"
                      {...getOverrideProps(overrides, "900-10000 Nachrichten")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 1405708977")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans5708978"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="2-10 verknüpfte Konten"
                      {...getOverrideProps(
                        overrides,
                        "2-10 verkn\u00FCpfte Konten"
                      )}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 1415708980")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans5708981"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="unbegrenzte Unternehmensprofile"
                      {...getOverrideProps(
                        overrides,
                        "unbegrenzte Unternehmensprofile5708982"
                      )}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 1425708983")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans5708984"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="Support und Updates"
                      {...getOverrideProps(
                        overrides,
                        "Support und Updates5708985"
                      )}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 1435708986")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans5708987"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="Zugang zu allen Funktionen"
                      {...getOverrideProps(
                        overrides,
                        "Zugang zu allen Funktionen5708988"
                      )}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="14.156378746032715px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 144")}
                  >
                    <Checkcircleoutlineplans
                      width="24px"
                      height="24px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "check_circle_outline_plans57371086"
                      )}
                    ></Checkcircleoutlineplans>
                    <Text
                      fontFamily="Inter"
                      fontSize="14.156378746032715px"
                      fontWeight="400"
                      color="rgba(13,26,38,1)"
                      lineHeight="21.234567642211914px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      grow="1"
                      shrink="1"
                      basis="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="früher Zugang zu neuen Funktionen"
                      {...getOverrideProps(
                        overrides,
                        "fr\u00FCher Zugang zu neuen Funktionen"
                      )}
                    ></Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-end"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44416735708989")}
              >
                <Flex
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44416785708990")}
                >
                  <Button
                    width="unset"
                    height="unset"
                    border="0.88px SOLID rgba(0,0,0,0)"
                    borderRadius="3.5390946865081787px"
                    padding="6.193415701389313px 13.27160507440567px 6.193415701389313px 13.27160507440567px"
                    shrink="0"
                    alignSelf="stretch"
                    backgroundColor="rgba(112,128,144,1)"
                    size="large"
                    isDisabled={false}
                    variation="primary"
                    children="Abonnieren"
                    {...getOverrideProps(overrides, "Button_2")}
                  ></Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
