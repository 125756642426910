import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import React from 'react';
import config from '../aws-exports';
import PriceTable from 'ui-components/PriceTable';
import Notification from 'ui-components/Notification';
import 'App.css';
import { useNavigate } from 'react-router-dom';

Amplify.configure(config);






const ContactHelpWidget = () => {
  
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/');
  };


  return (

    <div style={{ backgroundColor: '#002640' }}  className="flex flex-col items-center justify-center min-h-screen">
    
    <div className='mb-5'><button onClick={handleNavigate} className="custom-button"> Zurück zur Startseite  </button></div>

    <div>

    <Notification overrides={{
            Notification :{
              width:"100%",
              padding: '10px',
            },
            Layout:{
              maxWidth: '1000px',
            },
            notification_placeholder:{
              children : "Abonnementprozess fehlgeschlagen, bitte versuchen Sie es erneut" ,
            }
          }} info="error"
          /></div>



    <PriceTable overrides={{
        PriceTable:{
            backgroundColor: '#002640',
        }
    }} />

</div>


  );
};

export default ContactHelpWidget;
