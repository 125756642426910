/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function Dashboard(props) {
  const { userProperties, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Benutzerinformationen: {},
        "Frame 4442750471230": {},
        text_placeholder_info46461933: {},
        "Frame 4443150471237": {},
        li_ph: {},
        "Frame 4443050471236": {},
        "Frame 4443250471238": {},
        text_placeholder_info50471243: {},
        "Frame 4443150471242": {},
        status_ph: {},
        "Frame 4443050471244": {},
        "Frame 4443350471241": {},
        text_placeholder_info50471249: {},
        "Frame 4443150471248": {},
        st_ph: {},
        "Frame 4443050471250": {},
        "Frame 4443450471247": {},
        text_placeholder_info5247747: {},
        "Frame 444315247746": {},
        sl_ph: {},
        "Frame 444305247748": {},
        "Frame 444355247745": {},
        "Frame 4443550471246": {},
        "Frame 4443450471240": {},
        "Abo & Credits": {},
        "Frame 444275718980": {},
        subscription_start_ph: {},
        "Frame 4443157181004": {},
        sub_begin_ph: {},
        "Frame 4443057181006": {},
        "Frame 4443457181003": {},
        text_placeholder_ava_credits: {},
        "Frame 444315718984": {},
        available_credits_ph: {},
        "Frame 444305718986": {},
        "Frame 444325718983": {},
        text_placeholder_used_credits: {},
        "Frame 444315718989": {},
        used_credits_ph: {},
        "Frame 444305718991": {},
        "Frame 444335718988": {},
        "Frame 444355718982": {},
        "Frame 44437": {},
        "generierte Nachrichten": {},
        "Frame 4442750471253": {},
        text_placeholder_info50471258: {},
        "Frame 4443150471257": {},
        d_g_ph: {},
        "Frame 4443050471259": {},
        "Frame 4443250471256": {},
        text_placeholder_info5247732: {},
        "Frame 444315247731": {},
        w_gen_ph: {},
        "Frame 444305247733": {},
        Framew: {},
        text_placeholder_info50471263: {},
        "Frame 4443150471262": {},
        m_gen_ph: {},
        "Frame 4443050471264": {},
        "Frame 4443350471261": {},
        text_placeholder_info50471268: {},
        "Frame 4443150471267": {},
        all_gen_ph: {},
        "Frame 4443050471269": {},
        "Frame 4443450471266": {},
        "Frame 4443550471255": {},
        "Frame 4443550471252": {},
        "verschickte Nachrichten": {},
        "Frame 4442750471273": {},
        text_placeholder_info50471278: {},
        "Frame 4443150471277": {},
        d_s_ph: {},
        "Frame 4443050471279": {},
        "Frame 4443250471276": {},
        text_placeholder_info5247737: {},
        "Frame 444315247736": {},
        w_s_ph: {},
        "Frame 444305247738": {},
        "Frame 444355247735": {},
        text_placeholder_info50471283: {},
        "Frame 4443150471282": {},
        m_s_ph: {},
        "Frame 4443050471284": {},
        "Frame 4443350471281": {},
        text_placeholder_info50471288: {},
        "Frame 4443150471287": {},
        all_s_ph: {},
        "Frame 4443050471289": {},
        "Frame 4443450471286": {},
        "Frame 4443550471275": {},
        "Frame 44436": {},
        Layout: {},
        Dashboard: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        Benutzerinformationen: {},
        "Frame 4442750471230": {},
        text_placeholder_info46461933: {},
        "Frame 4443150471237": {},
        li_ph: {},
        "Frame 4443050471236": {},
        "Frame 4443250471238": {},
        text_placeholder_info50471243: {},
        "Frame 4443150471242": {},
        status_ph: {},
        "Frame 4443050471244": {},
        "Frame 4443350471241": {},
        text_placeholder_info50471249: {},
        "Frame 4443150471248": {},
        st_ph: {},
        "Frame 4443050471250": {},
        "Frame 4443450471247": {},
        text_placeholder_info5247747: {},
        "Frame 444315247746": {},
        sl_ph: {},
        "Frame 444305247748": {},
        "Frame 444355247745": {},
        "Frame 4443550471246": {},
        "Frame 4443450471240": {},
        "Abo & Credits": {},
        "Frame 444275718980": {},
        subscription_start_ph: {},
        "Frame 4443157181004": {},
        sub_begin_ph: {},
        "Frame 4443057181006": {},
        "Frame 4443457181003": {},
        text_placeholder_ava_credits: {},
        "Frame 444315718984": {},
        available_credits_ph: {},
        "Frame 444305718986": {},
        "Frame 444325718983": {},
        text_placeholder_used_credits: {},
        "Frame 444315718989": {},
        used_credits_ph: {},
        "Frame 444305718991": {},
        "Frame 444335718988": {},
        "Frame 444355718982": {},
        "Frame 44437": {},
        "generierte Nachrichten": {},
        "Frame 4442750471253": {},
        text_placeholder_info50471258: {},
        "Frame 4443150471257": {},
        d_g_ph: {},
        "Frame 4443050471259": {},
        "Frame 4443250471256": {},
        text_placeholder_info5247732: {},
        "Frame 444315247731": {},
        w_gen_ph: {},
        "Frame 444305247733": {},
        Framew: {},
        text_placeholder_info50471263: {},
        "Frame 4443150471262": {},
        m_gen_ph: {},
        "Frame 4443050471264": {},
        "Frame 4443350471261": {},
        text_placeholder_info50471268: {},
        "Frame 4443150471267": {},
        all_gen_ph: {},
        "Frame 4443050471269": {},
        "Frame 4443450471266": {},
        "Frame 4443550471255": {},
        "Frame 4443550471252": {},
        "verschickte Nachrichten": {},
        "Frame 4442750471273": {},
        text_placeholder_info50471278: {},
        "Frame 4443150471277": {},
        d_s_ph: {},
        "Frame 4443050471279": {},
        "Frame 4443250471276": {},
        text_placeholder_info5247737: {},
        "Frame 444315247736": {},
        w_s_ph: {},
        "Frame 444305247738": {},
        "Frame 444355247735": {},
        text_placeholder_info50471283: {},
        "Frame 4443150471282": {},
        m_s_ph: {},
        "Frame 4443050471284": {},
        "Frame 4443350471281": {},
        text_placeholder_info50471288: {},
        "Frame 4443150471287": {},
        all_s_ph: {},
        "Frame 4443050471289": {},
        "Frame 4443450471286": {},
        "Frame 4443550471275": {},
        "Frame 44436": {},
        Layout: {},
        Dashboard: { width: "768px", height: "936px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Benutzerinformationen: {
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "21px",
        },
        "Frame 4442750471230": { padding: "8px 0px 8px 0px" },
        text_placeholder_info46461933: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150471237": { shrink: "0", padding: "3px 0px 3px 0px" },
        li_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 4443050471236": { shrink: "0", padding: "4px 0px 4px 0px" },
        "Frame 4443250471238": {
          gap: "4px",
          direction: "column",
          height: "77px",
        },
        text_placeholder_info50471243: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150471242": { height: "26px", shrink: "0" },
        status_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 4443050471244": { height: "26px", shrink: "0" },
        "Frame 4443350471241": { gap: "4px", direction: "column" },
        text_placeholder_info50471249: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150471248": { height: "26px", shrink: "0" },
        st_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 4443050471250": { height: "26px", shrink: "0" },
        "Frame 4443450471247": { gap: "4px", direction: "column" },
        text_placeholder_info5247747: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 444315247746": { height: "26px", shrink: "0" },
        sl_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 444305247748": { height: "26px", shrink: "0" },
        "Frame 444355247745": { gap: "4px", direction: "column" },
        "Frame 4443550471246": {},
        "Frame 4443450471240": {},
        "Abo & Credits": {},
        "Frame 444275718980": {},
        subscription_start_ph: {},
        "Frame 4443157181004": { height: "26px", shrink: "0" },
        sub_begin_ph: {},
        "Frame 4443057181006": { height: "26px", shrink: "0" },
        "Frame 4443457181003": { gap: "4px", direction: "column" },
        text_placeholder_ava_credits: {},
        "Frame 444315718984": { shrink: "0" },
        available_credits_ph: {},
        "Frame 444305718986": { shrink: "0" },
        "Frame 444325718983": { gap: "4px", direction: "column" },
        text_placeholder_used_credits: {},
        "Frame 444315718989": { shrink: "0" },
        used_credits_ph: {},
        "Frame 444305718991": { shrink: "0" },
        "Frame 444335718988": { gap: "4px", direction: "column" },
        "Frame 444355718982": {},
        "Frame 44437": {},
        "generierte Nachrichten": {
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "21px",
        },
        "Frame 4442750471253": { padding: "8px 0px 8px 0px" },
        text_placeholder_info50471258: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150471257": { height: "26px", shrink: "0" },
        d_g_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 4443050471259": { height: "26px", shrink: "0" },
        "Frame 4443250471256": { gap: "4px", direction: "column" },
        text_placeholder_info5247732: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 444315247731": { height: "26px", shrink: "0" },
        w_gen_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 444305247733": { height: "26px", shrink: "0" },
        Framew: { gap: "4px", direction: "column" },
        text_placeholder_info50471263: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150471262": { height: "26px", shrink: "0" },
        m_gen_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 4443050471264": { height: "26px", shrink: "0" },
        "Frame 4443350471261": { gap: "4px", direction: "column" },
        text_placeholder_info50471268: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150471267": { height: "26px", shrink: "0" },
        all_gen_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 4443050471269": { height: "26px", shrink: "0" },
        "Frame 4443450471266": { gap: "4px", direction: "column" },
        "Frame 4443550471255": {},
        "Frame 4443550471252": {},
        "verschickte Nachrichten": {
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "21px",
        },
        "Frame 4442750471273": { padding: "8px 0px 8px 0px" },
        text_placeholder_info50471278: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150471277": { height: "26px", shrink: "0" },
        d_s_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 4443050471279": { height: "26px", shrink: "0" },
        "Frame 4443250471276": { gap: "4px", direction: "column" },
        text_placeholder_info5247737: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 444315247736": { height: "26px", shrink: "0" },
        w_s_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 444305247738": { height: "26px", shrink: "0" },
        "Frame 444355247735": { gap: "4px", direction: "column" },
        text_placeholder_info50471283: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150471282": { height: "26px", shrink: "0" },
        m_s_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 4443050471284": { height: "26px", shrink: "0" },
        "Frame 4443350471281": { gap: "4px", direction: "column" },
        text_placeholder_info50471288: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150471287": { height: "26px", shrink: "0" },
        all_s_ph: { fontSize: "14px", lineHeight: "21px" },
        "Frame 4443050471289": { height: "26px", shrink: "0" },
        "Frame 4443450471286": { gap: "4px", direction: "column" },
        "Frame 4443550471275": {},
        "Frame 44436": {},
        Layout: { padding: "20px 40px 20px 40px" },
        Dashboard: { width: "360px", height: "1567px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="907px"
      height="978px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="40px 0px 40px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "Dashboard")}
      {...rest}
    >
      <Flex
        gap="45px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="20px 80px 20px 80px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 4443450471240")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="10px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(30,136,229,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4442750471230")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(245,245,245,1)"
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Benutzerinformationen"
              {...getOverrideProps(overrides, "Benutzerinformationen")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4443550471246")}
          >
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443250471238")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150471237")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Email"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info46461933"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050471236")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={userProperties?.account_owner}
                  {...getOverrideProps(overrides, "li_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443350471241")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150471242")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Status"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50471243"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050471244")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="status_ph"
                  {...getOverrideProps(overrides, "status_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443450471247")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150471248")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Abonnementstufe"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50471249"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050471250")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={userProperties?.total_msgs_generated_week}
                  {...getOverrideProps(overrides, "st_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 444355247745")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 444315247746")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Abonnementtitel"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info5247747"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 444305247748")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="sub_label_ph"
                  {...getOverrideProps(overrides, "sl_ph")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 44437")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="10px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(30,136,229,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 444275718980")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(245,245,245,1)"
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Abo & Credits"
              {...getOverrideProps(overrides, "Abo & Credits")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 444355718982")}
          >
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443457181003")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443157181004")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Abostart"
                  {...getOverrideProps(overrides, "subscription_start_ph")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443057181006")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="sub_begin_ph"
                  {...getOverrideProps(overrides, "sub_begin_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 444325718983")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 444315718984")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Verfügbare Credits"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_ava_credits"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 444305718986")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="available_credits_ph"
                  {...getOverrideProps(overrides, "available_credits_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 444335718988")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 444315718989")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Benutzte Credits"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_used_credits"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 444305718991")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="used_credits_ph"
                  {...getOverrideProps(overrides, "used_credits_ph")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 4443550471252")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="10px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(30,136,229,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4442750471253")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(245,245,245,1)"
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="generierte Nachrichten"
              {...getOverrideProps(overrides, "generierte Nachrichten")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4443550471255")}
          >
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443250471256")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150471257")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Heute"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50471258"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050471259")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="daily_gen_ph"
                  {...getOverrideProps(overrides, "d_g_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Framew")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 444315247731")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="diese Woche"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info5247732"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 444305247733")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="weekly_gen_ph"
                  {...getOverrideProps(overrides, "w_gen_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443350471261")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150471262")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="diesen Monat"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50471263"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050471264")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="monthly_gen_ph"
                  {...getOverrideProps(overrides, "m_gen_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443450471266")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150471267")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="insgesamt"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50471268"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050471269")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="overall_gen_ph"
                  {...getOverrideProps(overrides, "all_gen_ph")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 44436")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="10px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(30,136,229,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4442750471273")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(245,245,245,1)"
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="verschickte Nachrichten"
              {...getOverrideProps(overrides, "verschickte Nachrichten")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4443550471275")}
          >
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443250471276")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150471277")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Heute"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50471278"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050471279")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="daily_sent_ph"
                  {...getOverrideProps(overrides, "d_s_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 444355247735")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 444315247736")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="diese Woche"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info5247737"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 444305247738")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="weekly_sent_ph"
                  {...getOverrideProps(overrides, "w_s_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443350471281")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150471282")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="diesen Monat"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50471283"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050471284")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="monthly_sent_ph"
                  {...getOverrideProps(overrides, "m_s_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443450471286")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150471287")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="insgesamt"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50471288"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050471289")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="overall_sent_ph"
                  {...getOverrideProps(overrides, "all_s_ph")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
