/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function Menubook(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="35px"
      height="35px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Menubook")}
      {...rest}
    >
      <View
        padding="0px 0px 0px 0px"
        width="35px"
        height="35px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        {...getOverrideProps(overrides, "Group5408966")}
      >
        <Icon
          width="35px"
          height="35px"
          viewBox={{ minX: 0, minY: 0, width: 35, height: 35 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector5408967")}
        ></Icon>
      </View>
      <View
        padding="0px 0px 0px 0px"
        width="32.08px"
        height="24.79px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="18.75%"
        bottom="10.42%"
        left="4.17%"
        right="4.17%"
        {...getOverrideProps(overrides, "Group5408968")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="32.08px"
          height="24.79px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Group5408969")}
        >
          <Icon
            width="32.08px"
            height="24.79px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 32.08333206176758,
              height: 24.79166603088379,
            }}
            paths={[
              {
                d: "M29.1667 0.729167C27.5479 0.21875 25.7687 0 24.0625 0C21.2187 0 18.1562 0.583333 16.0417 2.1875C13.9271 0.583333 10.8646 0 8.02083 0C5.17708 0 2.11458 0.583333 0 2.1875L0 23.5521C0 23.9167 0.364583 24.2812 0.729167 24.2812C0.875 24.2812 0.947917 24.2083 1.09375 24.2083C3.0625 23.2604 5.90625 22.6042 8.02083 22.6042C10.8646 22.6042 13.9271 23.1875 16.0417 24.7917C18.0104 23.5521 21.5833 22.6042 24.0625 22.6042C26.4687 22.6042 28.9479 23.0417 30.9896 24.1354C31.1354 24.2083 31.2083 24.2083 31.3542 24.2083C31.7187 24.2083 32.0833 23.8438 32.0833 23.4792L32.0833 2.1875C31.2083 1.53125 30.2604 1.09375 29.1667 0.729167ZM29.1667 20.4167C27.5625 19.9062 25.8125 19.6875 24.0625 19.6875C21.5833 19.6875 18.0104 20.6354 16.0417 21.875L16.0417 5.10417C18.0104 3.86458 21.5833 2.91667 24.0625 2.91667C25.8125 2.91667 27.5625 3.13542 29.1667 3.64583L29.1667 20.4167Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            {...getOverrideProps(overrides, "Vector5408970")}
          ></Icon>
          <View
            padding="0px 0px 0px 0px"
            width="10.21px"
            height="11.4px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="26.47%"
            bottom="27.53%"
            left="54.55%"
            right="13.64%"
            {...getOverrideProps(overrides, "Group5408971")}
          >
            <Icon
              width="10.21px"
              height="3.63px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 10.208333015441895,
                height: 3.6312496662139893,
              }}
              paths={[
                {
                  d: "M6.5625 2.1875C7.84583 2.1875 9.08542 2.31875 10.2083 2.56667L10.2083 0.35C9.05625 0.13125 7.81667 0 6.5625 0C4.08333 0 1.8375 0.422917 0 1.21042L0 3.63125C1.64792 2.69792 3.9375 2.1875 6.5625 2.1875Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="68.16%"
              left="0%"
              right="0%"
              {...getOverrideProps(overrides, "Vector5408972")}
            ></Icon>
            <Icon
              width="10.21px"
              height="3.63px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 10.208333015441895,
                height: 3.6312496662139893,
              }}
              paths={[
                {
                  d: "M0 1.21042L0 3.63125C1.64792 2.69792 3.9375 2.1875 6.5625 2.1875C7.84583 2.1875 9.08542 2.31875 10.2083 2.56667L10.2083 0.35C9.05625 0.13125 7.81667 0 6.5625 0C4.08333 0 1.8375 0.4375 0 1.21042Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="34.02%"
              bottom="34.14%"
              left="0%"
              right="0%"
              {...getOverrideProps(overrides, "Vector5408973")}
            ></Icon>
            <Icon
              width="10.21px"
              height="3.63px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 10.208333015441895,
                height: 3.6312496662139893,
              }}
              paths={[
                {
                  d: "M6.5625 0C4.08333 0 1.8375 0.422917 0 1.21042L0 3.63125C1.64792 2.69792 3.9375 2.1875 6.5625 2.1875C7.84583 2.1875 9.08542 2.31875 10.2083 2.56667L10.2083 0.35C9.05625 0.116666 7.81667 0 6.5625 0Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="68.16%"
              bottom="0%"
              left="0%"
              right="0%"
              {...getOverrideProps(overrides, "Vector5408974")}
            ></Icon>
          </View>
        </View>
      </View>
    </View>
  );
}
