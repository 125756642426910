/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getStripeSessionsLog } from "../graphql/queries";
import { updateStripeSessionsLog } from "../graphql/mutations";
const client = generateClient();
export default function StripeSessionsLogUpdateForm(props) {
  const {
    id: idProp,
    stripeSessionsLog: stripeSessionsLogModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    event_id: "",
    event_metadata: "",
    account_owner: "",
    email: "",
    hosted_invoice_url: "",
    event_type: "",
  };
  const [event_id, setEvent_id] = React.useState(initialValues.event_id);
  const [event_metadata, setEvent_metadata] = React.useState(
    initialValues.event_metadata
  );
  const [account_owner, setAccount_owner] = React.useState(
    initialValues.account_owner
  );
  const [email, setEmail] = React.useState(initialValues.email);
  const [hosted_invoice_url, setHosted_invoice_url] = React.useState(
    initialValues.hosted_invoice_url
  );
  const [event_type, setEvent_type] = React.useState(initialValues.event_type);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = stripeSessionsLogRecord
      ? { ...initialValues, ...stripeSessionsLogRecord }
      : initialValues;
    setEvent_id(cleanValues.event_id);
    setEvent_metadata(cleanValues.event_metadata);
    setAccount_owner(cleanValues.account_owner);
    setEmail(cleanValues.email);
    setHosted_invoice_url(cleanValues.hosted_invoice_url);
    setEvent_type(cleanValues.event_type);
    setErrors({});
  };
  const [stripeSessionsLogRecord, setStripeSessionsLogRecord] = React.useState(
    stripeSessionsLogModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getStripeSessionsLog.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getStripeSessionsLog
        : stripeSessionsLogModelProp;
      setStripeSessionsLogRecord(record);
    };
    queryData();
  }, [idProp, stripeSessionsLogModelProp]);
  React.useEffect(resetStateValues, [stripeSessionsLogRecord]);
  const validations = {
    event_id: [],
    event_metadata: [],
    account_owner: [],
    email: [],
    hosted_invoice_url: [],
    event_type: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          event_id: event_id ?? null,
          event_metadata: event_metadata ?? null,
          account_owner: account_owner ?? null,
          email: email ?? null,
          hosted_invoice_url: hosted_invoice_url ?? null,
          event_type: event_type ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateStripeSessionsLog.replaceAll("__typename", ""),
            variables: {
              input: {
                id: stripeSessionsLogRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "StripeSessionsLogUpdateForm")}
      {...rest}
    >
      <TextField
        label="Event id"
        isRequired={false}
        isReadOnly={false}
        value={event_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              event_id: value,
              event_metadata,
              account_owner,
              email,
              hosted_invoice_url,
              event_type,
            };
            const result = onChange(modelFields);
            value = result?.event_id ?? value;
          }
          if (errors.event_id?.hasError) {
            runValidationTasks("event_id", value);
          }
          setEvent_id(value);
        }}
        onBlur={() => runValidationTasks("event_id", event_id)}
        errorMessage={errors.event_id?.errorMessage}
        hasError={errors.event_id?.hasError}
        {...getOverrideProps(overrides, "event_id")}
      ></TextField>
      <TextField
        label="Event metadata"
        isRequired={false}
        isReadOnly={false}
        value={event_metadata}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              event_id,
              event_metadata: value,
              account_owner,
              email,
              hosted_invoice_url,
              event_type,
            };
            const result = onChange(modelFields);
            value = result?.event_metadata ?? value;
          }
          if (errors.event_metadata?.hasError) {
            runValidationTasks("event_metadata", value);
          }
          setEvent_metadata(value);
        }}
        onBlur={() => runValidationTasks("event_metadata", event_metadata)}
        errorMessage={errors.event_metadata?.errorMessage}
        hasError={errors.event_metadata?.hasError}
        {...getOverrideProps(overrides, "event_metadata")}
      ></TextField>
      <TextField
        label="Account owner"
        isRequired={false}
        isReadOnly={false}
        value={account_owner}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              event_id,
              event_metadata,
              account_owner: value,
              email,
              hosted_invoice_url,
              event_type,
            };
            const result = onChange(modelFields);
            value = result?.account_owner ?? value;
          }
          if (errors.account_owner?.hasError) {
            runValidationTasks("account_owner", value);
          }
          setAccount_owner(value);
        }}
        onBlur={() => runValidationTasks("account_owner", account_owner)}
        errorMessage={errors.account_owner?.errorMessage}
        hasError={errors.account_owner?.hasError}
        {...getOverrideProps(overrides, "account_owner")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              event_id,
              event_metadata,
              account_owner,
              email: value,
              hosted_invoice_url,
              event_type,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Hosted invoice url"
        isRequired={false}
        isReadOnly={false}
        value={hosted_invoice_url}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              event_id,
              event_metadata,
              account_owner,
              email,
              hosted_invoice_url: value,
              event_type,
            };
            const result = onChange(modelFields);
            value = result?.hosted_invoice_url ?? value;
          }
          if (errors.hosted_invoice_url?.hasError) {
            runValidationTasks("hosted_invoice_url", value);
          }
          setHosted_invoice_url(value);
        }}
        onBlur={() =>
          runValidationTasks("hosted_invoice_url", hosted_invoice_url)
        }
        errorMessage={errors.hosted_invoice_url?.errorMessage}
        hasError={errors.hosted_invoice_url?.hasError}
        {...getOverrideProps(overrides, "hosted_invoice_url")}
      ></TextField>
      <TextField
        label="Event type"
        isRequired={false}
        isReadOnly={false}
        value={event_type}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              event_id,
              event_metadata,
              account_owner,
              email,
              hosted_invoice_url,
              event_type: value,
            };
            const result = onChange(modelFields);
            value = result?.event_type ?? value;
          }
          if (errors.event_type?.hasError) {
            runValidationTasks("event_type", value);
          }
          setEvent_type(value);
        }}
        onBlur={() => runValidationTasks("event_type", event_type)}
        errorMessage={errors.event_type?.errorMessage}
        hasError={errors.event_type?.hasError}
        {...getOverrideProps(overrides, "event_type")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || stripeSessionsLogModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || stripeSessionsLogModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
