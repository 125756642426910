/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Button,
  Divider,
  Flex,
  Loader,
  Text,
  TextField,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function NotifyLaunch(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Divider: {},
        "Frame 44499": {},
        "M\u00F6chten Sie OptimaticLeads kostenlos und unverbindlich testen?":
          {},
        "Frame 44493": {},
        "Geben Sie Ihre E-Mail-Adresse ein, und unser Team wird sich in K\u00FCrze mit Ihnen in Verbindung setzen.":
          {},
        FrameInfo: {},
        notification_placeholder: {},
        "Frame 44495": {},
        "Frame 436": {},
        TextField: {},
        Button: {},
        Loader: {},
        Form: {},
        "Type Lock Up": {},
        Layout: {},
        NotifyLaunch: {},
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        Divider: {},
        "Frame 44499": { padding: "10px 50px 10px 50px" },
        "M\u00F6chten Sie OptimaticLeads kostenlos und unverbindlich testen?": {
          fontSize: "32px",
        },
        "Frame 44493": {},
        "Geben Sie Ihre E-Mail-Adresse ein, und unser Team wird sich in K\u00FCrze mit Ihnen in Verbindung setzen.":
          { fontSize: "12px", lineHeight: "18px" },
        FrameInfo: {
          justifyContent: "flex-start",
          padding: "0px 14px 0px 0px",
        },
        notification_placeholder: {
          fontSize: "12px",
          color: "rgba(245,245,245,1)",
          lineHeight: "18px",
        },
        "Frame 44495": {},
        "Frame 436": {},
        TextField: {},
        Button: {},
        Loader: {},
        Form: { gap: "12px", direction: "column", alignItems: "flex-start" },
        "Type Lock Up": { height: "325px", padding: "10px 15px 25px 15px" },
        Layout: { padding: "0px 10px 0px 10px" },
        NotifyLaunch: {
          width: "438px",
          height: "442px",
          padding: "10px 0px 10px 0px",
        },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="row"
      width="1065px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "NotifyLaunch")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="20px 0px 20px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 200px 10px 200px"
          display="none"
          {...getOverrideProps(overrides, "Frame 44499")}
        >
          <Divider
            width="unset"
            shrink="0"
            alignSelf="stretch"
            size="default"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider")}
          ></Divider>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="15px 25px 20px 25px"
          display="flex"
          {...getOverrideProps(overrides, "Type Lock Up")}
        >
          <Flex
            gap="6px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 436")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 5px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44493")}
            >
              <Text
                fontFamily="Poppins"
                fontSize="38px"
                fontWeight="600"
                color="rgba(246,114,35,1)"
                lineHeight="45px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Möchten Sie OptimaticLeads kostenlos und unverbindlich testen? "
                {...getOverrideProps(
                  overrides,
                  "M\u00F6chten Sie OptimaticLeads kostenlos und unverbindlich testen?"
                )}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "FrameInfo")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="500"
                color="rgba(245,245,245,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Geben Sie Ihre E-Mail-Adresse ein, und unser Team wird sich in Kürze mit Ihnen in Verbindung setzen."
                {...getOverrideProps(
                  overrides,
                  "Geben Sie Ihre E-Mail-Adresse ein, und unser Team wird sich in K\u00FCrze mit Ihnen in Verbindung setzen."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44495")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="500"
                color="rgba(217,217,217,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="notification_placeholder"
                {...getOverrideProps(overrides, "notification_placeholder")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Form")}
          >
            <TextField
              width="300px"
              height="unset"
              placeholder="Ihre E-Mail"
              shrink="0"
              borderRadius="4px"
              backgroundColor="rgba(255,255,255,1)"
              size="small"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              {...getOverrideProps(overrides, "TextField")}
            ></TextField>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              backgroundColor="rgba(30,136,229,1)"
              size="small"
              isDisabled={false}
              variation="primary"
              children="Senden"
              {...getOverrideProps(overrides, "Button")}
            ></Button>
            <Loader
              shrink="0"
              size="large"
              variation="circular"
              {...getOverrideProps(overrides, "Loader")}
            ></Loader>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
