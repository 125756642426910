/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function ContactHelp(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="35px"
      height="35px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 35, height: 35 }}
      paths={[
        {
          d: "M12.3958 0C5.55625 0 0 5.55625 0 12.3958C0 19.2354 5.55625 24.7917 12.3958 24.7917L13.125 24.7917L13.125 29.1667C20.2125 25.7542 24.7917 18.9583 24.7917 12.3958C24.7917 5.55625 19.2354 0 12.3958 0ZM13.8542 21.1458L10.9375 21.1458L10.9375 18.2292L13.8542 18.2292L13.8542 21.1458ZM14.4375 14.175C14.4229 14.1896 14.4083 14.2188 14.3937 14.2479C14.3208 14.3646 14.2479 14.4812 14.1896 14.5979C14.1604 14.6417 14.1458 14.7 14.1312 14.7583C14.0875 14.8604 14.0437 14.9625 14.0146 15.0646C13.9125 15.3708 13.8687 15.6917 13.8687 16.0562L10.9375 16.0562C10.9375 15.3125 11.0542 14.6854 11.2292 14.1604C11.2292 14.1458 11.2292 14.1312 11.2437 14.1167C11.2583 14.0583 11.3021 14.0292 11.3167 13.9708C11.4042 13.7375 11.5062 13.5333 11.6375 13.3292C11.6812 13.2562 11.7396 13.1833 11.7833 13.1104C11.8271 13.0521 11.8562 12.9792 11.9 12.9354L11.9146 12.95C13.1396 11.3458 15.1375 10.85 15.2979 9.04167C15.4292 7.6125 14.4083 6.22708 13.0083 5.93542C11.4917 5.61458 10.1208 6.50417 9.65417 7.80208C9.45 8.32708 8.96875 8.75 8.37083 8.75L8.07917 8.75C7.20417 8.75 6.5625 7.88958 6.81042 7.04375C7.6125 4.38958 10.2667 2.5375 13.2708 2.975C15.7354 3.33958 17.7042 5.36667 18.1271 7.83125C18.7687 11.3896 15.75 12.25 14.4375 14.175L14.4375 14.175Z",
          fill: "rgba(245,245,245,1)",
          fillRule: "nonzero",
          style: { transform: "translate(12.5%, 8.33%)" },
        },
      ]}
      {...getOverrideProps(overrides, "ContactHelp")}
      {...rest}
    ></Icon>
  );
}
