/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { useState } from "react";
import { generateClient } from "aws-amplify/api";
import { updateProfile } from "../graphql/mutations";
import { getOverrideProps } from "./utils";
import {
  Flex,
  Text,
  TextAreaField,
  TextField,
  View,
} from "@aws-amplify/ui-react";
const client = generateClient();
export default function EditProfile1(props) {
  const { profile, overrides, ...rest } = props;
  const [textFieldValue, setTextFieldValue] = useState("");
  const labelOnClick = async () => {
    await client.graphql({
      query: updateProfile.replaceAll("__typename", ""),
      variables: {
        input: {
          profile_name: textFieldValue,
          id: profile?.id,
        },
      },
    });
  };
  return (
    <Flex
      gap="22.25px"
      direction="column"
      width="862.5px"
      height="565.98px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      borderRadius="12.5px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(0,49,83,1)"
      {...getOverrideProps(overrides, "EditProfile1")}
      {...rest}
    >
      <View
        width="862.5px"
        height="566.25px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="21.5625px"
          direction="row"
          width="799px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          position="absolute"
          top="33.38px"
          left="33.38px"
          borderRadius="10px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(30,136,229,1)"
          {...getOverrideProps(overrides, "Edit Profile")}
        >
          <Text
            fontFamily="Inter"
            fontSize="21.5625px"
            fontWeight="700"
            color="rgba(245,245,245,1)"
            lineHeight="26.953125px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="799px"
            height="52px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Profil bearbeiten"
            {...getOverrideProps(overrides, "Profil bearbeiten")}
          ></Text>
        </Flex>
        <Flex
          gap="22.25px"
          direction="column"
          width="795.75px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          position="absolute"
          top="118.75px"
          left="33.38px"
          borderRadius="12.5px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(141,195,242,1)"
          {...getOverrideProps(overrides, "Forms")}
        >
          <TextField
            width="795px"
            height="unset"
            label=" Bezeichnung"
            placeholder="..."
            gap="11.125px"
            shrink="0"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            value={textFieldValue}
            onChange={(event) => {
              setTextFieldValue(event.target.value);
            }}
            {...getOverrideProps(overrides, "TextField")}
          ></TextField>
        </Flex>
        <TextAreaField
          width="795px"
          height="192.5px"
          label=" Beschreibung"
          placeholder="..."
          gap="11.125px"
          position="absolute"
          top="251.5px"
          left="33.38px"
          borderRadius="12.5px"
          backgroundColor="rgba(141,195,242,1)"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextAreaField")}
        ></TextAreaField>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top="477px"
          left="354px"
          border="1.39px SOLID rgba(0,0,0,0)"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="34.765621185302734px"
          padding="9.734375px 20.859375px 9.734375px 20.859375px"
          backgroundColor="rgba(30,136,229,1)"
          {...getOverrideProps(overrides, "Button")}
        >
          <Text
            fontFamily="Inter"
            fontSize="22.25px"
            fontWeight="700"
            color="rgba(245,245,245,1)"
            lineHeight="33.375px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="speichern"
            onClick={() => {
              labelOnClick();
            }}
            {...getOverrideProps(overrides, "label")}
          ></Text>
        </Flex>
      </View>
    </Flex>
  );
}
