/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getRetrievedBatchProfileMetadata } from "../graphql/queries";
import { updateRetrievedBatchProfileMetadata } from "../graphql/mutations";
const client = generateClient();
export default function RetrievedBatchProfileMetadataUpdateForm(props) {
  const {
    id: idProp,
    retrievedBatchProfileMetadata: retrievedBatchProfileMetadataModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    account_owner: "",
    mode: "",
    current_end_cursor: "",
    future_end_cursor: "",
    current_item_index: "",
    target_entity: "",
    target_entity_id: "",
    batch_size: "",
    retrieved_items: "",
    total_amount_retrieved: "",
    account_profile_id: "",
    filter_options: "",
    createdAt: "",
    updatedAt: "",
    total_processed: "",
  };
  const [account_owner, setAccount_owner] = React.useState(
    initialValues.account_owner
  );
  const [mode, setMode] = React.useState(initialValues.mode);
  const [current_end_cursor, setCurrent_end_cursor] = React.useState(
    initialValues.current_end_cursor
  );
  const [future_end_cursor, setFuture_end_cursor] = React.useState(
    initialValues.future_end_cursor
  );
  const [current_item_index, setCurrent_item_index] = React.useState(
    initialValues.current_item_index
  );
  const [target_entity, setTarget_entity] = React.useState(
    initialValues.target_entity
  );
  const [target_entity_id, setTarget_entity_id] = React.useState(
    initialValues.target_entity_id
  );
  const [batch_size, setBatch_size] = React.useState(initialValues.batch_size);
  const [retrieved_items, setRetrieved_items] = React.useState(
    initialValues.retrieved_items
  );
  const [total_amount_retrieved, setTotal_amount_retrieved] = React.useState(
    initialValues.total_amount_retrieved
  );
  const [account_profile_id, setAccount_profile_id] = React.useState(
    initialValues.account_profile_id
  );
  const [filter_options, setFilter_options] = React.useState(
    initialValues.filter_options
  );
  const [createdAt, setCreatedAt] = React.useState(initialValues.createdAt);
  const [updatedAt, setUpdatedAt] = React.useState(initialValues.updatedAt);
  const [total_processed, setTotal_processed] = React.useState(
    initialValues.total_processed
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = retrievedBatchProfileMetadataRecord
      ? { ...initialValues, ...retrievedBatchProfileMetadataRecord }
      : initialValues;
    setAccount_owner(cleanValues.account_owner);
    setMode(cleanValues.mode);
    setCurrent_end_cursor(cleanValues.current_end_cursor);
    setFuture_end_cursor(cleanValues.future_end_cursor);
    setCurrent_item_index(cleanValues.current_item_index);
    setTarget_entity(cleanValues.target_entity);
    setTarget_entity_id(cleanValues.target_entity_id);
    setBatch_size(cleanValues.batch_size);
    setRetrieved_items(cleanValues.retrieved_items);
    setTotal_amount_retrieved(cleanValues.total_amount_retrieved);
    setAccount_profile_id(cleanValues.account_profile_id);
    setFilter_options(
      typeof cleanValues.filter_options === "string" ||
        cleanValues.filter_options === null
        ? cleanValues.filter_options
        : JSON.stringify(cleanValues.filter_options)
    );
    setCreatedAt(cleanValues.createdAt);
    setUpdatedAt(cleanValues.updatedAt);
    setTotal_processed(cleanValues.total_processed);
    setErrors({});
  };
  const [
    retrievedBatchProfileMetadataRecord,
    setRetrievedBatchProfileMetadataRecord,
  ] = React.useState(retrievedBatchProfileMetadataModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getRetrievedBatchProfileMetadata.replaceAll(
                "__typename",
                ""
              ),
              variables: { id: idProp },
            })
          )?.data?.getRetrievedBatchProfileMetadata
        : retrievedBatchProfileMetadataModelProp;
      setRetrievedBatchProfileMetadataRecord(record);
    };
    queryData();
  }, [idProp, retrievedBatchProfileMetadataModelProp]);
  React.useEffect(resetStateValues, [retrievedBatchProfileMetadataRecord]);
  const validations = {
    account_owner: [],
    mode: [],
    current_end_cursor: [],
    future_end_cursor: [],
    current_item_index: [],
    target_entity: [],
    target_entity_id: [],
    batch_size: [],
    retrieved_items: [],
    total_amount_retrieved: [],
    account_profile_id: [],
    filter_options: [{ type: "JSON" }],
    createdAt: [],
    updatedAt: [],
    total_processed: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          account_owner: account_owner ?? null,
          mode: mode ?? null,
          current_end_cursor: current_end_cursor ?? null,
          future_end_cursor: future_end_cursor ?? null,
          current_item_index: current_item_index ?? null,
          target_entity: target_entity ?? null,
          target_entity_id: target_entity_id ?? null,
          batch_size: batch_size ?? null,
          retrieved_items: retrieved_items ?? null,
          total_amount_retrieved: total_amount_retrieved ?? null,
          account_profile_id: account_profile_id ?? null,
          filter_options: filter_options ?? null,
          createdAt: createdAt ?? null,
          updatedAt: updatedAt ?? null,
          total_processed: total_processed ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateRetrievedBatchProfileMetadata.replaceAll(
              "__typename",
              ""
            ),
            variables: {
              input: {
                id: retrievedBatchProfileMetadataRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "RetrievedBatchProfileMetadataUpdateForm"
      )}
      {...rest}
    >
      <TextField
        label="Account owner"
        isRequired={false}
        isReadOnly={false}
        value={account_owner}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner: value,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.account_owner ?? value;
          }
          if (errors.account_owner?.hasError) {
            runValidationTasks("account_owner", value);
          }
          setAccount_owner(value);
        }}
        onBlur={() => runValidationTasks("account_owner", account_owner)}
        errorMessage={errors.account_owner?.errorMessage}
        hasError={errors.account_owner?.hasError}
        {...getOverrideProps(overrides, "account_owner")}
      ></TextField>
      <TextField
        label="Mode"
        isRequired={false}
        isReadOnly={false}
        value={mode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              mode: value,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.mode ?? value;
          }
          if (errors.mode?.hasError) {
            runValidationTasks("mode", value);
          }
          setMode(value);
        }}
        onBlur={() => runValidationTasks("mode", mode)}
        errorMessage={errors.mode?.errorMessage}
        hasError={errors.mode?.hasError}
        {...getOverrideProps(overrides, "mode")}
      ></TextField>
      <TextField
        label="Current end cursor"
        isRequired={false}
        isReadOnly={false}
        value={current_end_cursor}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor: value,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.current_end_cursor ?? value;
          }
          if (errors.current_end_cursor?.hasError) {
            runValidationTasks("current_end_cursor", value);
          }
          setCurrent_end_cursor(value);
        }}
        onBlur={() =>
          runValidationTasks("current_end_cursor", current_end_cursor)
        }
        errorMessage={errors.current_end_cursor?.errorMessage}
        hasError={errors.current_end_cursor?.hasError}
        {...getOverrideProps(overrides, "current_end_cursor")}
      ></TextField>
      <TextField
        label="Future end cursor"
        isRequired={false}
        isReadOnly={false}
        value={future_end_cursor}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor: value,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.future_end_cursor ?? value;
          }
          if (errors.future_end_cursor?.hasError) {
            runValidationTasks("future_end_cursor", value);
          }
          setFuture_end_cursor(value);
        }}
        onBlur={() =>
          runValidationTasks("future_end_cursor", future_end_cursor)
        }
        errorMessage={errors.future_end_cursor?.errorMessage}
        hasError={errors.future_end_cursor?.hasError}
        {...getOverrideProps(overrides, "future_end_cursor")}
      ></TextField>
      <TextField
        label="Current item index"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={current_item_index}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index: value,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.current_item_index ?? value;
          }
          if (errors.current_item_index?.hasError) {
            runValidationTasks("current_item_index", value);
          }
          setCurrent_item_index(value);
        }}
        onBlur={() =>
          runValidationTasks("current_item_index", current_item_index)
        }
        errorMessage={errors.current_item_index?.errorMessage}
        hasError={errors.current_item_index?.hasError}
        {...getOverrideProps(overrides, "current_item_index")}
      ></TextField>
      <TextField
        label="Target entity"
        isRequired={false}
        isReadOnly={false}
        value={target_entity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity: value,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.target_entity ?? value;
          }
          if (errors.target_entity?.hasError) {
            runValidationTasks("target_entity", value);
          }
          setTarget_entity(value);
        }}
        onBlur={() => runValidationTasks("target_entity", target_entity)}
        errorMessage={errors.target_entity?.errorMessage}
        hasError={errors.target_entity?.hasError}
        {...getOverrideProps(overrides, "target_entity")}
      ></TextField>
      <TextField
        label="Target entity id"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={target_entity_id}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id: value,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.target_entity_id ?? value;
          }
          if (errors.target_entity_id?.hasError) {
            runValidationTasks("target_entity_id", value);
          }
          setTarget_entity_id(value);
        }}
        onBlur={() => runValidationTasks("target_entity_id", target_entity_id)}
        errorMessage={errors.target_entity_id?.errorMessage}
        hasError={errors.target_entity_id?.hasError}
        {...getOverrideProps(overrides, "target_entity_id")}
      ></TextField>
      <TextField
        label="Batch size"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={batch_size}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size: value,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.batch_size ?? value;
          }
          if (errors.batch_size?.hasError) {
            runValidationTasks("batch_size", value);
          }
          setBatch_size(value);
        }}
        onBlur={() => runValidationTasks("batch_size", batch_size)}
        errorMessage={errors.batch_size?.errorMessage}
        hasError={errors.batch_size?.hasError}
        {...getOverrideProps(overrides, "batch_size")}
      ></TextField>
      <TextField
        label="Retrieved items"
        isRequired={false}
        isReadOnly={false}
        value={retrieved_items}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items: value,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.retrieved_items ?? value;
          }
          if (errors.retrieved_items?.hasError) {
            runValidationTasks("retrieved_items", value);
          }
          setRetrieved_items(value);
        }}
        onBlur={() => runValidationTasks("retrieved_items", retrieved_items)}
        errorMessage={errors.retrieved_items?.errorMessage}
        hasError={errors.retrieved_items?.hasError}
        {...getOverrideProps(overrides, "retrieved_items")}
      ></TextField>
      <TextField
        label="Total amount retrieved"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total_amount_retrieved}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved: value,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.total_amount_retrieved ?? value;
          }
          if (errors.total_amount_retrieved?.hasError) {
            runValidationTasks("total_amount_retrieved", value);
          }
          setTotal_amount_retrieved(value);
        }}
        onBlur={() =>
          runValidationTasks("total_amount_retrieved", total_amount_retrieved)
        }
        errorMessage={errors.total_amount_retrieved?.errorMessage}
        hasError={errors.total_amount_retrieved?.hasError}
        {...getOverrideProps(overrides, "total_amount_retrieved")}
      ></TextField>
      <TextField
        label="Account profile id"
        isRequired={false}
        isReadOnly={false}
        value={account_profile_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id: value,
              filter_options,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.account_profile_id ?? value;
          }
          if (errors.account_profile_id?.hasError) {
            runValidationTasks("account_profile_id", value);
          }
          setAccount_profile_id(value);
        }}
        onBlur={() =>
          runValidationTasks("account_profile_id", account_profile_id)
        }
        errorMessage={errors.account_profile_id?.errorMessage}
        hasError={errors.account_profile_id?.hasError}
        {...getOverrideProps(overrides, "account_profile_id")}
      ></TextField>
      <TextAreaField
        label="Filter options"
        isRequired={false}
        isReadOnly={false}
        value={filter_options}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options: value,
              createdAt,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.filter_options ?? value;
          }
          if (errors.filter_options?.hasError) {
            runValidationTasks("filter_options", value);
          }
          setFilter_options(value);
        }}
        onBlur={() => runValidationTasks("filter_options", filter_options)}
        errorMessage={errors.filter_options?.errorMessage}
        hasError={errors.filter_options?.hasError}
        {...getOverrideProps(overrides, "filter_options")}
      ></TextAreaField>
      <TextField
        label="Created at"
        isRequired={false}
        isReadOnly={false}
        value={createdAt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt: value,
              updatedAt,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.createdAt ?? value;
          }
          if (errors.createdAt?.hasError) {
            runValidationTasks("createdAt", value);
          }
          setCreatedAt(value);
        }}
        onBlur={() => runValidationTasks("createdAt", createdAt)}
        errorMessage={errors.createdAt?.errorMessage}
        hasError={errors.createdAt?.hasError}
        {...getOverrideProps(overrides, "createdAt")}
      ></TextField>
      <TextField
        label="Updated at"
        isRequired={false}
        isReadOnly={false}
        value={updatedAt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt: value,
              total_processed,
            };
            const result = onChange(modelFields);
            value = result?.updatedAt ?? value;
          }
          if (errors.updatedAt?.hasError) {
            runValidationTasks("updatedAt", value);
          }
          setUpdatedAt(value);
        }}
        onBlur={() => runValidationTasks("updatedAt", updatedAt)}
        errorMessage={errors.updatedAt?.errorMessage}
        hasError={errors.updatedAt?.hasError}
        {...getOverrideProps(overrides, "updatedAt")}
      ></TextField>
      <TextField
        label="Total processed"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total_processed}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              mode,
              current_end_cursor,
              future_end_cursor,
              current_item_index,
              target_entity,
              target_entity_id,
              batch_size,
              retrieved_items,
              total_amount_retrieved,
              account_profile_id,
              filter_options,
              createdAt,
              updatedAt,
              total_processed: value,
            };
            const result = onChange(modelFields);
            value = result?.total_processed ?? value;
          }
          if (errors.total_processed?.hasError) {
            runValidationTasks("total_processed", value);
          }
          setTotal_processed(value);
        }}
        onBlur={() => runValidationTasks("total_processed", total_processed)}
        errorMessage={errors.total_processed?.errorMessage}
        hasError={errors.total_processed?.hasError}
        {...getOverrideProps(overrides, "total_processed")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || retrievedBatchProfileMetadataModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || retrievedBatchProfileMetadataModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
