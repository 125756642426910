/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getUserInfrastructureMetadata } from "../graphql/queries";
import { updateUserInfrastructureMetadata } from "../graphql/mutations";
const client = generateClient();
export default function UserInfrastructureMetadataUpdateForm(props) {
  const {
    id: idProp,
    userInfrastructureMetadata: userInfrastructureMetadataModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    sub_label: "",
    sub_tier: "",
    sending_is_limited_daily: false,
    sending_daily_limit: "",
    sending_is_limited_weekly: false,
    sending_weekly_limit: "",
    sending_is_limited_monthly: false,
    sending_monthly_limit: "",
    generation_is_limited_daily: false,
    generation_daily_limit: "",
    generation_is_limited_weekly: false,
    generation_weekly_limit: "",
    generation_is_limited_monthly: false,
    generation_monthly_limit: "",
    generation_is_limited: false,
    generation_limit: "",
    sending_is_limited: false,
    sending_limit: "",
    stripe_product_id: "",
  };
  const [sub_label, setSub_label] = React.useState(initialValues.sub_label);
  const [sub_tier, setSub_tier] = React.useState(initialValues.sub_tier);
  const [sending_is_limited_daily, setSending_is_limited_daily] =
    React.useState(initialValues.sending_is_limited_daily);
  const [sending_daily_limit, setSending_daily_limit] = React.useState(
    initialValues.sending_daily_limit
  );
  const [sending_is_limited_weekly, setSending_is_limited_weekly] =
    React.useState(initialValues.sending_is_limited_weekly);
  const [sending_weekly_limit, setSending_weekly_limit] = React.useState(
    initialValues.sending_weekly_limit
  );
  const [sending_is_limited_monthly, setSending_is_limited_monthly] =
    React.useState(initialValues.sending_is_limited_monthly);
  const [sending_monthly_limit, setSending_monthly_limit] = React.useState(
    initialValues.sending_monthly_limit
  );
  const [generation_is_limited_daily, setGeneration_is_limited_daily] =
    React.useState(initialValues.generation_is_limited_daily);
  const [generation_daily_limit, setGeneration_daily_limit] = React.useState(
    initialValues.generation_daily_limit
  );
  const [generation_is_limited_weekly, setGeneration_is_limited_weekly] =
    React.useState(initialValues.generation_is_limited_weekly);
  const [generation_weekly_limit, setGeneration_weekly_limit] = React.useState(
    initialValues.generation_weekly_limit
  );
  const [generation_is_limited_monthly, setGeneration_is_limited_monthly] =
    React.useState(initialValues.generation_is_limited_monthly);
  const [generation_monthly_limit, setGeneration_monthly_limit] =
    React.useState(initialValues.generation_monthly_limit);
  const [generation_is_limited, setGeneration_is_limited] = React.useState(
    initialValues.generation_is_limited
  );
  const [generation_limit, setGeneration_limit] = React.useState(
    initialValues.generation_limit
  );
  const [sending_is_limited, setSending_is_limited] = React.useState(
    initialValues.sending_is_limited
  );
  const [sending_limit, setSending_limit] = React.useState(
    initialValues.sending_limit
  );
  const [stripe_product_id, setStripe_product_id] = React.useState(
    initialValues.stripe_product_id
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = userInfrastructureMetadataRecord
      ? { ...initialValues, ...userInfrastructureMetadataRecord }
      : initialValues;
    setSub_label(cleanValues.sub_label);
    setSub_tier(cleanValues.sub_tier);
    setSending_is_limited_daily(cleanValues.sending_is_limited_daily);
    setSending_daily_limit(cleanValues.sending_daily_limit);
    setSending_is_limited_weekly(cleanValues.sending_is_limited_weekly);
    setSending_weekly_limit(cleanValues.sending_weekly_limit);
    setSending_is_limited_monthly(cleanValues.sending_is_limited_monthly);
    setSending_monthly_limit(cleanValues.sending_monthly_limit);
    setGeneration_is_limited_daily(cleanValues.generation_is_limited_daily);
    setGeneration_daily_limit(cleanValues.generation_daily_limit);
    setGeneration_is_limited_weekly(cleanValues.generation_is_limited_weekly);
    setGeneration_weekly_limit(cleanValues.generation_weekly_limit);
    setGeneration_is_limited_monthly(cleanValues.generation_is_limited_monthly);
    setGeneration_monthly_limit(cleanValues.generation_monthly_limit);
    setGeneration_is_limited(cleanValues.generation_is_limited);
    setGeneration_limit(cleanValues.generation_limit);
    setSending_is_limited(cleanValues.sending_is_limited);
    setSending_limit(cleanValues.sending_limit);
    setStripe_product_id(cleanValues.stripe_product_id);
    setErrors({});
  };
  const [
    userInfrastructureMetadataRecord,
    setUserInfrastructureMetadataRecord,
  ] = React.useState(userInfrastructureMetadataModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getUserInfrastructureMetadata.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getUserInfrastructureMetadata
        : userInfrastructureMetadataModelProp;
      setUserInfrastructureMetadataRecord(record);
    };
    queryData();
  }, [idProp, userInfrastructureMetadataModelProp]);
  React.useEffect(resetStateValues, [userInfrastructureMetadataRecord]);
  const validations = {
    sub_label: [],
    sub_tier: [],
    sending_is_limited_daily: [],
    sending_daily_limit: [],
    sending_is_limited_weekly: [],
    sending_weekly_limit: [],
    sending_is_limited_monthly: [],
    sending_monthly_limit: [],
    generation_is_limited_daily: [],
    generation_daily_limit: [],
    generation_is_limited_weekly: [],
    generation_weekly_limit: [],
    generation_is_limited_monthly: [],
    generation_monthly_limit: [],
    generation_is_limited: [],
    generation_limit: [],
    sending_is_limited: [],
    sending_limit: [],
    stripe_product_id: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          sub_label: sub_label ?? null,
          sub_tier: sub_tier ?? null,
          sending_is_limited_daily: sending_is_limited_daily ?? null,
          sending_daily_limit: sending_daily_limit ?? null,
          sending_is_limited_weekly: sending_is_limited_weekly ?? null,
          sending_weekly_limit: sending_weekly_limit ?? null,
          sending_is_limited_monthly: sending_is_limited_monthly ?? null,
          sending_monthly_limit: sending_monthly_limit ?? null,
          generation_is_limited_daily: generation_is_limited_daily ?? null,
          generation_daily_limit: generation_daily_limit ?? null,
          generation_is_limited_weekly: generation_is_limited_weekly ?? null,
          generation_weekly_limit: generation_weekly_limit ?? null,
          generation_is_limited_monthly: generation_is_limited_monthly ?? null,
          generation_monthly_limit: generation_monthly_limit ?? null,
          generation_is_limited: generation_is_limited ?? null,
          generation_limit: generation_limit ?? null,
          sending_is_limited: sending_is_limited ?? null,
          sending_limit: sending_limit ?? null,
          stripe_product_id: stripe_product_id ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateUserInfrastructureMetadata.replaceAll(
              "__typename",
              ""
            ),
            variables: {
              input: {
                id: userInfrastructureMetadataRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "UserInfrastructureMetadataUpdateForm")}
      {...rest}
    >
      <TextField
        label="Sub label"
        isRequired={false}
        isReadOnly={false}
        value={sub_label}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              sub_label: value,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.sub_label ?? value;
          }
          if (errors.sub_label?.hasError) {
            runValidationTasks("sub_label", value);
          }
          setSub_label(value);
        }}
        onBlur={() => runValidationTasks("sub_label", sub_label)}
        errorMessage={errors.sub_label?.errorMessage}
        hasError={errors.sub_label?.hasError}
        {...getOverrideProps(overrides, "sub_label")}
      ></TextField>
      <TextField
        label="Sub tier"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={sub_tier}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier: value,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.sub_tier ?? value;
          }
          if (errors.sub_tier?.hasError) {
            runValidationTasks("sub_tier", value);
          }
          setSub_tier(value);
        }}
        onBlur={() => runValidationTasks("sub_tier", sub_tier)}
        errorMessage={errors.sub_tier?.errorMessage}
        hasError={errors.sub_tier?.hasError}
        {...getOverrideProps(overrides, "sub_tier")}
      ></TextField>
      <SwitchField
        label="Sending is limited daily"
        defaultChecked={false}
        isDisabled={false}
        isChecked={sending_is_limited_daily}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily: value,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.sending_is_limited_daily ?? value;
          }
          if (errors.sending_is_limited_daily?.hasError) {
            runValidationTasks("sending_is_limited_daily", value);
          }
          setSending_is_limited_daily(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "sending_is_limited_daily",
            sending_is_limited_daily
          )
        }
        errorMessage={errors.sending_is_limited_daily?.errorMessage}
        hasError={errors.sending_is_limited_daily?.hasError}
        {...getOverrideProps(overrides, "sending_is_limited_daily")}
      ></SwitchField>
      <TextField
        label="Sending daily limit"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={sending_daily_limit}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit: value,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.sending_daily_limit ?? value;
          }
          if (errors.sending_daily_limit?.hasError) {
            runValidationTasks("sending_daily_limit", value);
          }
          setSending_daily_limit(value);
        }}
        onBlur={() =>
          runValidationTasks("sending_daily_limit", sending_daily_limit)
        }
        errorMessage={errors.sending_daily_limit?.errorMessage}
        hasError={errors.sending_daily_limit?.hasError}
        {...getOverrideProps(overrides, "sending_daily_limit")}
      ></TextField>
      <SwitchField
        label="Sending is limited weekly"
        defaultChecked={false}
        isDisabled={false}
        isChecked={sending_is_limited_weekly}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly: value,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.sending_is_limited_weekly ?? value;
          }
          if (errors.sending_is_limited_weekly?.hasError) {
            runValidationTasks("sending_is_limited_weekly", value);
          }
          setSending_is_limited_weekly(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "sending_is_limited_weekly",
            sending_is_limited_weekly
          )
        }
        errorMessage={errors.sending_is_limited_weekly?.errorMessage}
        hasError={errors.sending_is_limited_weekly?.hasError}
        {...getOverrideProps(overrides, "sending_is_limited_weekly")}
      ></SwitchField>
      <TextField
        label="Sending weekly limit"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={sending_weekly_limit}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit: value,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.sending_weekly_limit ?? value;
          }
          if (errors.sending_weekly_limit?.hasError) {
            runValidationTasks("sending_weekly_limit", value);
          }
          setSending_weekly_limit(value);
        }}
        onBlur={() =>
          runValidationTasks("sending_weekly_limit", sending_weekly_limit)
        }
        errorMessage={errors.sending_weekly_limit?.errorMessage}
        hasError={errors.sending_weekly_limit?.hasError}
        {...getOverrideProps(overrides, "sending_weekly_limit")}
      ></TextField>
      <SwitchField
        label="Sending is limited monthly"
        defaultChecked={false}
        isDisabled={false}
        isChecked={sending_is_limited_monthly}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly: value,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.sending_is_limited_monthly ?? value;
          }
          if (errors.sending_is_limited_monthly?.hasError) {
            runValidationTasks("sending_is_limited_monthly", value);
          }
          setSending_is_limited_monthly(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "sending_is_limited_monthly",
            sending_is_limited_monthly
          )
        }
        errorMessage={errors.sending_is_limited_monthly?.errorMessage}
        hasError={errors.sending_is_limited_monthly?.hasError}
        {...getOverrideProps(overrides, "sending_is_limited_monthly")}
      ></SwitchField>
      <TextField
        label="Sending monthly limit"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={sending_monthly_limit}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit: value,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.sending_monthly_limit ?? value;
          }
          if (errors.sending_monthly_limit?.hasError) {
            runValidationTasks("sending_monthly_limit", value);
          }
          setSending_monthly_limit(value);
        }}
        onBlur={() =>
          runValidationTasks("sending_monthly_limit", sending_monthly_limit)
        }
        errorMessage={errors.sending_monthly_limit?.errorMessage}
        hasError={errors.sending_monthly_limit?.hasError}
        {...getOverrideProps(overrides, "sending_monthly_limit")}
      ></TextField>
      <SwitchField
        label="Generation is limited daily"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generation_is_limited_daily}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily: value,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.generation_is_limited_daily ?? value;
          }
          if (errors.generation_is_limited_daily?.hasError) {
            runValidationTasks("generation_is_limited_daily", value);
          }
          setGeneration_is_limited_daily(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "generation_is_limited_daily",
            generation_is_limited_daily
          )
        }
        errorMessage={errors.generation_is_limited_daily?.errorMessage}
        hasError={errors.generation_is_limited_daily?.hasError}
        {...getOverrideProps(overrides, "generation_is_limited_daily")}
      ></SwitchField>
      <TextField
        label="Generation daily limit"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={generation_daily_limit}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit: value,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.generation_daily_limit ?? value;
          }
          if (errors.generation_daily_limit?.hasError) {
            runValidationTasks("generation_daily_limit", value);
          }
          setGeneration_daily_limit(value);
        }}
        onBlur={() =>
          runValidationTasks("generation_daily_limit", generation_daily_limit)
        }
        errorMessage={errors.generation_daily_limit?.errorMessage}
        hasError={errors.generation_daily_limit?.hasError}
        {...getOverrideProps(overrides, "generation_daily_limit")}
      ></TextField>
      <SwitchField
        label="Generation is limited weekly"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generation_is_limited_weekly}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly: value,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.generation_is_limited_weekly ?? value;
          }
          if (errors.generation_is_limited_weekly?.hasError) {
            runValidationTasks("generation_is_limited_weekly", value);
          }
          setGeneration_is_limited_weekly(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "generation_is_limited_weekly",
            generation_is_limited_weekly
          )
        }
        errorMessage={errors.generation_is_limited_weekly?.errorMessage}
        hasError={errors.generation_is_limited_weekly?.hasError}
        {...getOverrideProps(overrides, "generation_is_limited_weekly")}
      ></SwitchField>
      <TextField
        label="Generation weekly limit"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={generation_weekly_limit}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit: value,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.generation_weekly_limit ?? value;
          }
          if (errors.generation_weekly_limit?.hasError) {
            runValidationTasks("generation_weekly_limit", value);
          }
          setGeneration_weekly_limit(value);
        }}
        onBlur={() =>
          runValidationTasks("generation_weekly_limit", generation_weekly_limit)
        }
        errorMessage={errors.generation_weekly_limit?.errorMessage}
        hasError={errors.generation_weekly_limit?.hasError}
        {...getOverrideProps(overrides, "generation_weekly_limit")}
      ></TextField>
      <SwitchField
        label="Generation is limited monthly"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generation_is_limited_monthly}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly: value,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.generation_is_limited_monthly ?? value;
          }
          if (errors.generation_is_limited_monthly?.hasError) {
            runValidationTasks("generation_is_limited_monthly", value);
          }
          setGeneration_is_limited_monthly(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "generation_is_limited_monthly",
            generation_is_limited_monthly
          )
        }
        errorMessage={errors.generation_is_limited_monthly?.errorMessage}
        hasError={errors.generation_is_limited_monthly?.hasError}
        {...getOverrideProps(overrides, "generation_is_limited_monthly")}
      ></SwitchField>
      <TextField
        label="Generation monthly limit"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={generation_monthly_limit}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit: value,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.generation_monthly_limit ?? value;
          }
          if (errors.generation_monthly_limit?.hasError) {
            runValidationTasks("generation_monthly_limit", value);
          }
          setGeneration_monthly_limit(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "generation_monthly_limit",
            generation_monthly_limit
          )
        }
        errorMessage={errors.generation_monthly_limit?.errorMessage}
        hasError={errors.generation_monthly_limit?.hasError}
        {...getOverrideProps(overrides, "generation_monthly_limit")}
      ></TextField>
      <SwitchField
        label="Generation is limited"
        defaultChecked={false}
        isDisabled={false}
        isChecked={generation_is_limited}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited: value,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.generation_is_limited ?? value;
          }
          if (errors.generation_is_limited?.hasError) {
            runValidationTasks("generation_is_limited", value);
          }
          setGeneration_is_limited(value);
        }}
        onBlur={() =>
          runValidationTasks("generation_is_limited", generation_is_limited)
        }
        errorMessage={errors.generation_is_limited?.errorMessage}
        hasError={errors.generation_is_limited?.hasError}
        {...getOverrideProps(overrides, "generation_is_limited")}
      ></SwitchField>
      <TextField
        label="Generation limit"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={generation_limit}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit: value,
              sending_is_limited,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.generation_limit ?? value;
          }
          if (errors.generation_limit?.hasError) {
            runValidationTasks("generation_limit", value);
          }
          setGeneration_limit(value);
        }}
        onBlur={() => runValidationTasks("generation_limit", generation_limit)}
        errorMessage={errors.generation_limit?.errorMessage}
        hasError={errors.generation_limit?.hasError}
        {...getOverrideProps(overrides, "generation_limit")}
      ></TextField>
      <SwitchField
        label="Sending is limited"
        defaultChecked={false}
        isDisabled={false}
        isChecked={sending_is_limited}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited: value,
              sending_limit,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.sending_is_limited ?? value;
          }
          if (errors.sending_is_limited?.hasError) {
            runValidationTasks("sending_is_limited", value);
          }
          setSending_is_limited(value);
        }}
        onBlur={() =>
          runValidationTasks("sending_is_limited", sending_is_limited)
        }
        errorMessage={errors.sending_is_limited?.errorMessage}
        hasError={errors.sending_is_limited?.hasError}
        {...getOverrideProps(overrides, "sending_is_limited")}
      ></SwitchField>
      <TextField
        label="Sending limit"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={sending_limit}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit: value,
              stripe_product_id,
            };
            const result = onChange(modelFields);
            value = result?.sending_limit ?? value;
          }
          if (errors.sending_limit?.hasError) {
            runValidationTasks("sending_limit", value);
          }
          setSending_limit(value);
        }}
        onBlur={() => runValidationTasks("sending_limit", sending_limit)}
        errorMessage={errors.sending_limit?.errorMessage}
        hasError={errors.sending_limit?.hasError}
        {...getOverrideProps(overrides, "sending_limit")}
      ></TextField>
      <TextField
        label="Stripe product id"
        isRequired={false}
        isReadOnly={false}
        value={stripe_product_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              sub_label,
              sub_tier,
              sending_is_limited_daily,
              sending_daily_limit,
              sending_is_limited_weekly,
              sending_weekly_limit,
              sending_is_limited_monthly,
              sending_monthly_limit,
              generation_is_limited_daily,
              generation_daily_limit,
              generation_is_limited_weekly,
              generation_weekly_limit,
              generation_is_limited_monthly,
              generation_monthly_limit,
              generation_is_limited,
              generation_limit,
              sending_is_limited,
              sending_limit,
              stripe_product_id: value,
            };
            const result = onChange(modelFields);
            value = result?.stripe_product_id ?? value;
          }
          if (errors.stripe_product_id?.hasError) {
            runValidationTasks("stripe_product_id", value);
          }
          setStripe_product_id(value);
        }}
        onBlur={() =>
          runValidationTasks("stripe_product_id", stripe_product_id)
        }
        errorMessage={errors.stripe_product_id?.errorMessage}
        hasError={errors.stripe_product_id?.hasError}
        {...getOverrideProps(overrides, "stripe_product_id")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || userInfrastructureMetadataModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || userInfrastructureMetadataModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
