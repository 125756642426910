/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function SettingsNachrichten(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        "Button Icon4509799": {},
        label4509800: {},
        "Button Icon4509801": {},
        Button4509798: {},
        "Button Icon46221112": {},
        label46221113: {},
        "Button Icon46221114": {},
        Button46221111: {},
        "Button Icon4509795": {},
        label4509796: {},
        "Button Icon4509797": {},
        Button4509794: {},
        Layout: {},
        SettingsNachrichten: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        "Button Icon4509799": {},
        label4509800: { fontSize: "14px", lineHeight: "21px" },
        "Button Icon4509801": {},
        Button4509798: {},
        "Button Icon46221112": {},
        label46221113: { fontSize: "14px", lineHeight: "21px" },
        "Button Icon46221114": {},
        Button46221111: {},
        "Button Icon4509795": {},
        label4509796: { fontSize: "14px", lineHeight: "21px" },
        "Button Icon4509797": {},
        Button4509794: {},
        Layout: {},
        SettingsNachrichten: { width: "759px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        "Button Icon4509799": {},
        label4509800: { fontSize: "12px", lineHeight: "21px", shrink: "0" },
        "Button Icon4509801": {},
        Button4509798: {
          width: "unset",
          height: "unset",
          alignSelf: "stretch",
        },
        "Button Icon46221112": {},
        label46221113: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
          shrink: "0",
        },
        "Button Icon46221114": {},
        Button46221111: {
          width: "unset",
          height: "unset",
          alignSelf: "stretch",
        },
        "Button Icon4509795": {},
        label4509796: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
          shrink: "0",
        },
        "Button Icon4509797": {},
        Button4509794: {
          width: "unset",
          height: "unset",
          alignSelf: "stretch",
        },
        Layout: {},
        SettingsNachrichten: { width: "483px" },
      },
      variantValues: { breakpoint: "out" },
    },
    {
      overrides: {
        "Button Icon4509799": {},
        label4509800: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
          shrink: "0",
        },
        "Button Icon4509801": {},
        Button4509798: {
          width: "unset",
          height: "unset",
          shrink: "1",
          padding: "4px 4px 4px 4px",
          grow: "1",
          basis: "0",
        },
        "Button Icon46221112": {},
        label46221113: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
          shrink: "0",
        },
        "Button Icon46221114": {},
        Button46221111: {
          width: "unset",
          height: "unset",
          shrink: "1",
          padding: "4px 4px 4px 4px",
          grow: "1",
          basis: "0",
        },
        "Button Icon4509795": {},
        label4509796: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
          shrink: "0",
        },
        "Button Icon4509797": {},
        Button4509794: {
          width: "unset",
          height: "unset",
          shrink: "1",
          padding: "4px 4px 4px 4px",
          grow: "1",
          basis: "0",
        },
        Layout: { justifyContent: "flex-start" },
        SettingsNachrichten: { width: "360px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="812px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="16px 25px 16px 25px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "SettingsNachrichten")}
      {...rest}
    >
      <Flex
        gap="12px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="8px 20px 8px 20px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="0"
          direction="row"
          width="171.19px"
          height="38.19px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1.09px SOLID rgba(174,179,183,1)"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="5.0942230224609375px"
          padding="8.7537841796875px 17.507568359375px 8.7537841796875px 17.507568359375px"
          display="flex"
          {...getOverrideProps(overrides, "Button4509798")}
        >
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon4509799")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(245,245,245,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Ausstehend"
            {...getOverrideProps(overrides, "label4509800")}
          ></Text>
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon4509801")}
          ></Icon>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="171.19px"
          height="38.19px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1.09px SOLID rgba(174,179,183,1)"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="5.0942230224609375px"
          padding="8.7537841796875px 17.507568359375px 8.7537841796875px 17.507568359375px"
          display="flex"
          {...getOverrideProps(overrides, "Button46221111")}
        >
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon46221112")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(245,245,245,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Geplant"
            {...getOverrideProps(overrides, "label46221113")}
          ></Text>
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon46221114")}
          ></Icon>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="171.19px"
          height="38.19px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1.09px SOLID rgba(174,179,183,1)"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="5.0942230224609375px"
          padding="8.7537841796875px 17.507568359375px 8.7537841796875px 17.507568359375px"
          display="flex"
          {...getOverrideProps(overrides, "Button4509794")}
        >
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon4509795")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(245,245,245,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Verschickt"
            {...getOverrideProps(overrides, "label4509796")}
          ></Text>
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon4509797")}
          ></Icon>
        </Flex>
      </Flex>
    </Flex>
  );
}
