import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { Authenticator, View, Text, Image } from '@aws-amplify/ui-react';
import './index.css';
import '@aws-amplify/ui-react/styles.css';
import config from './aws-exports';
import { Amplify } from 'aws-amplify';
import LandingPage from 'components/Landing_page';
import Layout from 'components/SettingsWrapper';
// Import other components as needed
//import './App.css';
import Test from 'components/Test';
import SubscriptionTablePage from 'components/SubscriptionTablePage';
import SubscriptionSuccess from 'components/SubscriptionSuccess';
import SubscriptionFail from 'components/SubscriptionFail';
import { useTheme } from '@aws-amplify/ui-react';
import { signOut } from 'aws-amplify/auth';

Amplify.configure(config);

const AppWithAuthenticator = () => {
  const { tokens } = useTheme();
  const components = {
    Header() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="OptimaticLeads_logo" src={process.env.REACT_APP_IMG_6} />
        </View>
      );
    },
    Footer() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</Text>
        </View>
      );
    },
  };

  return (
    <Authenticator components={components}>
      <Layout>
        <App />
      </Layout>
    </Authenticator>
  );
};

const SubscriptionPageAuthenticated = () => {
  const { tokens } = useTheme();
  const components = {
    Header() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="OptimaticLeads_logo" src={process.env.REACT_APP_IMG_6} />
        </View>
      );
    },
    Footer() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</Text>
        </View>
      );
    },
  };

  return (
    <Authenticator components={components}>
      <SubscriptionTablePage />
    </Authenticator>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ThemeProvider>
      {/* Uncomment and use React.StrictMode during development for extra checks and warnings */}
      {/* <React.StrictMode> */}
      <Routes>
        <Route path="/app/*" element={<AppWithAuthenticator />} />
        <Route path="/" element={<LandingPage  signOut={signOut}  />} />
        <Route path="/test" element={<Test />} />
        <Route path="/subscribe" element={<SubscriptionPageAuthenticated />} />
        <Route path="/subscription_success" element={<SubscriptionSuccess />} />
        <Route path="/subscription_fail" element={<SubscriptionFail />} />
        {/* Define other routes as needed */}
      </Routes>
      {/* </React.StrictMode> */}
    </ThemeProvider>
  </BrowserRouter>
);
