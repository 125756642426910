import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import React from 'react';
import config from '../aws-exports';
import PriceTable from 'ui-components/PriceTable';
import { useEffect , useState } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';

Amplify.configure(config);






const SubscriptionTablePage = () => {

  const [successURL , setSuccessURL] = useState('/subscription_success')
  const [failURL , setFailURL] = useState('/subscription_fail')

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail]= useState('');
  const [sessionURL, setSessionURL] = useState('')


  useEffect(() => {
    const fetchSession = async () => {
      try {
        const session = await fetchAuthSession();
        console.log("logged_in_details", session);
        setUserEmail(session.tokens.idToken.payload.email)
        console.log(session.tokens.idToken.payload.name)
        setIsLoggedIn(true);

      } catch (error) {
        console.log("User not logged in or unable to fetch session", error);
      }
    };

    fetchSession();
  }, []);


  const handleSubscription_starter = async () => {
        
    console.log("subscription initiated")
    console.log(userEmail)
    console.log(successURL,failURL)

    const domain = window.location.origin;

    //console.log("domain is", `${domain}${successURL}`)
    const stripe = require('stripe')(process.env.REACT_APP_STRIPE_API_KEY);

    const session = await stripe.checkout.sessions.create({
        customer_email : userEmail,
        success_url: `${domain}${successURL}`, //successURL,
        cancel_url : `${domain}${failURL}`, //failURL,
        mode : 'subscription',
        line_items: [
            {
            price: process.env.REACT_APP_STRIPE_STARTER_PRICE_ID,
            quantity: 1,
            },
        ],
        automatic_tax: { enabled: true },

        });

    console.log("session is",session)
    setSessionURL(session.url)


    window.open(session.url, '_blank', 'noopener,noreferrer');

  }




  return (
    <div style={{ backgroundColor: '#002640' }}  className="flex flex-col items-center justify-center min-h-screen">


  <PriceTable className='m-10'
              overrides={{
                  PriceTable:{
                      width: '100%',
                      backgroundColor: '#002640',
                  },
                  Layout:{
                      maxWidth: '1200px',
                  },
                  Button_1:{
                    style: {
                        cursor: "pointer",
                    },
                    onClick: () => handleSubscription_starter(),
                },
                 
              }}
              
              />
</div>


  );
};

export default SubscriptionTablePage;
