/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStripeSessionsLog = /* GraphQL */ `
  query GetStripeSessionsLog($id: ID!) {
    getStripeSessionsLog(id: $id) {
      id
      event_id
      event_metadata
      account_owner
      email
      hosted_invoice_url
      event_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStripeSessionsLogs = /* GraphQL */ `
  query ListStripeSessionsLogs(
    $filter: ModelStripeSessionsLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripeSessionsLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_id
        event_metadata
        account_owner
        email
        hosted_invoice_url
        event_type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserInfrastructureMetadata = /* GraphQL */ `
  query GetUserInfrastructureMetadata($id: ID!) {
    getUserInfrastructureMetadata(id: $id) {
      id
      sub_label
      sub_tier
      sending_is_limited_daily
      sending_daily_limit
      sending_is_limited_weekly
      sending_weekly_limit
      sending_is_limited_monthly
      sending_monthly_limit
      generation_is_limited_daily
      generation_daily_limit
      generation_is_limited_weekly
      generation_weekly_limit
      generation_is_limited_monthly
      generation_monthly_limit
      generation_is_limited
      generation_limit
      sending_is_limited
      sending_limit
      stripe_product_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserInfrastructureMetadata = /* GraphQL */ `
  query ListUserInfrastructureMetadata(
    $filter: ModelUserInfrastructureMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInfrastructureMetadata(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub_label
        sub_tier
        sending_is_limited_daily
        sending_daily_limit
        sending_is_limited_weekly
        sending_weekly_limit
        sending_is_limited_monthly
        sending_monthly_limit
        generation_is_limited_daily
        generation_daily_limit
        generation_is_limited_weekly
        generation_weekly_limit
        generation_is_limited_monthly
        generation_monthly_limit
        generation_is_limited
        generation_limit
        sending_is_limited
        sending_limit
        stripe_product_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactForm = /* GraphQL */ `
  query GetContactForm($id: ID!) {
    getContactForm(id: $id) {
      id
      first_name
      last_name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactForms = /* GraphQL */ `
  query ListContactForms(
    $filter: ModelContactFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        last_name
        email
        message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProperties = /* GraphQL */ `
  query GetUserProperties($id: ID!) {
    getUserProperties(id: $id) {
      id
      account_owner
      sub_label
      sub_tier
      total_msgs_generated
      total_msgs_generated_day
      total_msgs_generated_month
      total_msgs_sent
      total_msgs_sent_day
      total_msgs_sent_month
      total_msgs_generated_week
      total_msgs_sent_week
      createdAt
      updatedAt
      stripe_subscription_id
      stripe_customer_id
      stripe_plan_id
      email
      stripe_subscription_status
      stripe_subscription_start
      credits_used
      credits_available
      __typename
    }
  }
`;
export const listUserProperties = /* GraphQL */ `
  query ListUserProperties(
    $filter: ModelUserPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account_owner
        sub_label
        sub_tier
        total_msgs_generated
        total_msgs_generated_day
        total_msgs_generated_month
        total_msgs_sent
        total_msgs_sent_day
        total_msgs_sent_month
        total_msgs_generated_week
        total_msgs_sent_week
        createdAt
        updatedAt
        stripe_subscription_id
        stripe_customer_id
        stripe_plan_id
        email
        stripe_subscription_status
        stripe_subscription_start
        credits_used
        credits_available
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewsletter = /* GraphQL */ `
  query GetNewsletter($id: ID!) {
    getNewsletter(id: $id) {
      id
      email
      mode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNewsletters = /* GraphQL */ `
  query ListNewsletters(
    $filter: ModelNewsletterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        mode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssignedProxyUrls = /* GraphQL */ `
  query GetAssignedProxyUrls($id: ID!) {
    getAssignedProxyUrls(id: $id) {
      id
      account_owner
      proxy_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssignedProxyUrls = /* GraphQL */ `
  query ListAssignedProxyUrls(
    $filter: ModelAssignedProxyUrlsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssignedProxyUrls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_owner
        proxy_url
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDisplayedTargetUsers = /* GraphQL */ `
  query GetDisplayedTargetUsers($id: ID!) {
    getDisplayedTargetUsers(id: $id) {
      id
      account_owner
      target_account_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDisplayedTargetUsers = /* GraphQL */ `
  query ListDisplayedTargetUsers(
    $filter: ModelDisplayedTargetUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisplayedTargetUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_owner
        target_account_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPredefinedMessages = /* GraphQL */ `
  query GetPredefinedMessages($id: ID!) {
    getPredefinedMessages(id: $id) {
      id
      account_owner
      predefined_message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPredefinedMessages = /* GraphQL */ `
  query ListPredefinedMessages(
    $filter: ModelPredefinedMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPredefinedMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_owner
        predefined_message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWaitingForConfirmation = /* GraphQL */ `
  query GetWaitingForConfirmation($id: ID!) {
    getWaitingForConfirmation(id: $id) {
      id
      account_owner
      target_user_id
      target_username
      outreach_message
      score
      target_assumed_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWaitingForConfirmations = /* GraphQL */ `
  query ListWaitingForConfirmations(
    $filter: ModelWaitingForConfirmationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWaitingForConfirmations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_owner
        target_user_id
        target_username
        outreach_message
        score
        target_assumed_name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRetrievedBatchProfileMetadata = /* GraphQL */ `
  query GetRetrievedBatchProfileMetadata($id: ID!) {
    getRetrievedBatchProfileMetadata(id: $id) {
      id
      account_owner
      mode
      current_end_cursor
      future_end_cursor
      current_item_index
      target_entity
      target_entity_id
      batch_size
      retrieved_items
      total_amount_retrieved
      account_profile_id
      filter_options
      createdAt
      updatedAt
      total_processed
      __typename
    }
  }
`;
export const listRetrievedBatchProfileMetadata = /* GraphQL */ `
  query ListRetrievedBatchProfileMetadata(
    $filter: ModelRetrievedBatchProfileMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetrievedBatchProfileMetadata(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account_owner
        mode
        current_end_cursor
        future_end_cursor
        current_item_index
        target_entity
        target_entity_id
        batch_size
        retrieved_items
        total_amount_retrieved
        account_profile_id
        filter_options
        createdAt
        updatedAt
        total_processed
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRetrievedTargetProfiles = /* GraphQL */ `
  query GetRetrievedTargetProfiles($id: ID!) {
    getRetrievedTargetProfiles(id: $id) {
      id
      pk
      username
      full_name
      profile_pic_url
      profile_pic_url_hd
      is_verified
      media_count
      follower_count
      following_count
      biography
      external_url
      account_type
      is_business
      public_email
      contact_phone_number
      public_phone_country_code
      public_phone_number
      business_contact_method
      business_category_name
      category_name
      category
      address_street
      city_id
      city_name
      latitude
      longitude
      zip
      instagram_location_id
      interop_messaging_user_fbid
      is_private
      assumed_gender
      assumed_location
      assumed_location_pk
      assumed_name
      profile_pic_s3_link
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRetrievedTargetProfiles = /* GraphQL */ `
  query ListRetrievedTargetProfiles(
    $filter: ModelRetrievedTargetProfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetrievedTargetProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        username
        full_name
        profile_pic_url
        profile_pic_url_hd
        is_verified
        media_count
        follower_count
        following_count
        biography
        external_url
        account_type
        is_business
        public_email
        contact_phone_number
        public_phone_country_code
        public_phone_number
        business_contact_method
        business_category_name
        category_name
        category
        address_street
        city_id
        city_name
        latitude
        longitude
        zip
        instagram_location_id
        interop_messaging_user_fbid
        is_private
        assumed_gender
        assumed_location
        assumed_location_pk
        assumed_name
        profile_pic_s3_link
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageLog = /* GraphQL */ `
  query GetMessageLog($id: ID!) {
    getMessageLog(id: $id) {
      id
      account_owner
      sent_to_user_id
      sent_to_username
      current_status
      scheduled_time
      reponded_to
      message_response
      conversation_id
      error_code
      sent_at
      sent_from_username
      message
      createdAt
      updatedAt
      similarity_score
      sent_from_id
      sent_to_assumed_name
      __typename
    }
  }
`;
export const listMessageLogs = /* GraphQL */ `
  query ListMessageLogs(
    $filter: ModelMessageLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account_owner
        sent_to_user_id
        sent_to_username
        current_status
        scheduled_time
        reponded_to
        message_response
        conversation_id
        error_code
        sent_at
        sent_from_username
        message
        createdAt
        updatedAt
        similarity_score
        sent_from_id
        sent_to_assumed_name
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIgAccounts = /* GraphQL */ `
  query GetIgAccounts($id: ID!) {
    getIgAccounts(id: $id) {
      id
      username
      password
      proxy_url
      account_owner
      createdAt
      updatedAt
      is_active
      __typename
    }
  }
`;
export const listIgAccounts = /* GraphQL */ `
  query ListIgAccounts(
    $filter: ModelIgAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIgAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        password
        proxy_url
        account_owner
        createdAt
        updatedAt
        is_active
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      account_owner
      profile_name
      company_description
      purpose_of_campaign
      call_to_action
      contact_person
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account_owner
        profile_name
        company_description
        purpose_of_campaign
        call_to_action
        contact_person
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
