/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Button,
  Flex,
  Loader,
  Text,
  TextField,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function AddAccount(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        "Konto Verkn\u00FCpfen": {},
        Title: {},
        label50341272: {},
        "Frame 44417": {},
        TextField50341274: {},
        "Frame 44423": {},
        "Frame 44415": {},
        label50341278: {},
        "Frame 44418": {},
        TextField50341280: {},
        "Frame 44425": {},
        "Frame 44416": {},
        "Frame 44424": {},
        twoFA_ph: {},
        Frame4441666: {},
        TextField2FA: {},
        Frame444425: {},
        Frame2FABody: {},
        Frame2FA: {},
        body: {},
        Button: {},
        "Frame 4441668": {},
        Loader: {},
        "Frame 4441669": {},
        "Bitte Warten Sie, w\u00E4hrend die Verbindung hergestellt wird, dies kann bis zu 1 Minute dauern.":
          {},
        "Frame 4441670": {},
        FrameLoading: {},
        buttomFrame: {},
        Layout: {},
        AddAccount: {},
      },
      variantValues: { breakpoint: "medium" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "medium",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="680px"
      height="544px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "AddAccount")}
      {...rest}
    >
      <Flex
        gap="48px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="32px 25px 32px 25px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="21.5625px"
          direction="row"
          width="unset"
          height="40px"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          borderRadius="10px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(30,136,229,1)"
          display="none"
          {...getOverrideProps(overrides, "Title")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(245,245,245,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children=" Konto Verknüpfen "
            {...getOverrideProps(overrides, "Konto Verkn\u00FCpfen")}
          ></Text>
        </Flex>
        <Flex
          gap="30px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "body")}
        >
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44415")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="4px"
              padding="4px 10px 4px 10px"
              backgroundColor="rgba(30,136,229,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44417")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(245,245,245,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Benutzername"
                {...getOverrideProps(overrides, "label50341272")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44423")}
            >
              <TextField
                width="unset"
                height="unset"
                placeholder="..."
                shrink="0"
                alignSelf="stretch"
                borderRadius="4px"
                backgroundColor="rgba(245,245,245,1)"
                size="small"
                isDisabled={false}
                labelHidden={true}
                variation="default"
                {...getOverrideProps(overrides, "TextField50341274")}
              ></TextField>
            </Flex>
          </Flex>
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44424")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44416")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="4px 10px 4px 10px"
                backgroundColor="rgba(30,136,229,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44418")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Passwort"
                  {...getOverrideProps(overrides, "label50341278")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44425")}
              >
                <TextField
                  width="unset"
                  height="unset"
                  placeholder="..."
                  shrink="0"
                  alignSelf="stretch"
                  borderRadius="4px"
                  size="small"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField50341280")}
                ></TextField>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame2FA")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame2FABody")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="4px 10px 4px 10px"
                backgroundColor="rgba(246,114,35,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame4441666")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="6-stelliger Verifizierungscode (falls 2FA aktiviert ist)."
                  {...getOverrideProps(overrides, "twoFA_ph")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame444425")}
              >
                <TextField
                  width="unset"
                  height="unset"
                  shrink="0"
                  alignSelf="stretch"
                  borderRadius="4px"
                  size="small"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField2FA")}
                ></TextField>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "buttomFrame")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4441668")}
          >
            <Button
              width="unset"
              height="unset"
              shrink="0"
              backgroundColor="rgba(30,136,229,1)"
              size="default"
              isDisabled={false}
              variation="default"
              children="Verknüpfen"
              {...getOverrideProps(overrides, "Button")}
            ></Button>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "FrameLoading")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4441669")}
            >
              <Loader
                shrink="0"
                size="large"
                variation="circular"
                {...getOverrideProps(overrides, "Loader")}
              ></Loader>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4441670")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="700"
                color="rgba(255,255,255,1)"
                lineHeight="21px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Bitte Warten Sie, während die Verbindung hergestellt wird, dies kann bis zu 1 Minute dauern."
                {...getOverrideProps(
                  overrides,
                  "Bitte Warten Sie, w\u00E4hrend die Verbindung hergestellt wird, dies kann bis zu 1 Minute dauern."
                )}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
