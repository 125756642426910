/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Button,
  Flex,
  Text,
  TextAreaField,
  TextField,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import Checkcircleoutline from "./Checkcircleoutline";
export default function AddProfile(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        "Unternehmensprofil hinzuf\u00FCgen": {},
        "Edit Profile": {},
        label46311328: {},
        descriptiveText46311329: {},
        "Frame 44539": {},
        TextField_title: {},
        "Frame 445385259776": {},
        "Frame 44540": {},
        label46311367: {},
        descriptiveText46311368: {},
        "Frame 44541": {},
        TextAreaField_goal: {},
        Framepoc: {},
        Framezz: {},
        label46311388: {},
        descriptiveText46311389: {},
        "Frame 44545": {},
        TextAreaField_comp_desc: {},
        Framecd: {},
        Frameyy: {},
        label46311348: {},
        descriptiveText46311349: {},
        "Frame 44547": {},
        TextField_CTA: {},
        "Frame 445385360787": {},
        "Frame 44549": {},
        label46311355: {},
        descriptiveText46311356: {},
        "Frame 44550": {},
        TextField_CP: {},
        "Frame 445385360795": {},
        "Frame 44552": {},
        "Frame 44554": {},
        Button: {},
        check_circle_outline: {},
        "Frame 44553": {},
        "Frame 44555": {},
        Layout: {},
        AddProfile: {},
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="951px"
      height="1155px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="20px 0px 20px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "AddProfile")}
      {...rest}
    >
      <Flex
        gap="30px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="40px 20px 40px 20px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="21.5625px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          borderRadius="4px"
          padding="8px 0px 8px 0px"
          backgroundColor="rgba(30,136,229,1)"
          display="flex"
          {...getOverrideProps(overrides, "Edit Profile")}
        >
          <Text
            fontFamily="Inter"
            fontSize="21.5625px"
            fontWeight="700"
            color="rgba(245,245,245,1)"
            lineHeight="26.953125px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Unternehmensprofil hinzufügen"
            {...getOverrideProps(
              overrides,
              "Unternehmensprofil hinzuf\u00FCgen"
            )}
          ></Text>
        </Flex>
        <Flex
          gap="38px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 44555")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44554")}
          >
            <Flex
              gap="4px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44540")}
            >
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="7px 7px 7px 7px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44539")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Bezeichnung"
                  {...getOverrideProps(overrides, "label46311328")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="none"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Diese gilt als Abkürzung für den schnellen Zug."
                  {...getOverrideProps(overrides, "descriptiveText46311329")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445385259776")}
              >
                <TextField
                  width="unset"
                  height="unset"
                  placeholder="..."
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField_title")}
                ></TextField>
              </Flex>
            </Flex>
            <Flex
              gap="4px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Framezz")}
            >
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="7px 7px 7px 7px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44541")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Zweck der Kampagne"
                  {...getOverrideProps(overrides, "label46311367")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="none"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Beschreiben Sie, was Sie mit der Nachricht erzielen wollen, z.B. marketing eines Produktes oder Kandidaten für eine Stelle anwerben, etc."
                  {...getOverrideProps(overrides, "descriptiveText46311368")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="3px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Framepoc")}
              >
                <TextAreaField
                  width="unset"
                  height="168.85px"
                  placeholder="..."
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextAreaField_goal")}
                ></TextAreaField>
              </Flex>
            </Flex>
            <Flex
              gap="4px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frameyy")}
            >
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="7px 7px 7px 7px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44545")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Unternehmensbeschreibung"
                  {...getOverrideProps(overrides, "label46311388")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="none"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Beschreiben Sie, was Ihr Unternehmen ist, und welche Arten von Dienstleistungen und/oder Produkten es anbietet"
                  {...getOverrideProps(overrides, "descriptiveText46311389")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="3px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Framecd")}
              >
                <TextAreaField
                  width="unset"
                  height="181.85px"
                  placeholder="..."
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextAreaField_comp_desc")}
                ></TextAreaField>
              </Flex>
            </Flex>
            <Flex
              gap="4px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44549")}
            >
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="7px 7px 7px 7px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44547")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Aufruf zum Handeln (CTA)"
                  {...getOverrideProps(overrides, "label46311348")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="none"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Geben Sie eine klare Aufforderung ein, was Ihr potenzieller Zielperson als Nächstes tun sollte. Z.B. 'Auf die Nachricht antworten', 'Mehr erfahren' oder 'Webseite besuchen'."
                  {...getOverrideProps(overrides, "descriptiveText46311349")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445385360787")}
              >
                <TextField
                  width="unset"
                  height="unset"
                  placeholder="..."
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField_CTA")}
                ></TextField>
              </Flex>
            </Flex>
            <Flex
              gap="4px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44552")}
            >
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="7px 7px 7px 7px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44550")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Ansprechpartner/in"
                  {...getOverrideProps(overrides, "label46311355")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="none"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Die Kontaktperson, deren Name als Absender der Nachrichten angegeben wird"
                  {...getOverrideProps(overrides, "descriptiveText46311356")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445385360795")}
              >
                <TextField
                  width="unset"
                  height="unset"
                  placeholder="..."
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField_CP")}
                ></TextField>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44553")}
          >
            <Button
              width="unset"
              height="36px"
              border="1.35px SOLID rgba(0,0,0,0)"
              padding="6.65234375px 14.65234375px 6.65234375px 14.65234375px"
              shrink="0"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              backgroundColor="rgba(30,136,229,1)"
              size="default"
              isDisabled={false}
              variation="primary"
              children="speichern"
              {...getOverrideProps(overrides, "Button")}
            ></Button>
            <Checkcircleoutline
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "check_circle_outline")}
            ></Checkcircleoutline>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
