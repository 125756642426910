import React, { useState } from 'react';

import { useEffect,useRef } from 'react';
import { AddProfileButton } from 'ui-components';
import { ShowPredefinedMessageCollection } from 'ui-components';
import { AddAccountButton } from 'ui-components';
import { AddPredefinedMessages } from 'ui-components';
import { AddPredefinedMessageButton } from 'ui-components';
import {SettingsEintellungen, AddProfile, ShowProfile, ShowIGAccountCollection, AddAccount, AddPredefinedMessage } from 'ui-components'; //ShowProfileCollection
import Notification from 'ui-components/Notification';
import {  withAuthenticator } from '@aws-amplify/ui-react';
import { generateClient } from "aws-amplify/api";
import { createProfile } from '../graphql/mutations';
import ShowProfileCollection from './ShowProfileCollectionWrapper';
import { Accordion, ThemeProvider, createTheme } from '@aws-amplify/ui-react';
import Layout from './SettingsWrapper';



const client = generateClient()

const SettingsEinstellungenWrapper = ({signOut,user}) => {
  const [activeComponent, setActiveComponent] = useState('default');
  const [showAddProfile, setshowAddProfile] = useState(false);
  const [showAddPredefinedMessage, setshowAddPredefinedMessage] = useState(false);
  const [displayAddAccountWidget,setDisplayAddAccountWidget] = useState(false);
  const [isLoadingAddAccount, SetIsLoadingAddAccount]= useState(false);
  const [successfullyAddedAccount, SetSuccessfullyAddedAccount] = useState(false);
  const [username,SetUsername] = useState('');
  const [pw,SetPw] = useState('');
  const [twoFA,SetTwoFA] = useState('');
  const [errorNotificationText, setErrorNotificationText] = useState(null);
  const [userID, setUserID] = useState(null);
  const [failedAddingAccount, setFailedAddingAccount] = useState(false);
  const [incompleteInput, setIncompleteInput] = useState(false);

  const [title, setTitle] = useState('');
  const [goal, setGoal] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [cta, setCta] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [processedProfile, setProcessedProfile] = useState(false);

  const [incompleteRequest, setIncompleteRequest] = useState(false);
  const notificationRef = useRef(null);  // Create a ref

  useEffect(() => {
    if (incompleteRequest && notificationRef.current) {
      // Scroll to the notification ref when incompleteRequest is true
      notificationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [incompleteRequest]);  // Dependency array includes incompleteRequest to run effect when it changes


  const theme = createTheme({
    name: 'Accordion-theme',
    tokens: {
      components: {
        accordion: {
          backgroundColor: '{colors.blue.10}',
          item: {
            trigger: {
              color: '{colors.blue.80}',
              _hover: {
                color: '{colors.blue.90}',
                backgroundColor: '{colors.blue.20}',
              },
            },
            content: {
              color: '{colors.blue.80}',
            },
          },
        },
      },
    },
  });

  const IGTutorial = () => {
    return (
      <>
      
      <div style={{ padding:'20px' }}>
                  <ol style={{ listStyleType: 'decimal' }}>
                <li>
               <div className='text-m p-2'>OptimaticLeads AI sollte das einzige Tool sein, mit dem Sie automatisierte Nachrichten über das verknüpfte Konto versenden. Antworten auf bestehende Nachrichten zählen nicht dazu.</div>
               </li>
               <li>
               <div className='text-m p-2'>Das verbundene Konto sollte regelmäßig für normales Nutzerverhalten verwendet werden, z. B. Scrollen, Posten, Liken, Anschauen von Inhalten usw. OptimaticLeads wird auch zufällige Beiträge in Ihrem Feed liken, um das Nutzerverhalten weiter zu simulieren </div>
               </li>
               <li>
               <div className='text-m p-2'>Falls die Zwei-Faktor-Authentisierung (2FA) aktiviert ist: Geben Sie beim Verknüpfen Ihres Kontos den 6-stelligen Verifizierungscode ein. <b>Stellen Sie sicher, dass der Code noch mindestens 15 Sekunden gültig ist, bevor er abläuft.</b> </div>
               </li>
               <li>
               <div className='text-m p-2'>Während des Verknüpfungsprozesses könnten Sie eine Benachrichtigung oder eine E-Mail von Instagram erhalten, die Sie über eine neue Anmeldung informiert. Dies ist der OptimaticLeads AI-Bot, der die Verbindung herstellt.<b className='text-amber-600'> In diesem Fall können Sie den Vorgang einfach bestätigen. </b></div>
               </li>

               </ol>
               </div>

  
      </>
    );
    }
    const AddProfileTutorial = () => {
      return (
        <>
        
        <div style={{ padding:'20px' }}>
          <div className='pt-5 pb-10'>Unten finden Sie Erläuterungen zu jedem Feld, das Sie ausfüllen müssen. Um Ihnen weiterzuhelfen, haben wir zwei Beispiele vorbereitet: Das erste Beispiel betrifft ein Unternehmen, das Personen für seine Marketingabteilung rekrutieren möchte, und das zweite Beispiel ein Unternehmen, das seine self-improvement Bücher bewerben möchte. Hier verwenden wir zwei fiktive Namen: <b>"MarketMasters GmbH"</b> für das Marketing-Rekrutierungsbeispiel und <b>"LifeCoach AG"</b> für das Beispiel der self-improvement Bücher.Die Namen der Firmen und Personen sind fiktiv und dienen nur als Beispiele.</div>
                    <ul style={{ listStyleType: 'disc' }}>
                  <li>
                    <div className='pb-5'>
                 <div className='text-m p-2'><b className='text-lg'>Bezeichnung:</b> Diese Abkürzung dient als schneller Zugriff in unserem App-Widget. Wählen Sie eine Bezeichnung, mit der Sie Ihr Profil leicht identifizieren können. Beispiel:</div>
                 <div className='pl-4' >MarketMasters GmbH: MM GmbH</div>
                 <div className='pl-4'>LifeCoach AG: LC AG</div>
                 </div>
                 </li>
                 <li>
                 <div className='pb-5'>

                 <div className='text-m p-2'><b className='text-lg'>Zweck der Kampagne: </b> Beschreiben Sie, was Sie mit der Nachricht erzielen wollen. Zum Beispiel Marketing eines Produktes oder Anwerben von Kandidaten für eine Stelle. Beispiel:</div>
                 <div className='pl-4'>MarketMasters GmbH: "qualifizierte Bewerber für unsere Marketingabteilung zu gewinnen."</div>
                 <div className='pl-4'>LifeCoach AG: "Werbung für unsere neuen Bücher zur persönlichen Weiterentwicklung."</div>
                 </div>
                 </li>
                 <li>
                 <div className='pb-5'>
                 <div className='text-m p-2'><b  className='text-lg'>Unternehmensbeschreibung:</b> beschreiben Sie, was Ihr Unternehmen macht und welche Arten von Produkten und/oder Dienstleistungen es anbietet. Beispiel:</div>
                 <div className='pl-4'>MarketMasters GmbH: "Wir sind eine führende Marketingagentur, die umfassende Lösungen für digitales Marketing und Markenmanagement anbietet."</div>
                  <div className='pl-4'>LifeCoach AG: "Wir bieten eine Vielzahl von Kursen und Workshops an, die darauf abzielen, persönliche und berufliche Fähigkeiten zu verbessern."</div>
                 </div>
                 </li>
                 <li>
                 <div className='pb-5'>
                 <div className='text-m p-2'><b  className='text-lg'>Aufruf zum Handeln (CTA): </b> Geben Sie eine klare Aufforderung ein, was Ihr potenzieller Zielperson als Nächstes tun sollte. Z.B. 'Auf die Nachricht antworten', 'Mehr erfahren' oder 'Webseite besuchen'.</div>
                 <div className='pl-4'>MarketMasters GmbH: "Bewerben Sie sich jetzt unter marketmasters.de/karriere , um Teil unseres dynamischen Teams zu werden!"</div>
                  <div className='pl-4'>LifeCoach AG: "Besuchen Sie unsere Webseite lifecoach.de, um mehr über unsere Kurse zu erfahren und sich anzumelden!"</div>
                  <div className='pl-4'>

                  <div className='pt-4 pb-2'>Andere Beispiele:</div> 
                  <ul style={{ listStyleType: 'disc' }}>
                  <li><div>"zurück schreiben wenn Interesse besteht"</div></li>
                  <li><div>"sich melden um ein Gespräch zu vereinbaren"</div></li>
                  <li><div>"Email an contact@firma.de schicken, wenn Interesse besteht"</div></li>
                  </ul>
                  </div>
                  </div>
                 </li>
                 <li>
                 <div className='pb-5'>
                 <div className='text-m p-2'><b  className='text-lg'> Ansprechpartner/in: </b> Die Kontaktperson, deren Name als Absender der Nachrichten angegeben wird</div>
                 <div className='pl-4'>MarketMasters GmbH: Julia Müller, HR-Managerin."</div>
                  <div className='pl-4'>LifeCoach AG: "Stefan Becker" oder einfach "Stefan"</div>
                  </div>                
                 </li>
                 </ul>
                 </div>
  
    
        </>
      );
      }
  

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleGoalChange = (event) => {
    setGoal(event.target.value);
  };

  const handleCompanyDescriptionChange = (event) => {
    setCompanyDescription(event.target.value);
  };

  const handleCtaChange = (event) => {
    setCta(event.target.value);
  };

  const handleContactPersonChange = (event) => {
    setContactPerson(event.target.value);
  };



  useEffect(() => {
    if (user && user.username) {
      setUserID(user.username);
      //console.log("user updated:",user)

    }
  }, [user]);

  const addAccount = (<div className='pt-5'> <AddAccount overrides={{
    AddAccount:{
      width:"100%",
    },
    Button:{
      color:"#F5F5F5"
    }
  }}/></div>)


  // Simplify state to control visibility of additional components directly within the activeComponent state
  const handleButtonClick = (componentName) => () => {
    setActiveComponent(componentName);
  };

  const handleShowAddProfile = () => {
    setshowAddProfile(true)
  }

  const handleshowAddPredefinedMessage = () => {
    setshowAddPredefinedMessage(true);
  }


  // Dynamic styling based on the active component
  const getButtonStyle = (componentName) => ({
    cursor: "pointer",
    //color: activeComponent === componentName ? "#000" : "white", // Example: Change text color based on active component
    className: 'hover-effect',
    color: "#F5F5F5",
  });

  const getBackgroundColor = (componentName) => {
    return activeComponent === componentName ? '#1E88E5' : '';
  }

  //display the component AddAccount
  const handleDisplayAddAccountWidget = () => {
    setDisplayAddAccountWidget(true);
  }

  const handleAddAccount = async ( action ) => {
    //setApiResponse(null); // Reset the apiResponse state
    SetIsLoadingAddAccount(true); // Set loading state to true
    SetSuccessfullyAddedAccount(false);
    setFailedAddingAccount(false);
    setErrorNotificationText(null);
    setIncompleteInput(false); // Reset the incomplete input state

    // Check if target_entity, mode, and selectedProfileId are not empty
    if (!username || !pw) {
      //console.log('Incomplete data provided');
      setIncompleteInput(true);
      SetIsLoadingAddAccount(false); // Set loading state to false
      return; // (<div>THIS IS ERROR</div>);//<IncompleteWidgetError/>;
    }
    else{
    const request_body = {
        "username": username,
        "password": pw,
        "verification_code": twoFA,
        "account_owner":userID,
        "mode": "first_time_login",
           };
    //console.log("sending request to userdecisionEndpoint :", request_body);
  
    // Check if target_entity, mode, and selectedProfileId are not empty

    //setIsLoadingProfile(true); // Set loading state to true
    try {
      const response = await fetch(process.env.REACT_APP_CONNECT_IG_ACCOUNT, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(request_body),
      });
      
      const data = await response.json();
      //setApiResponse(data.body);
      //console.log("data:", data)
  
      if ('error' in data) {
        //console.log("error in data:", data.error);
        setFailedAddingAccount(true);
        setErrorNotificationText(data.error);
        SetIsLoadingAddAccount(false);
      }
      if ('success' in data) {
        SetSuccessfullyAddedAccount(true);
        SetIsLoadingAddAccount(false);
      }
      //setImgUrl(data['profile_pic_s3_link']);
    } 
    catch (error) {
      console.error('Error processing endpoint request:', error);
    }
    finally {
      //setIsLoadingProfile(false); // Set loading state to false
      SetIsLoadingAddAccount(false);
    }
  }};



const handleOnChangeUsernameInput = (event) => {
  //console.log(event.target.value)
  SetUsername(event.target.value);
};

const handleOnChangePwInput = (event) => {
  //console.log(event.target.value)
  SetPw(event.target.value);
};
const handleOnChangeTwoFAInput = (event) => {
  //console.log(event.target.value)
  SetTwoFA(event.target.value);
};


const handleAddProfile = async () => {

  if (!userID || !title || !companyDescription || !goal || !cta || !contactPerson) {
    // Set incomplete request to true if any field is empty
    setIncompleteRequest(true);
    return; // Exit the function if any field is empty
  }

  const newProfile = await client.graphql({
    query: createProfile,
    variables: {
        input: {
		"account_owner": userID,
		"profile_name": title,
		"company_description": companyDescription,
		"purpose_of_campaign": goal,
		"call_to_action": cta,
		"contact_person": contactPerson,
	}
    }
});
setProcessedProfile(true);
};

//create a useeffect to call handleAddprofile
// useEffect(() => {
//   console.log("useeffect called")
//   handleAddProfile();
// }, []);

const handleDeleteProfile = () => {
  //console.log("delete profile")
}


  // Configurations for each component including dynamic visibility and styling adjustments
  const componentConfigs = {
    componentA: (
      <div>
        <ShowPredefinedMessageCollection overrideItems={() => {
          return {
            overrides: {
              ShowPredefinedMessage:{
                width:"100%",
              },

              Layout:{
                maxWidth: '1100px',
              },
            }
          }}} />
        <div className=''>
          <AddPredefinedMessageButton overrides={{
            AddPredefinedMessageButton:{
              width:"100%",
            },
            Layout:{
              maxWidth: '1100px',
            },
            Button: {
              //backgroundColor: getBackgroundColor('componentA'),
              onClick : () => { handleshowAddPredefinedMessage(); handleButtonClick('componentB'); } ,
              style: getButtonStyle('componentA'), // Apply dynamic style
              color:"#F5F5F5",
            }
          }}/>
          {/* {activeComponent === 'componentA' && <AddPredefinedMessages />}*/ }
          {showAddPredefinedMessage && (<div>
        <AddPredefinedMessages overrides={{
          AddPredefinedMessages:{
            width:"100%",
          },
          Layout:{
            maxWidth: '1100px',
          },
        
        }}/>
      </div>)}
          
        </div>
      </div>
    ),
    componentB: ( //'flex flex-col items-center justify-start min-h-screen'
      <div className='flex flex-col items-center justify-start w-full'>
        <ShowProfileCollection    
        
        overrideItems={() => {
          return {
            overrides: {
              ShowProfile:{
                
                width:"100%",
              },
              Layout:{
                maxWidth: '1100px',
              },
             
            }
          }}}
        />

          <AddProfileButton overrides={{
            AddProfileButton:{
              width:"100%",
            },
            Layout:{
              maxWidth: '800px',
            },
            Button: {
              //backgroundColor: getBackgroundColor('componentB'),
              onClick : () => { handleShowAddProfile(); handleButtonClick('componentB'); }   , // Adjusted for simplified state management
              style: getButtonStyle('componentB'), // Apply dynamic style
            }
          }}/>
          
          {showAddProfile && (<div>
            <div className='' style={{ width: '100%', maxWidth: '800px', }} >


            <ThemeProvider theme={theme} colorMode="light">
            <Accordion.Container defaultValue={['Tutorial']} >

            <Accordion.Item value="Tutorial">
                <Accordion.Trigger>
                Bitte lesen Sie die folgende Anleitung sorgfältig durch und füllen Sie die erforderlichen Felder wie unten erläutert aus, sodass Ihr Unternehmensprofil optimal von der KI analysiert werden kann:
                  <Accordion.Icon />
                </Accordion.Trigger>
                <Accordion.Content> {AddProfileTutorial()} </Accordion.Content>
              </Accordion.Item>


            </Accordion.Container>
            </ThemeProvider>

</div>

        <AddProfile overrides={{
          AddProfile:{
            width:"100%",
          },
          Layout:{
            maxWidth: '800px',
          },
          Framepoc:{
            height:"90px",
          },
          Framecd:{
            height:"90px",
          },
          TextAreaField_goal:{
            style:{
              //height: "100px",
            },
            //height:"100px",
          },
          check_circle_outline:{
            display: processedProfile ? 'block' : 'none',
          },
          Button:{
            display: processedProfile ? 'none' : 'block',
            className: 'hover-effect',
            onClick: handleAddProfile,
            color:"#F5F5F5",
            style:{
              cursor: 'pointer',
            }
          },
          TextField_title:{
            value: title,
            onChange: handleTitleChange,
          },

          TextAreaField_goal:{
            value: goal,
            onChange: handleGoalChange,
          },
          
          TextAreaField_comp_desc:{
            value: companyDescription,
            onChange: handleCompanyDescriptionChange,
          },
          
          TextField_CTA:{
            value: cta,
            onChange: handleCtaChange,
          },
          
          TextField_CP:{
            value: contactPerson,
            onChange: handleContactPersonChange,
          },
        
        }}/>
        {incompleteRequest &&
          <div ref={notificationRef} >
          <Notification overrides={{
            Notification :{
              width:"100%",
            },
            notification_placeholder:{
              children : "Bitte füllen Sie alle Felder aus und versuchen Sie es erneut." ,
            }
          }} info="error"
          />
          </div>
          } 
      </div>)}
      </div>
    ),
    componentC: (
      <div className=''>
        <ShowIGAccountCollection  
         overrideItems={() => {
          return {
            overrides: {
              ShowIGAccount:{
                padding: '5px',
                paddingTop: '10px',
                width:"100%",
              },

              Layout:{
                maxWidth: '1100px',
              },
              MyIcon : {
                className: 'loader',
                style:{
                  cursor: 'pointer',
                },
                backgroundColor: "#F67223",
              }
            }
          }}}

        overrides={{
          ShowIGAccount:{
            style:{
              backgroundColor:"white",
            },
            backgroundColor:'white'
          },
          ShowIGAccountCollection:{
            width:"100%",
          },
          Layout:{
            maxWidth: '1100px',
          },

        }} />
        <div className='pt-3'>

          <AddAccountButton overrides={{
            AddAccountButton:{
              width:"100%",
            },
            Layout : {
              maxWidth: '1100px',
            },
            Button: {
              //backgroundColor: getBackgroundColor('componentC'),
              onClick: handleDisplayAddAccountWidget, // Adjusted for simplified state management
              color:"#F5F5F5",
            }
          }}/>
          {displayAddAccountWidget &&  <div>
            <div style={{ maxWidth: '1100px',margin: 'auto', paddingTop: '20px'  }}>
            
          <div className='' style={{ width: '100%', maxWidth: '1100px', }} >


            <ThemeProvider theme={theme} colorMode="light">
            <Accordion.Container defaultValue={['Tutorial']} >

            <Accordion.Item value="Tutorial">
                <Accordion.Trigger>
                Bitte beachten Sie die folgenden Hinweise, um Ihr Konto und die KI synchron zu halten und technische Störungen zu vermeiden:
                  <Accordion.Icon />
                </Accordion.Trigger>
                <Accordion.Content> {IGTutorial()} </Accordion.Content>
              </Accordion.Item>


            </Accordion.Container>
            </ThemeProvider>

</div>
            
            
            </div>
          <div className='p-10'>
                
          {successfullyAddedAccount && <div className='pt-4'><Notification overrides={{
                  Notification:{
                    width:"100%",
                  },
                  Layout:{
                    maxWidth: '800px',
                  },
                    
                    notification_placeholder:{
                      children : "Die Verknüpfung des Kontos war erfolgreich." ,
                    }
                  }} info="success"
                  /> </div>}
                  {failedAddingAccount && <div className='pt-4 '><Notification overrides={{
                    Notification:{
                      width:"100%",
                    },
                    Layout:{
                      maxWidth: '800px',
                    },
                    
                    notification_placeholder:{
                      children : errorNotificationText ,
                    }
                  }} info="error"
                  /> </div>}

                {incompleteInput &&  
                      <div  >
                      <Notification    overrides={{
                        Notification:{
                          width:"100%",
                        },
                        Layout:{
                          maxWidth: '800px',
                        },
                        notification_placeholder:{
                          children : "Bitte geben Sie einen Benutzernamen und ein Passwort ein und versuchen Sie es erneut." ,
                        }
                      }} info="error"
                      />
                      </div>
                      }

             <AddAccount overrides={{
                  AddAccount:{
                    width:"100%",
                  },
                  Layout:{
                    maxWidth: '800px',
                  },
                  Button:{
                    color:"#F5F5F5",
                    display: isLoadingAddAccount ? 'none' : 'block',
                    onClick:handleAddAccount,

                  },
                  FrameLoading:{
                    display: isLoadingAddAccount ? 'block' : 'none',

                  },
                  //placeholder46311440
                  TextField50341274:{ //username
                    //children: "XX",
                    onChange: handleOnChangeUsernameInput,
                  },
                  TextField50341280:{ //pw
                    onChange: handleOnChangePwInput,
                  },
                  TextField2FA:{ //2FA
                    onChange: handleOnChangeTwoFAInput,
                  },
                }}/>


                      
                 
            </div>
          
            
          
             </div>}
          
          
        </div>
      </div>
    ),
    default: (
      <div>
        {/* Your default component's content */}
      </div>
    )
  };

  const renderComponent = () => {
    return componentConfigs[activeComponent] || componentConfigs['default'];
  };


  return (
    <div className="flex flex-col items-center justify-start min-h-screen ">
      <SettingsEintellungen  overrides={{
        SettingsEintellungen: {
          width: '100%',
        },
        Layout: {
          maxWidth: '1100px',
        },
        Button4509758: {
          backgroundColor: getBackgroundColor('componentA'),
          style: getButtonStyle('componentA'),
          //onClick:handleButtonClick('componentA'),
          
        },
        Button4509762: {
          backgroundColor: getBackgroundColor('componentB'),
          style: getButtonStyle('componentB'),
          onClick: handleButtonClick('componentB'),
        },
        Button4509770: {
          backgroundColor: getBackgroundColor('componentC'),
          style: getButtonStyle('componentC'),
          onClick: handleButtonClick('componentC'),
        }
      }} />
      {/* <div className='p-3'></div> */}
      <div className='w-full'> 
      {renderComponent()}
      </div>

    </div>
  );
};

export default withAuthenticator(SettingsEinstellungenWrapper);
