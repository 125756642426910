import '../App.css';
import '@aws-amplify/ui-react/styles.css';
import { useState } from 'react';
import * as React from 'react';
import { Accordion, ThemeProvider, createTheme } from '@aws-amplify/ui-react';
import TutorialHeader from 'ui-components/TutorialHeader';


const Tutorial = (usernameName) => {

    const [isOpen, setIsOpen] = useState(false);
    const theme = createTheme({
      name: 'Accordion-theme',
      tokens: {
        components: {
          accordion: {
            backgroundColor: '{colors.blue.10}',
            item: {
              trigger: {
                color: '{colors.blue.80}',
                _hover: {
                  color: '{colors.blue.90}',
                  backgroundColor: '{colors.blue.20}',
                },
              },
              content: {
                color: '{colors.blue.80}',
              },
            },
          },
        },
      },
    });

    const AppTutorial = () => {
        return (
            <>
             <div className='pt-3'>hier finden Sie das <b className='text-xl'>Hauptwidget der KI </b>, mit diesem widget können Sie ihre suchpräferenzen definieren und die zielgruppe entsprechend finden.</div>
             <div className='pt-3'>
             wenn Sie auf <b>Weiter</b> klicken, wird ihnen ein widget angezeigt, in dem Sie das angezeigte konto <b>akzeptieren</b> oder <b>ablehnen</b> können. 

             </div>
             <div className='pt-3'>
            Wenn Sie ein Konto akzeptieren, erstellt die KI eine Nachricht und speichert Sie unter <b>"Nachrichten" -&gt; "ausstehend"</b>. 

             </div>
             </>
        );
        }
        const firstTimeSetupTutorial = () => {
            return (
              <>
              <div className='p-4'>
              <div className=''>
                <> Die KI von OptimaticLeads simuliert die Rolle eines Marketingexperten. Damit die KI effektiv arbeiten und qualitativ hochwertige, personalisierte Nachrichten generieren kann, muss sie Ihre Ziele verstehen und wissen, was Sie mit der KI erreichen möchten. Ein aussagekräftiges Unternehmensprofil ist dafür unerlässlich. </>
              <ol style={{ listStyleType: 'decimal' }}>
              <li>
                
            <div className='text-xl p-3' ><b>Erstellen Sie ein Unternehmensprofil:</b> </div>

             <div className='text-m'> • ein unternehmensprofil enthält eine Beschreibung Ihres Unternehmens, den Zweck der Kampagne sowie eine Aufforderung zum handeln und den Namen des Ansprechpartners.</div>
             <div className='text-m p-2'> • Um ein unternehmensprofil hinzuzufügen navigieren Sie zu „Einstellungen” -&gt;  „Unternehmensprofil” -&gt;  „Profil erstellen”</div>

              </li>
              <div className='pt-2'></div>
              <li>
                
              <div className='text-xl p-3' ><b>Verbinden Sie ein Instagram-Konto:</b> </div>
              <div>Um Nachrichten zu versenden, ist ein verknüpftes Konto erforderlich. Sie können jedoch auch ohne ein verknüpftes Instagram-Konto Nachrichten generieren lassen, falls Sie diese Funktion vorab testen möchten.</div>
             <div className='text-m'> • Navigieren Sie dazu zu „Einstellungen” -&gt; „IG Konten” -&gt; „Konto verbinden”</div>
             
              </li>
              <div className='pt-3'></div>


          </ol>
          </div>
          </div>
          <div className='p-4'> Nachdem Sie diese beiden Schritte abgeschlossen haben, navigieren Sie zu „App“, um mit der Nutzung der KI zu beginnen.</div>

         </>
            );
            }

        const DashboardTutorial = () => {
            return (
                <>
                <div className='pt-3' >Das Dashboard zeigt benutzerbezogene Statistiken wie die Anzahl der zu einem bestimmten Zeitpunkt generierten und gesendeten Nachrichten sowie Details zum Abonnementtyp an 
                  
                   </div>
                </>
            );
            }

        const MessagesTutorial = () => {
            return (
                <>
                <div className='p-3' >Unter Nachrichten finden Sie drei Tabs  </div>

                <div className='text-l p-3' > <p><b>• Ausstehend</b></p>

                <div className='p-2' >Hier finden Sie die Nachrichten für die Konten, die Sie mit dem App-Widget akzeptiert haben. Sie können die Nachricht lesen und bei Bedarf ändern. Wenn Sie dann auf "Akzeptieren" klicken, wird die Nachricht an den Planer gesendet, um an das Konto gesendet zu werden. </div>
                </div>


                <div className='text-l p-3' > <p><b>• Geplant</b></p>

                <div className='p-2' >Hier finden Sie eine Liste der Nachrichten, die darauf warten, gesendet zu werden. </div>
                </div>

                <div className='text-l p-3' > <p><b>• Verschickt</b></p>

                <div className='p-2' >hier finden Sie eine Liste aller bereits versendeten Nachrichten. </div>
                </div>

                </>
            );
            }

        const SettingsTutorial = () => {
            return (
              <>
              <div className='p-3' >Unter Einstellungen finden Sie drei Tabs  </div>

              <div className='text-l p-3' > <p><b>• Unternehmensprofil</b></p>

              <div className='p-2' >Hier können Sie vordefinierte Definitionen für Ihr Unternehmen oder die Aktivität, die Sie fördern möchten, sehen und erstellen.  </div>
              </div>


              <div className='text-l p-3' > <p><b>• IG Konten</b></p>

              <div className='p-2' >Hier können Sie Ihre verbundenen Instagram-Konten sehen und weitere Konten verbinden.  </div>
              </div>

              <div className='text-l p-3' > <p><b>• vordef. Nachrichten</b></p>

              <div className='p-2' >Hier können Sie Ihre vordefinierten Nachrichten sehen und erstellen, die Sie verwenden können, wenn Sie sich dafür entscheiden, die KI-Generation nicht zu nutzen.  </div>
              </div>
              </>
            );
            }

            const Test = ({ usernameName }) => {
              return (
                <>
                <div>                  
                  {`Hallo ${usernameName} 😊`}
                </div>
                <div className='text-sm'>                  
                  {`Klicken Sie auf „Ersteinrichtung”, um zu beginnen.`}
                </div>


                </>
              );
            }

    return (
      <div className='flex flex-col items-center justify-start min-h-screen' >
    <TutorialHeader overrides = {{
      TutorialHeader:{
        width: '100%',
        backgroundColor: '#002640',
      },
      Layout:{
        maxWidth: '1200px',
      },
      header_ph:{
        children: '',
      },
      body_ph:{
        children: Test(usernameName),
        color: '#FF7F50',
      }
      
    }}/>
    
    <div className='' style={{ width: '100%', maxWidth: '800px', margin: '10px' }} >

    <ThemeProvider theme={theme} colorMode="light">
    <Accordion.Container>

    <Accordion.Item value="Tutorial">
        <Accordion.Trigger>
        🟠 Ersteinrichtung 🟠
          <Accordion.Icon />
        </Accordion.Trigger>
        <Accordion.Content> {firstTimeSetupTutorial()} </Accordion.Content>
      </Accordion.Item>




      <Accordion.Item value="App">
        <Accordion.Trigger>
          App
          <Accordion.Icon />
        </Accordion.Trigger>
        <Accordion.Content>{AppTutorial()}</Accordion.Content>
      </Accordion.Item>

      <Accordion.Item value="Dashboard">
        <Accordion.Trigger>
          Dashboard
          <Accordion.Icon />
        </Accordion.Trigger>
        <Accordion.Content>{DashboardTutorial()}</Accordion.Content>
      </Accordion.Item>

      <Accordion.Item value="Messages">
        <Accordion.Trigger>
          Nachrichten
          <Accordion.Icon />
        </Accordion.Trigger>
        <Accordion.Content>{MessagesTutorial()}</Accordion.Content>
      </Accordion.Item>

    {/*   <Accordion.Item value="settings">
        <Accordion.Trigger>
          Einstellungen
          <Accordion.Icon />
        </Accordion.Trigger>
        <Accordion.Content>{SettingsTutorial()}</Accordion.Content>
      </Accordion.Item>
      */}

    </Accordion.Container>
  </ThemeProvider>


</div>

      </div>
    );
  };



export default Tutorial;

