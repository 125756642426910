/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Flex,
  Icon,
  Loader,
  Radio,
  SelectField,
  Text,
  TextField,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function MainAppNavigation(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        label46301216: {},
        "Frame 4419": {},
        SelectField42942213: {},
        "Frame 44394": {},
        "Frame 56": {},
        label46301223: {},
        "Frame 4421": {},
        SelectField42942230: {},
        "Frame 44393": {},
        "Frame 57": {},
        "Frame 44396": {},
        label46301230: {},
        "Frame 4424": {},
        SelectField42942239: {},
        "Frame 58": {},
        label46301258: {},
        "Frame 4423": {},
        TextField46421777: {},
        "Frame 44398": {},
        "Frame 59": {},
        "Frame 44397": {},
        top: {},
        Status: {},
        "Frame 4417": {},
        Radio_group_146451867: {},
        Frame44523: {},
        Radio_group_146451868: {},
        Frame44524: {},
        Radio_group_146451869: {},
        Frame44525: {},
        Frame92: {},
        "Frame 44388": {},
        Geschlecht: {},
        "Frame 4418": {},
        Radio_group_252361063: {},
        Frame44520: {},
        Radio_group_246451893: {},
        Frame44521: {},
        Radio_group_246451894: {},
        Frame44522: {},
        Frame93: {},
        "Frame 44391": {},
        Blacklist: {},
        "Frame 4420": {},
        TextField46451917: {},
        "Frame 44389": {},
        "Frame 44390": {},
        middle: {},
        "Button Icon5466813": {},
        label5466814: {},
        "Button Icon5466815": {},
        Button: {},
        "Frame 44395": {},
        "Auf der Suche nach Personen, die Ihren Suchkriterien entsprechen, bitte warten Sie...":
          {},
        "Frame 44573": {},
        Loader: {},
        "Frame 44574": {},
        FrameLoader: {},
        bottom: {},
        Layout: {},
        MainAppNavigation: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        label46301216: {},
        "Frame 4419": { height: "33px" },
        SelectField42942213: { height: "33px", size: "small" },
        "Frame 44394": {},
        "Frame 56": {},
        label46301223: {},
        "Frame 4421": { height: "33px" },
        SelectField42942230: { height: "33px", size: "small" },
        "Frame 44393": {},
        "Frame 57": {},
        "Frame 44396": {},
        label46301230: {},
        "Frame 4424": { height: "33px" },
        SelectField42942239: { height: "33px", size: "small" },
        "Frame 58": {},
        label46301258: {},
        "Frame 4423": { height: "33px" },
        TextField46421777: { size: "small" },
        "Frame 44398": {},
        "Frame 59": {},
        "Frame 44397": {},
        top: { shrink: "0", padding: "4px 40px 4px 40px" },
        Status: {},
        "Frame 4417": { height: "33px" },
        Radio_group_146451867: { size: "small" },
        Frame44523: {},
        Radio_group_146451868: { size: "small" },
        Frame44524: {},
        Radio_group_146451869: { size: "small" },
        Frame44525: {},
        Frame92: { gap: "15px", padding: "0px 15px 0px 15px" },
        "Frame 44388": {},
        Geschlecht: {},
        "Frame 4418": { height: "33px" },
        Radio_group_252361063: { size: "small" },
        Frame44520: {},
        Radio_group_246451893: { size: "small" },
        Frame44521: {},
        Radio_group_246451894: { size: "small" },
        Frame44522: {},
        Frame93: { gap: "15px", padding: "0px 15px 0px 15px" },
        "Frame 44391": {},
        Blacklist: {},
        "Frame 4420": { height: "33px" },
        TextField46451917: { height: "33px", size: "small" },
        "Frame 44389": {},
        "Frame 44390": {},
        middle: { height: "156px", shrink: "0", padding: "4px 40px 4px 40px" },
        "Button Icon5466813": {},
        label5466814: { width: "unset", shrink: "1", grow: "1", basis: "0" },
        "Button Icon5466815": {},
        Button: {
          width: "259.34px",
          height: "35.34px",
          padding:
            "9.370342254638672px 18.740684509277344px 9.370342254638672px 18.740684509277344px",
        },
        "Frame 44395": {},
        "Auf der Suche nach Personen, die Ihren Suchkriterien entsprechen, bitte warten Sie...":
          { fontSize: "12px", lineHeight: "14.522727012634277px" },
        "Frame 44573": {},
        Loader: {},
        "Frame 44574": {},
        FrameLoader: {},
        bottom: { gap: "2px", shrink: "0", padding: "4px 40px 4px 40px" },
        Layout: {
          gap: "15px",
          justifyContent: "center",
          padding: "0px 0px 0px 0px",
        },
        MainAppNavigation: {
          width: "774px",
          height: "541px",
          padding: "30px 32px 30px 32px",
          backgroundColor: "rgba(0,38,64,1)",
        },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        label46301216: {},
        "Frame 4419": { height: "30px" },
        SelectField42942213: { height: "33px", size: "small" },
        "Frame 44394": { height: "30px" },
        "Frame 56": { shrink: "0", alignSelf: "stretch" },
        label46301223: {},
        "Frame 4421": { height: "30px" },
        SelectField42942230: { height: "33px", size: "small" },
        "Frame 44393": { height: "30px" },
        "Frame 57": { shrink: "0", alignSelf: "stretch" },
        "Frame 44396": { direction: "column" },
        label46301230: {},
        "Frame 4424": { height: "30px" },
        SelectField42942239: { height: "33px", size: "small" },
        "Frame 58": { shrink: "0", alignSelf: "stretch" },
        label46301258: {},
        "Frame 4423": { height: "30px" },
        TextField46421777: { size: "small" },
        "Frame 44398": { height: "30px" },
        "Frame 59": { shrink: "0", alignSelf: "stretch" },
        "Frame 44397": { direction: "column" },
        top: { shrink: "0", padding: "4px 0px 4px 0px" },
        Status: {},
        "Frame 4417": { height: "30px" },
        Radio_group_146451867: {
          size: "small",
          justifyContent: "space-between",
        },
        Frame44523: { shrink: "0", alignSelf: "stretch" },
        Radio_group_146451868: {
          size: "small",
          justifyContent: "space-between",
        },
        Frame44524: { shrink: "0", alignSelf: "stretch" },
        Radio_group_146451869: {
          size: "small",
          justifyContent: "space-between",
        },
        Frame44525: { shrink: "0", alignSelf: "stretch" },
        Frame92: {
          gap: "4px",
          direction: "column",
          shrink: "0",
          padding: "10px 40px 10px 40px",
        },
        "Frame 44388": { gap: "5px", direction: "column", height: "unset" },
        Geschlecht: {},
        "Frame 4418": { height: "30px" },
        Radio_group_252361063: {
          size: "small",
          justifyContent: "space-between",
        },
        Frame44520: { shrink: "0", alignSelf: "stretch" },
        Radio_group_246451893: {
          size: "small",
          justifyContent: "space-between",
        },
        Frame44521: { shrink: "0", alignSelf: "stretch" },
        Radio_group_246451894: {
          size: "small",
          justifyContent: "space-between",
        },
        Frame44522: { shrink: "0", alignSelf: "stretch" },
        Frame93: {
          gap: "4px",
          direction: "column",
          shrink: "0",
          padding: "10px 40px 10px 40px",
        },
        "Frame 44391": { gap: "5px", direction: "column", height: "unset" },
        Blacklist: {},
        "Frame 4420": {},
        TextField46451917: { height: "33px", size: "small" },
        "Frame 44389": { height: "30px", shrink: "0" },
        "Frame 44390": { gap: "5px", direction: "column", height: "unset" },
        middle: {
          justifyContent: "flex-start",
          shrink: "0",
          padding: "4px 0px 4px 0px",
        },
        "Button Icon5466813": {},
        label5466814: { width: "unset", shrink: "1", grow: "1", basis: "0" },
        "Button Icon5466815": {},
        Button: {
          width: "259.34px",
          height: "32.34px",
          padding: "4px 4px 4px 4px",
        },
        "Frame 44395": {},
        "Auf der Suche nach Personen, die Ihren Suchkriterien entsprechen, bitte warten Sie...":
          { fontSize: "12px", lineHeight: "14.522727012634277px" },
        "Frame 44573": { padding: "6px 0px 6px 0px" },
        Loader: {},
        "Frame 44574": {},
        FrameLoader: { gap: "4px", padding: "7px 0px 7px 0px" },
        bottom: { gap: "0", shrink: "0", padding: "21px 0px 21px 0px" },
        Layout: {
          gap: "10px",
          justifyContent: "center",
          padding: "0px 20px 0px 20px",
        },
        MainAppNavigation: {
          width: "417px",
          height: "906px",
          padding: "24px 0px 24px 0px",
          backgroundColor: "rgba(0,38,64,1)",
        },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="1100px"
      height="667px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="40px 80px 40px 80px"
      backgroundColor="rgba(0,48,83,1)"
      display="flex"
      {...getOverrideProps(overrides, "MainAppNavigation")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="40px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="4px 20px 4px 20px"
          display="flex"
          {...getOverrideProps(overrides, "top")}
        >
          <Flex
            gap="20px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44396")}
          >
            <Flex
              gap="5px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 56")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="41px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4419")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Unternehmensprofil"
                  {...getOverrideProps(overrides, "label46301216")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44394")}
              >
                <SelectField
                  width="unset"
                  height="40px"
                  shrink="0"
                  alignSelf="stretch"
                  borderRadius="4px"
                  backgroundColor="rgba(245,245,245,1)"
                  placeholder="Placeholder"
                  size="default"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "SelectField42942213")}
                ></SelectField>
              </Flex>
            </Flex>
            <Flex
              gap="5px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 57")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="41px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="0px 4px 0px 4px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4421")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Prozessmodus"
                  {...getOverrideProps(overrides, "label46301223")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44393")}
              >
                <SelectField
                  width="unset"
                  height="40.5px"
                  shrink="0"
                  alignSelf="stretch"
                  borderRadius="4px"
                  backgroundColor="rgba(245,245,245,1)"
                  placeholder="Placeholder"
                  size="default"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "SelectField42942230")}
                ></SelectField>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="20px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44397")}
          >
            <Flex
              gap="5px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 58")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="41px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4424")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Ermittlungsmethodik"
                  {...getOverrideProps(overrides, "label46301230")}
                ></Text>
              </Flex>
              <SelectField
                width="unset"
                height="40px"
                shrink="0"
                alignSelf="stretch"
                borderRadius="4px"
                backgroundColor="rgba(245,245,245,1)"
                placeholder="Placeholder"
                size="default"
                isDisabled={false}
                labelHidden={true}
                variation="default"
                {...getOverrideProps(overrides, "SelectField42942239")}
              ></SelectField>
            </Flex>
            <Flex
              gap="5px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 59")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="41px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4423")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Eingabewert"
                  {...getOverrideProps(overrides, "label46301258")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44398")}
              >
                <TextField
                  width="unset"
                  height="unset"
                  shrink="0"
                  alignSelf="stretch"
                  borderRadius="4px"
                  backgroundColor="rgba(245,245,245,1)"
                  placeholder="Placeholder"
                  size="default"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField46421777")}
                ></TextField>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="4px 20px 4px 20px"
          display="flex"
          {...getOverrideProps(overrides, "middle")}
        >
          <Flex
            gap="20px"
            direction="row"
            width="unset"
            height="36px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44388")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(245,245,245,1)"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4417")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(245,245,245,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="255px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Status"
                {...getOverrideProps(overrides, "Status")}
              ></Text>
            </Flex>
            <Flex
              gap="30px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="4px"
              padding="0px 24px 0px 24px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame92")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame44523")}
              >
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  defaultChecked={false}
                  isDisabled={false}
                  labelPosition="start"
                  children="privat"
                  {...getOverrideProps(overrides, "Radio_group_146451867")}
                ></Radio>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame44524")}
              >
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  defaultChecked={false}
                  isDisabled={false}
                  labelPosition="start"
                  children="öffentlich"
                  {...getOverrideProps(overrides, "Radio_group_146451868")}
                ></Radio>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame44525")}
              >
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="start"
                  children="Keine Angabe"
                  {...getOverrideProps(overrides, "Radio_group_146451869")}
                ></Radio>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="20px"
            direction="row"
            width="unset"
            height="36px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44391")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(245,245,245,1)"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4418")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(245,245,245,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="257px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Geschlecht"
                {...getOverrideProps(overrides, "Geschlecht")}
              ></Text>
            </Flex>
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="4px"
              padding="0px 24px 0px 24px"
              backgroundColor="rgba(255,255,255,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame93")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame44520")}
              >
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  defaultChecked={false}
                  isDisabled={false}
                  labelPosition="start"
                  children="männlich "
                  {...getOverrideProps(overrides, "Radio_group_252361063")}
                ></Radio>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame44521")}
              >
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  defaultChecked={false}
                  isDisabled={false}
                  labelPosition="start"
                  children="weiblich"
                  {...getOverrideProps(overrides, "Radio_group_246451893")}
                ></Radio>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame44522")}
              >
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  alignSelf="stretch"
                  size="default"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="start"
                  children="Keine Angabe"
                  {...getOverrideProps(overrides, "Radio_group_246451894")}
                ></Radio>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="20px"
            direction="row"
            width="unset"
            height="36px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44390")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(245,245,245,1)"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4420")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(255,255,255,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="257px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Blacklist"
                {...getOverrideProps(overrides, "Blacklist")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44389")}
            >
              <TextField
                width="unset"
                height="40px"
                placeholder="Kommagetrennte Schlagwörter..."
                grow="1"
                shrink="1"
                basis="0"
                borderRadius="4px"
                backgroundColor="rgba(245,245,245,1)"
                size="default"
                isDisabled={false}
                labelHidden={true}
                variation="default"
                {...getOverrideProps(overrides, "TextField46451917")}
              ></TextField>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="16px 40px 16px 40px"
          display="flex"
          {...getOverrideProps(overrides, "bottom")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="4px"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44395")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              border="1.17px SOLID rgba(174,179,183,1)"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="5.171292781829834px"
              padding="10px 18.740684509277344px 10px 18.740684509277344px"
              backgroundColor="rgba(30,136,229,1)"
              display="flex"
              {...getOverrideProps(overrides, "Button")}
            >
              <Icon
                width="18.74px"
                height="18.74px"
                display="none"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="none"
                fontSize="18.74px"
                {...getOverrideProps(overrides, "Button Icon5466813")}
              ></Icon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(245,245,245,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="219.52px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Weiter"
                {...getOverrideProps(overrides, "label5466814")}
              ></Text>
              <Icon
                width="18.74px"
                height="18.74px"
                display="none"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="none"
                fontSize="18.74px"
                {...getOverrideProps(overrides, "Button Icon5466815")}
              ></Icon>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="4px 0px 4px 0px"
            display="flex"
            {...getOverrideProps(overrides, "FrameLoader")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44573")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(255,255,255,1)"
                lineHeight="16.94318199157715px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="27px"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Auf der Suche nach Personen, die Ihren Suchkriterien entsprechen, bitte warten Sie..."
                {...getOverrideProps(
                  overrides,
                  "Auf der Suche nach Personen, die Ihren Suchkriterien entsprechen, bitte warten Sie..."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44574")}
            >
              <Loader
                shrink="0"
                size="large"
                variation="circular"
                {...getOverrideProps(overrides, "Loader")}
              ></Loader>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
