/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Doneoutline(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M19.77 2.83L21.17 4.23L8.43 16.97L2.83 11.37L4.23 9.97L8.43 14.17L19.77 2.83ZM19.77 0L8.43 11.34L4.23 7.14L0 11.37L8.43 19.8L24 4.23L19.77 0Z",
          fill: "rgba(46,125,50,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 8.75%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Doneoutline")}
      {...rest}
    ></Icon>
  );
}
