/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createUserProperties } from "../graphql/mutations";
const client = generateClient();
export default function UserPropertiesCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    account_owner: "",
    sub_label: "",
    sub_tier: "",
    total_msgs_generated: "",
    total_msgs_generated_day: "",
    total_msgs_generated_month: "",
    total_msgs_sent: "",
    total_msgs_sent_day: "",
    total_msgs_sent_month: "",
    total_msgs_generated_week: "",
    total_msgs_sent_week: "",
    createdAt: "",
    updatedAt: "",
    stripe_subscription_id: "",
    stripe_customer_id: "",
    stripe_plan_id: "",
    email: "",
    stripe_subscription_status: "",
    stripe_subscription_start: "",
    credits_used: "",
    credits_available: "",
  };
  const [account_owner, setAccount_owner] = React.useState(
    initialValues.account_owner
  );
  const [sub_label, setSub_label] = React.useState(initialValues.sub_label);
  const [sub_tier, setSub_tier] = React.useState(initialValues.sub_tier);
  const [total_msgs_generated, setTotal_msgs_generated] = React.useState(
    initialValues.total_msgs_generated
  );
  const [total_msgs_generated_day, setTotal_msgs_generated_day] =
    React.useState(initialValues.total_msgs_generated_day);
  const [total_msgs_generated_month, setTotal_msgs_generated_month] =
    React.useState(initialValues.total_msgs_generated_month);
  const [total_msgs_sent, setTotal_msgs_sent] = React.useState(
    initialValues.total_msgs_sent
  );
  const [total_msgs_sent_day, setTotal_msgs_sent_day] = React.useState(
    initialValues.total_msgs_sent_day
  );
  const [total_msgs_sent_month, setTotal_msgs_sent_month] = React.useState(
    initialValues.total_msgs_sent_month
  );
  const [total_msgs_generated_week, setTotal_msgs_generated_week] =
    React.useState(initialValues.total_msgs_generated_week);
  const [total_msgs_sent_week, setTotal_msgs_sent_week] = React.useState(
    initialValues.total_msgs_sent_week
  );
  const [createdAt, setCreatedAt] = React.useState(initialValues.createdAt);
  const [updatedAt, setUpdatedAt] = React.useState(initialValues.updatedAt);
  const [stripe_subscription_id, setStripe_subscription_id] = React.useState(
    initialValues.stripe_subscription_id
  );
  const [stripe_customer_id, setStripe_customer_id] = React.useState(
    initialValues.stripe_customer_id
  );
  const [stripe_plan_id, setStripe_plan_id] = React.useState(
    initialValues.stripe_plan_id
  );
  const [email, setEmail] = React.useState(initialValues.email);
  const [stripe_subscription_status, setStripe_subscription_status] =
    React.useState(initialValues.stripe_subscription_status);
  const [stripe_subscription_start, setStripe_subscription_start] =
    React.useState(initialValues.stripe_subscription_start);
  const [credits_used, setCredits_used] = React.useState(
    initialValues.credits_used
  );
  const [credits_available, setCredits_available] = React.useState(
    initialValues.credits_available
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setAccount_owner(initialValues.account_owner);
    setSub_label(initialValues.sub_label);
    setSub_tier(initialValues.sub_tier);
    setTotal_msgs_generated(initialValues.total_msgs_generated);
    setTotal_msgs_generated_day(initialValues.total_msgs_generated_day);
    setTotal_msgs_generated_month(initialValues.total_msgs_generated_month);
    setTotal_msgs_sent(initialValues.total_msgs_sent);
    setTotal_msgs_sent_day(initialValues.total_msgs_sent_day);
    setTotal_msgs_sent_month(initialValues.total_msgs_sent_month);
    setTotal_msgs_generated_week(initialValues.total_msgs_generated_week);
    setTotal_msgs_sent_week(initialValues.total_msgs_sent_week);
    setCreatedAt(initialValues.createdAt);
    setUpdatedAt(initialValues.updatedAt);
    setStripe_subscription_id(initialValues.stripe_subscription_id);
    setStripe_customer_id(initialValues.stripe_customer_id);
    setStripe_plan_id(initialValues.stripe_plan_id);
    setEmail(initialValues.email);
    setStripe_subscription_status(initialValues.stripe_subscription_status);
    setStripe_subscription_start(initialValues.stripe_subscription_start);
    setCredits_used(initialValues.credits_used);
    setCredits_available(initialValues.credits_available);
    setErrors({});
  };
  const validations = {
    account_owner: [],
    sub_label: [],
    sub_tier: [],
    total_msgs_generated: [],
    total_msgs_generated_day: [],
    total_msgs_generated_month: [],
    total_msgs_sent: [],
    total_msgs_sent_day: [],
    total_msgs_sent_month: [],
    total_msgs_generated_week: [],
    total_msgs_sent_week: [],
    createdAt: [],
    updatedAt: [],
    stripe_subscription_id: [],
    stripe_customer_id: [],
    stripe_plan_id: [],
    email: [],
    stripe_subscription_status: [],
    stripe_subscription_start: [],
    credits_used: [],
    credits_available: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          account_owner,
          sub_label,
          sub_tier,
          total_msgs_generated,
          total_msgs_generated_day,
          total_msgs_generated_month,
          total_msgs_sent,
          total_msgs_sent_day,
          total_msgs_sent_month,
          total_msgs_generated_week,
          total_msgs_sent_week,
          createdAt,
          updatedAt,
          stripe_subscription_id,
          stripe_customer_id,
          stripe_plan_id,
          email,
          stripe_subscription_status,
          stripe_subscription_start,
          credits_used,
          credits_available,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createUserProperties.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "UserPropertiesCreateForm")}
      {...rest}
    >
      <TextField
        label="Account owner"
        isRequired={false}
        isReadOnly={false}
        value={account_owner}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner: value,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.account_owner ?? value;
          }
          if (errors.account_owner?.hasError) {
            runValidationTasks("account_owner", value);
          }
          setAccount_owner(value);
        }}
        onBlur={() => runValidationTasks("account_owner", account_owner)}
        errorMessage={errors.account_owner?.errorMessage}
        hasError={errors.account_owner?.hasError}
        {...getOverrideProps(overrides, "account_owner")}
      ></TextField>
      <TextField
        label="Sub label"
        isRequired={false}
        isReadOnly={false}
        value={sub_label}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label: value,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.sub_label ?? value;
          }
          if (errors.sub_label?.hasError) {
            runValidationTasks("sub_label", value);
          }
          setSub_label(value);
        }}
        onBlur={() => runValidationTasks("sub_label", sub_label)}
        errorMessage={errors.sub_label?.errorMessage}
        hasError={errors.sub_label?.hasError}
        {...getOverrideProps(overrides, "sub_label")}
      ></TextField>
      <TextField
        label="Sub tier"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={sub_tier}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier: value,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.sub_tier ?? value;
          }
          if (errors.sub_tier?.hasError) {
            runValidationTasks("sub_tier", value);
          }
          setSub_tier(value);
        }}
        onBlur={() => runValidationTasks("sub_tier", sub_tier)}
        errorMessage={errors.sub_tier?.errorMessage}
        hasError={errors.sub_tier?.hasError}
        {...getOverrideProps(overrides, "sub_tier")}
      ></TextField>
      <TextField
        label="Total msgs generated"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total_msgs_generated}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated: value,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.total_msgs_generated ?? value;
          }
          if (errors.total_msgs_generated?.hasError) {
            runValidationTasks("total_msgs_generated", value);
          }
          setTotal_msgs_generated(value);
        }}
        onBlur={() =>
          runValidationTasks("total_msgs_generated", total_msgs_generated)
        }
        errorMessage={errors.total_msgs_generated?.errorMessage}
        hasError={errors.total_msgs_generated?.hasError}
        {...getOverrideProps(overrides, "total_msgs_generated")}
      ></TextField>
      <TextField
        label="Total msgs generated day"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total_msgs_generated_day}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day: value,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.total_msgs_generated_day ?? value;
          }
          if (errors.total_msgs_generated_day?.hasError) {
            runValidationTasks("total_msgs_generated_day", value);
          }
          setTotal_msgs_generated_day(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "total_msgs_generated_day",
            total_msgs_generated_day
          )
        }
        errorMessage={errors.total_msgs_generated_day?.errorMessage}
        hasError={errors.total_msgs_generated_day?.hasError}
        {...getOverrideProps(overrides, "total_msgs_generated_day")}
      ></TextField>
      <TextField
        label="Total msgs generated month"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total_msgs_generated_month}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month: value,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.total_msgs_generated_month ?? value;
          }
          if (errors.total_msgs_generated_month?.hasError) {
            runValidationTasks("total_msgs_generated_month", value);
          }
          setTotal_msgs_generated_month(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "total_msgs_generated_month",
            total_msgs_generated_month
          )
        }
        errorMessage={errors.total_msgs_generated_month?.errorMessage}
        hasError={errors.total_msgs_generated_month?.hasError}
        {...getOverrideProps(overrides, "total_msgs_generated_month")}
      ></TextField>
      <TextField
        label="Total msgs sent"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total_msgs_sent}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent: value,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.total_msgs_sent ?? value;
          }
          if (errors.total_msgs_sent?.hasError) {
            runValidationTasks("total_msgs_sent", value);
          }
          setTotal_msgs_sent(value);
        }}
        onBlur={() => runValidationTasks("total_msgs_sent", total_msgs_sent)}
        errorMessage={errors.total_msgs_sent?.errorMessage}
        hasError={errors.total_msgs_sent?.hasError}
        {...getOverrideProps(overrides, "total_msgs_sent")}
      ></TextField>
      <TextField
        label="Total msgs sent day"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total_msgs_sent_day}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day: value,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.total_msgs_sent_day ?? value;
          }
          if (errors.total_msgs_sent_day?.hasError) {
            runValidationTasks("total_msgs_sent_day", value);
          }
          setTotal_msgs_sent_day(value);
        }}
        onBlur={() =>
          runValidationTasks("total_msgs_sent_day", total_msgs_sent_day)
        }
        errorMessage={errors.total_msgs_sent_day?.errorMessage}
        hasError={errors.total_msgs_sent_day?.hasError}
        {...getOverrideProps(overrides, "total_msgs_sent_day")}
      ></TextField>
      <TextField
        label="Total msgs sent month"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total_msgs_sent_month}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month: value,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.total_msgs_sent_month ?? value;
          }
          if (errors.total_msgs_sent_month?.hasError) {
            runValidationTasks("total_msgs_sent_month", value);
          }
          setTotal_msgs_sent_month(value);
        }}
        onBlur={() =>
          runValidationTasks("total_msgs_sent_month", total_msgs_sent_month)
        }
        errorMessage={errors.total_msgs_sent_month?.errorMessage}
        hasError={errors.total_msgs_sent_month?.hasError}
        {...getOverrideProps(overrides, "total_msgs_sent_month")}
      ></TextField>
      <TextField
        label="Total msgs generated week"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total_msgs_generated_week}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week: value,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.total_msgs_generated_week ?? value;
          }
          if (errors.total_msgs_generated_week?.hasError) {
            runValidationTasks("total_msgs_generated_week", value);
          }
          setTotal_msgs_generated_week(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "total_msgs_generated_week",
            total_msgs_generated_week
          )
        }
        errorMessage={errors.total_msgs_generated_week?.errorMessage}
        hasError={errors.total_msgs_generated_week?.hasError}
        {...getOverrideProps(overrides, "total_msgs_generated_week")}
      ></TextField>
      <TextField
        label="Total msgs sent week"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={total_msgs_sent_week}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week: value,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.total_msgs_sent_week ?? value;
          }
          if (errors.total_msgs_sent_week?.hasError) {
            runValidationTasks("total_msgs_sent_week", value);
          }
          setTotal_msgs_sent_week(value);
        }}
        onBlur={() =>
          runValidationTasks("total_msgs_sent_week", total_msgs_sent_week)
        }
        errorMessage={errors.total_msgs_sent_week?.errorMessage}
        hasError={errors.total_msgs_sent_week?.hasError}
        {...getOverrideProps(overrides, "total_msgs_sent_week")}
      ></TextField>
      <TextField
        label="Created at"
        isRequired={false}
        isReadOnly={false}
        value={createdAt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt: value,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.createdAt ?? value;
          }
          if (errors.createdAt?.hasError) {
            runValidationTasks("createdAt", value);
          }
          setCreatedAt(value);
        }}
        onBlur={() => runValidationTasks("createdAt", createdAt)}
        errorMessage={errors.createdAt?.errorMessage}
        hasError={errors.createdAt?.hasError}
        {...getOverrideProps(overrides, "createdAt")}
      ></TextField>
      <TextField
        label="Updated at"
        isRequired={false}
        isReadOnly={false}
        value={updatedAt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt: value,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.updatedAt ?? value;
          }
          if (errors.updatedAt?.hasError) {
            runValidationTasks("updatedAt", value);
          }
          setUpdatedAt(value);
        }}
        onBlur={() => runValidationTasks("updatedAt", updatedAt)}
        errorMessage={errors.updatedAt?.errorMessage}
        hasError={errors.updatedAt?.hasError}
        {...getOverrideProps(overrides, "updatedAt")}
      ></TextField>
      <TextField
        label="Stripe subscription id"
        isRequired={false}
        isReadOnly={false}
        value={stripe_subscription_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id: value,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.stripe_subscription_id ?? value;
          }
          if (errors.stripe_subscription_id?.hasError) {
            runValidationTasks("stripe_subscription_id", value);
          }
          setStripe_subscription_id(value);
        }}
        onBlur={() =>
          runValidationTasks("stripe_subscription_id", stripe_subscription_id)
        }
        errorMessage={errors.stripe_subscription_id?.errorMessage}
        hasError={errors.stripe_subscription_id?.hasError}
        {...getOverrideProps(overrides, "stripe_subscription_id")}
      ></TextField>
      <TextField
        label="Stripe customer id"
        isRequired={false}
        isReadOnly={false}
        value={stripe_customer_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id: value,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.stripe_customer_id ?? value;
          }
          if (errors.stripe_customer_id?.hasError) {
            runValidationTasks("stripe_customer_id", value);
          }
          setStripe_customer_id(value);
        }}
        onBlur={() =>
          runValidationTasks("stripe_customer_id", stripe_customer_id)
        }
        errorMessage={errors.stripe_customer_id?.errorMessage}
        hasError={errors.stripe_customer_id?.hasError}
        {...getOverrideProps(overrides, "stripe_customer_id")}
      ></TextField>
      <TextField
        label="Stripe plan id"
        isRequired={false}
        isReadOnly={false}
        value={stripe_plan_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id: value,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.stripe_plan_id ?? value;
          }
          if (errors.stripe_plan_id?.hasError) {
            runValidationTasks("stripe_plan_id", value);
          }
          setStripe_plan_id(value);
        }}
        onBlur={() => runValidationTasks("stripe_plan_id", stripe_plan_id)}
        errorMessage={errors.stripe_plan_id?.errorMessage}
        hasError={errors.stripe_plan_id?.hasError}
        {...getOverrideProps(overrides, "stripe_plan_id")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email: value,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Stripe subscription status"
        isRequired={false}
        isReadOnly={false}
        value={stripe_subscription_status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status: value,
              stripe_subscription_start,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.stripe_subscription_status ?? value;
          }
          if (errors.stripe_subscription_status?.hasError) {
            runValidationTasks("stripe_subscription_status", value);
          }
          setStripe_subscription_status(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "stripe_subscription_status",
            stripe_subscription_status
          )
        }
        errorMessage={errors.stripe_subscription_status?.errorMessage}
        hasError={errors.stripe_subscription_status?.hasError}
        {...getOverrideProps(overrides, "stripe_subscription_status")}
      ></TextField>
      <TextField
        label="Stripe subscription start"
        isRequired={false}
        isReadOnly={false}
        value={stripe_subscription_start}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start: value,
              credits_used,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.stripe_subscription_start ?? value;
          }
          if (errors.stripe_subscription_start?.hasError) {
            runValidationTasks("stripe_subscription_start", value);
          }
          setStripe_subscription_start(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "stripe_subscription_start",
            stripe_subscription_start
          )
        }
        errorMessage={errors.stripe_subscription_start?.errorMessage}
        hasError={errors.stripe_subscription_start?.hasError}
        {...getOverrideProps(overrides, "stripe_subscription_start")}
      ></TextField>
      <TextField
        label="Credits used"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={credits_used}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used: value,
              credits_available,
            };
            const result = onChange(modelFields);
            value = result?.credits_used ?? value;
          }
          if (errors.credits_used?.hasError) {
            runValidationTasks("credits_used", value);
          }
          setCredits_used(value);
        }}
        onBlur={() => runValidationTasks("credits_used", credits_used)}
        errorMessage={errors.credits_used?.errorMessage}
        hasError={errors.credits_used?.hasError}
        {...getOverrideProps(overrides, "credits_used")}
      ></TextField>
      <TextField
        label="Credits available"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={credits_available}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sub_label,
              sub_tier,
              total_msgs_generated,
              total_msgs_generated_day,
              total_msgs_generated_month,
              total_msgs_sent,
              total_msgs_sent_day,
              total_msgs_sent_month,
              total_msgs_generated_week,
              total_msgs_sent_week,
              createdAt,
              updatedAt,
              stripe_subscription_id,
              stripe_customer_id,
              stripe_plan_id,
              email,
              stripe_subscription_status,
              stripe_subscription_start,
              credits_used,
              credits_available: value,
            };
            const result = onChange(modelFields);
            value = result?.credits_available ?? value;
          }
          if (errors.credits_available?.hasError) {
            runValidationTasks("credits_available", value);
          }
          setCredits_available(value);
        }}
        onBlur={() =>
          runValidationTasks("credits_available", credits_available)
        }
        errorMessage={errors.credits_available?.errorMessage}
        hasError={errors.credits_available?.hasError}
        {...getOverrideProps(overrides, "credits_available")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
