/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text, useBreakpointValue } from "@aws-amplify/ui-react";
import ContactHelp from "./ContactHelp";
import Menubook from "./Menubook";
import Home from "./Home";
import SettingsWidget from "./SettingsWidget";
import Login from "./Login";
export default function Settingsv2(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        ContactHelp: {},
        menu_book: {},
        "Frame 4441684": {},
        home: {},
        "Frame 4441683": {},
        SettingsWidget: {},
        "Frame 4441690": {},
        login: {},
        "Frame 4441685": {},
        FrameBar: {},
        "Button Icon4509745": {},
        label4509746: {},
        "Button Icon4509747": {},
        Button4509744: {},
        "Button Icon4509741": {},
        label4509742: {},
        "Button Icon4509743": {},
        Button4509740: {},
        Frame1: {},
        "Button Icon4509749": {},
        label4509750: {},
        "Button Icon4509751": {},
        Button4509748: {},
        "Button Icon4509737": {},
        label4509738: {},
        "Button Icon4509739": {},
        Button4509736: {},
        Frame2: {},
        "Frame 4441688": {},
        "Frame 4441691": {},
        Layout: {},
        Settingsv2: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        ContactHelp: {},
        menu_book: {},
        "Frame 4441684": {},
        home: {},
        "Frame 4441683": {},
        SettingsWidget: {},
        "Frame 4441690": {},
        login: {},
        "Frame 4441685": {},
        FrameBar: {},
        "Button Icon4509745": {},
        label4509746: {},
        "Button Icon4509747": {},
        Button4509744: {},
        "Button Icon4509741": {},
        label4509742: {},
        "Button Icon4509743": {},
        Button4509740: {},
        Frame1: { direction: "column" },
        "Button Icon4509749": {},
        label4509750: {},
        "Button Icon4509751": {},
        Button4509748: {},
        "Button Icon4509737": {},
        label4509738: {},
        "Button Icon4509739": {},
        Button4509736: {},
        Frame2: {},
        "Frame 4441688": {},
        "Frame 4441691": {},
        Layout: {},
        Settingsv2: { width: "476px", height: "193px" },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        ContactHelp: {},
        menu_book: {},
        "Frame 4441684": {},
        home: {},
        "Frame 4441683": {},
        SettingsWidget: {},
        "Frame 4441690": {},
        login: {},
        "Frame 4441685": {},
        FrameBar: {},
        "Button Icon4509745": {},
        label4509746: {},
        "Button Icon4509747": {},
        Button4509744: {},
        "Button Icon4509741": {},
        label4509742: {},
        "Button Icon4509743": {},
        Button4509740: {},
        Frame1: { direction: "column" },
        "Button Icon4509749": {},
        label4509750: {},
        "Button Icon4509751": {},
        Button4509748: {},
        "Button Icon4509737": {},
        label4509738: {},
        "Button Icon4509739": {},
        Button4509736: {},
        Frame2: {},
        "Frame 4441688": { direction: "column" },
        "Frame 4441691": { shrink: "1", grow: "1", basis: "0" },
        Layout: {},
        Settingsv2: { width: "372px", height: "270px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="12.5px"
      direction="row"
      width="616px"
      height="127px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="12.5px 0px 12.5px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "Settingsv2")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="8px 20px 8px 20px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 4441691")}
        >
          <Flex
            gap="40px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "FrameBar")}
          >
            <ContactHelp
              width="35px"
              height="35px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "ContactHelp")}
            ></ContactHelp>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4441684")}
            >
              <Menubook
                width="35px"
                height="35px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "menu_book")}
              ></Menubook>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4441683")}
            >
              <Home
                width="35px"
                height="35px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "home")}
              ></Home>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4441690")}
            >
              <SettingsWidget
                width="35px"
                height="35px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "SettingsWidget")}
              ></SettingsWidget>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4441685")}
            >
              <Login
                width="35px"
                height="35px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "login")}
              ></Login>
            </Flex>
          </Flex>
          <Flex
            gap="12px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4441688")}
          >
            <Flex
              gap="12px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame1")}
            >
              <Flex
                gap="0"
                direction="row"
                width="162.52px"
                height="38.19px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                border="1.09px SOLID rgba(174,179,183,1)"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="5.0942230224609375px"
                padding="8.7537841796875px 17.507568359375px 8.7537841796875px 17.507568359375px"
                display="flex"
                {...getOverrideProps(overrides, "Button4509744")}
              >
                <Icon
                  width="17.51px"
                  height="17.51px"
                  display="none"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="none"
                  fontSize="17.51px"
                  {...getOverrideProps(overrides, "Button Icon4509745")}
                ></Icon>
                <Text
                  fontFamily="Inter"
                  fontSize="17.507568359375px"
                  fontWeight="700"
                  color="rgba(245,245,245,1)"
                  lineHeight="26.2613525390625px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="App"
                  {...getOverrideProps(overrides, "label4509746")}
                ></Text>
                <Icon
                  width="17.51px"
                  height="17.51px"
                  display="none"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="none"
                  fontSize="17.51px"
                  {...getOverrideProps(overrides, "Button Icon4509747")}
                ></Icon>
              </Flex>
              <Flex
                gap="0"
                direction="row"
                width="162.52px"
                height="38.19px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                border="1.09px SOLID rgba(174,179,183,1)"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="5.0942230224609375px"
                padding="8.7537841796875px 17.507568359375px 8.7537841796875px 17.507568359375px"
                display="flex"
                {...getOverrideProps(overrides, "Button4509740")}
              >
                <Icon
                  width="17.51px"
                  height="17.51px"
                  display="none"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="none"
                  fontSize="17.51px"
                  {...getOverrideProps(overrides, "Button Icon4509741")}
                ></Icon>
                <Text
                  fontFamily="Inter"
                  fontSize="17.507568359375px"
                  fontWeight="700"
                  color="rgba(245,245,245,1)"
                  lineHeight="26.2613525390625px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Dashboard"
                  {...getOverrideProps(overrides, "label4509742")}
                ></Text>
                <Icon
                  width="17.51px"
                  height="17.51px"
                  display="none"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="none"
                  fontSize="17.51px"
                  {...getOverrideProps(overrides, "Button Icon4509743")}
                ></Icon>
              </Flex>
            </Flex>
            <Flex
              gap="12px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame2")}
            >
              <Flex
                gap="0"
                direction="row"
                width="162.52px"
                height="38.19px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                border="1.09px SOLID rgba(174,179,183,1)"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="5.0942230224609375px"
                padding="8.7537841796875px 17.507568359375px 8.7537841796875px 17.507568359375px"
                display="flex"
                {...getOverrideProps(overrides, "Button4509748")}
              >
                <Icon
                  width="17.51px"
                  height="17.51px"
                  display="none"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="none"
                  fontSize="17.51px"
                  {...getOverrideProps(overrides, "Button Icon4509749")}
                ></Icon>
                <Text
                  fontFamily="Inter"
                  fontSize="17.507568359375px"
                  fontWeight="700"
                  color="rgba(245,245,245,1)"
                  lineHeight="26.2613525390625px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Nachrichten"
                  {...getOverrideProps(overrides, "label4509750")}
                ></Text>
                <Icon
                  width="17.51px"
                  height="17.51px"
                  display="none"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="none"
                  fontSize="17.51px"
                  {...getOverrideProps(overrides, "Button Icon4509751")}
                ></Icon>
              </Flex>
              <Flex
                gap="0"
                direction="row"
                width="162.52px"
                height="38.19px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                border="1.09px SOLID rgba(174,179,183,1)"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="5.0942230224609375px"
                padding="8.7537841796875px 17.507568359375px 8.7537841796875px 17.507568359375px"
                display="none"
                {...getOverrideProps(overrides, "Button4509736")}
              >
                <Icon
                  width="17.51px"
                  height="17.51px"
                  display="none"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="none"
                  fontSize="17.51px"
                  {...getOverrideProps(overrides, "Button Icon4509737")}
                ></Icon>
                <Text
                  fontFamily="Inter"
                  fontSize="17.507568359375px"
                  fontWeight="700"
                  color="rgba(245,245,245,1)"
                  lineHeight="26.2613525390625px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Einstellungen"
                  {...getOverrideProps(overrides, "label4509738")}
                ></Text>
                <Icon
                  width="17.51px"
                  height="17.51px"
                  display="none"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="none"
                  fontSize="17.51px"
                  {...getOverrideProps(overrides, "Button Icon4509739")}
                ></Icon>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
