/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Button,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import Login from "./Login";
export default function NavigationBar(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Rectangle_1228: {},
        Startseite: {},
        "Warum OptimaticLeads w\u00E4hlen?": {},
        FrameReason: {},
        Preise: {},
        Kontakt: {},
        "Frame 5": {},
        Button49841563: {},
        Button49841579: {},
        login: {},
        actions: {},
        Layout: {},
        NavigationBar: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        Rectangle_1228: {},
        Startseite: {},
        "Warum OptimaticLeads w\u00E4hlen?": {},
        FrameReason: {},
        Preise: {},
        Kontakt: { width: "62px" },
        "Frame 5": { gap: "20px" },
        Button49841563: {},
        Button49841579: {},
        login: {},
        actions: {},
        Layout: { gap: "20px", padding: "0px 20px 0px 20px" },
        NavigationBar: {
          width: "768px",
          height: "60px",
          justifyContent: "space-between",
        },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Rectangle_1228: { width: "25px", height: "25px" },
        Startseite: { fontSize: "12px", lineHeight: "18px" },
        "Warum OptimaticLeads w\u00E4hlen?": {
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "18px",
          display: "none",
          children: "Anleitung",
        },
        FrameReason: { padding: "10px 10px 10px 10px" },
        Preise: { fontSize: "12px", lineHeight: "18px" },
        Kontakt: { fontSize: "12px", lineHeight: "18px" },
        "Frame 5": { gap: "10px" },
        Button49841563: {},
        Button49841579: {},
        login: {},
        actions: { gap: "4px" },
        Layout: { gap: "10px", padding: "0px 15px 0px 15px" },
        NavigationBar: {
          width: "480px",
          height: "50px",
          justifyContent: "space-between",
          padding: "6px 0px 6px 0px",
        },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        Rectangle_1228: { width: "20px", height: "20px" },
        Startseite: {
          fontSize: "10px",
          lineHeight: "15px",
          children: "Startseite",
        },
        "Warum OptimaticLeads w\u00E4hlen?": {
          fontSize: "10px",
          fontWeight: "500",
          lineHeight: "15px",
          display: "none",
          children: "Anleitung",
        },
        FrameReason: { padding: "10px 10px 10px 10px" },
        Preise: { fontSize: "10px", lineHeight: "15px" },
        Kontakt: { fontSize: "10px", lineHeight: "15px" },
        "Frame 5": { gap: "10px" },
        Button49841563: {},
        Button49841579: {},
        login: {},
        actions: {},
        Layout: { padding: "12px 10px 12px 10px" },
        NavigationBar: {
          width: "374px",
          height: "unset",
          padding: "4px 0px 4px 0px",
        },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="row"
      width="1300px"
      height="70px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
      padding="8px 0px 8px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "NavigationBar")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="0px 0px 4px 4px"
        padding="0px 25px 0px 25px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="21px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 5")}
        >
          <Image
            width="30px"
            height="30px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="10px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "Rectangle_1228")}
          ></Image>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            color="rgba(245,245,245,1)"
            lineHeight="21px"
            textAlign="left"
            display="none"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Eigenschaften"
            {...getOverrideProps(overrides, "Startseite")}
          ></Text>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="none"
            {...getOverrideProps(overrides, "FrameReason")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="600"
              color="rgba(245,245,245,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Warum OptimaticLeads wählen?"
              {...getOverrideProps(
                overrides,
                "Warum OptimaticLeads w\u00E4hlen?"
              )}
            ></Text>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            color="rgba(245,245,245,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Preise"
            {...getOverrideProps(overrides, "Preise")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            color="rgba(245,245,245,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Kontakt"
            {...getOverrideProps(overrides, "Kontakt")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "actions")}
        >
          <Button
            width="unset"
            height="unset"
            borderRadius="4px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            isDisabled={false}
            variation="default"
            children="Einloggen"
            {...getOverrideProps(overrides, "Button49841563")}
          ></Button>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            alignSelf="stretch"
            size="small"
            isDisabled={false}
            variation="default"
            children="Registrieren"
            {...getOverrideProps(overrides, "Button49841579")}
          ></Button>
          <Login
            width="35px"
            height="35px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "login")}
          ></Login>
        </Flex>
      </Flex>
    </Flex>
  );
}
