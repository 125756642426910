/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Button, Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function HeroV2(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Eyebrow: {},
        "Frame 44468": {},
        Heading: {},
        Frame44470: {},
        Message: {},
        Button: {},
        "Frame 44469": {},
        "Frame 44474": {},
        Layout: {},
        HeroV2: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Eyebrow: {
          fontFamily: "Poppins",
          fontSize: "32px",
          lineHeight: "40px",
        },
        "Frame 44468": {},
        Heading: { fontSize: "12px" },
        Frame44470: { padding: "0px 30px 0px 30px" },
        Message: { padding: "0px 0px 0px 0px" },
        Button: { size: "small" },
        "Frame 44469": { height: "75px" },
        "Frame 44474": { gap: "40px", padding: "15px 0px 15px 0px" },
        Layout: { padding: "0px 10px 0px 10px" },
        HeroV2: { width: "372px", height: "453px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="1217px"
      height="382px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "HeroV2")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="25px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="25px 0px 25px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 44474")}
        >
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 10px 0px 10px"
            display="flex"
            {...getOverrideProps(overrides, "Message")}
          >
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="15px 0px 15px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44468")}
            >
              <Text
                fontFamily="Inter"
                fontSize="48px"
                fontWeight="900"
                color="rgba(245,245,245,1)"
                lineHeight="60px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="KI-generierte Akquise-Nachrichten, in Ihrem Schreibstil über Social Media."
                {...getOverrideProps(overrides, "Eyebrow")}
              ></Text>
            </Flex>
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame44470")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(245,245,245,1)"
                lineHeight="21px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Die Künstliche Intelligenz, die Ihre Zielgruppe identifiziert, Nachrichten in Ihrem persönlichen Stil verfasst und diese versendet."
                {...getOverrideProps(overrides, "Heading")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44469")}
          >
            <Button
              width="unset"
              height="unset"
              padding="9px 63px 9px 63px"
              shrink="0"
              backgroundColor="rgba(246,114,35,1)"
              size="default"
              isDisabled={false}
              variation="primary"
              children="Kostenlos testen"
              {...getOverrideProps(overrides, "Button")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
