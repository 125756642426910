/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Flex,
  Image,
  Loader,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function TargetGroupDecisionWidget(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Rectangle_1169: {},
        "Frame 44441": {},
        user_name_ph: {},
        "Frame 44442": {},
        "Frame 44462": {},
        MyIcon50671376: {},
        FrameReject: {},
        MyIcon50671378: {},
        FrameAccept: {},
        DecisionFrame: {},
        Loader: {},
        "Frame 44458": {},
        "Frame 44453": {},
        "Frame 44463": {},
        Name: {},
        "Frame 4428": {},
        full_name_ph: {},
        "Frame 44439": {},
        "Frame 44454": {},
        verifiziert: {},
        "Frame 4430": {},
        is_verified_ph: {},
        "Frame 44448": {},
        "Frame 44449": {},
        Kontostatus: {},
        "Frame 4429": {},
        is_private_ph: {},
        "Frame 44446": {},
        "Frame 44447": {},
        "Frame 44580": {},
        "Batch Fortschritt": {},
        "Frame 4445550671317": {},
        ci_ph: {},
        "/": {},
        bs_ph: {},
        "Frame 44457": {},
        "Frame 44456": {},
        "Frame 4445550671316": {},
        "E.Methodik": {},
        FrameModeIn: {},
        placeholder_mode: {},
        FramepH_Mode: {},
        FrameModeText: {},
        FrameMode: {},
        "Verarbeitete Profile": {},
        FrameProgressIn: {},
        placeholder_process: {},
        FramepH_Process: {},
        FrameProcessedTxt: {},
        FrameProgress: {},
        "Frame 44581": {},
        "Frame 44464": {},
        Layout: {},
        TargetGroupDecisionWidget: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Rectangle_1169: {},
        "Frame 44441": {},
        user_name_ph: {},
        "Frame 44442": {},
        "Frame 44462": {},
        MyIcon50671376: {},
        FrameReject: {},
        MyIcon50671378: {},
        FrameAccept: {},
        DecisionFrame: {},
        Loader: {},
        "Frame 44458": {},
        "Frame 44453": {},
        "Frame 44463": {},
        Name: {},
        "Frame 4428": {},
        full_name_ph: {},
        "Frame 44439": {},
        "Frame 44454": {},
        verifiziert: {},
        "Frame 4430": {},
        is_verified_ph: {},
        "Frame 44448": {},
        "Frame 44449": {},
        Kontostatus: {},
        "Frame 4429": {},
        is_private_ph: {},
        "Frame 44446": {},
        "Frame 44447": {},
        "Frame 44580": { width: "unset" },
        "Batch Fortschritt": {},
        "Frame 4445550671317": {},
        ci_ph: {},
        "/": {},
        bs_ph: {},
        "Frame 44457": {},
        "Frame 44456": {},
        "Frame 4445550671316": {},
        "E.Methodik": {},
        FrameModeIn: {},
        placeholder_mode: {},
        FramepH_Mode: {},
        FrameModeText: {},
        FrameMode: {},
        "Verarbeitete Profile": {},
        FrameProgressIn: {},
        placeholder_process: {},
        FramepH_Process: {},
        FrameProcessedTxt: {},
        FrameProgress: {},
        "Frame 44581": { width: "unset" },
        "Frame 44464": {
          direction: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        Layout: { direction: "column", alignSelf: "stretch" },
        TargetGroupDecisionWidget: {
          width: "545px",
          height: "1129px",
          padding: "40px 20px 40px 20px",
        },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="963px"
      height="497px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="40px 60px 40px 60px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "TargetGroupDecisionWidget")}
      {...rest}
    >
      <Flex
        gap="40px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="40px 60px 40px 60px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="5px SOLID rgba(30,136,229,0.5)"
          borderRadius="9px"
          padding="8px 26px 8px 26px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 44463")}
        >
          <Flex
            gap="12px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 4px 0px 4px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44462")}
          >
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44441")}
            >
              <Image
                width="160px"
                height="171px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                borderRadius="10px"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                {...getOverrideProps(overrides, "Rectangle_1169")}
              ></Image>
            </Flex>
            <Flex
              gap="4px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              borderRadius="10px"
              padding="4px 8px 4px 8px"
              backgroundColor="rgba(245,245,245,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44442")}
            >
              <Text
                fontFamily="Inter"
                fontSize="17.5px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="26.25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="user_name_ph"
                {...getOverrideProps(overrides, "user_name_ph")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44453")}
          >
            <Flex
              gap="20px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "DecisionFrame")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "FrameReject")}
              >
                <MyIcon
                  width="50px"
                  height="50px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  borderRadius="50px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(211,47,47,1)"
                  type="type37"
                  {...getOverrideProps(overrides, "MyIcon50671376")}
                ></MyIcon>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "FrameAccept")}
              >
                <MyIcon
                  width="50px"
                  height="50px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  borderRadius="50px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(46,125,50,1)"
                  type="checkmark"
                  {...getOverrideProps(overrides, "MyIcon50671378")}
                ></MyIcon>
              </Flex>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44458")}
            >
              <Loader
                width="20.92px"
                shrink="0"
                size="large"
                variation="circular"
                {...getOverrideProps(overrides, "Loader")}
              ></Loader>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="13px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 44464")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="197px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44580")}
          >
            <Flex
              gap="5px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-end"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44454")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="3px 0px 3px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4428")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Name"
                  {...getOverrideProps(overrides, "Name")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="4px 0px 4px 0px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44439")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="24px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="full_name_ph"
                  {...getOverrideProps(overrides, "full_name_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="2px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44449")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="3px 0px 3px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4430")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="verifiziert"
                  {...getOverrideProps(overrides, "verifiziert")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="4px 0px 4px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44448")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="is_verified_ph"
                  {...getOverrideProps(overrides, "is_verified_ph")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="4px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44447")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="3px 0px 3px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4429")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="21.17897605895996px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Kontostatus"
                  {...getOverrideProps(overrides, "Kontostatus")}
                ></Text>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="4px 0px 4px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44446")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="is_private_ph"
                  {...getOverrideProps(overrides, "is_private_ph")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="197.33px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44581")}
          >
            <Flex
              gap="4px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4445550671316")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 9px 0px 9px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4445550671317")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Batch Fortschritt"
                  {...getOverrideProps(overrides, "Batch Fortschritt")}
                ></Text>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="4px 0px 4px 0px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44456")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44457")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="17.5px"
                    fontWeight="400"
                    color="rgba(0,0,0,0.87)"
                    lineHeight="26.25px"
                    textAlign="right"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="63.95px"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="ci_ph"
                    {...getOverrideProps(overrides, "ci_ph")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="17.5px"
                    fontWeight="400"
                    color="rgba(0,0,0,0.87)"
                    lineHeight="26.25px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="8.95px"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="/"
                    {...getOverrideProps(overrides, "/")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="17.5px"
                    fontWeight="400"
                    color="rgba(0,0,0,0.87)"
                    lineHeight="26.25px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="63.95px"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="bs_ph"
                    {...getOverrideProps(overrides, "bs_ph")}
                  ></Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              gap="4px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "FrameMode")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 9px 0px 9px"
                display="flex"
                {...getOverrideProps(overrides, "FrameModeIn")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="E.Methodik"
                  {...getOverrideProps(overrides, "E.Methodik")}
                ></Text>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="4px 0px 4px 0px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "FrameModeText")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "FramepH_Mode")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="17.5px"
                    fontWeight="400"
                    color="rgba(0,0,0,0.87)"
                    lineHeight="26.25px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="ph_mode"
                    {...getOverrideProps(overrides, "placeholder_mode")}
                  ></Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              gap="4px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "FrameProgress")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="10px"
                padding="0px 9px 0px 9px"
                display="flex"
                {...getOverrideProps(overrides, "FrameProgressIn")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="17.5px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="26.25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Verarbeitete Profile"
                  {...getOverrideProps(overrides, "Verarbeitete Profile")}
                ></Text>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="10px"
                padding="4px 0px 4px 0px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "FrameProcessedTxt")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "FramepH_Process")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="17.5px"
                    fontWeight="400"
                    color="rgba(0,0,0,0.87)"
                    lineHeight="26.25px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="ph_mode"
                    {...getOverrideProps(overrides, "placeholder_process")}
                  ></Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
