/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStripeSessionsLog = /* GraphQL */ `
  mutation CreateStripeSessionsLog(
    $input: CreateStripeSessionsLogInput!
    $condition: ModelStripeSessionsLogConditionInput
  ) {
    createStripeSessionsLog(input: $input, condition: $condition) {
      id
      event_id
      event_metadata
      account_owner
      email
      hosted_invoice_url
      event_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStripeSessionsLog = /* GraphQL */ `
  mutation UpdateStripeSessionsLog(
    $input: UpdateStripeSessionsLogInput!
    $condition: ModelStripeSessionsLogConditionInput
  ) {
    updateStripeSessionsLog(input: $input, condition: $condition) {
      id
      event_id
      event_metadata
      account_owner
      email
      hosted_invoice_url
      event_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStripeSessionsLog = /* GraphQL */ `
  mutation DeleteStripeSessionsLog(
    $input: DeleteStripeSessionsLogInput!
    $condition: ModelStripeSessionsLogConditionInput
  ) {
    deleteStripeSessionsLog(input: $input, condition: $condition) {
      id
      event_id
      event_metadata
      account_owner
      email
      hosted_invoice_url
      event_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserInfrastructureMetadata = /* GraphQL */ `
  mutation CreateUserInfrastructureMetadata(
    $input: CreateUserInfrastructureMetadataInput!
    $condition: ModelUserInfrastructureMetadataConditionInput
  ) {
    createUserInfrastructureMetadata(input: $input, condition: $condition) {
      id
      sub_label
      sub_tier
      sending_is_limited_daily
      sending_daily_limit
      sending_is_limited_weekly
      sending_weekly_limit
      sending_is_limited_monthly
      sending_monthly_limit
      generation_is_limited_daily
      generation_daily_limit
      generation_is_limited_weekly
      generation_weekly_limit
      generation_is_limited_monthly
      generation_monthly_limit
      generation_is_limited
      generation_limit
      sending_is_limited
      sending_limit
      stripe_product_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserInfrastructureMetadata = /* GraphQL */ `
  mutation UpdateUserInfrastructureMetadata(
    $input: UpdateUserInfrastructureMetadataInput!
    $condition: ModelUserInfrastructureMetadataConditionInput
  ) {
    updateUserInfrastructureMetadata(input: $input, condition: $condition) {
      id
      sub_label
      sub_tier
      sending_is_limited_daily
      sending_daily_limit
      sending_is_limited_weekly
      sending_weekly_limit
      sending_is_limited_monthly
      sending_monthly_limit
      generation_is_limited_daily
      generation_daily_limit
      generation_is_limited_weekly
      generation_weekly_limit
      generation_is_limited_monthly
      generation_monthly_limit
      generation_is_limited
      generation_limit
      sending_is_limited
      sending_limit
      stripe_product_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserInfrastructureMetadata = /* GraphQL */ `
  mutation DeleteUserInfrastructureMetadata(
    $input: DeleteUserInfrastructureMetadataInput!
    $condition: ModelUserInfrastructureMetadataConditionInput
  ) {
    deleteUserInfrastructureMetadata(input: $input, condition: $condition) {
      id
      sub_label
      sub_tier
      sending_is_limited_daily
      sending_daily_limit
      sending_is_limited_weekly
      sending_weekly_limit
      sending_is_limited_monthly
      sending_monthly_limit
      generation_is_limited_daily
      generation_daily_limit
      generation_is_limited_weekly
      generation_weekly_limit
      generation_is_limited_monthly
      generation_monthly_limit
      generation_is_limited
      generation_limit
      sending_is_limited
      sending_limit
      stripe_product_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactForm = /* GraphQL */ `
  mutation CreateContactForm(
    $input: CreateContactFormInput!
    $condition: ModelContactFormConditionInput
  ) {
    createContactForm(input: $input, condition: $condition) {
      id
      first_name
      last_name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactForm = /* GraphQL */ `
  mutation UpdateContactForm(
    $input: UpdateContactFormInput!
    $condition: ModelContactFormConditionInput
  ) {
    updateContactForm(input: $input, condition: $condition) {
      id
      first_name
      last_name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactForm = /* GraphQL */ `
  mutation DeleteContactForm(
    $input: DeleteContactFormInput!
    $condition: ModelContactFormConditionInput
  ) {
    deleteContactForm(input: $input, condition: $condition) {
      id
      first_name
      last_name
      email
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserProperties = /* GraphQL */ `
  mutation CreateUserProperties(
    $input: CreateUserPropertiesInput!
    $condition: ModelUserPropertiesConditionInput
  ) {
    createUserProperties(input: $input, condition: $condition) {
      id
      account_owner
      sub_label
      sub_tier
      total_msgs_generated
      total_msgs_generated_day
      total_msgs_generated_month
      total_msgs_sent
      total_msgs_sent_day
      total_msgs_sent_month
      total_msgs_generated_week
      total_msgs_sent_week
      createdAt
      updatedAt
      stripe_subscription_id
      stripe_customer_id
      stripe_plan_id
      email
      stripe_subscription_status
      stripe_subscription_start
      credits_used
      credits_available
      __typename
    }
  }
`;
export const updateUserProperties = /* GraphQL */ `
  mutation UpdateUserProperties(
    $input: UpdateUserPropertiesInput!
    $condition: ModelUserPropertiesConditionInput
  ) {
    updateUserProperties(input: $input, condition: $condition) {
      id
      account_owner
      sub_label
      sub_tier
      total_msgs_generated
      total_msgs_generated_day
      total_msgs_generated_month
      total_msgs_sent
      total_msgs_sent_day
      total_msgs_sent_month
      total_msgs_generated_week
      total_msgs_sent_week
      createdAt
      updatedAt
      stripe_subscription_id
      stripe_customer_id
      stripe_plan_id
      email
      stripe_subscription_status
      stripe_subscription_start
      credits_used
      credits_available
      __typename
    }
  }
`;
export const deleteUserProperties = /* GraphQL */ `
  mutation DeleteUserProperties(
    $input: DeleteUserPropertiesInput!
    $condition: ModelUserPropertiesConditionInput
  ) {
    deleteUserProperties(input: $input, condition: $condition) {
      id
      account_owner
      sub_label
      sub_tier
      total_msgs_generated
      total_msgs_generated_day
      total_msgs_generated_month
      total_msgs_sent
      total_msgs_sent_day
      total_msgs_sent_month
      total_msgs_generated_week
      total_msgs_sent_week
      createdAt
      updatedAt
      stripe_subscription_id
      stripe_customer_id
      stripe_plan_id
      email
      stripe_subscription_status
      stripe_subscription_start
      credits_used
      credits_available
      __typename
    }
  }
`;
export const createNewsletter = /* GraphQL */ `
  mutation CreateNewsletter(
    $input: CreateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    createNewsletter(input: $input, condition: $condition) {
      id
      email
      mode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNewsletter = /* GraphQL */ `
  mutation UpdateNewsletter(
    $input: UpdateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    updateNewsletter(input: $input, condition: $condition) {
      id
      email
      mode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNewsletter = /* GraphQL */ `
  mutation DeleteNewsletter(
    $input: DeleteNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    deleteNewsletter(input: $input, condition: $condition) {
      id
      email
      mode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssignedProxyUrls = /* GraphQL */ `
  mutation CreateAssignedProxyUrls(
    $input: CreateAssignedProxyUrlsInput!
    $condition: ModelAssignedProxyUrlsConditionInput
  ) {
    createAssignedProxyUrls(input: $input, condition: $condition) {
      id
      account_owner
      proxy_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssignedProxyUrls = /* GraphQL */ `
  mutation UpdateAssignedProxyUrls(
    $input: UpdateAssignedProxyUrlsInput!
    $condition: ModelAssignedProxyUrlsConditionInput
  ) {
    updateAssignedProxyUrls(input: $input, condition: $condition) {
      id
      account_owner
      proxy_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssignedProxyUrls = /* GraphQL */ `
  mutation DeleteAssignedProxyUrls(
    $input: DeleteAssignedProxyUrlsInput!
    $condition: ModelAssignedProxyUrlsConditionInput
  ) {
    deleteAssignedProxyUrls(input: $input, condition: $condition) {
      id
      account_owner
      proxy_url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDisplayedTargetUsers = /* GraphQL */ `
  mutation CreateDisplayedTargetUsers(
    $input: CreateDisplayedTargetUsersInput!
    $condition: ModelDisplayedTargetUsersConditionInput
  ) {
    createDisplayedTargetUsers(input: $input, condition: $condition) {
      id
      account_owner
      target_account_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDisplayedTargetUsers = /* GraphQL */ `
  mutation UpdateDisplayedTargetUsers(
    $input: UpdateDisplayedTargetUsersInput!
    $condition: ModelDisplayedTargetUsersConditionInput
  ) {
    updateDisplayedTargetUsers(input: $input, condition: $condition) {
      id
      account_owner
      target_account_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDisplayedTargetUsers = /* GraphQL */ `
  mutation DeleteDisplayedTargetUsers(
    $input: DeleteDisplayedTargetUsersInput!
    $condition: ModelDisplayedTargetUsersConditionInput
  ) {
    deleteDisplayedTargetUsers(input: $input, condition: $condition) {
      id
      account_owner
      target_account_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPredefinedMessages = /* GraphQL */ `
  mutation CreatePredefinedMessages(
    $input: CreatePredefinedMessagesInput!
    $condition: ModelPredefinedMessagesConditionInput
  ) {
    createPredefinedMessages(input: $input, condition: $condition) {
      id
      account_owner
      predefined_message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePredefinedMessages = /* GraphQL */ `
  mutation UpdatePredefinedMessages(
    $input: UpdatePredefinedMessagesInput!
    $condition: ModelPredefinedMessagesConditionInput
  ) {
    updatePredefinedMessages(input: $input, condition: $condition) {
      id
      account_owner
      predefined_message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePredefinedMessages = /* GraphQL */ `
  mutation DeletePredefinedMessages(
    $input: DeletePredefinedMessagesInput!
    $condition: ModelPredefinedMessagesConditionInput
  ) {
    deletePredefinedMessages(input: $input, condition: $condition) {
      id
      account_owner
      predefined_message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWaitingForConfirmation = /* GraphQL */ `
  mutation CreateWaitingForConfirmation(
    $input: CreateWaitingForConfirmationInput!
    $condition: ModelWaitingForConfirmationConditionInput
  ) {
    createWaitingForConfirmation(input: $input, condition: $condition) {
      id
      account_owner
      target_user_id
      target_username
      outreach_message
      score
      target_assumed_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWaitingForConfirmation = /* GraphQL */ `
  mutation UpdateWaitingForConfirmation(
    $input: UpdateWaitingForConfirmationInput!
    $condition: ModelWaitingForConfirmationConditionInput
  ) {
    updateWaitingForConfirmation(input: $input, condition: $condition) {
      id
      account_owner
      target_user_id
      target_username
      outreach_message
      score
      target_assumed_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWaitingForConfirmation = /* GraphQL */ `
  mutation DeleteWaitingForConfirmation(
    $input: DeleteWaitingForConfirmationInput!
    $condition: ModelWaitingForConfirmationConditionInput
  ) {
    deleteWaitingForConfirmation(input: $input, condition: $condition) {
      id
      account_owner
      target_user_id
      target_username
      outreach_message
      score
      target_assumed_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRetrievedBatchProfileMetadata = /* GraphQL */ `
  mutation CreateRetrievedBatchProfileMetadata(
    $input: CreateRetrievedBatchProfileMetadataInput!
    $condition: ModelRetrievedBatchProfileMetadataConditionInput
  ) {
    createRetrievedBatchProfileMetadata(input: $input, condition: $condition) {
      id
      account_owner
      mode
      current_end_cursor
      future_end_cursor
      current_item_index
      target_entity
      target_entity_id
      batch_size
      retrieved_items
      total_amount_retrieved
      account_profile_id
      filter_options
      createdAt
      updatedAt
      total_processed
      __typename
    }
  }
`;
export const updateRetrievedBatchProfileMetadata = /* GraphQL */ `
  mutation UpdateRetrievedBatchProfileMetadata(
    $input: UpdateRetrievedBatchProfileMetadataInput!
    $condition: ModelRetrievedBatchProfileMetadataConditionInput
  ) {
    updateRetrievedBatchProfileMetadata(input: $input, condition: $condition) {
      id
      account_owner
      mode
      current_end_cursor
      future_end_cursor
      current_item_index
      target_entity
      target_entity_id
      batch_size
      retrieved_items
      total_amount_retrieved
      account_profile_id
      filter_options
      createdAt
      updatedAt
      total_processed
      __typename
    }
  }
`;
export const deleteRetrievedBatchProfileMetadata = /* GraphQL */ `
  mutation DeleteRetrievedBatchProfileMetadata(
    $input: DeleteRetrievedBatchProfileMetadataInput!
    $condition: ModelRetrievedBatchProfileMetadataConditionInput
  ) {
    deleteRetrievedBatchProfileMetadata(input: $input, condition: $condition) {
      id
      account_owner
      mode
      current_end_cursor
      future_end_cursor
      current_item_index
      target_entity
      target_entity_id
      batch_size
      retrieved_items
      total_amount_retrieved
      account_profile_id
      filter_options
      createdAt
      updatedAt
      total_processed
      __typename
    }
  }
`;
export const createRetrievedTargetProfiles = /* GraphQL */ `
  mutation CreateRetrievedTargetProfiles(
    $input: CreateRetrievedTargetProfilesInput!
    $condition: ModelRetrievedTargetProfilesConditionInput
  ) {
    createRetrievedTargetProfiles(input: $input, condition: $condition) {
      id
      pk
      username
      full_name
      profile_pic_url
      profile_pic_url_hd
      is_verified
      media_count
      follower_count
      following_count
      biography
      external_url
      account_type
      is_business
      public_email
      contact_phone_number
      public_phone_country_code
      public_phone_number
      business_contact_method
      business_category_name
      category_name
      category
      address_street
      city_id
      city_name
      latitude
      longitude
      zip
      instagram_location_id
      interop_messaging_user_fbid
      is_private
      assumed_gender
      assumed_location
      assumed_location_pk
      assumed_name
      profile_pic_s3_link
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRetrievedTargetProfiles = /* GraphQL */ `
  mutation UpdateRetrievedTargetProfiles(
    $input: UpdateRetrievedTargetProfilesInput!
    $condition: ModelRetrievedTargetProfilesConditionInput
  ) {
    updateRetrievedTargetProfiles(input: $input, condition: $condition) {
      id
      pk
      username
      full_name
      profile_pic_url
      profile_pic_url_hd
      is_verified
      media_count
      follower_count
      following_count
      biography
      external_url
      account_type
      is_business
      public_email
      contact_phone_number
      public_phone_country_code
      public_phone_number
      business_contact_method
      business_category_name
      category_name
      category
      address_street
      city_id
      city_name
      latitude
      longitude
      zip
      instagram_location_id
      interop_messaging_user_fbid
      is_private
      assumed_gender
      assumed_location
      assumed_location_pk
      assumed_name
      profile_pic_s3_link
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRetrievedTargetProfiles = /* GraphQL */ `
  mutation DeleteRetrievedTargetProfiles(
    $input: DeleteRetrievedTargetProfilesInput!
    $condition: ModelRetrievedTargetProfilesConditionInput
  ) {
    deleteRetrievedTargetProfiles(input: $input, condition: $condition) {
      id
      pk
      username
      full_name
      profile_pic_url
      profile_pic_url_hd
      is_verified
      media_count
      follower_count
      following_count
      biography
      external_url
      account_type
      is_business
      public_email
      contact_phone_number
      public_phone_country_code
      public_phone_number
      business_contact_method
      business_category_name
      category_name
      category
      address_street
      city_id
      city_name
      latitude
      longitude
      zip
      instagram_location_id
      interop_messaging_user_fbid
      is_private
      assumed_gender
      assumed_location
      assumed_location_pk
      assumed_name
      profile_pic_s3_link
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessageLog = /* GraphQL */ `
  mutation CreateMessageLog(
    $input: CreateMessageLogInput!
    $condition: ModelMessageLogConditionInput
  ) {
    createMessageLog(input: $input, condition: $condition) {
      id
      account_owner
      sent_to_user_id
      sent_to_username
      current_status
      scheduled_time
      reponded_to
      message_response
      conversation_id
      error_code
      sent_at
      sent_from_username
      message
      createdAt
      updatedAt
      similarity_score
      sent_from_id
      sent_to_assumed_name
      __typename
    }
  }
`;
export const updateMessageLog = /* GraphQL */ `
  mutation UpdateMessageLog(
    $input: UpdateMessageLogInput!
    $condition: ModelMessageLogConditionInput
  ) {
    updateMessageLog(input: $input, condition: $condition) {
      id
      account_owner
      sent_to_user_id
      sent_to_username
      current_status
      scheduled_time
      reponded_to
      message_response
      conversation_id
      error_code
      sent_at
      sent_from_username
      message
      createdAt
      updatedAt
      similarity_score
      sent_from_id
      sent_to_assumed_name
      __typename
    }
  }
`;
export const deleteMessageLog = /* GraphQL */ `
  mutation DeleteMessageLog(
    $input: DeleteMessageLogInput!
    $condition: ModelMessageLogConditionInput
  ) {
    deleteMessageLog(input: $input, condition: $condition) {
      id
      account_owner
      sent_to_user_id
      sent_to_username
      current_status
      scheduled_time
      reponded_to
      message_response
      conversation_id
      error_code
      sent_at
      sent_from_username
      message
      createdAt
      updatedAt
      similarity_score
      sent_from_id
      sent_to_assumed_name
      __typename
    }
  }
`;
export const createIgAccounts = /* GraphQL */ `
  mutation CreateIgAccounts(
    $input: CreateIgAccountsInput!
    $condition: ModelIgAccountsConditionInput
  ) {
    createIgAccounts(input: $input, condition: $condition) {
      id
      username
      password
      proxy_url
      account_owner
      createdAt
      updatedAt
      is_active
      __typename
    }
  }
`;
export const updateIgAccounts = /* GraphQL */ `
  mutation UpdateIgAccounts(
    $input: UpdateIgAccountsInput!
    $condition: ModelIgAccountsConditionInput
  ) {
    updateIgAccounts(input: $input, condition: $condition) {
      id
      username
      password
      proxy_url
      account_owner
      createdAt
      updatedAt
      is_active
      __typename
    }
  }
`;
export const deleteIgAccounts = /* GraphQL */ `
  mutation DeleteIgAccounts(
    $input: DeleteIgAccountsInput!
    $condition: ModelIgAccountsConditionInput
  ) {
    deleteIgAccounts(input: $input, condition: $condition) {
      id
      username
      password
      proxy_url
      account_owner
      createdAt
      updatedAt
      is_active
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      account_owner
      profile_name
      company_description
      purpose_of_campaign
      call_to_action
      contact_person
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      account_owner
      profile_name
      company_description
      purpose_of_campaign
      call_to_action
      contact_person
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      account_owner
      profile_name
      company_description
      purpose_of_campaign
      call_to_action
      contact_person
      createdAt
      updatedAt
      __typename
    }
  }
`;
