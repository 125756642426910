import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AppMainMenu from 'components/AppMainMenu';
import Messages from 'components/Messages';
import SettingsEinstellungenWrapper from 'components/SettingsEinstellungenWrapper';
import DashboardWrapper from 'components/DashboardWrapper';
import Tutorial from 'components/Tutorial';
import Test from 'components/Test';
import SubscriptionTablePage from 'components/SubscriptionTablePage';
import { generateClient } from "aws-amplify/api";
import { listUserProperties } from "./graphql/queries";
import { Notification } from 'ui-components';
import ContactHelpWidget from 'components/ContactHelpWidget';
import { fetchAuthSession } from 'aws-amplify/auth';

const client = generateClient();

const App = ({ signOut, user }) => {
  const navigate = useNavigate();
  const [userID, setUserID] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [usernameName, setUsernameName] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Add loading state



  useEffect(() => {
    const fetchSession = async () => {
      try {
        const session = await fetchAuthSession();
        //console.log("Session", session);
        setUsernameName(session.tokens.idToken.payload.name);
        setUserID(session.tokens.idToken.payload.sub);
      } catch (error) {
        console.log("User not logged in or unable to fetch session", error);
      }
    };

    fetchSession();
  }, []);

  const getUserProperties = async () => {
    try {
      const oneUserProperties = await client.graphql({
        query: listUserProperties,
      });
      const sub_status = oneUserProperties.data.listUserProperties.items[0].stripe_subscription_status;

      if (sub_status === 'active') {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    getUserProperties();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Render a loading indicator
  }

  return (
    <>
      {isActive ? (
        <>
          <Routes>
            <Route path="/info" element={<Tutorial usernameName={usernameName} />} />
            <Route path="/main_menu" element={<AppMainMenu />} />
            <Route path="/dashboard" element={<DashboardWrapper />} />
            <Route path="/messages" element={<Messages userID={userID} />} />
            <Route path="/settings" element={<SettingsEinstellungenWrapper />} />
            <Route path="/contact_help" element={<ContactHelpWidget />} />
          </Routes>
        </>
      ) : (
        <div style={{ backgroundColor: '#002640' }} className="flex flex-col items-center justify-center min-h-screen">

<div className='mt-10'>
            <Notification
              overrides={{
                Notification: {
                  width: "100%",
                  padding: '20px',
                },
                Layout: {
                  maxWidth: '800px',
                },
                notification_placeholder: {
                  children: "Falls Sie neu bei OptimaticLeads sind und die App kostenlos und unverbindlich für 7 Tage testen möchten, senden Sie uns gerne eine Nachricht über WhatsApp an die Nummer +49 151 5058 7612 oder per E-Mail an info@optimaticleads.com. Zögern Sie nicht, uns bei Fragen oder für weitere Informationen zu kontaktieren.",
                }
              }}
              info="info"
            />
          </div>

          <div className='mt-10'>
            <Notification
              overrides={{
                Notification: {
                  width: "100%",
                  padding: '20px',
                },
                Layout: {
                  maxWidth: '800px',
                },
                notification_placeholder: {
                  children: "Sie haben kein aktives Abonnement, um die App zu nutzen. Überprüfen Sie bitte die Abonnement-Optionen unten oder kontaktieren Sie den Support, wenn Sie glauben, dass Sie diese Seite nicht sehen sollten.",
                }
              }}
              info="error"
            />
          </div>
         
          <div style={{ backgroundColor: '#002640' }}>
            <SubscriptionTablePage />
          </div>
        </div>
      )}
    </>
  );
};

export default App;
