/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Button, Flex, useBreakpointValue } from "@aws-amplify/ui-react";
export default function AddAccountButton(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: { Button: {}, Layout: {}, AddAccountButton: {} },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="863px"
      height="81px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="14px 0px 14px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "AddAccountButton")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        borderRadius="4px"
        padding="9px 20px 9px 20px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Button
          width="unset"
          height="unset"
          borderRadius="11px"
          shrink="0"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          backgroundColor="rgba(30,136,229,1)"
          size="small"
          isDisabled={false}
          variation="default"
          children="Instagram Konto verbinden"
          {...getOverrideProps(overrides, "Button")}
        ></Button>
      </Flex>
    </Flex>
  );
}
