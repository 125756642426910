/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Divider, Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
import FeaturesV2breakpoint4 from "./FeaturesV2breakpoint4";
import Doneoutline from "./Doneoutline";
export default function FeaturesV2(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        text: {},
        Header: {},
        Frame44583: {},
        Frame44627: {},
        "FeaturesV2/breakpoint455931592": {},
        "Frame 44611": {},
        "Skalierung manueller, personalisierter Akquise": {},
        "Frame 44610": {},
        "Frame 4450351552603": {},
        "Die Skalierbarkeit manueller, personalisierter Akquise-Strategien in sozialen Medien stellt eine erhebliche Herausforderung dar. W\u00E4hrend die Kosten f\u00FCr Werbung steigen und die Erreichbarkeit des Publikums abnimmt, m\u00FCssen Unternehmen innovative Ans\u00E4tze entwickeln, um effektiv zu skalieren und ihre Zielgruppen individuell anzusprechen.":
          {},
        "Frame 4450451552605": {},
        "Frame 37351552599": {},
        "Frame 13651552598": {},
        Divider51552608: {},
        "Frame 4450251552607": {},
        done_outline55931290: {},
        "Frame 44613": {},
        "Automatisierte Erstellung und Versand von Nachrichten": {},
        "Frame 44612": {},
        "Frame 4450551552614": {},
        "Mit OptimaticLeads generiert unsere KI automatisch personalisierte Nachrichten und \u00FCbernimmt den Versand. Sie m\u00FCssen nur ein paar Klicks machen, um den gesamten Prozess in Gang zu setzen. F\u00FCgen Sie bis zu 10 Konten hinzu und versenden Sie bis zu 15.000 Outreach-Nachrichten pro Monat, ohne den Aufwand manueller Arbeit. So k\u00F6nnen Sie effizient und zeitsparend Ihre Zielgruppe erreichen.":
          {},
        "Frame 4450651552616": {},
        "Frame 37651552610": {},
        "Frame 39351552609": {},
        "Frame 4450051552597": {},
        "FeaturesV2/breakpoint455931637": {},
        "Frame 44617": {},
        "Identische Nachrichten spammen f\u00FChrt zu Vertrauensverlust": {},
        "Frame 44616": {},
        "Frame 4450351551920": {},
        "Das Versenden derselben statischen Nachricht an viele Empf\u00E4nger f\u00FChrt zu mangelndem Vertrauen und geringer Interaktion. Personalisierung und Variabilit\u00E4t sind entscheidend, um echte Gespr\u00E4che zu f\u00F6rdern und Vertrauen aufzubauen.":
          {},
        "Frame 4450451551927": {},
        "Frame 37351101162": {},
        "Frame 13651101139": {},
        Divider51101146: {},
        "Frame 4450251551894": {},
        done_outline55931419: {},
        "Frame 44619": {},
        "Personalisierte Nachrichten f\u00FChren zu einer pers\u00F6nlichen Verbindung":
          {},
        "Frame 44618": {},
        "Frame 4450551551935": {},
        "Unsere KI analysiert das Profil jeder Person und erstellt eine einzigartige Nachricht, die die Wahrscheinlichkeit einer Antwort erh\u00F6ht. So entsteht eine pers\u00F6nliche Verbindung und das Vertrauen wird gest\u00E4rkt.":
          {},
        "Frame 4450651551942": {},
        "Frame 37651101174": {},
        done_outline55931456: {},
        "Frame 44621": {},
        "Gezielte Zielgruppenfindung": {},
        "Frame 44620": {},
        "Frame 4450051551863": {},
        "Unsere Plattform erm\u00F6glicht es Ihnen, Ihre Zielgruppe basierend auf Standort, Hashtags oder den Followern und Followings spezifischer interessanter Seiten zu finden. So erreichen Sie genau die Personen, die f\u00FCr Ihr Angebot relevant sind.":
          {},
        "Frame 4450151551886": {},
        "Frame 375": {},
        "Frame 39351101147": {},
        "Frame 44477": {},
        "FeaturesV2/breakpoint455931672": {},
        "Frame 44625": {},
        "Sicherheitsrisiken durch aktuelle L\u00F6sungen": {},
        "Frame 44624": {},
        "Frame 445035247759": {},
        "Viele derzeitige L\u00F6sungen erfordern die Installation von Browser-Plugins und \u00E4hnlichen Tools, die Sicherheitsrisiken darstellen. Zudem nutzen sie Benutzerprofile, um Informationen zu extrahieren, was das Risiko einer Kontosperrung erh\u00F6ht.":
          {},
        "Frame 445045247761": {},
        "Frame 3735247755": {},
        "Frame 1365247754": {},
        Divider5247764: {},
        "Frame 445025247763": {},
        done_outline55931486: {},
        "Frame 44623": {},
        "Sichere, installationsfreie L\u00F6sung": {},
        "Frame 44622": {},
        "Frame 445055247770": {},
        "Unsere AI erfordert keine Installation von Plugins oder zus\u00E4tzlichen Tools, da alles auf unserer Website abl\u00E4uft. Zudem wird das Profil des Nutzers niemals zur Informationsgewinnung verwendet.":
          {},
        "Frame 445065247772": {},
        "Frame 3765247766": {},
        "Frame 3935247765": {},
        "Frame 445015247753": {},
        Layout: {},
        FeaturesV2: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        text: { fontSize: "32px", lineHeight: "40px" },
        Header: {},
        Frame44583: {},
        Frame44627: {},
        "FeaturesV2/breakpoint455931592": {},
        "Frame 44611": {},
        "Skalierung manueller, personalisierter Akquise": { fontSize: "32px" },
        "Frame 44610": {},
        "Frame 4450351552603": {},
        "Die Skalierbarkeit manueller, personalisierter Akquise-Strategien in sozialen Medien stellt eine erhebliche Herausforderung dar. W\u00E4hrend die Kosten f\u00FCr Werbung steigen und die Erreichbarkeit des Publikums abnimmt, m\u00FCssen Unternehmen innovative Ans\u00E4tze entwickeln, um effektiv zu skalieren und ihre Zielgruppen individuell anzusprechen.":
          { fontSize: "10px", lineHeight: "15px" },
        "Frame 4450451552605": {},
        "Frame 37351552599": { padding: "0px 10px 0px 10px" },
        "Frame 13651552598": { shrink: "0" },
        Divider51552608: { orientation: "horizontal", width: "300px" },
        "Frame 4450251552607": {},
        done_outline55931290: {},
        "Frame 44613": {},
        "Automatisierte Erstellung und Versand von Nachrichten": {
          fontSize: "32px",
        },
        "Frame 44612": {},
        "Frame 4450551552614": {},
        "Mit OptimaticLeads generiert unsere KI automatisch personalisierte Nachrichten und \u00FCbernimmt den Versand. Sie m\u00FCssen nur ein paar Klicks machen, um den gesamten Prozess in Gang zu setzen. F\u00FCgen Sie bis zu 10 Konten hinzu und versenden Sie bis zu 15.000 Outreach-Nachrichten pro Monat, ohne den Aufwand manueller Arbeit. So k\u00F6nnen Sie effizient und zeitsparend Ihre Zielgruppe erreichen.":
          { fontSize: "10px", lineHeight: "15px" },
        "Frame 4450651552616": {},
        "Frame 37651552610": { padding: "0px 10px 0px 10px" },
        "Frame 39351552609": { gap: "20px", shrink: "0" },
        "Frame 4450051552597": { gap: "20px", direction: "column" },
        "FeaturesV2/breakpoint455931637": {},
        "Frame 44617": {},
        "Identische Nachrichten spammen f\u00FChrt zu Vertrauensverlust": {
          fontSize: "32px",
        },
        "Frame 44616": {},
        "Frame 4450351551920": {},
        "Das Versenden derselben statischen Nachricht an viele Empf\u00E4nger f\u00FChrt zu mangelndem Vertrauen und geringer Interaktion. Personalisierung und Variabilit\u00E4t sind entscheidend, um echte Gespr\u00E4che zu f\u00F6rdern und Vertrauen aufzubauen.":
          { fontSize: "10px", lineHeight: "15px" },
        "Frame 4450451551927": {},
        "Frame 37351101162": { padding: "0px 10px 0px 10px" },
        "Frame 13651101139": { shrink: "0" },
        Divider51101146: { orientation: "horizontal", width: "300px" },
        "Frame 4450251551894": {},
        done_outline55931419: {},
        "Frame 44619": {},
        "Personalisierte Nachrichten f\u00FChren zu einer pers\u00F6nlichen Verbindung":
          { fontSize: "32px" },
        "Frame 44618": {},
        "Frame 4450551551935": {},
        "Unsere KI analysiert das Profil jeder Person und erstellt eine einzigartige Nachricht, die die Wahrscheinlichkeit einer Antwort erh\u00F6ht. So entsteht eine pers\u00F6nliche Verbindung und das Vertrauen wird gest\u00E4rkt.":
          { fontSize: "10px", lineHeight: "15px" },
        "Frame 4450651551942": {},
        "Frame 37651101174": { padding: "0px 10px 0px 10px" },
        done_outline55931456: {},
        "Frame 44621": {},
        "Gezielte Zielgruppenfindung": { fontSize: "32px" },
        "Frame 44620": {},
        "Frame 4450051551863": {},
        "Unsere Plattform erm\u00F6glicht es Ihnen, Ihre Zielgruppe basierend auf Standort, Hashtags oder den Followern und Followings spezifischer interessanter Seiten zu finden. So erreichen Sie genau die Personen, die f\u00FCr Ihr Angebot relevant sind.":
          { fontSize: "10px", lineHeight: "15px" },
        "Frame 4450151551886": {},
        "Frame 375": { padding: "0px 10px 0px 10px" },
        "Frame 39351101147": { shrink: "0" },
        "Frame 44477": { gap: "20px", direction: "column" },
        "FeaturesV2/breakpoint455931672": {},
        "Frame 44625": {},
        "Sicherheitsrisiken durch aktuelle L\u00F6sungen": { fontSize: "32px" },
        "Frame 44624": {},
        "Frame 445035247759": {},
        "Viele derzeitige L\u00F6sungen erfordern die Installation von Browser-Plugins und \u00E4hnlichen Tools, die Sicherheitsrisiken darstellen. Zudem nutzen sie Benutzerprofile, um Informationen zu extrahieren, was das Risiko einer Kontosperrung erh\u00F6ht.":
          { fontSize: "10px", lineHeight: "15px" },
        "Frame 445045247761": {},
        "Frame 3735247755": { padding: "0px 10px 0px 10px" },
        "Frame 1365247754": { shrink: "0" },
        Divider5247764: { orientation: "horizontal", width: "300px" },
        "Frame 445025247763": {},
        done_outline55931486: {},
        "Frame 44623": {},
        "Sichere, installationsfreie L\u00F6sung": { fontSize: "32px" },
        "Frame 44622": {},
        "Frame 445055247770": {},
        "Unsere AI erfordert keine Installation von Plugins oder zus\u00E4tzlichen Tools, da alles auf unserer Website abl\u00E4uft. Zudem wird das Profil des Nutzers niemals zur Informationsgewinnung verwendet.":
          { fontSize: "10px", lineHeight: "15px" },
        "Frame 445065247772": {},
        "Frame 3765247766": { padding: "0px 10px 0px 10px" },
        "Frame 3935247765": { shrink: "0" },
        "Frame 445015247753": { gap: "20px", direction: "column" },
        Layout: { gap: "40px" },
        FeaturesV2: { width: "360px", padding: "24px 10px 24px 10px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="21px"
      direction="row"
      width="1021px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="24px 20px 24px 20px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "FeaturesV2")}
      {...rest}
    >
      <Flex
        gap="25px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="0px 10px 0px 10px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="none"
          {...getOverrideProps(overrides, "Frame44627")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="4px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(245,245,245,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame44583")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="10px 10px 10px 10px"
              display="flex"
              {...getOverrideProps(overrides, "Header")}
            >
              <Text
                fontFamily="Inter"
                fontSize="48px"
                fontWeight="900"
                color="rgba(255,127,80,1)"
                lineHeight="58.09090805053711px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Status Quo vs. OptimaticLeads "
                {...getOverrideProps(overrides, "text")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="25px 0px 25px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 4450051552597")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 13651552598")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="10px"
              padding="0px 25px 0px 25px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 37351552599")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4450351552603")}
              >
                <Flex
                  gap="10px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44611")}
                >
                  <FeaturesV2breakpoint4
                    width="45px"
                    height="45px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(
                      overrides,
                      "FeaturesV2/breakpoint455931592"
                    )}
                  ></FeaturesV2breakpoint4>
                </Flex>
                <Flex
                  gap="10px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44610")}
                >
                  <Text
                    fontFamily="Poppins"
                    fontSize="38px"
                    fontWeight="600"
                    color="rgba(245,245,245,1)"
                    lineHeight="45px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Skalierung manueller, personalisierter Akquise"
                    {...getOverrideProps(
                      overrides,
                      "Skalierung manueller, personalisierter Akquise"
                    )}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 10px 0px 10px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4450451552605")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Die Skalierbarkeit manueller, personalisierter Akquise-Strategien in sozialen Medien stellt eine erhebliche Herausforderung dar. Während die Kosten für Werbung steigen und die Erreichbarkeit des Publikums abnimmt, müssen Unternehmen innovative Ansätze entwickeln, um effektiv zu skalieren und ihre Zielgruppen individuell anzusprechen.&#xA;"
                  {...getOverrideProps(
                    overrides,
                    "Die Skalierbarkeit manueller, personalisierter Akquise-Strategien in sozialen Medien stellt eine erhebliche Herausforderung dar. W\u00E4hrend die Kosten f\u00FCr Werbung steigen und die Erreichbarkeit des Publikums abnimmt, m\u00FCssen Unternehmen innovative Ans\u00E4tze entwickeln, um effektiv zu skalieren und ihre Zielgruppen individuell anzusprechen."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4450251552607")}
          >
            <Divider
              height="unset"
              shrink="0"
              alignSelf="stretch"
              size="default"
              orientation="vertical"
              {...getOverrideProps(overrides, "Divider51552608")}
            ></Divider>
          </Flex>
          <Flex
            gap="50px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 39351552609")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="10px"
              padding="0px 25px 0px 25px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 37651552610")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4450551552614")}
              >
                <Flex
                  gap="10px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44613")}
                >
                  <Doneoutline
                    width="45px"
                    height="45px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "done_outline55931290")}
                  ></Doneoutline>
                </Flex>
                <Flex
                  gap="10px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44612")}
                >
                  <Text
                    fontFamily="Poppins"
                    fontSize="38px"
                    fontWeight="600"
                    color="rgba(245,245,245,1)"
                    lineHeight="45px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Automatisierte Erstellung und Versand von Nachrichten"
                    {...getOverrideProps(
                      overrides,
                      "Automatisierte Erstellung und Versand von Nachrichten"
                    )}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 10px 0px 10px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4450651552616")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Mit OptimaticLeads generiert unsere KI automatisch personalisierte Nachrichten und übernimmt den Versand. Sie müssen nur ein paar Klicks machen, um den gesamten Prozess in Gang zu setzen. Fügen Sie bis zu 10 Konten hinzu und versenden Sie bis zu 15.000 Outreach-Nachrichten pro Monat, ohne den Aufwand manueller Arbeit. So können Sie effizient und zeitsparend Ihre Zielgruppe erreichen."
                  {...getOverrideProps(
                    overrides,
                    "Mit OptimaticLeads generiert unsere KI automatisch personalisierte Nachrichten und \u00FCbernimmt den Versand. Sie m\u00FCssen nur ein paar Klicks machen, um den gesamten Prozess in Gang zu setzen. F\u00FCgen Sie bis zu 10 Konten hinzu und versenden Sie bis zu 15.000 Outreach-Nachrichten pro Monat, ohne den Aufwand manueller Arbeit. So k\u00F6nnen Sie effizient und zeitsparend Ihre Zielgruppe erreichen."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="25px 0px 25px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 44477")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 13651101139")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="10px"
              padding="0px 25px 0px 25px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 37351101162")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4450351551920")}
              >
                <Flex
                  gap="10px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44617")}
                >
                  <FeaturesV2breakpoint4
                    width="45px"
                    height="45px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(
                      overrides,
                      "FeaturesV2/breakpoint455931637"
                    )}
                  ></FeaturesV2breakpoint4>
                </Flex>
                <Flex
                  gap="10px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44616")}
                >
                  <Text
                    fontFamily="Poppins"
                    fontSize="38px"
                    fontWeight="600"
                    color="rgba(245,245,245,1)"
                    lineHeight="45px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Identische Nachrichten spammen führt zu Vertrauensverlust"
                    {...getOverrideProps(
                      overrides,
                      "Identische Nachrichten spammen f\u00FChrt zu Vertrauensverlust"
                    )}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 10px 0px 10px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4450451551927")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Das Versenden derselben statischen Nachricht an viele Empfänger führt zu mangelndem Vertrauen und geringer Interaktion. Personalisierung und Variabilität sind entscheidend, um echte Gespräche zu fördern und Vertrauen aufzubauen."
                  {...getOverrideProps(
                    overrides,
                    "Das Versenden derselben statischen Nachricht an viele Empf\u00E4nger f\u00FChrt zu mangelndem Vertrauen und geringer Interaktion. Personalisierung und Variabilit\u00E4t sind entscheidend, um echte Gespr\u00E4che zu f\u00F6rdern und Vertrauen aufzubauen."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4450251551894")}
          >
            <Divider
              height="unset"
              shrink="0"
              alignSelf="stretch"
              size="default"
              orientation="vertical"
              {...getOverrideProps(overrides, "Divider51101146")}
            ></Divider>
          </Flex>
          <Flex
            gap="50px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 39351101147")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="10px"
              padding="0px 25px 0px 25px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 37651101174")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4450551551935")}
              >
                <Flex
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44618")}
                >
                  <Flex
                    gap="10px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 44619")}
                  >
                    <Doneoutline
                      width="45px"
                      height="45px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(overrides, "done_outline55931419")}
                    ></Doneoutline>
                  </Flex>
                  <Text
                    fontFamily="Poppins"
                    fontSize="38px"
                    fontWeight="600"
                    color="rgba(245,245,245,1)"
                    lineHeight="45px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Personalisierte Nachrichten führen zu einer persönlichen Verbindung"
                    {...getOverrideProps(
                      overrides,
                      "Personalisierte Nachrichten f\u00FChren zu einer pers\u00F6nlichen Verbindung"
                    )}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 10px 0px 10px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4450651551942")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Unsere KI analysiert das Profil jeder Person und erstellt eine einzigartige Nachricht, die die Wahrscheinlichkeit einer Antwort erhöht. So entsteht eine persönliche Verbindung und das Vertrauen wird gestärkt."
                  {...getOverrideProps(
                    overrides,
                    "Unsere KI analysiert das Profil jeder Person und erstellt eine einzigartige Nachricht, die die Wahrscheinlichkeit einer Antwort erh\u00F6ht. So entsteht eine pers\u00F6nliche Verbindung und das Vertrauen wird gest\u00E4rkt."
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="10px"
              padding="0px 25px 0px 25px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 375")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4450051551863")}
              >
                <Flex
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44620")}
                >
                  <Flex
                    gap="10px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 44621")}
                  >
                    <Doneoutline
                      width="45px"
                      height="45px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(overrides, "done_outline55931456")}
                    ></Doneoutline>
                  </Flex>
                  <Text
                    fontFamily="Poppins"
                    fontSize="38px"
                    fontWeight="600"
                    color="rgba(245,245,245,1)"
                    lineHeight="45px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Gezielte Zielgruppenfindung"
                    {...getOverrideProps(
                      overrides,
                      "Gezielte Zielgruppenfindung"
                    )}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 10px 0px 10px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4450151551886")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Unsere Plattform ermöglicht es Ihnen, Ihre Zielgruppe basierend auf Standort, Hashtags oder den Followern und Followings spezifischer interessanter Seiten zu finden. So erreichen Sie genau die Personen, die für Ihr Angebot relevant sind."
                  {...getOverrideProps(
                    overrides,
                    "Unsere Plattform erm\u00F6glicht es Ihnen, Ihre Zielgruppe basierend auf Standort, Hashtags oder den Followern und Followings spezifischer interessanter Seiten zu finden. So erreichen Sie genau die Personen, die f\u00FCr Ihr Angebot relevant sind."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="25px 0px 25px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 445015247753")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 1365247754")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="10px"
              padding="0px 25px 0px 25px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 3735247755")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445035247759")}
              >
                <Flex
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44624")}
                >
                  <Flex
                    gap="10px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 44625")}
                  >
                    <FeaturesV2breakpoint4
                      width="45px"
                      height="45px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "FeaturesV2/breakpoint455931672"
                      )}
                    ></FeaturesV2breakpoint4>
                  </Flex>
                  <Text
                    fontFamily="Poppins"
                    fontSize="38px"
                    fontWeight="600"
                    color="rgba(245,245,245,1)"
                    lineHeight="45px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Sicherheitsrisiken durch aktuelle Lösungen"
                    {...getOverrideProps(
                      overrides,
                      "Sicherheitsrisiken durch aktuelle L\u00F6sungen"
                    )}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 10px 0px 10px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445045247761")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Viele derzeitige Lösungen erfordern die Installation von Browser-Plugins und ähnlichen Tools, die Sicherheitsrisiken darstellen. Zudem nutzen sie Benutzerprofile, um Informationen zu extrahieren, was das Risiko einer Kontosperrung erhöht."
                  {...getOverrideProps(
                    overrides,
                    "Viele derzeitige L\u00F6sungen erfordern die Installation von Browser-Plugins und \u00E4hnlichen Tools, die Sicherheitsrisiken darstellen. Zudem nutzen sie Benutzerprofile, um Informationen zu extrahieren, was das Risiko einer Kontosperrung erh\u00F6ht."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 445025247763")}
          >
            <Divider
              height="unset"
              shrink="0"
              alignSelf="stretch"
              size="default"
              orientation="vertical"
              {...getOverrideProps(overrides, "Divider5247764")}
            ></Divider>
          </Flex>
          <Flex
            gap="50px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 3935247765")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="10px"
              padding="0px 25px 0px 25px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 3765247766")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445055247770")}
              >
                <Flex
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 44622")}
                >
                  <Flex
                    gap="10px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "Frame 44623")}
                  >
                    <Doneoutline
                      width="45px"
                      height="45px"
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      overflow="hidden"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(overrides, "done_outline55931486")}
                    ></Doneoutline>
                  </Flex>
                  <Text
                    fontFamily="Poppins"
                    fontSize="38px"
                    fontWeight="600"
                    color="rgba(245,245,245,1)"
                    lineHeight="45px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Sichere, installationsfreie Lösung"
                    {...getOverrideProps(
                      overrides,
                      "Sichere, installationsfreie L\u00F6sung"
                    )}
                  ></Text>
                </Flex>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 10px 0px 10px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 445065247772")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="18px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Unsere AI erfordert keine Installation von Plugins oder zusätzlichen Tools, da alles auf unserer Website abläuft. Zudem wird das Profil des Nutzers niemals zur Informationsgewinnung verwendet."
                  {...getOverrideProps(
                    overrides,
                    "Unsere AI erfordert keine Installation von Plugins oder zus\u00E4tzlichen Tools, da alles auf unserer Website abl\u00E4uft. Zudem wird das Profil des Nutzers niemals zur Informationsgewinnung verwendet."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
