import React ,{ useState, useEffect } from 'react';
import MarketingFooter from 'ui-components/MarketingFooter';
import { useNavigate } from 'react-router-dom';
import { NotifyLaunch } from 'ui-components';
import NavigationBar from 'ui-components/NavigationBar';
import Contact from 'ui-components/Contact';
import { PopupWidget } from "react-calendly";
import HeroV2 from 'ui-components/HeroV2';
import { FeaturesV2 } from 'ui-components';
import { LandingPageImage } from 'ui-components';
import {  createTheme } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';

import 'App.css';
import SubscriptionTablePage from './SubscriptionTablePage';



const LandingPage = ({ signOut, user }) => {



    const navigate = useNavigate();
    //const [inputFieldStyle, setInputFieldStyle] = useState({});

    const [apiResponse, setApiResponse] = useState(null);
    
    const [textFieldValue      , setTextFieldValue] = useState('');
    const [newsletterTextValue , setNewsletterTextValue] = useState('')
    const [valueOfEmail        , setValueOfEmail] = useState('');

    const [valueOfNotificationText , setValueOfNotificationText] = useState('');
    const [colorOfNotificationText , setColorOfNotificationText] = useState('black');
    const [valueofNewsletterText   , setValueOfNewsletterText] = useState('');
    const [colorOfNewsletterText   , setColorOfNewsletterText] = useState('black');


    const [contactFormFirstName, setContactFormFirstName] = useState('');
    const [contactFormLastName, setContactFormLastName] = useState('');
    const [contactFormEmail, setContactFormEmail] = useState('');
    const [contactFormMessage, setContactFormMessage] = useState('');
    const [contactFormNotificationText, setContactFormNotificationText] = useState('');
    const [contactFormNotificationColor, setContactFormNotificationColor] = useState('black');


    const [isLoadingTestPhaseRegistration, setIsLoadingTestPhaseRegistration] = useState(false);
    const [isLoadingContactForm, setIsLoadingContactForm] = useState(false);
    const [isLoadingNewsletter, setisLoadingNewsletter] = useState(false);

    const [showImpressum, setShowImpressum] = useState(false);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userEmail, setUserEmail]= useState('');



    useEffect(() => {
      const fetchSession = async () => {
        try {
          const session = await fetchAuthSession();
          //console.log("logged_in_details", session);
          setUserEmail(session.tokens.idToken.payload.email)
          //console.log(session.tokens.idToken.payload.name)
          setIsLoggedIn(true);

        } catch (error) {
          console.log("User not logged in or unable to fetch session", error);
          
        }
      };
  
      fetchSession();
    }, []);
  


    // const [isLoggedIn, setIsLoggedIn] = useState(false);
        // useEffect(() => {
    //     // Adjust based on how you manage authentication state
    //     // This could be checking a global state, context, or directly with AWS Amplify's Auth module
    //     if (user && user.username) {
    //         setIsLoggedIn(true);
    //     } else {
    //         setIsLoggedIn(false);
    //     }
    //     //console.log('user logged in :', setIsLoggedIn);
    // }, [user]);


    const theme = createTheme({
        name: 'Accordion-theme',
        tokens: {
        components: {
            accordion: {
            backgroundColor: '{colors.blue.10}',
            item: {
                trigger: {
                color: '{colors.blue.80}',
                _hover: {
                    color: '{colors.blue.90}',
                    backgroundColor: '{colors.blue.20}',
                },
                },
                content: {
                color: '{colors.blue.80}',
                },
            },
            },
        },
        },
    });


    const Impressum = () => {
        return (
          <>
  <div style={{
      display: 'flex',       // Enables flexbox
      flexDirection: 'column', // Stacks children vertically
      justifyContent: 'center', // Centers children vertically in the container
      alignItems: 'center',     // Centers children horizontally in the container
      height: 'auto',          // Full viewport height
      width: '100%',           // Full width of the viewport
      margin: '0 auto',         // Centers the div horizontally in the viewport
      backgroundColor: '#002640', // Optional: Background color for visibility
      color: '#F5F5F5',         // Optional: Text color for visibility
    }}>
        {/*<div className='w-full items-center justify-center' style={{color:'#F5F5F5', backgroundColor: '#002640' }} >*/}
        <>
       <div style={{ padding:'20px' }}>
        <h1 className='pb-5 text-xl'>Impressum</h1>
        <p>Maher Al Sadeq<br />
        Gründer & Datenwissenschaftler<br />
        Hinter den G&auml;rten 10<br />
        86157 Augsburg</p>
        <h2 className='pt-5 pb-5 text-lg'>Kontakt</h2>
        <p>Telefon: 004915150587612<br />
        E-Mail: info@optimaticleads.com</p>
        <h2 className='pt-5 text-lg'>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz: DE367000922</p>

        <h2 className='pt-5 text-lg'>Gewerbeanmeldung</h2>
        <p>Die Gewerbeerlaubnis nach 34c GewO wurde am 29.02.2024 von folgender Stelle erteilt: Stadt Augsburg,
        Ordnungsamt.</p>

        <h2 className='pt-5 text-lg'>EU-Streitschlichtung</h2>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
        href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener
        noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im
        Impressum.</p>
        <h2 className='pt-5 text-lg'>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. </p>
            <p>Zuständig ist die Universalschlichtungsstelle des Zentrums für Schlichtung e.V., </p>
                <p>Straßburger Straße 8, 77694 Kehl am Rhein https://www.verbraucher-schlichter.de. </p>
        </div>

            </>
        </div>
    </>
        );
        }

    const handleLoginButtonClick = () => {
        //console.log('Button clicked');
        // here we need to call the table and perform check on the time and amount of items left etc.
        navigate('/app/info');
    }
    const handleNavigationClick = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleSignOut = () => {
        signOut();
        //refresh the page
        window.location.reload();
    }

    const handleRegisterButtonClick = () => {
        //console.log('Button clicked');
        // if (isLoggedIn) {
        //     handleSignOut()
            
        // }
        //else
        navigate('/app/info');
    }

    useEffect(() => {
        //console.log('API Response rendered:', apiResponse); // Log whenever apiResponse updates
        //extract the current domain I am operating from
        //console.log("current domain is", window.location.origin)
    }, []);


    const loggedOutOverrides = {

        NavigationBar : {
            width:"100%",
            //marginbottom: '10px', 
          },
          Layout:{
            maxWidth: '1200px',
    
          },
          login:{
            display: isLoggedIn ? 'block' : 'none',
            onClick: () => handleSignOut(),
            style:{
                cursor: "pointer",
            }
          },

        Rectangle_1228: { //process.env.REACT_APP_IMG_1
            src: process.env.REACT_APP_IMG_1,
            alt: 'logo',
            width: '50px',
            height: '50px',
            overflow: 'hidden',
        },
        Button49841563: { // Assuming this is a "Log in" button
            children: isLoggedIn ? 'App Starten' : 'Einloggen',

            style: {
                cursor: "pointer",
                //backgroundColor: '#1E88E5',
                color: '#1E88E5',

            },

            onClick: () => handleLoginButtonClick(), // Adjust as needed
        },
        Button49841579: { // Assuming this is a "Sign up" button
            style: {
                backgroundColor: '#1E88E5',
                cursor: "pointer",
                color: 'white',
            },
            onClick: () => handleRegisterButtonClick(), // Adjust as needed 
            display: isLoggedIn ? 'none' : 'block',
        },
        // Add any other overrides for the logged-out state

        Startseite: {
            style:{
                cursor: "pointer",
            },
            onClick: () => handleNavigationClick('hero-section'),
        },
        FrameReason: {
            style:{
                cursor: "pointer",
            },
            onClick: () => handleNavigationClick('anleitung'),
        },
        Preise: {
            //display: 'none',
            style:{
                cursor: "pointer",
                //opacity: 0.5,
            },
            onClick: () => handleNavigationClick('pricing-section'),
        },
        Kontakt: {
            style:{
                cursor: "pointer",
            },
            onClick: () => handleNavigationClick('contact'),
        },

    };

    const handleSubscribeToNewsletter = async ( notification ) => {
        //console.log('handleSubscribeToNewsletter clicked ', notification);

        //setApiResponse(null); // Reset the apiResponse state
        if (notification === 'launch_announcement'){
            setIsLoadingTestPhaseRegistration(true); // Set loading state to true
        }
        else if (notification === 'newsletter'){
            setisLoadingNewsletter(true); // Set loading state to true

        }


        const emailToSend = notification === "launch_announcement" ? textFieldValue : newsletterTextValue;
        setValueOfEmail(emailToSend); // This will update the state for future use

        //console.log("value debugged", valueOfEmail )

        const request_body = {
          "body": {
            'mode' : notification,
            'email': emailToSend,
           
          }
        };
        //console.log("sending request to newsletter :", request_body);
      
        // Check if target_entity, mode, and selectedProfileId are not empty
    
        //setIsLoadingProfile(true); // Set loading state to true
        //console.log("newsletter endpoint : ", process.env.REACT_APP_NEWSLETTER)
        try {
          const response = await fetch(process.env.REACT_APP_NEWSLETTER, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(request_body),
          });
      
          const data = await response.json();
          //console.log('Received data:', data); // Check the structure of data

          setApiResponse(data.body);
        if ('success' in data) {
            if (notification === 'launch_announcement'){
        setValueOfNotificationText("Ihre E-Mail wurde erfolgreich erfasst. Wir melden uns in Kürze mit Details zum Testablauf.");
        setColorOfNotificationText('#4CAF50');
            }
            if (notification === 'newsletter'){
                setValueOfNewsletterText("Erfolgreich abonniert, vielen Dank!");
                setColorOfNewsletterText('#4CAF50');
            }    
    } 
      
        if ('error' in data) {

        if (notification === 'launch_announcement'){

        setValueOfNotificationText("keine gültige E-Mail-Adresse");
        setColorOfNotificationText('#ff4d4d');
        }
        if (notification === 'newsletter'){
            setValueOfNewsletterText("keine gültige E-Mail-Adresse");
            setColorOfNewsletterText('#ff4d4d');
        }   
        }
        } 
        catch (error) {
          console.error('Error in newsletter endpoint:', error);
        }
        finally {
          //setIsLoadingProfile(false); // Set loading state to false
          if (notification === 'launch_announcement'){
            setIsLoadingTestPhaseRegistration(false); // Set loading state to true
        }
        else if (notification === 'newsletter'){
            setisLoadingNewsletter(false); // Set loading state to true

        }        }
      };

      useEffect(() => {
        //console.log('API Response rendered:', apiResponse); // Log whenever apiResponse updates
      }, [apiResponse]);
      
        const handleChangeTextField = (e) => {
            //console.log(e.target.value);
            setTextFieldValue(e.target.value);
        }

        const handleChangeNewsletterTextField = (e) => {
            //console.log("value of news letter", e.target.value);
            setNewsletterTextValue(e.target.value);
        }


        const handleContactFormSubmit = async () => {
            setIsLoadingContactForm(true); // Set loading state to true
            //console.log('handleContactFormSubmit clicked');
            // Log the form data
            //console.log("contact form data : ", contactFormFirstName, contactFormLastName, contactFormEmail, contactFormMessage);
          
            // Check if email or message is empty
            if (!contactFormEmail.trim() || !contactFormMessage.trim()) {
              console.error("Email and message are required fields.");
              setContactFormNotificationText("Email und Nachricht sind erforderlich. Bitte füllen Sie diese Felder aus.");
              setContactFormNotificationColor('#ff4d4d'); // Setting the color to red or any error color
              return; // Stop the function execution
            }
          
            const request_body = {
              "body": {
                'first_name': contactFormFirstName,
                'last_name': contactFormLastName,
                'email': contactFormEmail,
                'message': contactFormMessage,
              }
            };
            //console.log("sending request to contact form :", request_body);
            //console.log("contact form endpoint : ", process.env.REACT_APP_CONTACT_FORM);
            try {
              const response = await fetch(process.env.REACT_APP_CONTACT_FORM, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request_body),
              });
              const data = await response.json();
              //console.log('Received data:', data); // Log the received data
              setApiResponse(data.body);
              if ('success' in data) {
                setValueOfEmail(contactFormEmail);
          
                setContactFormNotificationText("Erfolgreich abgesendet, vielen Dank!");
                setContactFormNotificationColor('#4CAF50');
              } 
              if ('error' in data) {
                setContactFormNotificationText("Fehler beim Absenden, bitte versuchen Sie es erneut");
                setContactFormNotificationColor('#ff4d4d');
              }
            } catch (error) {
              console.error('Error in contact form endpoint:', error);
            } finally {
              // Reset form values
              setContactFormFirstName('');
              setContactFormLastName('');
              setContactFormEmail('');
              setContactFormMessage('');
              setIsLoadingContactForm(false); // Set loading state to false
            }
          }
          const loadStripeScript = () => {
            const script = document.createElement('script');
            script.src = "https://js.stripe.com/v3/pricing-table.js";
            script.async = true;
            document.body.appendChild(script);
          };

            useEffect(() => {
            loadStripeScript();
            }, []);

        const handleShowImpressum = () => {
            //console.log('Impressum clicked');
            // here we need to call the table and perform check on the time and amount of items left etc.
            setShowImpressum(true);
        }

    return (

        <div className="flex flex-col items-center justify-center min-h-screen " style={{ backgroundColor: '#002640' }}>
            <NavigationBar overrides={loggedOutOverrides} />



            <LandingPageImage overrides={{
                LandingPageImage:{
                    width: '100%',
                    maxWidth: '1200px',
                },
                Image:{ //process.env.REACT_APP_IMG_1,
                    src:process.env.REACT_APP_IMG_2,
                    alt: 'hero_image',
                    //maxWidth: '1200px',
                    overflow: 'hidden',

                }
            }}/>
            



            <HeroV2 className='backgroundImageClass' overrides={{
                HeroV2:{
                    width : '100%',
                },
                Layout:{
                    maxWidth: '1200px',
                },
                Heading:{
                    maxWidth: '600px',
                },

                 Eyebrow: { //F67223
                        children: (<div style={{ fontFamily: 'Poppins, sans-serif'}}><span style={{ color: '#F67223' }}   >KI</span>-generierte Akquise-Nachrichten in Ihrem Schreibstil über Social Media.</div>),
                    },
                    Button:{
                        onClick: () => handleNavigationClick('notify-launch'),
                        style:{
                            cursor: "pointer",
                        }
                    }
              }}/>

              <FeaturesV2 id='anleitung' overrides={{
                FeaturesV2:{
                    width: '100%',
                  //  marginbottom: '10px',

                },
                Layout:{
                    maxWidth: '1200px',
                }
              }}/>
<LandingPageImage style={{ backgroundColor: '#002640' }} overrides={{
                LandingPageImage:{
                    width: '100%',
                    maxWidth: '1200px',
                },
                Image:{ 
                    src:process.env.REACT_APP_IMG_3,
                    alt: 'hero_image2',
                    //maxWidth: '1200px',
                    overflow: 'hidden',

                }
            }}/>

            <div id='pricing-section'>
            <SubscriptionTablePage /></div>

<NotifyLaunch id='notify-launch' overrides={{
                NotifyLaunch:{
                    //marginbottom: '10px',
                    width: '100%',
                },
                Layout:{
                    maxWidth: '1200px',
                },
                FrameInfo: {
                    maxWidth: '1000px',
                },
                Loader:{
                    display: isLoadingTestPhaseRegistration ? 'block' : 'none',

                },

                notification_placeholder:{
                    children: valueOfNotificationText,
                    color: colorOfNotificationText,
                },
                Button:{
                    style: {
                        cursor: "pointer",
                    },
                    onClick: () => handleSubscribeToNewsletter('launch_announcement'),
                    display: isLoadingTestPhaseRegistration ? 'none' : 'block',

                    
                },

            Loader:{
                display: isLoadingTestPhaseRegistration ? 'block' : 'none',
            },

                
                TextField:{
                   // value: 'Ihre E-Mail',
                   onChange: (e) => handleChangeTextField(e),

                },
            
            }}/>

{/*
<Roadmap id="roadmap" overrides={{
                Roadmap:{
                    width: '100%',
                },
                Layout:{
                    maxWidth: '1200px',
                }
            }}/>
 */}


    {/*        
<Newsletter id='newsletter' overrides={{
                Newsletter:
                {  
                    width: '100%',
                    marginbottom: '10px',

                },
                Layout:{
                    maxWidth: '1200px',
                }
                
                ,
                Button:{
                    style: {
                        cursor: "pointer",
                    },
                    onClick: () => handleSubscribeToNewsletter('newsletter'),
                    display: isLoadingNewsletter ? 'none' : 'block',
                },
                Loader:{
                    display: isLoadingNewsletter ? 'block' : 'none',
                },
                newsletter_placeholder:{
                    children: valueofNewsletterText,
                    color: colorOfNewsletterText,
                
                },
                TextField:{
                    // value: 'Ihre E-Mail',
                    onChange: (e) => handleChangeNewsletterTextField(e),
 
                 },


            }}  />

*/}


            <Contact id="contact" overrides={{
            Contact:{
                //marginbottom: '10px',
                width: '100%',
            },
            Layout:{
                maxWidth: '1200px',
            }
            ,
            TextField29766936:{ //first name
                value : contactFormFirstName,
                backgroundColor: 'white',
                onChange: (e) => setContactFormFirstName(e.target.value),


            },
            TextField29766938:{ //last name
                value : contactFormLastName,
                backgroundColor: 'white',
                onChange: (e) => setContactFormLastName(e.target.value),
            
            },
            TextField29766939:{ //email
                value : contactFormEmail,
                backgroundColor: 'white',
                onChange: (e) => setContactFormEmail(e.target.value),
            
            },
            TextAreaField:{ //message
                value : contactFormMessage,
                backgroundColor: 'white',
                onChange: (e) => setContactFormMessage(e.target.value),

            },
            Button:{
                backgroundColor: '#1E88E5',
                onClick: () => handleContactFormSubmit(),
                display: isLoadingContactForm ? 'none' : 'block',

            },

            Loader:{
                display: isLoadingContactForm ? 'block' : 'none',
            },


            contactForm_placeholder:{

                children: contactFormNotificationText,
                color: contactFormNotificationColor,
            },
        }}/>



{showImpressum && <Impressum />}

            <MarketingFooter overrides={{
                MarketingFooter:{
                   // marginBottom: '80px',
                    width: '100%',
                
                },
                Impressum:{
                    onClick: () => handleShowImpressum(),
                    style:{
                        cursor: "pointer",
                    },
                },
                Frame44511:{
                    maxWidth: '1200px',
                },
                Rectangle1226:{
                    src: process.env.REACT_APP_IMG_4,
                    alt: 'logo',
                    //width: '24px',
                    //height: '24px',
                    overflow: 'hidden',
                
                },
                Frame28:{
                    //make the onclick takes the user to a newtab with a url
                    onClick: () => window.open('https://www.instagram.com/optimaticleads/'),
                    style:{
                        cursor: "pointer",
                    }
                },
                Frame30:{
                    //make the onclick takes the user to a newtab with a url
                    onClick: () => window.open('https://www.linkedin.com/company/optimaticleads/'),
                    style:{
                        cursor: "pointer",
                    }

                },
                Rectangle1228:{ 
                    src: process.env.REACT_APP_IMG_5,
                    alt: 'logo',
                    //width: '24px',
                    //height: '24px',
                    overflow: 'hidden',

                
                },
            }}/>
      

        <PopupWidget
        url="https://calendly.com/optimaticleads/30-minutiges-meeting"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Beratung Vereinbaren"
        textColor="#f5f5f5"
        color="#1E88E5"
        
      />
        </div>
    );
};

export default LandingPage;
