/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function SettingsEintellungen(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        "Button Icon4509763": {},
        label4509764: {},
        "Button Icon4509765": {},
        Button4509762: {},
        "Button Icon4509771": {},
        label4509772: {},
        "Button Icon4509773": {},
        Button4509770: {},
        "Button Icon4509759": {},
        label4509760: {},
        "Button Icon4509761": {},
        Button4509758: {},
        Layout: {},
        SettingsEintellungen: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        "Button Icon4509763": {},
        label4509764: { fontSize: "14px", lineHeight: "21px" },
        "Button Icon4509765": {},
        Button4509762: {},
        "Button Icon4509771": {},
        label4509772: { fontSize: "14px", lineHeight: "21px" },
        "Button Icon4509773": {},
        Button4509770: { width: "148.52px" },
        "Button Icon4509759": {},
        label4509760: { fontSize: "14px", lineHeight: "21px" },
        "Button Icon4509761": {},
        Button4509758: {},
        Layout: { padding: "8px 10px 8px 10px" },
        SettingsEintellungen: { width: "749px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        "Button Icon4509763": {},
        label4509764: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
        },
        "Button Icon4509765": {},
        Button4509762: {
          width: "unset",
          height: "unset",
          padding: "4px 17.507568359375px 4px 17.507568359375px",
        },
        "Button Icon4509771": {},
        label4509772: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
          width: "111.32px",
          shrink: "0",
        },
        "Button Icon4509773": {},
        Button4509770: {
          width: "unset",
          height: "unset",
          padding: "4px 17.507568359375px 4px 17.507568359375px",
        },
        "Button Icon4509759": {},
        label4509760: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
        },
        "Button Icon4509761": {},
        Button4509758: {
          width: "unset",
          height: "unset",
          padding: "4px 17.507568359375px 4px 17.507568359375px",
        },
        Layout: { padding: "8px 10px 8px 10px" },
        SettingsEintellungen: { width: "543px" },
      },
      variantValues: { breakpoint: "out" },
    },
    {
      overrides: {
        "Button Icon4509763": {},
        label4509764: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
        },
        "Button Icon4509765": {},
        Button4509762: {
          width: "unset",
          height: "unset",
          padding: "4px 4px 4px 4px",
        },
        "Button Icon4509771": {},
        label4509772: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
          textAlign: "left",
          shrink: "0",
        },
        "Button Icon4509773": {},
        Button4509770: {
          width: "unset",
          height: "unset",
          padding: "4px 4px 4px 4px",
        },
        "Button Icon4509759": {},
        label4509760: {
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "21px",
        },
        "Button Icon4509761": {},
        Button4509758: {
          width: "unset",
          height: "unset",
          padding: "4px 4px 4px 4px",
        },
        Layout: {},
        SettingsEintellungen: { width: "416px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="844px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="16px 0px 16px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "SettingsEintellungen")}
      {...rest}
    >
      <Flex
        gap="12px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="8px 20px 8px 20px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="0"
          direction="row"
          width="181.52px"
          height="38.19px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1.09px SOLID rgba(174,179,183,1)"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="5.0942230224609375px"
          padding="8.7537841796875px 17.507568359375px 8.7537841796875px 17.507568359375px"
          display="flex"
          {...getOverrideProps(overrides, "Button4509762")}
        >
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon4509763")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(245,245,245,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Unternehmensprofil"
            {...getOverrideProps(overrides, "label4509764")}
          ></Text>
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon4509765")}
          ></Icon>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="181.52px"
          height="38.19px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1.09px SOLID rgba(174,179,183,1)"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="5.0942230224609375px"
          padding="8.7537841796875px 17.507568359375px 8.7537841796875px 17.507568359375px"
          display="flex"
          {...getOverrideProps(overrides, "Button4509770")}
        >
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon4509771")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(245,245,245,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="IG Konten"
            {...getOverrideProps(overrides, "label4509772")}
          ></Text>
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon4509773")}
          ></Icon>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="181.52px"
          height="38.19px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1.09px SOLID rgba(174,179,183,1)"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="5.0942230224609375px"
          padding="8.7537841796875px 17.507568359375px 8.7537841796875px 17.507568359375px"
          display="flex"
          {...getOverrideProps(overrides, "Button4509758")}
        >
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon4509759")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(245,245,245,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="vordef. Nachrichten"
            {...getOverrideProps(overrides, "label4509760")}
          ></Text>
          <Icon
            width="17.51px"
            height="17.51px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="none"
            fontSize="17.51px"
            {...getOverrideProps(overrides, "Button Icon4509761")}
          ></Icon>
        </Flex>
      </Flex>
    </Flex>
  );
}
