import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionSuccess = () => {
    const [countdown, setCountdown] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    useEffect(() => {
        if (countdown <= 0) {
            navigate('/app/info'); // Replace '/newpage' with your desired route
        }
    }, [countdown, navigate]);

    return (
        <div style={{ backgroundColor: '#002640' }}  className="flex flex-col items-center justify-center min-h-screen">
            <h1 className='m-10 font-medium' style={{color : '#E5E5E5'}}>Abonnement erfolgreich! Weiterleitung in {countdown} Sekunden....</h1>
        </div>
    );
};

export default SubscriptionSuccess;
