/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function ShowConfirmedMessage(props) {
  const { messageLog, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        text_holder_output_message: {},
        FrameText: {},
        Absender: {},
        "Frame 44534": {},
        abender_ph: {},
        "Frame 44533": {},
        "Frame 44535": {},
        "geplanter Sendezeit": {},
        "Frame 44530": {},
        geplanter_senderzeit_ph: {},
        "Frame 44531": {},
        "Frame 44532": {},
        Zielkonto: {},
        "Frame 44528": {},
        zielkonto_ph: {},
        "Frame 44527": {},
        "Frame 44529": {},
        "Frame 44537": {},
        Layout: {},
        ShowConfirmedMessage: {},
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        text_holder_output_message: {
          fontSize: "14px",
          lineHeight: "21px",
          width: "unset",
          height: "284px",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        FrameText: { alignSelf: "stretch" },
        Absender: {
          fontSize: "14px",
          lineHeight: "21px",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        "Frame 44534": {},
        abender_ph: {
          fontSize: "14px",
          lineHeight: "21px",
          width: "unset",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        "Frame 44533": { alignSelf: "stretch" },
        "Frame 44535": { alignSelf: "stretch" },
        "geplanter Sendezeit": {
          fontSize: "14px",
          lineHeight: "21px",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        "Frame 44530": { shrink: "0" },
        geplanter_senderzeit_ph: {
          fontSize: "14px",
          lineHeight: "21px",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        "Frame 44531": {},
        "Frame 44532": { alignSelf: "stretch" },
        Zielkonto: {
          fontSize: "14px",
          lineHeight: "21px",
          width: "unset",
          height: "unset",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        "Frame 44528": { alignSelf: "stretch" },
        zielkonto_ph: {
          fontSize: "14px",
          lineHeight: "21px",
          width: "unset",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        "Frame 44527": { alignSelf: "stretch" },
        "Frame 44529": { alignSelf: "stretch" },
        "Frame 44537": {
          gap: "8px",
          direction: "column",
          justifyContent: "center",
        },
        Layout: {
          gap: "40px",
          shrink: "1",
          padding: "20px 20px 20px 20px",
          grow: "1",
          basis: "0",
        },
        ShowConfirmedMessage: { width: "360px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="1011px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="20px 0px 20px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "ShowConfirmedMessage")}
      {...rest}
    >
      <Flex
        gap="12px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="60px 60px 60px 60px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="4px 4px 4px 4px"
          backgroundColor="rgba(245,245,245,1)"
          display="flex"
          {...getOverrideProps(overrides, "FrameText")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16.25px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24.375px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="755px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={messageLog?.message}
            {...getOverrideProps(overrides, "text_holder_output_message")}
          ></Text>
        </Flex>
        <Flex
          gap="40px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 44537")}
        >
          <Flex
            gap="2px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44535")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(245,245,245,1)"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44534")}
            >
              <Text
                fontFamily="Inter"
                fontSize="17.5px"
                fontWeight="400"
                color="rgba(245,245,245,1)"
                lineHeight="26.25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Absender"
                {...getOverrideProps(overrides, "Absender")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(245,245,245,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44533")}
            >
              <Text
                fontFamily="Inter"
                fontSize="17.5px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="26.25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="218.95px"
                height="30px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={messageLog?.sent_from_username}
                {...getOverrideProps(overrides, "abender_ph")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="2px"
            direction="column"
            width="unset"
            height="62px"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44532")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(245,245,245,1)"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44530")}
            >
              <Text
                fontFamily="Inter"
                fontSize="17.5px"
                fontWeight="400"
                color="rgba(245,245,245,1)"
                lineHeight="26.25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="geplanter Sendezeit"
                {...getOverrideProps(overrides, "geplanter Sendezeit")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(245,245,245,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44531")}
            >
              <Text
                fontFamily="Inter"
                fontSize="17.5px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="26.25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={messageLog?.scheduled_time}
                {...getOverrideProps(overrides, "geplanter_senderzeit_ph")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="2px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44529")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              border="1px SOLID rgba(245,245,245,1)"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44528")}
            >
              <Text
                fontFamily="Inter"
                fontSize="17.5px"
                fontWeight="400"
                color="rgba(245,245,245,1)"
                lineHeight="26.25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="218.08px"
                height="30px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Zielkonto"
                {...getOverrideProps(overrides, "Zielkonto")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              borderRadius="4px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(245,245,245,1)"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44527")}
            >
              <Text
                fontFamily="Inter"
                fontSize="17.5px"
                fontWeight="400"
                color="rgba(0,0,0,0.87)"
                lineHeight="26.25px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="218.95px"
                height="30px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={messageLog?.sent_to_username}
                {...getOverrideProps(overrides, "zielkonto_ph")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
