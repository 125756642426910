import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settingsv2 } from 'ui-components';
import 'App.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Notification from 'ui-components/Notification';


const Layout = ({ children }) => {
    const navigate = useNavigate();
    const { signOut } = useAuthenticator((context) => [context.signOut]);
    const [activeButton, setActiveButton] = useState(null); // Step 1: State to track active button

    const handleNavigation = (route, buttonId) => {
      navigate(route);
      setActiveButton(buttonId); // Update active button state
    };

    const handleSignOut = (buttonId) => {
      signOut();
      navigate('/');
      navigate(0);
      //window.location.reload();
      //navigate('/');
      //setActiveButton(buttonId);
  }
    // Dynamically generate button styles based on the active state
    const getButtonStyle = () => ({
      cursor: "pointer",
      className: 'hover-effect',
    });

    const getBackgroundColor = (buttonId) => {
      return activeButton === buttonId ? '#1E88E5' : ''; //#1E88E5
    }

    return ( //style={{ backgroundColor: '#002640' }}
      <div  style={{ backgroundColor: '#002640' }} className="flex flex-col items-center justify-start min-h-screen">
        <Settingsv2 overrides={{
          Settingsv2:{
            width: '100%',
          },
          Layout:{
            maxWidth: '1100px',
          },
          
            style: {
              //marginBottom: '80px',
              //position: 'fixed',
              //top: '0',
              //zIndex: 1000,
            },
            Button4509744: {
              className: 'hover-effect',
              onClick: () => handleNavigation('/app/main_menu', 'Button4509744'),
              style: getButtonStyle(), // Apply dynamic style
              backgroundColor: getBackgroundColor('Button4509744'),
            },
            Button4509740: {
              className: 'hover-effect',
              onClick: () => handleNavigation('/app/dashboard', 'Button4509740'),
              style: getButtonStyle(), // Apply dynamic style
              backgroundColor: getBackgroundColor('Button4509740'),

            },
            Button4509748: {
              className: 'hover-effect',
              onClick: () => handleNavigation('/app/messages', 'Button4509748'),
              style: getButtonStyle(), // Apply dynamic style
              backgroundColor: getBackgroundColor('Button4509748'),

            },
            SettingsWidget: {
              className: 'hover-effect',
              onClick: () => handleNavigation('/app/settings', 'Button4509736'),
              style: getButtonStyle(), // Apply dynamic style
              backgroundColor: getBackgroundColor('Button4509736'),

            },
            Button4509752: {
              className: 'hover-effect',
              onClick: () => handleSignOut('Button4509752'),
              style: getButtonStyle(), // Apply dynamic style
              //backgroundColor: '#EF6C00',

            },
            ContactHelp:{
              className: 'hover-effect',
              onClick: () => handleNavigation('/app/contact_help', 'ContactHelp'),
              style: getButtonStyle(), // Apply dynamic style
              //backgroundColor: '#EF6C00',
            },
            menu_book :{
              className: 'hover-effect',
              onClick: () => handleNavigation('/app/info', 'menu_book'),
              style: getButtonStyle(), // Apply dynamic style
              //backgroundColor: '#EF6C00',
            },
            home:{

              className: 'hover-effect',
              onClick: () => handleNavigation('/', 'home'),
              style: getButtonStyle(), // Apply dynamic style
              //backgroundColor: '#EF6C00',
            },
            login:{ // it's actually the logout button
              className: 'hover-effect',
              onClick: () => handleSignOut('Button4509752'),
              style: getButtonStyle(), // Apply dynamic style
              //backgroundColor: '#EF6C00',
            },
        }} />
       {/* <div className='p-4'>
        <Notification overrides={{
            Notification :{
              width:"100%",
            },
            Layout:{
              maxWidth: '800px',
              
            },
            notification_placeholder:{
              children : "wir aktualisieren gerade die App, einige Funktionen werden für die nächsten 24 Stunden pausiert." ,
            }
          }} info="warning"
          /></div>*/}
        <div className='w-full'> {/* w-full max-w-1100 */}
                {children}
        </div>
        {/*<div style={{ backgroundColor: '#002640', color: 'white', padding: '20px' }}>
      This is a blue div with text color white.
    </div> */}  
       </div>
    );
};

export default Layout;
