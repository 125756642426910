/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Home(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="35px"
      height="35px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 35, height: 35 }}
      paths={[
        {
          d: "M10.4969 22.8302L10.4969 15.5385L16.3302 15.5385L16.3302 22.8302C16.3302 23.6323 16.9865 24.2885 17.7885 24.2885L22.1635 24.2885C22.9656 24.2885 23.6219 23.6323 23.6219 22.8302L23.6219 12.6219L26.101 12.6219C26.7719 12.6219 27.0927 11.7906 26.5823 11.3531L14.3906 0.371875C13.8365 -0.123958 12.9906 -0.123958 12.4365 0.371875L0.244784 11.3531C-0.251049 11.7906 0.0552007 12.6219 0.726034 12.6219L3.2052 12.6219L3.2052 22.8302C3.2052 23.6323 3.86145 24.2885 4.66353 24.2885L9.03853 24.2885C9.84062 24.2885 10.4969 23.6323 10.4969 22.8302Z",
          fill: "rgba(245,245,245,1)",
          fillRule: "nonzero",
          style: { transform: "translate(11.68%, 13.94%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Home")}
      {...rest}
    ></Icon>
  );
}
