/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function SettingsWidget(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="35px"
      height="35px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 35, height: 35 }}
      paths={[
        {
          d: "M25.024 16.0125C25.0824 15.5458 25.1261 15.0792 25.1261 14.5833C25.1261 14.0875 25.0824 13.6208 25.024 13.1542L28.1011 10.7479C28.3782 10.5292 28.4511 10.1354 28.2761 9.81458L25.3594 4.76875C25.1844 4.44792 24.7907 4.33125 24.4699 4.44792L20.8386 5.90625C20.0803 5.32292 19.2636 4.84167 18.374 4.47708L17.8199 0.6125C17.7761 0.2625 17.4699 0 17.1053 0L11.2719 0C10.9074 0 10.6011 0.2625 10.5574 0.6125L10.0032 4.47708C9.11361 4.84167 8.29694 5.3375 7.53861 5.90625L3.90736 4.44792C3.57194 4.31667 3.19278 4.44792 3.01778 4.76875L0.101109 9.81458C-0.0884748 10.1354 -0.000974607 10.5292 0.276109 10.7479L3.35319 13.1542C3.29486 13.6208 3.25111 14.1021 3.25111 14.5833C3.25111 15.0646 3.29486 15.5458 3.35319 16.0125L0.276109 18.4187C-0.000974607 18.6375 -0.0738915 19.0312 0.101109 19.3521L3.01778 24.3979C3.19278 24.7187 3.58653 24.8354 3.90736 24.7188L7.53861 23.2604C8.29694 23.8438 9.11361 24.325 10.0032 24.6896L10.5574 28.5542C10.6011 28.9042 10.9074 29.1667 11.2719 29.1667L17.1053 29.1667C17.4699 29.1667 17.7761 28.9042 17.8199 28.5542L18.374 24.6896C19.2636 24.325 20.0803 23.8292 20.8386 23.2604L24.4699 24.7188C24.8053 24.85 25.1844 24.7187 25.3594 24.3979L28.2761 19.3521C28.4511 19.0312 28.3782 18.6375 28.1011 18.4187L25.024 16.0125L25.024 16.0125ZM14.1886 19.6875C11.374 19.6875 9.08444 17.3979 9.08444 14.5833C9.08444 11.7687 11.374 9.47917 14.1886 9.47917C17.0032 9.47917 19.2928 11.7687 19.2928 14.5833C19.2928 17.3979 17.0032 19.6875 14.1886 19.6875Z",
          fill: "rgba(245,245,245,1)",
          fillRule: "nonzero",
          style: { transform: "translate(9.46%, 8.33%)" },
        },
      ]}
      {...getOverrideProps(overrides, "SettingsWidget")}
      {...rest}
    ></Icon>
  );
}
