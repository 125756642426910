/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function ShowPredefinedMessage(props) {
  const { predefinedMessages, overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="row"
      width="899px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
      borderRadius="4px"
      padding="44px 56px 44px 56px"
      backgroundColor="rgba(0,38,64,1)"
      {...getOverrideProps(overrides, "ShowPredefinedMessage")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        borderRadius="10px"
        padding="7px 7px 7px 7px"
        backgroundColor="rgba(245,245,245,1)"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 44562")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="346px"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={predefinedMessages?.predefined_message}
            {...getOverrideProps(overrides, "show_predefined_message_ph")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
