/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getMessageLog } from "../graphql/queries";
import { updateMessageLog } from "../graphql/mutations";
const client = generateClient();
export default function MessageLogUpdateForm(props) {
  const {
    id: idProp,
    messageLog: messageLogModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    account_owner: "",
    sent_to_user_id: "",
    sent_to_username: "",
    current_status: "",
    scheduled_time: "",
    reponded_to: false,
    message_response: "",
    conversation_id: "",
    error_code: "",
    sent_at: "",
    sent_from_username: "",
    message: "",
    createdAt: "",
    updatedAt: "",
    similarity_score: "",
    sent_from_id: "",
    sent_to_assumed_name: "",
  };
  const [account_owner, setAccount_owner] = React.useState(
    initialValues.account_owner
  );
  const [sent_to_user_id, setSent_to_user_id] = React.useState(
    initialValues.sent_to_user_id
  );
  const [sent_to_username, setSent_to_username] = React.useState(
    initialValues.sent_to_username
  );
  const [current_status, setCurrent_status] = React.useState(
    initialValues.current_status
  );
  const [scheduled_time, setScheduled_time] = React.useState(
    initialValues.scheduled_time
  );
  const [reponded_to, setReponded_to] = React.useState(
    initialValues.reponded_to
  );
  const [message_response, setMessage_response] = React.useState(
    initialValues.message_response
  );
  const [conversation_id, setConversation_id] = React.useState(
    initialValues.conversation_id
  );
  const [error_code, setError_code] = React.useState(initialValues.error_code);
  const [sent_at, setSent_at] = React.useState(initialValues.sent_at);
  const [sent_from_username, setSent_from_username] = React.useState(
    initialValues.sent_from_username
  );
  const [message, setMessage] = React.useState(initialValues.message);
  const [createdAt, setCreatedAt] = React.useState(initialValues.createdAt);
  const [updatedAt, setUpdatedAt] = React.useState(initialValues.updatedAt);
  const [similarity_score, setSimilarity_score] = React.useState(
    initialValues.similarity_score
  );
  const [sent_from_id, setSent_from_id] = React.useState(
    initialValues.sent_from_id
  );
  const [sent_to_assumed_name, setSent_to_assumed_name] = React.useState(
    initialValues.sent_to_assumed_name
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = messageLogRecord
      ? { ...initialValues, ...messageLogRecord }
      : initialValues;
    setAccount_owner(cleanValues.account_owner);
    setSent_to_user_id(cleanValues.sent_to_user_id);
    setSent_to_username(cleanValues.sent_to_username);
    setCurrent_status(cleanValues.current_status);
    setScheduled_time(cleanValues.scheduled_time);
    setReponded_to(cleanValues.reponded_to);
    setMessage_response(cleanValues.message_response);
    setConversation_id(cleanValues.conversation_id);
    setError_code(cleanValues.error_code);
    setSent_at(cleanValues.sent_at);
    setSent_from_username(cleanValues.sent_from_username);
    setMessage(cleanValues.message);
    setCreatedAt(cleanValues.createdAt);
    setUpdatedAt(cleanValues.updatedAt);
    setSimilarity_score(cleanValues.similarity_score);
    setSent_from_id(cleanValues.sent_from_id);
    setSent_to_assumed_name(cleanValues.sent_to_assumed_name);
    setErrors({});
  };
  const [messageLogRecord, setMessageLogRecord] =
    React.useState(messageLogModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getMessageLog.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getMessageLog
        : messageLogModelProp;
      setMessageLogRecord(record);
    };
    queryData();
  }, [idProp, messageLogModelProp]);
  React.useEffect(resetStateValues, [messageLogRecord]);
  const validations = {
    account_owner: [],
    sent_to_user_id: [],
    sent_to_username: [],
    current_status: [],
    scheduled_time: [],
    reponded_to: [],
    message_response: [],
    conversation_id: [],
    error_code: [],
    sent_at: [],
    sent_from_username: [],
    message: [],
    createdAt: [],
    updatedAt: [],
    similarity_score: [],
    sent_from_id: [],
    sent_to_assumed_name: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          account_owner: account_owner ?? null,
          sent_to_user_id: sent_to_user_id ?? null,
          sent_to_username: sent_to_username ?? null,
          current_status: current_status ?? null,
          scheduled_time: scheduled_time ?? null,
          reponded_to: reponded_to ?? null,
          message_response: message_response ?? null,
          conversation_id: conversation_id ?? null,
          error_code: error_code ?? null,
          sent_at: sent_at ?? null,
          sent_from_username: sent_from_username ?? null,
          message: message ?? null,
          createdAt: createdAt ?? null,
          updatedAt: updatedAt ?? null,
          similarity_score: similarity_score ?? null,
          sent_from_id: sent_from_id ?? null,
          sent_to_assumed_name: sent_to_assumed_name ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateMessageLog.replaceAll("__typename", ""),
            variables: {
              input: {
                id: messageLogRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "MessageLogUpdateForm")}
      {...rest}
    >
      <TextField
        label="Account owner"
        isRequired={false}
        isReadOnly={false}
        value={account_owner}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner: value,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.account_owner ?? value;
          }
          if (errors.account_owner?.hasError) {
            runValidationTasks("account_owner", value);
          }
          setAccount_owner(value);
        }}
        onBlur={() => runValidationTasks("account_owner", account_owner)}
        errorMessage={errors.account_owner?.errorMessage}
        hasError={errors.account_owner?.hasError}
        {...getOverrideProps(overrides, "account_owner")}
      ></TextField>
      <TextField
        label="Sent to user id"
        isRequired={false}
        isReadOnly={false}
        value={sent_to_user_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id: value,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.sent_to_user_id ?? value;
          }
          if (errors.sent_to_user_id?.hasError) {
            runValidationTasks("sent_to_user_id", value);
          }
          setSent_to_user_id(value);
        }}
        onBlur={() => runValidationTasks("sent_to_user_id", sent_to_user_id)}
        errorMessage={errors.sent_to_user_id?.errorMessage}
        hasError={errors.sent_to_user_id?.hasError}
        {...getOverrideProps(overrides, "sent_to_user_id")}
      ></TextField>
      <TextField
        label="Sent to username"
        isRequired={false}
        isReadOnly={false}
        value={sent_to_username}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username: value,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.sent_to_username ?? value;
          }
          if (errors.sent_to_username?.hasError) {
            runValidationTasks("sent_to_username", value);
          }
          setSent_to_username(value);
        }}
        onBlur={() => runValidationTasks("sent_to_username", sent_to_username)}
        errorMessage={errors.sent_to_username?.errorMessage}
        hasError={errors.sent_to_username?.hasError}
        {...getOverrideProps(overrides, "sent_to_username")}
      ></TextField>
      <TextField
        label="Current status"
        isRequired={false}
        isReadOnly={false}
        value={current_status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status: value,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.current_status ?? value;
          }
          if (errors.current_status?.hasError) {
            runValidationTasks("current_status", value);
          }
          setCurrent_status(value);
        }}
        onBlur={() => runValidationTasks("current_status", current_status)}
        errorMessage={errors.current_status?.errorMessage}
        hasError={errors.current_status?.hasError}
        {...getOverrideProps(overrides, "current_status")}
      ></TextField>
      <TextField
        label="Scheduled time"
        isRequired={false}
        isReadOnly={false}
        value={scheduled_time}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time: value,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.scheduled_time ?? value;
          }
          if (errors.scheduled_time?.hasError) {
            runValidationTasks("scheduled_time", value);
          }
          setScheduled_time(value);
        }}
        onBlur={() => runValidationTasks("scheduled_time", scheduled_time)}
        errorMessage={errors.scheduled_time?.errorMessage}
        hasError={errors.scheduled_time?.hasError}
        {...getOverrideProps(overrides, "scheduled_time")}
      ></TextField>
      <SwitchField
        label="Reponded to"
        defaultChecked={false}
        isDisabled={false}
        isChecked={reponded_to}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to: value,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.reponded_to ?? value;
          }
          if (errors.reponded_to?.hasError) {
            runValidationTasks("reponded_to", value);
          }
          setReponded_to(value);
        }}
        onBlur={() => runValidationTasks("reponded_to", reponded_to)}
        errorMessage={errors.reponded_to?.errorMessage}
        hasError={errors.reponded_to?.hasError}
        {...getOverrideProps(overrides, "reponded_to")}
      ></SwitchField>
      <TextField
        label="Message response"
        isRequired={false}
        isReadOnly={false}
        value={message_response}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response: value,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.message_response ?? value;
          }
          if (errors.message_response?.hasError) {
            runValidationTasks("message_response", value);
          }
          setMessage_response(value);
        }}
        onBlur={() => runValidationTasks("message_response", message_response)}
        errorMessage={errors.message_response?.errorMessage}
        hasError={errors.message_response?.hasError}
        {...getOverrideProps(overrides, "message_response")}
      ></TextField>
      <TextField
        label="Conversation id"
        isRequired={false}
        isReadOnly={false}
        value={conversation_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id: value,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.conversation_id ?? value;
          }
          if (errors.conversation_id?.hasError) {
            runValidationTasks("conversation_id", value);
          }
          setConversation_id(value);
        }}
        onBlur={() => runValidationTasks("conversation_id", conversation_id)}
        errorMessage={errors.conversation_id?.errorMessage}
        hasError={errors.conversation_id?.hasError}
        {...getOverrideProps(overrides, "conversation_id")}
      ></TextField>
      <TextField
        label="Error code"
        isRequired={false}
        isReadOnly={false}
        value={error_code}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code: value,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.error_code ?? value;
          }
          if (errors.error_code?.hasError) {
            runValidationTasks("error_code", value);
          }
          setError_code(value);
        }}
        onBlur={() => runValidationTasks("error_code", error_code)}
        errorMessage={errors.error_code?.errorMessage}
        hasError={errors.error_code?.hasError}
        {...getOverrideProps(overrides, "error_code")}
      ></TextField>
      <TextField
        label="Sent at"
        isRequired={false}
        isReadOnly={false}
        value={sent_at}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at: value,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.sent_at ?? value;
          }
          if (errors.sent_at?.hasError) {
            runValidationTasks("sent_at", value);
          }
          setSent_at(value);
        }}
        onBlur={() => runValidationTasks("sent_at", sent_at)}
        errorMessage={errors.sent_at?.errorMessage}
        hasError={errors.sent_at?.hasError}
        {...getOverrideProps(overrides, "sent_at")}
      ></TextField>
      <TextField
        label="Sent from username"
        isRequired={false}
        isReadOnly={false}
        value={sent_from_username}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username: value,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.sent_from_username ?? value;
          }
          if (errors.sent_from_username?.hasError) {
            runValidationTasks("sent_from_username", value);
          }
          setSent_from_username(value);
        }}
        onBlur={() =>
          runValidationTasks("sent_from_username", sent_from_username)
        }
        errorMessage={errors.sent_from_username?.errorMessage}
        hasError={errors.sent_from_username?.hasError}
        {...getOverrideProps(overrides, "sent_from_username")}
      ></TextField>
      <TextField
        label="Message"
        isRequired={false}
        isReadOnly={false}
        value={message}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message: value,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.message ?? value;
          }
          if (errors.message?.hasError) {
            runValidationTasks("message", value);
          }
          setMessage(value);
        }}
        onBlur={() => runValidationTasks("message", message)}
        errorMessage={errors.message?.errorMessage}
        hasError={errors.message?.hasError}
        {...getOverrideProps(overrides, "message")}
      ></TextField>
      <TextField
        label="Created at"
        isRequired={false}
        isReadOnly={false}
        value={createdAt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt: value,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.createdAt ?? value;
          }
          if (errors.createdAt?.hasError) {
            runValidationTasks("createdAt", value);
          }
          setCreatedAt(value);
        }}
        onBlur={() => runValidationTasks("createdAt", createdAt)}
        errorMessage={errors.createdAt?.errorMessage}
        hasError={errors.createdAt?.hasError}
        {...getOverrideProps(overrides, "createdAt")}
      ></TextField>
      <TextField
        label="Updated at"
        isRequired={false}
        isReadOnly={false}
        value={updatedAt}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt: value,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.updatedAt ?? value;
          }
          if (errors.updatedAt?.hasError) {
            runValidationTasks("updatedAt", value);
          }
          setUpdatedAt(value);
        }}
        onBlur={() => runValidationTasks("updatedAt", updatedAt)}
        errorMessage={errors.updatedAt?.errorMessage}
        hasError={errors.updatedAt?.hasError}
        {...getOverrideProps(overrides, "updatedAt")}
      ></TextField>
      <TextField
        label="Similarity score"
        isRequired={false}
        isReadOnly={false}
        value={similarity_score}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score: value,
              sent_from_id,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.similarity_score ?? value;
          }
          if (errors.similarity_score?.hasError) {
            runValidationTasks("similarity_score", value);
          }
          setSimilarity_score(value);
        }}
        onBlur={() => runValidationTasks("similarity_score", similarity_score)}
        errorMessage={errors.similarity_score?.errorMessage}
        hasError={errors.similarity_score?.hasError}
        {...getOverrideProps(overrides, "similarity_score")}
      ></TextField>
      <TextField
        label="Sent from id"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={sent_from_id}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id: value,
              sent_to_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.sent_from_id ?? value;
          }
          if (errors.sent_from_id?.hasError) {
            runValidationTasks("sent_from_id", value);
          }
          setSent_from_id(value);
        }}
        onBlur={() => runValidationTasks("sent_from_id", sent_from_id)}
        errorMessage={errors.sent_from_id?.errorMessage}
        hasError={errors.sent_from_id?.hasError}
        {...getOverrideProps(overrides, "sent_from_id")}
      ></TextField>
      <TextField
        label="Sent to assumed name"
        isRequired={false}
        isReadOnly={false}
        value={sent_to_assumed_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              sent_to_user_id,
              sent_to_username,
              current_status,
              scheduled_time,
              reponded_to,
              message_response,
              conversation_id,
              error_code,
              sent_at,
              sent_from_username,
              message,
              createdAt,
              updatedAt,
              similarity_score,
              sent_from_id,
              sent_to_assumed_name: value,
            };
            const result = onChange(modelFields);
            value = result?.sent_to_assumed_name ?? value;
          }
          if (errors.sent_to_assumed_name?.hasError) {
            runValidationTasks("sent_to_assumed_name", value);
          }
          setSent_to_assumed_name(value);
        }}
        onBlur={() =>
          runValidationTasks("sent_to_assumed_name", sent_to_assumed_name)
        }
        errorMessage={errors.sent_to_assumed_name?.errorMessage}
        hasError={errors.sent_to_assumed_name?.hasError}
        {...getOverrideProps(overrides, "sent_to_assumed_name")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || messageLogModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || messageLogModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
