import React, { useEffect, useState } from 'react';
import SettingsNachrichten from "ui-components/SettingsNachrichten";
import { WaitingForConfirmation, ShowConfirmedMessageCollection } from "ui-components";
import ShowMessageLogV2Collection from 'ui-components/ShowMessageLogV2Collection';
import { generateClient } from 'aws-amplify/api';
import { listMessageLogs } from 'graphql/queries';
import { ShowConfirmedMessageCollectionVersionTwo } from 'ui-components';
import Notification from 'ui-components/Notification';
import ShowDeliveredMessagesCollection from 'ui-components/ShowDeliveredMessagesCollection';
import { updateMessageLog } from '../graphql/mutations';
const client = generateClient({ authMode: "userPool"});


const Messages = (userID) => {
  
  const [activeComponent, setActiveComponent] = useState('default');
  const [textAreaValue, setTextAreaValue] = useState('Nachrichten werden geladen, bitte warten...');
  const [isLoadingNextMessage, setIsLoadingNextMessage] = useState(false);
  const [username, setUsername] = useState('');
  const [tableID, setTableID] = useState('');
  const [accountOwner, setAccountOwner] = useState('');
  const [RequestReturnedError, setRequestReturnedError] = useState(false);
  const [requestErrorText, setRequestErrorText] = useState('');
  const [NumberOfItems, setNumberOfItems] = useState(0);
  const [internalItemData, setInternalItemData] = useState({});

  const [firstItemData, setFirstItemData] = useState(null);
  const [error, setError] = useState(null);

  const fetchFirstPendingItem = async () => {
    const requestBody = {
      account_owner: userID.userID,
    };

    try {
      //console.log(userID.userID,"useriD")
      //console.log("sending request with body:", requestBody);
      const response = await fetch(process.env.REACT_APP_RETRIEVE_FIRST_PENDING_ITEM, { // Replace with your function URL
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();

      

      if (response.ok) {

        if ('error' in result) {
          if (result['error'] === 'no pending messages available') {
            //console.log("no pending messages available");
            setRequestReturnedError(true);
            setRequestErrorText('Keine ausstehenden Nachrichten verfügbar. Navigieren Sie zur App, um neue Nachrichten zu generieren.');
            setTextAreaValue('...');
            setUsername('...');
          }}

        else{

        setFirstItemData(result[0]);
        setNumberOfItems(result[0].pending_items_left)
        setTextAreaValue(result[0].message);
        setUsername(result[0].sent_to_username);
        setTableID(result[0].id);
        setAccountOwner(result[0].account_owner);

        //console.log('API response:', result);
      }
      } else {
        setError(result.error || 'Unknown error');
        console.error('Error:', result);
        setRequestReturnedError(true);

      }
    } catch (error) {
      setError(error.message || 'Unknown error');
      console.error('Error:', error);
    }
  };

    useEffect(() => {
      //console.log("Fetching the first item...");
      fetchFirstPendingItem();
    }, []);


  const handleButtonClick = componentName => () => {
    setActiveComponent(componentName);
  };
  

  const handleUserDecision = async (action) => {
    setIsLoadingNextMessage(true);
    //console.log("updating MessageLog");
    try {
      // First, update the MessageLog table
      await client.graphql({
        query: updateMessageLog,
        variables: {
            input: {
            id: tableID,
            message: textAreaValue,
            updatedAt: new Date().toISOString().split('.')[0]+"Z" ,
          }
        }
      });
  
      // Proceed with sending the request only if the update is successful
      const request_body = {
        "body": {
          "table_id": tableID,
          "action": action,
          "account_owner": accountOwner,
        }
      };
  
      //console.log("sending request with body:", request_body);
      const response = await fetch(process.env.REACT_APP_SCHEDULE_MESSAGE, { //process.env.REACT_APP_SCHEDULE_MESSAGE
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(request_body),
      });
  
      const data = await response.json();
      setTableID(data['id']);
      setUsername(data['sent_to_username']);
      setTextAreaValue(data['message']);
      setAccountOwner(data['account_owner']);
      setNumberOfItems(data['remaning_messages']);
      //console.log('API response:', data); // Log the response data
  
      if ('error' in data) {
        if (data['error'] === 'no pending messages available') {
          console.log("no pending messages available");
          setRequestReturnedError(true);
          setRequestErrorText('Keine ausstehenden Nachrichten verfügbar. Navigieren Sie zur App, um neue Nachrichten zu generieren.');
          setTextAreaValue('...');
          setUsername('...');
        }
        else if (data['error'] === 'limit reached') {
          console.log("limit reached");
          setRequestReturnedError(true);
          setRequestErrorText('Sie haben das Sendelimit für dieses Zeitintervall erreicht, bitte kommen Sie später wieder.');
        }
        else if (data['error'] === 'no available accounts') {
          console.log("no available accounts")
          setRequestReturnedError(true);
          setRequestErrorText('Sie benötigen mindestens ein verknüpftes Instagram Konto, um Nachrichten versenden zu können. Navigieren Sie zu "Einstellungen" -> "IG Konten" -> "Instagram Konto verbinden", um ein Konto hinzuzufügen.');

        }
      }
    } catch (error) {
      console.error('Error in handling user decision:', error);
    } finally {
      setIsLoadingNextMessage(false);
    }
  };
  

  const getBackgroundColor = (componentName) => {
    return activeComponent === componentName ? '#1E88E5' : ''; // Example colors: Active green, Inactive blue
  };


  const handleTextAreaChange = event => {
    setTextAreaValue(event.target.value);
  };

  // const WaitingForConfirmationWrapper = () => (
  //   <WaitingForConfirmation overrides={{
  //     TextAreaField: {
  //       value: textAreaValue,
  //       onChange: handleTextAreaChange,
  //     },
  //   }} />
  // );


  const handleNavigateToTargetProfile = () => {
    if(username ) {
      const instagramUrl = `https://www.instagram.com/${username}`;
      window.open(instagramUrl, '_blank', 'noopener,noreferrer');
    }
  };





  // Using a function to avoid unnecessary re-renders
  const renderComponent = () => {
    switch (activeComponent) {
      case 'ComponentA':
        return (
        <>
        <WaitingForConfirmation key="ComponentA" overrides={{
          WaitingForConfirmation: {
            width: '100%',
          },
          Layout: {
            maxWidth: '1100px',
          },
          remaining_ph:{
            children: NumberOfItems
          },
          FrameReject:{
            display: isLoadingNextMessage ? 'none' : 'block',
  
          },
          FrameAccept:{
            display: isLoadingNextMessage ? 'none' : 'block',
  
          },
          
          Loader: {
            // if isloadingprofile is true, we display the loader, otherwise we display the button
            display: isLoadingNextMessage ? 'block' : 'none',
            //display: 'none',
            },
          TextAreaField: {
            value: textAreaValue,
            onChange: handleTextAreaChange,
            style:{
            height:"290px",
          }
          },
          user_name:{
            children: username,//"X",// itemData.sent_to_username,
            onClick: handleNavigateToTargetProfile,
            color: 'RGB(25, 118, 210)',
            style: {
              textDecoration: 'underline', // Underlines the text
              cursor: 'pointer', // Changes the cursor to a pointer on hover
            },

          },
          score_placeholder :{
            //children: `${(Number(itemData.similarity_score) * 100).toFixed(0)}%`,
            children: "X",
          },

          MyIcon5236722:{//accept button
            className: 'hover-effect',
            style:{
              cursor: 'pointer',
            },
            onClick: () => handleUserDecision('accept'),
          },
          MyIcon5236720:{ //rejection button
            className: 'hover-effect',
            style:{
              cursor: 'pointer',
            },
            onClick: () => handleUserDecision('reject'),
          },
          Group88:{
            display: isLoadingNextMessage ? 'none' : 'block',
          }

        }} />

    {RequestReturnedError &&  
      <div>
      <Notification info="warning" overrides={{
        Notification :{
          width:"100%",
        },
        Layout:{
          maxWidth: "800px",
        },
        notification_placeholder:{
          children : requestErrorText,
        },
      }} 
      />
      
      </div>
      }
        </>
        );
      case 'ComponentB':
        return <div><ShowDeliveredMessagesCollection   overrideItems={() => {
          return {
            overrides: {
              ShowDeliveredMessages:{
                width:"100%",
              },

              Layout:{
                maxWidth: '1100px',
              },
              
            }
          }}}/></div>;
      case 'componentC':
        return <div>
  
          <ShowConfirmedMessageCollectionVersionTwo key="componentC"   
          
          overrideItems={() => {
            return {
              overrides: {
                ShowConfirmedMessage:{
                  width:"100%",
                },
  
                Layout:{
                  maxWidth: '1100px',
                },
                MyIcon : {
                  className: 'loader',
                  style:{
                    cursor: 'pointer',
                  },
                  backgroundColor: "#F67223",
                }
              }
            }}}
          
          
          />
        </div>;
      default:
        return <></>//<WaitingForConfirmation key="default" />;
    }
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen ">
      <SettingsNachrichten overrides={{
        SettingsNachrichten: {
          width: '100%',
        },
        Layout: {
          maxWidth: '1100px',
        },
        Button4509798: {
          backgroundColor: getBackgroundColor('ComponentA'), // Dynamically set background color
          className: 'hover-effect',
          style: { cursor: "pointer" },
          onClick: handleButtonClick('ComponentA'),
        },
        Button4509794: {
          backgroundColor: getBackgroundColor('ComponentB'), // Dynamically set background color
          className: 'hover-effect',
          style: { cursor: "pointer" },
          onClick: handleButtonClick('ComponentB'),
        },
        Button46221111: {
          backgroundColor: getBackgroundColor('componentC'), // Dynamically set background color
          className: 'hover-effect',
          style: { cursor: "pointer" },
          onClick: handleButtonClick('componentC'),
        },
      }} />
      <div className='w-full'></div>
      {renderComponent()}


    </div>
  );
};

export default Messages;
