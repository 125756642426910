/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { TextAreaField } from "@aws-amplify/ui-react"
import { mergeVariantsAndOverrides, getOverridesFromVariants, getOverrideProps } from "../ui-components/utils";

import { Button, Flex, Text, useBreakpointValue,TextField } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { updateProfile } from '../graphql/mutations';
import { deleteProfile } from "../graphql/mutations";
import Checkcircleoutline from "../ui-components/Checkcircleoutline";


/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

const client = generateClient();
export default function ShowProfile(props) {
  const { profile, overrides: overridesProp, ...restProp } = props;

  const [showSuccess, setShowSuccess] = React.useState(false);

  const [TextCompanyDescription, setTextCompanyDescription] = React.useState(profile? profile.company_description : "");
  const [TextContactPerson, setTextContactPerson] = React.useState(profile? profile.contact_person : "");
  const [TextCTA, setTextCTA] = React.useState(profile? profile.call_to_action : "");
  const [TextPurposeOfCampaign, setTextPurposeOfCampaign] = React.useState(profile? profile.purpose_of_campaign : "");
  const [TextProfileName, setTextProfileName] = React.useState(profile? profile.profile_name : "");


  const variants = [
    {
      overrides: {
        Unternehmensprofil: {},
        "Frame 44427": {},
        text_placeholder_info50571118: {},
        "Frame 4443150571117": {},
        TextField_cs: {},
        "Frame 4443050571119": {},
        "Frame 44432": {},
        text_placeholder_info50571123: {},
        "Frame 4443150571122": {},
        TextField_ph: {},
        "Frame 4443050571124": {},
        "Frame 44433": {},
        text_placeholder_info50571138: {},
        "Frame 4443150571137": {},
        TextAreaField_cta55151430: {},
        "Frame 4443050571139": {},
        "Frame 4443450571136": {},
        text_placeholder_info50571153: {},
        "Frame 4443150571152": {},
        TextAreaField_cta55151444: {},
        "Frame 4443055151443": {},
        "Frame 4443050571154": {},
        "Frame 4443550571151": {},
        text_placeholder_info50571158: {},
        "Frame 4443150571157": {},
        TextAreaField_cta55151452: {},
        FramePH_UB: {},
        "Frame 4443050571159": {},
        "Frame 44436": {},
        "Frame 4443550571115": {},
        "Frame 4443450571112": {},
        Button42191803: {},
        Button55151460: {},
        "Frame 44577": {},
        check_circle_outline: {},
        "Frame 44576": {},
        PlaceholderTextNotification: {},
        "Frame 44575": {},
        FrameSuccess: {},
        "Frame 44437": {},
        Layout: {},
        ShowProfile: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Unternehmensprofil: {},
        "Frame 44427": {
          width: "unset",
          padding: "4px 0px 4px 0px",
          alignSelf: "stretch",
        },
        text_placeholder_info50571118: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150571117": { height: "33px", shrink: "0" },
        TextField_cs: {},
        "Frame 4443050571119": { height: "33px", shrink: "0" },
        "Frame 44432": {
          gap: "4px",
          direction: "column",
          justifyContent: "flex-start",
        },
        text_placeholder_info50571123: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150571122": { height: "33px", shrink: "0" },
        TextField_ph: {},
        "Frame 4443050571124": { height: "33px", shrink: "0" },
        "Frame 44433": {
          gap: "4px",
          direction: "column",
          justifyContent: "flex-start",
        },
        text_placeholder_info50571138: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
          width: "unset",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        "Frame 4443150571137": { alignSelf: "stretch" },
        TextAreaField_cta55151430: {},
        "Frame 4443050571139": { height: "93px", shrink: "0" },
        "Frame 4443450571136": {
          gap: "4px",
          direction: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        text_placeholder_info50571153: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150571152": { shrink: "0", alignSelf: "stretch" },
        TextAreaField_cta55151444: {},
        "Frame 4443055151443": {},
        "Frame 4443050571154": { shrink: "0", alignSelf: "stretch" },
        "Frame 4443550571151": {
          gap: "4px",
          direction: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        text_placeholder_info50571158: {
          fontSize: "14px",
          lineHeight: "16.94318199157715px",
        },
        "Frame 4443150571157": { shrink: "0", alignSelf: "stretch" },
        TextAreaField_cta55151452: {},
        FramePH_UB: {},
        "Frame 4443050571159": { shrink: "0", alignSelf: "stretch" },
        "Frame 44436": {
          gap: "4px",
          direction: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        "Frame 4443550571115": {
          gap: "20px",
          width: "unset",
          alignSelf: "stretch",
        },
        "Frame 4443450571112": {
          justifyContent: "flex-start",
          alignSelf: "stretch",
        },
        Button42191803: {},
        Button55151460: { height: "35px", size: "small" },
        "Frame 44577": {},
        check_circle_outline: { height: "unset", alignSelf: "stretch" },
        "Frame 44576": { height: "24px", alignSelf: "stretch" },
        PlaceholderTextNotification: { height: "unset" },
        "Frame 44575": { height: "26px" },
        FrameSuccess: {
          gap: "18px",
          height: "95px",
          padding: "7px 0px 7px 0px",
        },
        "Frame 44437": {},
        Layout: {
          gap: "30px",
          shrink: "1",
          padding: "40px 20px 40px 20px",
          grow: "1",
          basis: "0",
        },
        ShowProfile: {
          width: "360px",
          height: "954px",
          padding: "20px 0px 20px 0px",
        },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const buttonFourTwoOneNineOneEightZeroThreeOnClick = async () => {
    await client.graphql({
      query: deleteProfile.replaceAll("__typename", ""),
      variables: {
        input: {
          id: profile?.id,
        },
      },
    });
    setShowSuccess(true);
    console.log("Profile deleted");
  };


  
  const buttonOnClickUpdate = async () => {
    console.log("updating profile ");
    console.log("profile id: ", profile?.id);
    console.log("profile name: ", TextProfileName);
    await client.graphql({
      query: updateProfile,
      variables: {
          input: {
        id: profile?.id,
      //"account_owner": this stays the same,
      profile_name: TextProfileName,
      contact_person: TextContactPerson,
      call_to_action: TextCTA,
      purpose_of_campaign: TextPurposeOfCampaign,
      company_description: TextCompanyDescription,
    }
      }
  });



    setShowSuccess(true);
    console.log("Profile deleted");
  };

  const handleOnChangeCompanyDescription = (event) => {
    setTextCompanyDescription(event.target.value); //

  };

  const handleOnChangeProfileName= (event) => {
    setTextProfileName(event.target.value); //

  }

  const handleOnChangeContactPerson = (event) => {
    setTextContactPerson(event.target.value); //

  }

  const handleOnChangeCTA = (event) => {
    setTextCTA(event.target.value); //

  }

  const handleOnChangePurposeOfCampaign = (event) => {
    setTextPurposeOfCampaign(event.target.value); //

  }

  const handleonchangeCTA = (event) => {
    setTextCTA(event.target.value); //

  }

  return (
    <Flex
      gap="10px"
      direction="column"
      width="958px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="20px 25px 20px 25px"
      backgroundColor="#002640"
      display="flex"
      {...getOverrideProps(overrides, "ShowProfile")}
      {...rest}
    >
      <Flex
        gap="40px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        padding="40px 40px 40px 40px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 4443450571112")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="720px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            borderRadius="4px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(30,136,229,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44427")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(245,245,245,1)"
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Unternehmensprofil"
              {...getOverrideProps(overrides, "Unternehmensprofil")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="720px"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 4443550571115")}
          >
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44432")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150571117")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="19.363636016845703px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Unternehmensbezeichnung"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50571118"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050571119")}
              >
                <TextField
                  width="unset"
                  height="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  placeholder="Placeholder"
                  size="small"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  onChange={handleOnChangeProfileName}  
                  value= {TextProfileName}
                  {...getOverrideProps(overrides, "TextField_cs")}
                ></TextField>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44433")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150571122")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="19.363636016845703px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Ansprechpartner"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50571123"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050571124")}
              >
                <TextField
                  width="unset"
                  height="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  placeholder="Placeholder"
                  size="small"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  onChange={handleOnChangeContactPerson}  
                  value= {TextContactPerson}
                  {...getOverrideProps(overrides, "TextField_ph")}
                ></TextField>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443450571136")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="7px 0px 7px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150571137")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="19.363636016845703px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="347.5px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Aufruf Zum Handeln"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50571138"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050571139")}
              >
                <TextAreaField
                  width="unset"
                  height="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  placeholder="Placeholder"
                  size="small"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  onChange={handleonchangeCTA}  
                  value= {TextCTA}
                  {...getOverrideProps(overrides, "TextAreaField_cta55151430")}
                ></TextAreaField>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 4443550571151")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="7px 0px 7px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150571152")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="19.363636016845703px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Zweck der Kampagne"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50571153"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050571154")}
              >
                <Flex
                  gap="10px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  borderRadius="4px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(245,245,245,1)"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 4443055151443")}
                >
                  <TextAreaField
                    width="unset"
                    height="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    placeholder="Placeholder"
                    size="small"
                    isDisabled={false}
                    labelHidden={true}
                    variation="default"
                    onChange={handleOnChangePurposeOfCampaign}  
                    value= {TextPurposeOfCampaign}
                    {...getOverrideProps(
                      overrides,
                      "TextAreaField_cta55151444"
                    )}
                  ></TextAreaField>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              gap="25px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44436")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                border="1px SOLID rgba(245,245,245,1)"
                borderRadius="4px"
                padding="7px 0px 7px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443150571157")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="19.363636016845703px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Unternehmensbeschreibung"
                  {...getOverrideProps(
                    overrides,
                    "text_placeholder_info50571158"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                borderRadius="4px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(245,245,245,1)"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4443050571159")}
              >
                <Flex
                  gap="10px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  borderRadius="4px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(245,245,245,1)"
                  display="flex"
                  {...getOverrideProps(overrides, "FramePH_UB")}
                >
                  <TextAreaField
                    width="unset"
                    height="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    placeholder="Placeholder"
                    size="small"
                    isDisabled={false}
                    labelHidden={true}
                    onChange={handleOnChangeCompanyDescription}  
                    value= {TextCompanyDescription}
                    variation="default"
                    {...getOverrideProps(
                      overrides,
                      "TextAreaField_cta55151452"
                    )}
                  ></TextAreaField>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 44437")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-end"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display = {showSuccess ? "none" : "flex"}
            {...getOverrideProps(overrides, "Frame 44577")}
          >
            <Button
              width="132.5px"
              height="36.5px"
              border="1.25px SOLID rgba(174,179,183,1)"
              borderRadius="5.25px"
              padding="7.5px 15px 7.5px 15px"
              shrink="0"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              backgroundColor="rgba(246,114,35,1)"
              size="small"
              isDisabled={false}
              variation="default"
              children="Löschen"
              color='#F5F5F5'
              onClick={() => {
                buttonFourTwoOneNineOneEightZeroThreeOnClick();
              }}
              {...getOverrideProps(overrides, "Button42191803")}
            ></Button>
            <Button
              width="unset"
              height="36px"
              shrink="0"
              backgroundColor="rgba(46,125,50,1)"
              size="default"
              isDisabled={false}
              variation="default"
              children="Änderungen Speichern"
              color='#F5F5F5'
              onClick={() => {
                buttonOnClickUpdate();
              }}
              {...getOverrideProps(overrides, "Button55151460")}
            ></Button>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display = {showSuccess ? "flex" : "none"}
            {...getOverrideProps(overrides, "FrameSuccess")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44576")}
            >
              <Checkcircleoutline
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "check_circle_outline")}
              ></Checkcircleoutline>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44575")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="700"
                color="rgba(255,255,255,1)"
                lineHeight="21px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="26px"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Die liste der Profile wurde erfolgreich bearbeitet. Aktualisieren Sie die Seite, um die Änderungen zu sehen."
                {...getOverrideProps(overrides, "PlaceholderTextNotification")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

