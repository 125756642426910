/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Button,
  Divider,
  Flex,
  Loader,
  Text,
  TextAreaField,
  TextField,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function Contact(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Divider51935412: {},
        "Frame 44499": {},
        Kontakt: {},
        "Frame 44491": {},
        "Fragen oder Anregungen? Bitte z\u00F6gern Sie nicht, uns zu kontaktieren. Wir antworten auf Ihre Anfrage schnellstm\u00F6glich.":
          {},
        "Frame 44490": {},
        contactForm_placeholder: {},
        "Frame 44492": {},
        "Frame 436": {},
        Divider49562204: {},
        "Frame 44476": {},
        Vorname: {},
        "Frame 4440850052811": {},
        TextField29766936: {},
        "Frame 44404": {},
        Nachname: {},
        "Frame 4440850052967": {},
        TextField29766938: {},
        "Frame 44405": {},
        "Frame 428": {},
        Email: {},
        "Frame 44409": {},
        TextField29766939: {},
        Frame50053758: {},
        "Frame 44406": {},
        Nachricht: {},
        "Frame 4440850053313": {},
        TextAreaField: {},
        "Frame 44407": {},
        Button: {},
        Loader: {},
        "Frame 44382": {},
        "Frame 44475": {},
        Frame50051127: {},
        Layout: {},
        Contact: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Divider51935412: {},
        "Frame 44499": { padding: "10px 50px 10px 50px" },
        Kontakt: { fontSize: "32px" },
        "Frame 44491": {},
        "Fragen oder Anregungen? Bitte z\u00F6gern Sie nicht, uns zu kontaktieren. Wir antworten auf Ihre Anfrage schnellstm\u00F6glich.":
          { fontSize: "12px", lineHeight: "18px" },
        "Frame 44490": {},
        contactForm_placeholder: { fontSize: "12px" },
        "Frame 44492": {},
        "Frame 436": { shrink: "0" },
        Divider49562204: {},
        "Frame 44476": {
          shrink: "1",
          padding: "0px 13px 0px 13px",
          display: "none",
          grow: "1",
          basis: "0",
        },
        Vorname: { fontSize: "12px", fontWeight: "500", lineHeight: "18px" },
        "Frame 4440850052811": {},
        TextField29766936: {},
        "Frame 44404": {
          gap: "4px",
          height: "unset",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "stretch",
        },
        Nachname: { fontSize: "12px", fontWeight: "500", lineHeight: "18px" },
        "Frame 4440850052967": {},
        TextField29766938: {},
        "Frame 44405": {
          gap: "4px",
          justifyContent: "center",
          alignItems: "center",
        },
        "Frame 428": { height: "55px" },
        Email: {
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "18px",
          alignSelf: "stretch",
        },
        "Frame 44409": { shrink: "1", grow: "1", basis: "0" },
        TextField29766939: {},
        Frame50053758: { padding: "8px 0px 8px 0px" },
        "Frame 44406": { height: "67px" },
        Nachricht: { fontSize: "12px", fontWeight: "500", lineHeight: "18px" },
        "Frame 4440850053313": {
          height: "unset",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        TextAreaField: {},
        "Frame 44407": { height: "127px", padding: "8px 0px 8px 0px" },
        Button: {},
        Loader: {},
        "Frame 44382": { padding: "16px 0px 16px 0px" },
        "Frame 44475": { gap: "10px", shrink: "0" },
        Frame50051127: {
          gap: "16px",
          direction: "column",
          shrink: "0",
          padding: "0px 0px 0px 0px",
        },
        Layout: { padding: "0px 20px 0px 20px" },
        Contact: { width: "454px", height: "unset" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="24px"
      direction="row"
      width="1275px"
      height="616px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="32px 0px 32px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "Contact")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 200px 10px 200px"
          display="none"
          {...getOverrideProps(overrides, "Frame 44499")}
        >
          <Divider
            width="unset"
            shrink="0"
            alignSelf="stretch"
            size="default"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider51935412")}
          ></Divider>
        </Flex>
        <Flex
          gap="33px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 25px 0px 25px"
          display="flex"
          {...getOverrideProps(overrides, "Frame50051127")}
        >
          <Flex
            gap="12px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="10px 10px 0px 0px"
            padding="20px 0px 20px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 436")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 5px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44491")}
            >
              <Text
                fontFamily="Poppins"
                fontSize="38px"
                fontWeight="600"
                color="rgba(246,114,35,1)"
                lineHeight="45px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Kontakt"
                {...getOverrideProps(overrides, "Kontakt")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 20px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44490")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="500"
                color="rgba(245,245,245,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Fragen oder Anregungen? Bitte zögern Sie nicht, uns zu kontaktieren. Wir antworten auf Ihre Anfrage schnellstmöglich."
                {...getOverrideProps(
                  overrides,
                  "Fragen oder Anregungen? Bitte z\u00F6gern Sie nicht, uns zu kontaktieren. Wir antworten auf Ihre Anfrage schnellstm\u00F6glich."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44492")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="500"
                color="rgba(245,245,245,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="newsletter_placeholder"
                {...getOverrideProps(overrides, "contactForm_placeholder")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="65px 13px 65px 13px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44476")}
          >
            <Divider
              height="unset"
              grow="1"
              shrink="1"
              basis="0"
              size="small"
              orientation="vertical"
              {...getOverrideProps(overrides, "Divider49562204")}
            ></Divider>
          </Flex>
          <Flex
            gap="17px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44475")}
          >
            <Flex
              gap="24px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 428")}
            >
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="74px"
                justifyContent="flex-start"
                alignItems="flex-start"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44404")}
              >
                <Flex
                  gap="10px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 4440850052811")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(245,245,245,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Vorname"
                    {...getOverrideProps(overrides, "Vorname")}
                  ></Text>
                </Flex>
                <TextField
                  width="unset"
                  height="unset"
                  label="Vorname"
                  shrink="0"
                  alignSelf="stretch"
                  borderRadius="4px"
                  placeholder=""
                  size="small"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField29766936")}
                ></TextField>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44405")}
              >
                <Flex
                  gap="10px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  grow="1"
                  shrink="1"
                  basis="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "Frame 4440850052967")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(245,245,245,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Nachname"
                    {...getOverrideProps(overrides, "Nachname")}
                  ></Text>
                </Flex>
                <TextField
                  width="unset"
                  height="unset"
                  label="Nachname"
                  shrink="0"
                  alignSelf="stretch"
                  borderRadius="4px"
                  placeholder=""
                  size="small"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField29766938")}
                ></TextField>
              </Flex>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="77px"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44406")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 44409")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Email"
                  {...getOverrideProps(overrides, "Email")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame50053758")}
              >
                <TextField
                  width="unset"
                  height="33px"
                  label="Email "
                  shrink="0"
                  alignSelf="stretch"
                  borderRadius="4px"
                  placeholder=""
                  size="small"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField29766939")}
                ></TextField>
              </Flex>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44407")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="31px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Frame 4440850053313")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(245,245,245,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Nachricht"
                  {...getOverrideProps(overrides, "Nachricht")}
                ></Text>
              </Flex>
              <TextAreaField
                width="unset"
                height="unset"
                label="Nachricht"
                shrink="0"
                alignSelf="stretch"
                borderRadius="4px"
                placeholder=""
                size="small"
                isDisabled={false}
                labelHidden={true}
                variation="default"
                {...getOverrideProps(overrides, "TextAreaField")}
              ></TextAreaField>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 44382")}
            >
              <Button
                width="unset"
                height="unset"
                borderRadius="10px"
                shrink="0"
                size="small"
                isDisabled={false}
                variation="primary"
                children="Nachricht senden "
                {...getOverrideProps(overrides, "Button")}
              ></Button>
              <Loader
                shrink="0"
                size="large"
                variation="circular"
                {...getOverrideProps(overrides, "Loader")}
              ></Loader>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
