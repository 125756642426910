import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import React from 'react';
import config from '../aws-exports';
import PriceTable from 'ui-components/PriceTable';
import Notification from 'ui-components/Notification';

Amplify.configure(config);

const ContactHelpWidget = () => {
    const infoText = (
        <div className='p-1'>
         <div>   Für eine schnellere Antwort können Sie uns gerne eine Nachricht über WhatsApp an die Nummer 004915150587612 senden. </div>
            <div className='mt-5'>Alternativ können Sie uns auch per E-Mail unter info@optimaticleads.com erreichen.</div> 
            <div className='mt-5 '>  Wir freuen uns darauf, von Ihnen zu hören und Ihnen weiterzuhelfen!</div> 
        </div>
    );

    return (
        <div style={{ backgroundColor: '#002640' }} className="flex flex-col items-center justify-top min-h-screen mt-10">
            <Notification
                overrides={{
                    Notification: {
                        width: "100%",
                    },
                    Layout: {
                        maxWidth: '800px',
                    },
                    notification_placeholder: {
                        children: infoText,
                    }
                }}
                info="info"
            />
        </div>
    );
};

export default ContactHelpWidget;
