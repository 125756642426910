/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { deleteIgAccounts } from "../graphql/mutations";
import MyIcon from "./MyIcon";
const client = generateClient();
export default function ShowIGAccount(props) {
  const { igAccounts, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Benutzername: {},
        "Frame 44413": {},
        Benutzername_placeholder: {},
        "Frame 44412": {},
        "Frame 44411": {},
        MyIcon: {},
        "Frame 44410": {},
        Layout: {},
        ShowIGAccount: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Benutzername: {},
        "Frame 44413": { height: "36px", shrink: "0" },
        Benutzername_placeholder: {},
        "Frame 44412": { height: "36px", shrink: "0" },
        "Frame 44411": {
          gap: "8px",
          direction: "column",
          width: "197px",
          height: "unset",
        },
        MyIcon: {},
        "Frame 44410": { shrink: "0" },
        Layout: {
          justifyContent: "space-between",
          padding: "12px 20px 12px 20px",
        },
        ShowIGAccount: { width: "421px", padding: "4px 0px 4px 0px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const frameFourFourFourOneZeroOnClick = async () => {
    await client.graphql({
      query: deleteIgAccounts.replaceAll("__typename", ""),
      variables: {
        input: {
          id: igAccounts?.id,
        },
      },
    });
  };
  return (
    <Flex
      gap="20px"
      direction="row"
      width="713px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="20px 0px 20px 0px"
      backgroundColor="rgba(0,38,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "ShowIGAccount")}
      {...rest}
    >
      <Flex
        gap="25px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        boxShadow="0px 4px 12px rgba(0.05000000074505806, 0.10000000149011612, 0.15000000596046448, 0.25)"
        borderRadius="4px"
        padding="12px 25px 12px 25px"
        display="flex"
        {...getOverrideProps(overrides, "Layout")}
      >
        <Flex
          gap="12px"
          direction="row"
          width="496px"
          height="36px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 44411")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            border="1px SOLID rgba(245,245,245,1)"
            borderRadius="10px"
            padding="0px 7px 0px 7px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44413")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(245,245,245,1)"
              lineHeight="21px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Benutzername"
              {...getOverrideProps(overrides, "Benutzername")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="10px"
            padding="0px 8px 0px 8px"
            backgroundColor="rgba(245,245,245,1)"
            display="flex"
            {...getOverrideProps(overrides, "Frame 44412")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(0,0,0,0.87)"
              lineHeight="21px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={igAccounts?.username}
              {...getOverrideProps(overrides, "Benutzername_placeholder")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          onClick={() => {
            frameFourFourFourOneZeroOnClick();
          }}
          {...getOverrideProps(overrides, "Frame 44410")}
        >
          <MyIcon
            width="30px"
            height="30px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            borderRadius="6px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(198,40,40,1)"
            type="delete"
            {...getOverrideProps(overrides, "MyIcon")}
          ></MyIcon>
        </Flex>
      </Flex>
    </Flex>
  );
}
