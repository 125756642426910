/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon, View } from "@aws-amplify/ui-react";
export default function Login(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="35px"
      height="35px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Login")}
      {...rest}
    >
      <View
        padding="0px 0px 0px 0px"
        width="35px"
        height="35px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        {...getOverrideProps(overrides, "Group5723980")}
      >
        <Icon
          width="35px"
          height="35px"
          viewBox={{ minX: 0, minY: 0, width: 35, height: 35 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector5723981")}
        ></Icon>
        <Icon
          width="35px"
          height="35px"
          viewBox={{ minX: 0, minY: 0, width: 35, height: 35 }}
          paths={[]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector5723982")}
        ></Icon>
      </View>
      <View
        padding="0px 0px 0px 0px"
        width="29.17px"
        height="26.25px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="12.5%"
        bottom="12.5%"
        left="8.33%"
        right="8.33%"
        {...getOverrideProps(overrides, "Group5723983")}
      >
        <Icon
          width="29.17px"
          height="26.25px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 29.16666603088379,
            height: 26.25,
          }}
          paths={[
            {
              d: "M12.1042 6.85417C11.5354 7.42292 11.5354 8.32708 12.1042 8.89583L14.875 11.6667L1.45833 11.6667C0.65625 11.6667 0 12.3229 0 13.125C0 13.9271 0.65625 14.5833 1.45833 14.5833L14.875 14.5833L12.1042 17.3542C11.5354 17.9229 11.5354 18.8271 12.1042 19.3958C12.6729 19.9646 13.5771 19.9646 14.1458 19.3958L19.3812 14.1604C19.95 13.5917 19.95 12.6729 19.3812 12.1042L14.1458 6.85417C13.5771 6.28542 12.6729 6.28542 12.1042 6.85417ZM26.25 23.3333L16.0417 23.3333C15.2396 23.3333 14.5833 23.9896 14.5833 24.7917C14.5833 25.5938 15.2396 26.25 16.0417 26.25L26.25 26.25C27.8542 26.25 29.1667 24.9375 29.1667 23.3333L29.1667 2.91667C29.1667 1.3125 27.8542 0 26.25 0L16.0417 0C15.2396 0 14.5833 0.65625 14.5833 1.45833C14.5833 2.26042 15.2396 2.91667 16.0417 2.91667L26.25 2.91667L26.25 23.3333Z",
              fill: "rgba(245,245,245,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          {...getOverrideProps(overrides, "Vector5723984")}
        ></Icon>
      </View>
    </View>
  );
}
