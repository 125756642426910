/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getWaitingForConfirmation } from "../graphql/queries";
import { updateWaitingForConfirmation } from "../graphql/mutations";
const client = generateClient();
export default function WaitingForConfirmationUpdateForm(props) {
  const {
    id: idProp,
    waitingForConfirmation: waitingForConfirmationModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    account_owner: "",
    target_user_id: "",
    target_username: "",
    outreach_message: "",
    score: "",
    target_assumed_name: "",
  };
  const [account_owner, setAccount_owner] = React.useState(
    initialValues.account_owner
  );
  const [target_user_id, setTarget_user_id] = React.useState(
    initialValues.target_user_id
  );
  const [target_username, setTarget_username] = React.useState(
    initialValues.target_username
  );
  const [outreach_message, setOutreach_message] = React.useState(
    initialValues.outreach_message
  );
  const [score, setScore] = React.useState(initialValues.score);
  const [target_assumed_name, setTarget_assumed_name] = React.useState(
    initialValues.target_assumed_name
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = waitingForConfirmationRecord
      ? { ...initialValues, ...waitingForConfirmationRecord }
      : initialValues;
    setAccount_owner(cleanValues.account_owner);
    setTarget_user_id(cleanValues.target_user_id);
    setTarget_username(cleanValues.target_username);
    setOutreach_message(cleanValues.outreach_message);
    setScore(cleanValues.score);
    setTarget_assumed_name(cleanValues.target_assumed_name);
    setErrors({});
  };
  const [waitingForConfirmationRecord, setWaitingForConfirmationRecord] =
    React.useState(waitingForConfirmationModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getWaitingForConfirmation.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getWaitingForConfirmation
        : waitingForConfirmationModelProp;
      setWaitingForConfirmationRecord(record);
    };
    queryData();
  }, [idProp, waitingForConfirmationModelProp]);
  React.useEffect(resetStateValues, [waitingForConfirmationRecord]);
  const validations = {
    account_owner: [],
    target_user_id: [],
    target_username: [],
    outreach_message: [],
    score: [],
    target_assumed_name: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          account_owner: account_owner ?? null,
          target_user_id: target_user_id ?? null,
          target_username: target_username ?? null,
          outreach_message: outreach_message ?? null,
          score: score ?? null,
          target_assumed_name: target_assumed_name ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateWaitingForConfirmation.replaceAll("__typename", ""),
            variables: {
              input: {
                id: waitingForConfirmationRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "WaitingForConfirmationUpdateForm")}
      {...rest}
    >
      <TextField
        label="Account owner"
        isRequired={false}
        isReadOnly={false}
        value={account_owner}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner: value,
              target_user_id,
              target_username,
              outreach_message,
              score,
              target_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.account_owner ?? value;
          }
          if (errors.account_owner?.hasError) {
            runValidationTasks("account_owner", value);
          }
          setAccount_owner(value);
        }}
        onBlur={() => runValidationTasks("account_owner", account_owner)}
        errorMessage={errors.account_owner?.errorMessage}
        hasError={errors.account_owner?.hasError}
        {...getOverrideProps(overrides, "account_owner")}
      ></TextField>
      <TextField
        label="Target user id"
        isRequired={false}
        isReadOnly={false}
        value={target_user_id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              target_user_id: value,
              target_username,
              outreach_message,
              score,
              target_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.target_user_id ?? value;
          }
          if (errors.target_user_id?.hasError) {
            runValidationTasks("target_user_id", value);
          }
          setTarget_user_id(value);
        }}
        onBlur={() => runValidationTasks("target_user_id", target_user_id)}
        errorMessage={errors.target_user_id?.errorMessage}
        hasError={errors.target_user_id?.hasError}
        {...getOverrideProps(overrides, "target_user_id")}
      ></TextField>
      <TextField
        label="Target username"
        isRequired={false}
        isReadOnly={false}
        value={target_username}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              target_user_id,
              target_username: value,
              outreach_message,
              score,
              target_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.target_username ?? value;
          }
          if (errors.target_username?.hasError) {
            runValidationTasks("target_username", value);
          }
          setTarget_username(value);
        }}
        onBlur={() => runValidationTasks("target_username", target_username)}
        errorMessage={errors.target_username?.errorMessage}
        hasError={errors.target_username?.hasError}
        {...getOverrideProps(overrides, "target_username")}
      ></TextField>
      <TextField
        label="Outreach message"
        isRequired={false}
        isReadOnly={false}
        value={outreach_message}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              target_user_id,
              target_username,
              outreach_message: value,
              score,
              target_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.outreach_message ?? value;
          }
          if (errors.outreach_message?.hasError) {
            runValidationTasks("outreach_message", value);
          }
          setOutreach_message(value);
        }}
        onBlur={() => runValidationTasks("outreach_message", outreach_message)}
        errorMessage={errors.outreach_message?.errorMessage}
        hasError={errors.outreach_message?.hasError}
        {...getOverrideProps(overrides, "outreach_message")}
      ></TextField>
      <TextField
        label="Score"
        isRequired={false}
        isReadOnly={false}
        value={score}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              target_user_id,
              target_username,
              outreach_message,
              score: value,
              target_assumed_name,
            };
            const result = onChange(modelFields);
            value = result?.score ?? value;
          }
          if (errors.score?.hasError) {
            runValidationTasks("score", value);
          }
          setScore(value);
        }}
        onBlur={() => runValidationTasks("score", score)}
        errorMessage={errors.score?.errorMessage}
        hasError={errors.score?.hasError}
        {...getOverrideProps(overrides, "score")}
      ></TextField>
      <TextField
        label="Target assumed name"
        isRequired={false}
        isReadOnly={false}
        value={target_assumed_name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              account_owner,
              target_user_id,
              target_username,
              outreach_message,
              score,
              target_assumed_name: value,
            };
            const result = onChange(modelFields);
            value = result?.target_assumed_name ?? value;
          }
          if (errors.target_assumed_name?.hasError) {
            runValidationTasks("target_assumed_name", value);
          }
          setTarget_assumed_name(value);
        }}
        onBlur={() =>
          runValidationTasks("target_assumed_name", target_assumed_name)
        }
        errorMessage={errors.target_assumed_name?.errorMessage}
        hasError={errors.target_assumed_name?.hasError}
        {...getOverrideProps(overrides, "target_assumed_name")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || waitingForConfirmationModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || waitingForConfirmationModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
