import { generateClient } from 'aws-amplify/api';
import { MainAppNavigation } from 'ui-components';
import { listProfiles } from '../graphql/queries';
import { useNavigate } from 'react-router-dom';
import {  withAuthenticator } from '@aws-amplify/ui-react';
import React, { useState, useEffect ,useRef} from 'react';
import TargetGroupDecisionWidget from 'ui-components/TargetGroupDecisionWidget';
//import Outputmessage from 'ui-components/Outputmessage';
//import axios from 'axios'; // Ensure you have axios installed
import { Amplify } from 'aws-amplify';
import config from '../aws-exports';
//import { listIgAccounts, getIgAccounts } from "../graphql/queries";
import Notification from 'ui-components/Notification';
import 'App.css' ;
import Layout from './SettingsWrapper';
import { DashboardCollection } from 'ui-components';
import { Dashboard } from 'ui-components';
import { Accordion, ThemeProvider, createTheme } from '@aws-amplify/ui-react';



Amplify.configure(config);
const client = generateClient({ authMode: "userPool"});

const AppMainMenu = ({signOut,user}) => {

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingUserDecision, setisLoadingUserDecision] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState('');
  const [selectedOptionSearchSource, setSelectedOptionSearchSource] = useState('');
  const [selectedAutomationOption, setSelectedAutomationOption] = useState('');
  const [userID , setUserID] = useState('');
  const [textField, setTextField] = useState('');
  const [blacklist, setBlacklist] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedCompanyDescription, setSelectedCompanyDescription] = useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const apiResponseRef = useRef(null);
  const [isErrorFromBatchDataRetrieval, setIsErrorFromBatchDataRetrieval] = useState(false);
  const [errorDetailsFromBatchDataRetrieval, setErrorDetailsFromBatchDataRetrieval] = useState('');

  //const [imgUrl , setImgUrl] = useState(null);
  const [outreachMessage , setOutreachMessage] = useState('');
  const outreachMessageRef = useRef(null);

  const [selectedProfileId, setSelectedProfileId] = useState('');
  const [selectedOptionGroup1, setSelectedOptionGroup1] = useState(''); // Default selected value for group 1
  const [selectedOptionGroup2, setSelectedOptionGroup2] = useState(''); // Default selected value for group 2
  const navigate = useNavigate();
  const [group92Style, setGroup92Style] = useState({});
  const [selectedOption, setSelectedOption] = useState('');
  const [InputFieldStyle, setInputFieldStyle] = useState({});
  const [incompleteInput, setIncompleteInput] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('Initial text or generated text here');
  const [AutomationOptionSoonAvailable, setAutomationOptionSoonAvailable] = useState(false);

  const theme = createTheme({
    name: 'Accordion-theme',
    tokens: {
      components: {
        accordion: {
          backgroundColor: '{colors.blue.10}',
          item: {
            trigger: {
              color: '{colors.blue.80}',
              _hover: {
                color: '{colors.blue.90}',
                backgroundColor: '{colors.blue.20}',
              },
            },
            content: {
              color: '{colors.blue.80}',
            },
          },
        },
      },
    },
  });




  const handleTextAreaChange = (event) => {
    setTextAreaValue(event.target.value);
  };

// useEffect to update the userID whenever the user updates
  useEffect(() => {
    if (user && user.username) {
      setUserID(user.username);
      //console.log("user updated:",user)
      //console.log("email",user.signInDetails.loginId)

    }
  }, [user]);



 //ProfileOptions is an array of arrays containing the profile names
  const profileOptions = profiles.map(profile => [profile.profile_name]);


  const fetchProfiles = async () => {

    try {
      //console.log("Fetching profiles..."); // Before the call
      const profileData = await client.graphql({
        query: listProfiles,
        authMode: "userPool"
      });
      //console.log("Profiles fetched:", profileData); // After receiving the data
      const profiles = profileData.data.listProfiles.items;
      setProfiles(profiles); // Store the entire profile objects
    } catch (err) {
      console.error('error fetching profiles:', err);
    }
    //console.log('Client in fetchProfiles' , client);

  };
    
  useEffect(() => {
    fetchProfiles();
  }, []);
  
  const handleProfileChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedProfile(selectedValue);
    // Find the selected profile object to retrieve its company_description
    const selectedProfileObj = profiles.find(profile => profile.profile_name === selectedValue);
    if (selectedProfileObj) {
      setSelectedCompanyDescription(selectedProfileObj.company_description);
      setSelectedProfileId(selectedProfileObj.id);
    }
  };

  const handleRadioChangeGroup1 = (event) => {
    setSelectedOptionGroup1(event.target.value);
  };
  
  const handleRadioChangeGroup2 = (event) => {
    setSelectedOptionGroup2(event.target.value);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const optionsMap = {
      'Einzelkonto': { searchSource: 'individual', option: 'Einzelkonto' },
      'Followers': { searchSource: 'followers', option: 'Followers' },
      'Following': { searchSource: 'following', option: 'Following' },
      'Ort': { searchSource: 'location', option: 'Ort' },
      'Hashtag (demnächst verfügbar)': { searchSource: 'hashtag', option: 'Hashtag' }
    };
  
    const { searchSource, option } = optionsMap[selectedValue] || {};
  
    if (searchSource && option) {
      setSelectedOptionSearchSource(searchSource);
      setSelectedOption(option);
    }
  };
  

  const handleSelectAutomationOption = (event) => {
    setSelectedAutomationOption(event.target.value);
  };

  const handleInputChange = (event) => {
    setTextField(event.target.value);
  };

  const handleInputChangeForBlacklist = (event) => {
    const raw_value = event.target.value;
    const newList = raw_value.split(',').map(item => item.trim());
    setBlacklist(newList);
  };

  const handleNavigateToTargetProfile = () => {
    if(apiResponse && apiResponse['username']) {
      const instagramUrl = `https://www.instagram.com/${apiResponse['username']}`;
      window.open(instagramUrl, '_blank', 'noopener,noreferrer');
    }
  };
  
  const handleNavigateToPendingMessages = () => {
    navigate('/app/messages');
  }


const handleBatchDataRetrieval = async () => {

    setIncompleteInput(false); // Reset the incomplete input state
    //setApiResponse(null); // Reset the apiResponse state
    setGroup92Style({}); // Reset the group 92 style
    setInputFieldStyle({}); // Reset the input field style
    setErrorDetailsFromBatchDataRetrieval(''); // Reset the error details
    setIsErrorFromBatchDataRetrieval(false); // Reset the error state
    const request_body = {
      "body": {
        "target_entity": textField,
        "account_owner": userID,
        "account_profile_id": selectedProfileId,
        "mode": selectedOptionSearchSource,
        "invocation_type":"initial",
        "filter_options": {
          "layer_1": {"is_private": selectedOptionGroup1},
          "layer_2": {"blacklist": blacklist},
          "layer_3": {"gender": selectedOptionGroup2},
        }
      }
    };
    // Check if target_entity, mode, and selectedProfileId are not empty
    if (!textField || !selectedOptionSearchSource || !selectedProfileId) {
      //console.log('Incomplete data provided');
      setIncompleteInput(true);
      return; // (<div>THIS IS ERROR</div>);//<IncompleteWidgetError/>;
    }
    else{

      if (selectedAutomationOption === 'Automatisiert (bald verfügbar)') {
        setAutomationOptionSoonAvailable(true);
        //console.log('Automated mode selected');
        return;
      }
      else{

      setIsLoadingProfile(true); // Set loading state to true
      //console.log("batch data retrieval : ", process.env.REACT_APP_IG_BATCH_RETRIEVAL_ENDPOINT)

    try {
      const response = await fetch(process.env.REACT_APP_IG_BATCH_RETRIEVAL_ENDPOINT, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(request_body),
      });
  
      const data = await response.json();
      setApiResponse(data);
      //console.log('API response rom handleBatchDataRetrieval:', data); // Log the response data

      if ('error' in data) {
        setIsErrorFromBatchDataRetrieval(true);
        setErrorDetailsFromBatchDataRetrieval(data.error);
        setInputFieldStyle({
          backgroundColor: 'rgba(198, 40, 40, 0.5)',
        });
      }
    } 
    catch (error) {
      console.error('Error:', error);
    }
    finally {
      setIsLoadingProfile(false); // Set loading state to false
    }
  }}};


  const handleUserDecision = async ( action ) => {
    //reset the input field style

    //setApiResponse(null); // Reset the apiResponse state
    setErrorDetailsFromBatchDataRetrieval(''); // Reset the error details
    setIsErrorFromBatchDataRetrieval(false); // Reset the error state
    setisLoadingUserDecision(true); // Set loading state to true
    const request_body = {
      "body": {
        "action" : action,
        "target_entity": textField,
        "pk" : apiResponse.target_entity_id,

        "selected_profile_pk": apiResponse.pk,
        "selected_profile_username": apiResponse.username,

        "account_owner": userID,
        "account_profile_id": selectedProfileId,

        "mode": selectedOptionSearchSource,
        "invocation_type":"subsequent",

        "filter_options": {
          "layer_1": {"is_private": selectedOptionGroup1},
          "layer_2": {"blacklist": blacklist},
          "layer_3": {"gender": selectedOptionGroup2},
        }
      }
    };
    //console.log("sending request to userdecisionEndpoint :", request_body);
  
    // Check if target_entity, mode, and selectedProfileId are not empty

    //setIsLoadingProfile(true); // Set loading state to true
    //console.log("user decision endpoint : ", process.env.REACT_APP_IG_USER_DECISION_ENDPOINT)
    try {
      const response = await fetch(process.env.REACT_APP_IG_USER_DECISION_ENDPOINT, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(request_body),
      });
  
      const data = await response.json();
      //console.log('API response from handleUserDecision:', data); // Log the response data
      setApiResponse(data.body);
      //console.log("data",data.body)
      //console.log("total_processed",data.body.total_processed)

      if ('error' in data) {
        //console.log("error in data",data.error)
        setIsErrorFromBatchDataRetrieval(true);
        setErrorDetailsFromBatchDataRetrieval(data.error);
        setInputFieldStyle({
          backgroundColor: 'rgba(198, 40, 40, 0.5)',
        });
      }
      if (selectedOptionSearchSource === 'individual') {
        setApiResponse(null);
      }
     
      //setImgUrl(data['profile_pic_s3_link']);
    } 
    catch (error) {
      console.error('Error in user decision:', error);
    }
    finally {
      //setIsLoadingProfile(false); // Set loading state to false
      setisLoadingUserDecision(false);
    }
  };

// useEffect to scroll to apiResponse division when it has content
  useEffect(() => {
    if (apiResponse && apiResponseRef.current) {
      apiResponseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [apiResponse]);

//useEffect to log the apiResponse whenever it updates
useEffect(() => {
  if (apiResponse && apiResponse.body) {
    //console.log("api response updated", apiResponse.body.username);
  }
}, [apiResponse]);


useEffect(() => {
  //console.log("RENDERED")
  if (selectedOption === 'Einzelkonto') {
    setGroup92Style({
      pointerEvents: 'none',
      opacity: 0.3,
    });
  } else {
    // Resetting the style if necessary, or apply different styles for other options
    setGroup92Style({});
  }
}, [selectedOption]); // Dependency array ensures this effect runs when selectedOption changes


const TargetGroupDecisionWidgetWrapper = React.forwardRef((props, ref) => {
  // You can use the ref here to interact with the DOM directly, if necessary,
  // or just pass it to a child DOM element or another component that supports refs.
  return (
    <div className='w-full' ref={ref}>
      <TargetGroupDecisionWidget {...props} />
    </div>
  );
});

// Use TargetGroupDecisionWidgetWrapper in place of TargetGroupDecisionWidget in your render method.


const AppTutorial = () => {
  return (
    <>
    <div className='p-3' >Hier können Sie die Parameter der Suche anpassen, um Ihre Zielgruppe bestmöglich zu finden 
    
    </div>

    <div className='text-l p-3' > <p><b>• Unternehmensprofil</b></p>

    <div className='p-2' ><p className='p-1'>Die Liste der Unternehmensprofile, aus der Sie wählen können.</p>
    <p>•  Um ein Unternehmensprofil zu erstellen, navigieren Sie zu Einstellungen -&gt; Unternehmensprofil -&gt; Profil erstellen  </p>
     </div>
    </div>

    <div className='text-l p-3' > <p><b>• Prozessmodus</b></p>

    <div className='p-2' ><p className='p-1'> • Manuell : Sie können manuell festlegen, an welchen Benutzer die KI eine Nachricht sendet. </p>
    <p> • Automatisiert (bald verfügbar): Legen Sie automatisch eine Anzahl von Nutzern fest, die Sie erreichen möchten, und die KI erledigt den Rest.</p>   </div>
    </div>

    <div className='text-l p-3' > <p><b>• Ermittlungsmethodik</b></p>

    <div className='p-2' ><p className='p-2'>Entscheiden Sie, wie Sie Ihre Zielgruppe finden wollen. </p>
          <p className='p-1'>• Einzelnutzer: wenn Sie einen bestimmten Nutzer erreichen wollen</p>
          <p className='p-1'> • Follower/Following : Suchen Sie Ihre Zielgruppe unter den Followern oder Followern eines bestimmten Nutzers oder einer Seite</p>
          <p className='p-1'>• Ort : Suche nach Ihrer Zielgruppe anhand des Standorts</p>
          <p className='p-1'> • Hashtag : (bald verfügbar) : Suche nach Ihrer Zielgruppe anhand des Hashtags </p></div>
    </div>


    <div className='text-l p-3' > <p><b>• Eingabewert</b></p>

    <div className='p-2' ><p className='p-2'>Dieser Wert ist abhängig von Ihrer Wahl bei der Ermittlungsmethodk: </p>
          <p className='p-1'>• Für Einzelnutzer: der Benutzername des Nutzers</p>
          <p className='p-1'> • Für Follower & Follower: der Benutzername des Benutzers, von dem Sie Follower/Follower extrahieren möchten </p>
          <p className='p-1'>• Für Ort : der Name der Stadt</p>
          <p className='p-1'> • Für Hashtag: der Hashtag </p></div>
</div>


<div className='p-3' >Nach einem Klick auf weiter erscheint ein Widget, das potenzielle Profile anzeigt. Wenn Sie ein Profil akzeptieren, wird es an die KI weitergeleitet, um eine Nachricht zu erstellen. 

Die generierten Nachrichten finden Sie unter "Nachrichten" -&gt; "Austehend". Dort können Sie die Nachrichten überprüfen und an die jeweiligen Nutzer senden. </div>



    </>
  );
  }



  return ( //className="flex flex-col items-center justify-start min-h-screen pt-5"
    <div className="flex flex-col items-center justify-start min-h-screen"> 

<div className='' style={{ width: '100%', maxWidth: '1000px', }} >


    <ThemeProvider theme={theme} colorMode="light">
    <Accordion.Container >

    <Accordion.Item value="Tutorial">
        <Accordion.Trigger>
        💡Anleitung
          <Accordion.Icon />
        </Accordion.Trigger>
        <Accordion.Content> {AppTutorial()} </Accordion.Content>
      </Accordion.Item>


    </Accordion.Container>
  </ThemeProvider>

</div>
       <MainAppNavigation  overrides={{
        MainAppNavigation:{
          width: "100%",
          backgroundColor: '#002640',

        },
        Layout:{
          maxWidth: '1000px',

        },
        FrameLoader: {
          // if isloadingprofile is true, we display the loader, otherwise we display the button
          display: isLoadingProfile ? 'block' : 'none',
          //display: 'none',
          },
        middle:{
          style:group92Style,
        },
        SelectField42942239: {
          onChange: handleSelectChange,
          options: [
            ["Einzelkonto"],
            ["Followers"],
            ["Following"],
            ["Ort"],
            ["Hashtag (demnächst verfügbar)"],
          ],
          //defaultValue: ["Ich habe einen bestimmten Nutzer"], // Set default option here

          labelHidden: true,
          placeholder: "...",
          //label: "\u0000 \u0000 Ermittlungsmethodik",
        },
        
        SwitchField :{
          //make the font white
          backgroundcolor: 'white',
          style:{
            backgroundcolor: 'white',
          },
          label: "",
          padding: '10px',
        },
        Blacklist:{
          padding: '5px',
        },

        TextField46421777: { //wert
          onChange: handleInputChange,
         // backgroundColor: "rgba(0,255,255,0.9)",
          labelHidden: true,
          placeholder: "...",
          position : "relative",
          //label: "\u0000 \u0000 Eingabewertt",
        style:InputFieldStyle,
      },
          
        SelectField42942213: { //Profile Auswählen
          onChange: handleProfileChange,
          options: profileOptions, // Use the updated profileOptions
          //labelHidden: true,
          placeholder: "Unter Einstellungen Profil Hinzufügen",
          //label: "Profil",
          style:{
            color: "#808080",
          }
        },
        SelectField42942230: { //Prozess Modus umschalten
          onChange: handleSelectAutomationOption,
          labelHidden: true,
          placeholder: "...",
          options: [
            ["Manuell"],
            ["Automatisiert (bald verfügbar)"],
          ],
          //label: " \u0000 \u0000 Prozess Modus",
         // defaultValue: ["Manuell auswählen, an welche Nutzer eine Nachricht gesendet werden soll"], // Set default option here
          
        },


        Button:{
          className: 'hover-effect',
          onClick: handleBatchDataRetrieval,
          // if isloadingprofile is true, we display the loader, otherwise we display the button
          display: isLoadingProfile ? 'none' : 'block',
          style: {
            color :'white',
            cursor: "pointer",
          }},
          Radio_group_246451893:{
            name: "radio_group_2",
            onChange: handleRadioChangeGroup2,
            value : "female",
          },
          Radio_group_246451894:{
            name : "radio_group_2",
            onChange: handleRadioChangeGroup2,
            value : ""
          },
          Radio_group_252361063:{
            name : "radio_group_2",
            onChange: handleRadioChangeGroup2,
            value : "male"
          },
          Radio_group_146451867:{
            name : "radio_group_1",
            onChange: handleRadioChangeGroup1,
            value : "true",
          },
          Radio_group_146451868:{
            name : "radio_group_1",
            onChange: handleRadioChangeGroup1,
            value : "false"
          },
          Radio_group_146451869:{
            name : "radio_group_1",
            onChange: handleRadioChangeGroup1,
            value : ""
          },
          TextField46451917:{
            onChange: handleInputChangeForBlacklist,
          }
          
      }} />  
{AutomationOptionSoonAvailable &&
  
  <div>
      <Notification overrides={{
        Notification :{
          width:"100%",
        },
        notification_placeholder:{
          children : "Automation ist noch nicht verfügbar. Bitte wählen Sie Manuell aus." ,
        }
      }} info="error"
      />
      
      </div>
  }

{apiResponse && 
      <TargetGroupDecisionWidgetWrapper  ref={apiResponseRef} user={user} overrides={{
        TargetGroupDecisionWidget:{
          width: "100%",
        },
        Layout:{
          maxWidth: '1000px',
        },
        placeholder_mode:{
          children: selectedOptionSearchSource
        },
        placeholder_process:{
          children: apiResponse.total_processed ? apiResponse.total_processed : "-",
        },

        ci_ph :{
          children:  apiResponse.current_item_index !== undefined && apiResponse.current_item_index !== null
          ? `${(+apiResponse.current_item_index + 1).toFixed(0)}`
          : "-",
        },
        bs_ph:{
          children: apiResponse.batch_size ? apiResponse.batch_size : "-",
        },

        // Group_88:{
        //   display: isLoadingUserDecision ? 'none' : 'block',

        // },
        FrameReject:{
          display: isLoadingUserDecision ? 'none' : 'block',

        },
        FrameAccept:{
          display: isLoadingUserDecision ? 'none' : 'block',

        },
        Loader:{
          display: isLoadingUserDecision ? 'block' : 'none',
          
        },

        MyIcon50671378:{//Confirmation button
          
          className: 'hover-effect',
          style:{
            cursor: 'pointer',
          },
          onClick: () => handleUserDecision('accept'),
        },
        MyIcon50671376:{ //rejection button
          className: 'hover-effect',
          style:{
            cursor: 'pointer',
          },
          onClick: () => handleUserDecision('reject'),
        },
        /*
          Vector46311553: {
            style:{
                //pointer
                cursor: 'pointer',
    
            }
          },
          */
          Frame :{
            style:{
              //pointer
              cursor: 'pointer',
            }
          ,
          },
          /*
          SVGRepo_iconCarrier46481697 :{
            style:{
              //pointer
              cursor: 'pointer',
            }
          },*/
    
        Rectangle_1169 : {
          src : apiResponse.profile_pic_s3_link ? apiResponse.profile_pic_s3_link  : 'https://placehold.co/178x171?text=Profilbild',
          alt : "profile picture",
          onClick: handleNavigateToTargetProfile,
          style:{
            //pointer
            cursor: 'pointer',
          
          overflow: 'hidden',
          }},

        /*
        biography:{
          children: apiResponse['biography'],
        },
        */
        user_name_ph:{

            children: apiResponse.username ? String(apiResponse.username) : "-",
            onClick: handleNavigateToTargetProfile,
            color: 'RGB(25, 118, 210)',
            style: {
              textDecoration: 'underline', // Underlines the text
              cursor: 'pointer', // Changes the cursor to a pointer on hover
            },
            
          },
          is_private_ph:{
            
            children: apiResponse.is_private !== undefined && apiResponse.is_private !== null
            ? (apiResponse.is_private === true || apiResponse.is_private === 'true' ? "privat" : "öffentlich")
            : "-",
          }
          ,
          is_verified_ph:{
            
            children:  apiResponse.is_verified !== undefined && apiResponse.is_verified !== null
            ? (apiResponse.is_verified === true || apiResponse.is_verified === 'true' ? "Ja" : "Nein")
            : "-", 
          }
          ,
          full_name_ph:{
            children: apiResponse.full_name ? String(apiResponse.full_name) : "-",
            //readOnly: true,
          },
          //we need to make the next contained in the frame
          style:{
            //pointer
            cursor: 'pointer',
          },

          /*
          Button42902162 :{
    
            onClick: handleGenerateOutreachMessage,
            style: {
              color :'white',
              cursor: "pointer",
            }
          },
          */
          label42902164 :{
            children: isLoadingUserDecision ? '  Lädt...  ': 'Generate Message',
          },
          Button:{
            onClick: handleNavigateToPendingMessages,
            style:{
              cursor: 'pointer',
            
            }
          }
      }}/>
}
<div className='pt-5'>
      {/* Your component's existing markup */}

      {incompleteInput &&  
      <div>
      <Notification overrides={{
        Notification :{
          width:"95%",
        },
        notification_placeholder:{
          children : "Die Anfrage ist unvollständig. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut." ,
        }
      }} info="error"
      />
      
      
      </div>
      }
      {isErrorFromBatchDataRetrieval &&  
      <div>
      <Notification overrides={{
        Notification :{
          width:"95%",
        },
        notification_placeholder:{
          children : errorDetailsFromBatchDataRetrieval ? errorDetailsFromBatchDataRetrieval : "",
        }
      }} info="error"
      />
      
      
      </div>
      }

    </div>
      </div>
  );
};

export default withAuthenticator(AppMainMenu);
//export default withauthenAppWithAuthenticator;
// export default function AppInterface() {
//   return (
//     <Authenticator>
//       <AppInterface />
//     </Authenticator>
//   );
// }